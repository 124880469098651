import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";

import { ErrorMessage, EmptyLabel } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconLeftCaret } from "../../../helpers/iconStyles";

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeTC: false,
      errors: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.agreeTC, _get(this.state, "agreeTC", false))) {
      const { intl } = this.props;
      let errors = {};

      if (_get(this.state, "agreeTC", false) === false) {
        errors["agreeTC"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}" }, {
          field: intl.formatMessage({ id: "this_box", defaultMessage: "this box" }),
        });
      }

      this.setState({ errors: errors });
    }
  }

  _handleNext = () => {
    const { setState, intl } = this.props;
    const { agreeTC } = this.state;

    let errors = {};

    if (agreeTC === false) {
      errors["agreeTC"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}" }, {
        field: intl.formatMessage({ id: "this_box", defaultMessage: "this box" }),
      });
    }

    if (!_isEmpty(errors)) {
      this.setState({ errors: errors });
      return false;
    }

    if (typeof setState === "function") {
      setState({ showForm: false, showPreview: false, showSuccess: true });
    }
  }

  _renderProjectName = () => {
    const { formData: { projectName } } = this.props;

    return (
      <div>
        <div className="bg-primary py-5 px-10">
          <h6 className="heading-05 text-white">
            <FormattedMessage id="app_sell_leads.title.targeting" defaultMessage="Targeting" />
          </h6>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.project_name" defaultMessage="Project Name" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{(projectName || <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderUploadDataSample = () => {
    const { formData: { fileName } } = this.props;

    return (
      <div>
        <div className="bg-primary py-5 px-10">
          <h6 className="heading-05 text-white">
            <FormattedMessage id="app_sell_leads.title.upload_lead_sample" defaultMessage="Upload Lead Sample" />
          </h6>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.file_name" defaultMessage="File Name" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{(fileName || <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderDataFields = () => {
    const { formData: { selDataFieldNames } } = this.props;

    return (
      <div>
        <div className="bg-primary py-5 px-10">
          <h6 className="heading-05 text-white">
            <FormattedMessage id="app_sell_leads.title.data_fields" defaultMessage="Data Fields" />
          </h6>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.fields" defaultMessage="Fields" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{(selDataFieldNames || []).join(", ")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderTargeting = () => {
    const { formData: { region, countriesToTarget, languages, industriesInclude, otherIndustry, industriesExclude, employeeSizes, LeadsPerOrg, jobFunctions, jobLevels, jobTitleToInclude, jobTitleToExclude, jobKeywords } } = this.props;

    return (
      <div>
        <div className="bg-primary py-5 px-10">
          <h6 className="heading-05 text-white">
            <FormattedMessage id="app_sell_leads.title.targeting" defaultMessage="Targeting" />
          </h6>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.region" defaultMessage="Region" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(region, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.countries_to_target" defaultMessage="Countries to Target" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_map(countriesToTarget, "name").join(", ")}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.languages" defaultMessage="Languages" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(languages, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.industries_include" defaultMessage="Industries Include" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(industriesInclude, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.other_industries" defaultMessage="Other Industries" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{(otherIndustry || <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.industries_exclude" defaultMessage="Industries Exclude" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(industriesExclude, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.employee_sizes" defaultMessage="Employee Sizes" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(employeeSizes, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.leads_per_org_per_country" defaultMessage="Leads per Org per Country" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(LeadsPerOrg, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.job_functions" defaultMessage="Job Functions" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(jobFunctions, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.job_levels" defaultMessage="Job Levels" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(jobLevels, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.job_titles_to_include" defaultMessage="Job Titles to Include" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(jobTitleToInclude, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.job_titles_to_exclude" defaultMessage="Job Titles to Exclude" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(jobTitleToExclude, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-lg-4 col-5">
            <div className="table-preview-left">
              <p><FormattedMessage id="app_sell_leads.job_keywords" defaultMessage="Job Keywords" /></p>
            </div>
          </div>
          <div className="col-lg-8 col-7">
            <div className="table-preview-right">
              <p>{_get(jobKeywords, "name", <EmptyLabel />)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { setState } = this.props;
    const { agreeTC, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-8">
          <h4 className="heading-03 text-primary fw-medium mt-10 mb-20">
            <FormattedMessage id="app_sell_leads.preview.page_title" defaultMessage="Preview" />
          </h4>
          <div className="card p-15">
            <div className="table-preview">
              {this._renderProjectName()}
              {this._renderUploadDataSample()}
              {this._renderDataFields()}
              {this._renderTargeting()}
            </div>
            <div className="mt-25 position-relative">
              <button className="btn btn-md btn-primary" onClick={() => setState({ showForm: true, showPreview: false, showSuccess: false })}>
                <FormattedMessage id="btn.back" defaultMessage="back" />
                <div className="img-fluid position-absolute top-50 start-0 translate-middle-y ms-20">
                  <IconLeftCaret height="17" width="17" color="#FFFFFF" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card p-15 mt-lg-55 mt-30">
            <div>
              <p><FormattedMessage id="app_sell_leads.preview.title" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua." /></p>
              <div className="form-check mt-36">
                <input
                  className="form-check-input border"
                  type="checkbox"
                  value={(agreeTC || false)}
                  id="flexCheckDefault"
                  checked={(agreeTC || false)}
                  onChange={(e) => {
                    const isChecked = _get(e, "currentTarget.checked", false);

                    this.setState({ agreeTC: isChecked });
                  }}
                />
                <label className="form-check-label heading-05" htmlFor="flexCheckDefault">
                  <FormattedMessage id="app_sell_leads.preview.sub_title" defaultMessage="By ticking the box, you acknowledge that if campaign assets and copy are not delivery on time, your campaign may be delayed by up to 10 bussiness days. *" />
                </label>
                {(errors.agreeTC || "") && (<ErrorMessage className="text-danger mt-5 text-capitalize-first" message={(errors.agreeTC || "")} />)}
              </div>
              <div className="mt-30 mb-15 ms-27">
                <button className="btn btn-md btn-primary" onClick={() => this._handleNext()}>
                  <FormattedMessage id="btn.submit" defaultMessage="submit" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(Preview));
