import { get as _get } from "lodash";

import api from "../helpers/api";

export const fetchUsers = (variables) => {

  return api().put("/user/get-users-list", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", [])
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching users."),
      data: {}
    };
  });
}

export const approveRejectSeller = (variables) => {

  return api().put("/user/approve-reject-seller", (variables || {})).then((res) => {
    return {
      flag: _get(res, "data.status", false),
      message: _get(res, "data.message", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.message", "Something went wrong while changing user's profile status."),
      data: {},
    };
  });
};

export const lockUnlockUser = (variables) => {

  return api().put("/user/lock-unlock-user", (variables || {})).then((res) => {
    return {
      flag: _get(res, "data.status", false),
      message: _get(res, "data.message", ""),
      data: _get(res, "data", {}),
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", false),
      message: _get(err, "response.data.message", "Something went wrong while changing user status."),
      data: {},
    };
  });
};