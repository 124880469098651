// Buy leads
export const FETCH_DROPDOWN_LOOKUP_REQUEST = "FETCH_DROPDOWN_LOOKUP_REQUEST";
export const FETCH_DROPDOWN_LOOKUP_SUCCESS = "FETCH_DROPDOWN_LOOKUP_SUCCESS";
export const FETCH_DROPDOWN_LOOKUP_ERROR = "FETCH_DROPDOWN_LOOKUP_ERROR";

export const fetchDropdownLookup = (payload) => {

  return ({
    type: FETCH_DROPDOWN_LOOKUP_REQUEST,
    payload
  });
}

export const RESET_DROPDOWN_LOOKUP_DATA = "RESET_DROPDOWN_LOOKUP_DATA";

export const resetDropdownLookupData = () => {
  return ({ type: RESET_DROPDOWN_LOOKUP_DATA });
}

export const ADD_BUY_LEADS_REQUEST = "ADD_BUY_LEADS_REQUEST";
export const ADD_BUY_LEADS_SUCCESS = "ADD_BUY_LEADS_SUCCESS";
export const ADD_BUY_LEADS_ERROR = "ADD_BUY_LEADS_ERROR";

export const addBuyLeadsRequest = (payload) => {

  return ({
    type: ADD_BUY_LEADS_REQUEST,
    payload
  });
}

export const RESET_BUY_LEADS_DATA = "RESET_BUY_LEADS_DATA";

export const resetBuyLeadsData = () => {
  return ({ type: RESET_BUY_LEADS_DATA });
}

export const FETCH_DASHBOARD_TOP_FILTERS_REQUEST = "FETCH_DASHBOARD_TOP_FILTERS_REQUEST";
export const FETCH_DASHBOARD_TOP_FILTERS_SUCCESS = "FETCH_DASHBOARD_TOP_FILTERS_SUCCESS";
export const FETCH_DASHBOARD_TOP_FILTERS_ERROR = "FETCH_DASHBOARD_TOP_FILTERS_ERROR";

export const fetchDashboardTopFilters = (payload) => {

  return ({
    type: FETCH_DASHBOARD_TOP_FILTERS_REQUEST,
    payload
  });
}

export const RESET_DASHBOARD_TOP_FILTERS_DATA = "RESET_DASHBOARD_TOP_FILTERS_DATA";

export const resetDashboardTopFiltersData = () => {
  return ({ type: RESET_DASHBOARD_TOP_FILTERS_DATA });
}

export const UPDATE_DASHBOARD_FILTERS_SELECTION = "UPDATE_DASHBOARD_FILTERS_SELECTION";
export const UpdateDashboardFilterSelection = (payload) => {

  return ({
    type: UPDATE_DASHBOARD_FILTERS_SELECTION,
    payload
  });
}

// Get Project Details with Audience Targeting Criteria
export const FETCH_PROJECT_DETAILS_REQUEST = "FETCH_PROJECT_DETAILS_REQUEST";
export const FETCH_PROJECT_DETAILS_SUCCESS = "FETCH_PROJECT_DETAILS_SUCCESS";
export const FETCH_PROJECT_DETAILS_ERROR = "FETCH_PROJECT_DETAILS_ERROR";

export const fetchProjectDetails = (payload) => {

  return ({
    type: FETCH_PROJECT_DETAILS_REQUEST,
    payload
  });
}

export const RESET_DASHBOARD_ERROR = "RESET_DASHBOARD_ERROR";

export const resetDashboardError = () => {
  return ({ type: RESET_DASHBOARD_ERROR });
}

export const SUBMIT_QUOTE_REQUEST = "SUBMIT_QUOTE_REQUEST";
export const SUBMIT_QUOTE_SUCCESS = "SUBMIT_QUOTE_SUCCESS";
export const SUBMIT_QUOTE_ERROR = "SUBMIT_QUOTE_ERROR";

export const submitQuoteRequest = (payload) => {

  return ({
    type: SUBMIT_QUOTE_REQUEST,
    payload
  });
}

export const RESET_SUBMIT_QUOTE_DATA = "RESET_SUBMIT_QUOTE_DATA";

export const resetSubmitQuoteData = () => {
  return ({ type: RESET_SUBMIT_QUOTE_DATA });
}

export const UPLOAD_READ_FILE_SUCCESS = "UPLOAD_READ_FILE_SUCCESS";

export const uploadReadFileSuccess = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_SUCCESS,
    payload
  });
}

export const UPLOAD_READ_FILE_ERROR = "UPLOAD_READ_FILE_ERROR";

export const uploadReadFileError = (payload) => {
  return ({
    type: UPLOAD_READ_FILE_ERROR,
    payload
  });
}

export const SAVE_UPLOAD_MAPPING_DATA_REQUEST = "SAVE_UPLOAD_MAPPING_DATA_REQUEST";
export const SAVE_UPLOAD_MAPPING_SUCCESS = "SAVE_UPLOAD_MAPPING_SUCCESS";
export const SAVE_UPLOAD_MAPPING_ERROR = "SAVE_UPLOAD_MAPPING_ERROR";

export const saveUploadMappingData = (payload) => {
  return ({
    type: SAVE_UPLOAD_MAPPING_DATA_REQUEST,
    payload
  });
}

export const RESET_DATA_MAPPING_ERROR = "RESET_DATA_MAPPING_ERROR";

export const resetDataMappingError = () => {
  return ({
    type: RESET_DATA_MAPPING_ERROR
  });
}

export const RESET_DATA_MAPPING_CONFIG = "RESET_DATA_MAPPING_CONFIG";

export const resetDataMappingConfig = () => {
  return ({
    type: RESET_DATA_MAPPING_CONFIG
  });
}

export const REMOVE_UPLOADED_FILE_REQUEST = "REMOVE_UPLOADED_FILE_REQUEST";
export const REMOVE_UPLOADED_FILE_SUCCESS = "REMOVE_UPLOADED_FILE_SUCCESS";
export const REMOVE_UPLOADED_FILE_ERROR = "REMOVE_UPLOADED_FILE_ERROR";

export const removeUploadedFile = (payload) => {
  return ({ type: REMOVE_UPLOADED_FILE_REQUEST, payload });
}

export const RESET_REMOVE_UPLOADED_FILE = "RESET_REMOVE_UPLOADED_FILE";

export const resetRemoveUploadedFile = () => {
  return ({
    type: RESET_REMOVE_UPLOADED_FILE,
  });
}

// Overall dashboard
export const FETCH_OVERALL_DASHBOARD_RECORDS_REQUEST = "FETCH_OVERALL_DASHBOARD_RECORDS_REQUEST";
export const FETCH_OVERALL_DASHBOARD_RECORDS_SUCCESS = "FETCH_OVERALL_DASHBOARD_RECORDS_SUCCESS";
export const FETCH_OVERALL_DASHBOARD_RECORDS_ERROR = "FETCH_OVERALL_DASHBOARD_RECORDS_ERROR";

export const fetchOverallDashboardRecords = (payload) => {

  return ({
    type: FETCH_OVERALL_DASHBOARD_RECORDS_REQUEST,
    payload
  });
}

export const FETCH_OVERALL_DASHBOARD_PROJECTS_REQUEST = "FETCH_OVERALL_DASHBOARD_PROJECTS_REQUEST";
export const FETCH_OVERALL_DASHBOARD_PROJECTS_SUCCESS = "FETCH_OVERALL_DASHBOARD_PROJECTS_SUCCESS";
export const FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR = "FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR";

export const fetchOverallDashboardProjects = (payload) => {

  return ({
    type: FETCH_OVERALL_DASHBOARD_PROJECTS_REQUEST,
    payload
  });
}

export const FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_REQUEST = "FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_REQUEST";
export const FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_SUCCESS = "FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_SUCCESS";
export const FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR = "FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR";

export const fetchDashboardProjectQuoteDetails = (payload) => {

  return ({
    type: FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_REQUEST,
    payload
  });
}

export const RESET_DASHBOARD_PROJECT_QUOTE_DETAILS = "RESET_DASHBOARD_PROJECT_QUOTE_DETAILS";

export const resetDashboardProjectQuote = () => {
  return ({
    type: RESET_DASHBOARD_PROJECT_QUOTE_DETAILS
  });
}

export const FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_REQUEST = "FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_REQUEST";
export const FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_SUCCESS = "FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_SUCCESS";
export const FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR = "FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR";

export const fetchDashboardProjectTimelineDetails = (payload) => {

  return ({
    type: FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_REQUEST,
    payload
  });
}

export const RESET_DASHBOARD_PROJECT_TIMELINE_DETAILS = "RESET_DASHBOARD_PROJECT_TIMELINE_DETAILS";

export const resetDashboardProjectTimeline = () => {
  return ({
    type: RESET_DASHBOARD_PROJECT_TIMELINE_DETAILS
  });
}

export const FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_REQUEST = "FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_REQUEST";
export const FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_SUCCESS = "FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_SUCCESS";
export const FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR = "FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR";

export const fetchDashboardCostPerLeadDetails = (payload) => {

  return ({
    type: FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_REQUEST,
    payload
  });
}

export const RESET_DASHBOARD_COST_PER_LEAD_DETAILS = "RESET_DASHBOARD_COST_PER_LEAD_DETAILS";

export const resetDashboardCostPerLeadDetails = () => {
  return ({
    type: RESET_DASHBOARD_COST_PER_LEAD_DETAILS
  });
}

export const RESET_OVERALL_DASHBOARD_RECORDS = "RESET_OVERALL_DASHBOARD_RECORDS";

export const resetOverallDashboardRecords = () => {
  return ({
    type: RESET_OVERALL_DASHBOARD_RECORDS
  });
}

export const RESET_OVERALL_DASHBOARD_PROJECTS = "RESET_OVERALL_DASHBOARD_PROJECTS";

export const resetOverallDashboardProjects = () => {
  return ({
    type: RESET_OVERALL_DASHBOARD_PROJECTS
  });
}

// Get Quote Details
export const FETCH_QUOTE_DETAILS_REQUEST = "FETCH_QUOTE_DETAILS_REQUEST";
export const FETCH_QUOTE_DETAILS_SUCCESS = "FETCH_QUOTE_DETAILS_SUCCESS";
export const FETCH_QUOTE_DETAILS_ERROR = "FETCH_QUOTE_DETAILS_ERROR";

export const fetchQuoteDetails = (payload) => {

  return ({
    type: FETCH_QUOTE_DETAILS_REQUEST,
    payload
  });
}

export const RESET_QUOTE_DETAILS_DATA = "RESET_QUOTE_DETAILS_DATA";

export const resetQuoteDetails = () => {
  return ({
    type: RESET_QUOTE_DETAILS_DATA
  });
}

// Set Quote Group ID in redux
export const SET_QUOTE_GROUP_ID = "SET_QUOTE_GROUP_ID";
export const setQuoteGroupID = (groupId) => {

  return ({
    type: SET_QUOTE_GROUP_ID,
    groupId
  });
}

// Set Quote ID in redux
export const SET_QUOTE_ID = "SET_QUOTE_ID";
export const setQuoteID = (quoteId) => {

  return ({
    type: SET_QUOTE_ID,
    quoteId
  });
}

export const UPDATE_QUOTE_STATUS_REQUEST = "UPDATE_QUOTE_STATUS_REQUEST";
export const UPDATE_QUOTE_STATUS_SUCCESS = "UPDATE_QUOTE_STATUS_SUCCESS";
export const UPDATE_QUOTE_STATUS_ERROR = "UPDATE_QUOTE_STATUS_ERROR";

export const updateQuoteStatus = (payload) => {

  return ({
    type: UPDATE_QUOTE_STATUS_REQUEST,
    payload
  });
}

export const RESET_UPDATE_QUOTE_STATUS_DATA = "RESET_UPDATE_QUOTE_STATUS_DATA";

export const resetUpdateQuoteStatusData = () => {
  return ({
    type: RESET_UPDATE_QUOTE_STATUS_DATA
  });
}

export const RESET_PROPS_AFTER_PROJECT_DELETED = "RESET_PROPS_AFTER_PROJECT_DELETED";
export const resetPropsAfterProjectDeleted = () => {

  return ({
    type: RESET_PROPS_AFTER_PROJECT_DELETED
  });
}

// Fetch Quote group list
export const FETCH_QUOTE_GROUP_LIST_REQUEST = "FETCH_QUOTE_GROUP_LIST_REQUEST";
export const FETCH_QUOTE_GROUP_LIST_SUCCESS = "FETCH_QUOTE_GROUP_LIST_SUCCESS";
export const FETCH_QUOTE_GROUP_LIST_ERROR = "FETCH_QUOTE_GROUP_LIST_ERROR";

export const fetchQuoteGroupList = (payload) => {
  return ({
    type: FETCH_QUOTE_GROUP_LIST_REQUEST,
    payload
  });
}

// Reset quote group list response
export const RESET_QUOTE_GROUP_LIST_DATA = "RESET_QUOTE_GROUP_LIST_DATA";
export const resetQuoteGroupListData = () => {
  return ({
    type: RESET_QUOTE_GROUP_LIST_DATA
  });
}

// Fetch Quote batch list
export const FETCH_QUOTE_BATCH_LIST_REQUEST = "FETCH_QUOTE_BATCH_LIST_REQUEST";
export const FETCH_QUOTE_BATCH_LIST_SUCCESS = "FETCH_QUOTE_BATCH_LIST_SUCCESS";
export const FETCH_QUOTE_BATCH_LIST_ERROR = "FETCH_QUOTE_BATCH_LIST_ERROR";

export const fetchQuoteBatchList = (payload) => {
  return ({
    type: FETCH_QUOTE_BATCH_LIST_REQUEST,
    payload
  });
}

// Reset quote batch list response
export const RESET_QUOTE_BATCH_LIST_DATA = "RESET_QUOTE_BATCH_LIST_DATA";
export const resetQuoteBatchListData = () => {
  return ({
    type: RESET_QUOTE_BATCH_LIST_DATA
  });
}

// Save upload lead data
export const SAVE_UPLOAD_LEAD_DATA_REQUEST = "SAVE_UPLOAD_LEAD_DATA_REQUEST";
export const SAVE_UPLOAD_LEAD_DATA_SUCCESS = "SAVE_UPLOAD_LEAD_DATA_SUCCESS";
export const SAVE_UPLOAD_LEAD_DATA_ERROR = "SAVE_UPLOAD_LEAD_DATA_ERROR";

export const saveUploadLeadData = (payload) => {
  return ({
    type: SAVE_UPLOAD_LEAD_DATA_REQUEST,
    payload
  });
}

// Reset Save upload lead data
export const RESET_UPLOAD_LEAD_DATA = "RESET_UPLOAD_LEAD_DATA";
export const resetUploadLeadData = () => {
  return ({
    type: RESET_UPLOAD_LEAD_DATA
  });
}

// Fetch Data upload history
export const FETCH_DATA_UPLOAD_HISTORY_REQUEST = "FETCH_DATA_UPLOAD_HISTORY_REQUEST";
export const FETCH_DATA_UPLOAD_HISTORY_SUCCESS = "FETCH_DATA_UPLOAD_HISTORY_SUCCESS";
export const FETCH_DATA_UPLOAD_HISTORY_ERROR = "FETCH_DATA_UPLOAD_HISTORY_ERROR";

export const fetchDataUploadHistory = (payload) => {
  return ({
    type: FETCH_DATA_UPLOAD_HISTORY_REQUEST,
    payload
  });
}

// Reset Data upload history data
export const RESET_DATA_UPLOAD_HISTORY_DATA = "RESET_DATA_UPLOAD_HISTORY_DATA";
export const resetDataUploadHistory = () => {
  return ({
    type: RESET_DATA_UPLOAD_HISTORY_DATA
  });
}

// Fetch Data summary log
export const FETCH_DATA_SUMMARY_LOG_REQUEST = "FETCH_DATA_SUMMARY_LOG_REQUEST";
export const FETCH_DATA_SUMMARY_LOG_SUCCESS = "FETCH_DATA_SUMMARY_LOG_SUCCESS";
export const FETCH_DATA_SUMMARY_LOG_ERROR = "FETCH_DATA_SUMMARY_LOG_ERROR";

export const fetchDataSummaryLog = (payload) => {
  return ({
    type: FETCH_DATA_SUMMARY_LOG_REQUEST,
    payload
  });
}

// Reset Data summary log
export const RESET_DATA_SUMMARY_LOG_DATA = "RESET_DATA_SUMMARY_LOG_DATA";
export const resetDataSummaryLog = () => {
  return ({
    type: RESET_DATA_SUMMARY_LOG_DATA
  });
}

// Fetch Data acceptance details
export const FETCH_DATA_ACCEPTANCE_DETAILS_REQUEST = "FETCH_DATA_ACCEPTANCE_DETAILS_REQUEST";
export const FETCH_DATA_ACCEPTANCE_DETAILS_SUCCESS = "FETCH_DATA_ACCEPTANCE_DETAILS_SUCCESS";
export const FETCH_DATA_ACCEPTANCE_DETAILS_ERROR = "FETCH_DATA_ACCEPTANCE_DETAILS_ERROR";

export const fetchDataAcceptanceDetails = (payload) => {
  return ({
    type: FETCH_DATA_ACCEPTANCE_DETAILS_REQUEST,
    payload
  });
}

// Reset Data acceptance details
export const RESET_DATA_ACCEPTANCE_DETAILS_DATA = "RESET_DATA_ACCEPTANCE_DETAILS_DATA";
export const resetDataAcceptanceDetails = () => {
  return ({
    type: RESET_DATA_ACCEPTANCE_DETAILS_DATA
  });
}

// Fetch Quote Batch Details
export const FETCH_UPLOADED_DATA_CHECK_FILE_REQUEST = "FETCH_UPLOADED_DATA_CHECK_FILE_REQUEST";
export const FETCH_UPLOADED_DATA_CHECK_FILE_SUCCESS = "FETCH_UPLOADED_DATA_CHECK_FILE_SUCCESS";
export const FETCH_UPLOADED_DATA_CHECK_FILE_ERROR = "FETCH_UPLOADED_DATA_CHECK_FILE_ERROR";

export const fetchUploadedDataCheckFile = (payload) => {
  return ({
    type: FETCH_UPLOADED_DATA_CHECK_FILE_REQUEST,
    payload
  });
}

export const RESET_UPLOADED_DATA_CHECK_FILE_DATA = "RESET_UPLOADED_DATA_CHECK_FILE_DATA";
export const resetUploadedDataCheckFileData = () => {
  return ({
    type: RESET_UPLOADED_DATA_CHECK_FILE_DATA
  });
}

// Update Data Check File Status
export const UPDATE_QUOTE_BATCH_UPLOAD_STATUS_REQUEST = "UPDATE_QUOTE_BATCH_UPLOAD_STATUS_REQUEST";
export const UPDATE_QUOTE_BATCH_UPLOAD_STATUS_SUCCESS = "UPDATE_QUOTE_BATCH_UPLOAD_STATUS_SUCCESS";
export const UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR = "UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR";

export const updateQuoteBatchUploadStatus = (payload) => {
  return ({
    type: UPDATE_QUOTE_BATCH_UPLOAD_STATUS_REQUEST,
    payload
  });
}

// Reset Data Check File Status details
export const RESET_UPDATE_QUOTE_BATCH_UPLOAD_STATUS_DATA = "RESET_UPDATE_QUOTE_BATCH_UPLOAD_STATUS_DATA";
export const resetUpdateQuoteBatchUploadStatusData = () => {
  return ({
    type: RESET_UPDATE_QUOTE_BATCH_UPLOAD_STATUS_DATA
  });
}

// Fetch Data Numbers
export const FETCH_DATA_NUMBERS_REQUEST = "FETCH_DATA_NUMBERS_REQUEST";
export const FETCH_DATA_NUMBERS_SUCCESS = "FETCH_DATA_NUMBERS_SUCCESS";
export const FETCH_DATA_NUMBERS_ERROR = "FETCH_DATA_NUMBERS_ERROR";

export const fetchDataNumbers = (payload) => {
  return ({
    type: FETCH_DATA_NUMBERS_REQUEST,
    payload
  });
}

// Reset Data Numbers
export const RESET_DATA_NUMBERS_DATA = "RESET_DATA_NUMBERS_DATA";
export const resetDataNumbersData = () => {
  return ({
    type: RESET_DATA_NUMBERS_DATA
  });
}

// Set IS Edit Quote Details
export const SET_EDIT_QUOTE_GROUP_ID = "SET_EDIT_QUOTE_GROUP_ID";
export const setEditQuoteGroupId = (payload) => {
  return ({
    type: SET_EDIT_QUOTE_GROUP_ID,
    payload
  });
}

// Reset IS Edit Quote Details
export const RESET_EDIT_QUOTE_GROUP_ID = "RESET_EDIT_QUOTE_GROUP_ID";
export const resetEditQuoteGroupId = (payload) => {
  return ({
    type: RESET_EDIT_QUOTE_GROUP_ID,
    payload
  });
}

// Discussion - Fetch old conversion
export const FETCH_DISCUSSION_MESSAGES_REQUEST = "FETCH_DISCUSSION_MESSAGES_REQUEST";
export const FETCH_DISCUSSION_MESSAGES_SUCCESS = "FETCH_DISCUSSION_MESSAGES_SUCCESS";
export const FETCH_DISCUSSION_MESSAGES_ERROR = "FETCH_DISCUSSION_MESSAGES_ERROR";

export const fetchDiscussionMessages = (payload) => {
  return ({
    type: FETCH_DISCUSSION_MESSAGES_REQUEST,
    payload
  });
}

// Discussion - Add new message
export const ADD_DISCUSSION_MESSAGE_REQUEST = "ADD_DISCUSSION_MESSAGE_REQUEST";
export const ADD_DISCUSSION_MESSAGE_SUCCESS = "ADD_DISCUSSION_MESSAGE_SUCCESS";
export const ADD_DISCUSSION_MESSAGE_ERROR = "ADD_DISCUSSION_MESSAGE_ERROR";

export const AddDiscussionMessage = (payload) => {
  return ({
    type: ADD_DISCUSSION_MESSAGE_REQUEST,
    payload
  });
}

// Discussion - reset errors
export const RESET_DISCUSSION_MESSAGE_ERRORS = "RESET_DISCUSSION_MESSAGE_ERRORS";
export const resetDiscussionMessageErrors = () => {
  return ({
    type: RESET_DISCUSSION_MESSAGE_ERRORS
  });
}

// Get Project Overall Dashboard Data
export const FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_REQUEST = "FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_REQUEST";
export const FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_SUCCESS = "FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_SUCCESS";
export const FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR = "FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR";

export const fetchProjectDashboardOverallRecords = (payload) => {

  return ({
    type: FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_REQUEST,
    payload
  });
}

// Approve seller quote from Admin side
export const APPROVE_SELLER_QUOTE_REQUEST = "APPROVE_SELLER_QUOTE_REQUEST";
export const APPROVE_SELLER_QUOTE_SUCCESS = "APPROVE_SELLER_QUOTE_SUCCESS";
export const APPROVE_SELLER_QUOTE_ERROR = "APPROVE_SELLER_QUOTE_ERROR";

export const approveSellerQuote = (payload) => {

  return ({
    type: APPROVE_SELLER_QUOTE_REQUEST,
    payload
  });
}
export const RESET_APPROVE_SELLER_QUOTE_DATA = "RESET_APPROVE_SELLER_QUOTE_DATA";
export const resetApproveSellerQuote = (payload) => {
  return ({
    type: RESET_APPROVE_SELLER_QUOTE_DATA,
    payload
  });
}

// Get quote List
export const GET_QUOTE_LEADS_REQUEST = "GET_QUOTE_LEADS_REQUEST";
export const GET_QUOTE_LEADS_SUCCESS = "GET_QUOTE_LEADS_SUCCESS";
export const GET_QUOTE_LEADS_ERROR = "GET_QUOTE_LEADS_ERROR";

export const fetchQuoteLeads = (payload) => {

  return ({
    type: GET_QUOTE_LEADS_REQUEST,
    payload
  });
}

// Approve/Reject quote leads
export const APPROVE_REJECT_QUOTE_LEADS_REQUEST = "APPROVE_REJECT_QUOTE_LEADS_REQUEST";
export const APPROVE_REJECT_QUOTE_LEADS_SUCCESS = "APPROVE_REJECT_QUOTE_LEADS_SUCCESS";
export const APPROVE_REJECT_QUOTE_LEADS_ERROR = "APPROVE_REJECT_QUOTE_LEADS_ERROR";

export const approveRejectQuoteLeads = (payload) => {

  return ({
    type: APPROVE_REJECT_QUOTE_LEADS_REQUEST,
    payload
  });
}
export const RESET_APPROVE_REJECT_QUOTE_LEADS_DATA = "RESET_APPROVE_REJECT_QUOTE_LEADS_DATA";
export const resetApproveRejectQuoteLeads = (payload) => {
  return ({
    type: RESET_APPROVE_REJECT_QUOTE_LEADS_DATA,
    payload
  });
}

// Export Quote Leads
export const EXPORT_QUOTE_LEADS_REQUEST = "EXPORT_QUOTE_LEADS_REQUEST";
export const EXPORT_QUOTE_LEADS_SUCCESS = "EXPORT_QUOTE_LEADS_SUCCESS";
export const EXPORT_QUOTE_LEADS_ERROR = "EXPORT_QUOTE_LEADS_ERROR";

export const exportQuoteLeads = (payload) => {

  return ({
    type: EXPORT_QUOTE_LEADS_REQUEST,
    payload
  });
}

export const RESET_EXPORT_QUOTE_LEADS_DATA = "RESET_EXPORT_QUOTE_LEADS_DATA";
export const resetExportQuoteLeads = (payload) => {
  return ({
    type: RESET_EXPORT_QUOTE_LEADS_DATA,
    payload
  });
}

// Fetch Lead Rejection Reasons
export const FETCH_LEAD_REJECTION_REASONS_REQUEST = "FETCH_LEAD_REJECTION_REASONS_REQUEST";
export const FETCH_LEAD_REJECTION_REASONS_SUCCESS = "FETCH_LEAD_REJECTION_REASONS_SUCCESS";
export const FETCH_LEAD_REJECTION_REASONS_ERROR = "FETCH_LEAD_REJECTION_REASONS_ERROR";

export const fetchLeadRejectionReasons = (payload) => {

  return ({
    type: FETCH_LEAD_REJECTION_REASONS_REQUEST,
    payload
  });
}

export const RESET_FETCH_LEAD_REJECTION_REASONS_DATA = "RESET_FETCH_LEAD_REJECTION_REASONS_DATA";
export const resetLeadRejectionReasons = (payload) => {
  return ({
    type: RESET_FETCH_LEAD_REJECTION_REASONS_DATA,
    payload
  });
}

// Update Lead
export const UPDATE_LEAD_REQUEST = "UPDATE_LEAD_REQUEST";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_ERROR = "UPDATE_LEAD_ERROR";

export const updateLead = (payload) => {
  return ({
    type: UPDATE_LEAD_REQUEST,
    payload
  });
}

// Update Lead
export const RESET_UPDATE_LEAD_DATA = "RESET_UPDATE_LEAD_DATA";
export const resetUpdateLeadData = () => {
  return ({
    type: RESET_UPDATE_LEAD_DATA
  });
}

//RESET Other Tabs Data
export const RESET_ALL_TABS_DATA = "RESET_ALL_TABS_DATA";
export const resetAllTabsData = () => {
  return ({
    type: RESET_ALL_TABS_DATA
  });
}

// Fetch Leads Required Fields Lookup Values (To show in buye leads form)
export const FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_REQUEST = "FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_REQUEST";
export const FETCHLEADS_REQUIRED_FIELDS_LOOKUP_VALUES_SUCCESS = "FETCHLEADS_REQUIRED_FIELDS_LOOKUP_VALUES_SUCCESS";
export const FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR = "FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR";

export const fetchLeadsRequiredFieldsLookupValues = (payload) => {

  return ({
    type: FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_REQUEST,
    payload
  });
}

export const RESET_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES = "RESET_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES";
export const resetLeadsRequiredFieldsLookupValues = () => {
  return ({
    type: RESET_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES
  });
}

// Get Project Required Fields for Update Lead Popup
export const FETCH_PROJECT_REQUIRED_FIELDS_REQUEST = "FETCH_PROJECT_REQUIRED_FIELDS_REQUEST";
export const FETCH_PROJECT_REQUIRED_FIELDS_SUCCESS = "FETCH_PROJECT_REQUIRED_FIELDS_SUCCESS";
export const FETCH_PROJECT_REQUIRED_FIELDS_ERROR = "FETCH_PROJECT_REQUIRED_FIELDS_ERROR";

export const fetchProjectRequiredFields = (payload) => {

  return ({
    type: FETCH_PROJECT_REQUIRED_FIELDS_REQUEST,
    payload
  });
}