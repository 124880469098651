import React, { Component } from "react";
import { get as _get/*, isEqual as _isEqual, isEmpty as _isEmpty*/ } from "lodash";
import { Column, Table, AutoSizer } from "react-virtualized";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { NoDataMessage, TableHeaderRenderer, TableCellRenderer/*, showAlertMessage*/ } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import { IconDownload } from "../../helpers/iconStyles";

import { fetchInvoices, resetInvoiceError } from "../../actions/application";

class Invoices extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.columns = [
      {
        id: "receipt_number",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "receipt_number",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.invoice_name", defaultMessage: "Invoice Name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "receipt_date",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "receipt_date",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.invoice_date", defaultMessage: "Invoice Date" })} />),
        cellRenderer: (row) => {
          const { displayDateTimeFormat } = this.props;
          const date = _get(row, "cellData", null);

          return (<TableCellRenderer value={moment(date).format(displayDateTimeFormat)} tooltip={true} />)
        },
      },
      {
        id: "amount",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "amount",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.amount", defaultMessage: "Amount" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "payment_receipt_url",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "payment_receipt_url",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "invoices.table.download", defaultMessage: "Download" })} />),
        cellRenderer: (row) => {
          const downloadLink = _get(row, "cellData", "");

          if (!downloadLink) { return null; }

          return (
            <a href={downloadLink} className="span-link cursor-pointer" target="_blank" rel="noopener noreferrer">
              <IconDownload width="14px" height="14px" />
            </a>
          );
        }
      },
    ];

    this.state = {
      tableData: [{
        "receipt_number": "Receipt 1",
        "receipt_date": "2020-04-23T00:00:00",
        "amount": "300",
        "payment_receipt_url": "http://www.africau.edu/images/default/sample.pdf",
      }, {
        "receipt_number": "Receipt 2",
        "receipt_date": "2021-04-23T00:00:00",
        "amount": "100",
        "payment_receipt_url": "http://www.africau.edu/images/default/sample.pdf",
      }, {
        "receipt_number": "Receipt 2",
        "receipt_date": "2022-04-23T00:00:00",
        "amount": "400",
        "payment_receipt_url": "http://www.africau.edu/images/default/sample.pdf",
      }]
    };
  }

  /*componentDidMount() {
    const { fetchInvoices, tableData } = this.props;

    if (typeof fetchInvoices === "function") { fetchInvoices(); }

    this.setState({ tableData: _get(tableData, "data", []) });
  }

  componentDidUpdate(prevProps, prevStates) {

    /*if (!_isEqual(prevProps.tableData, this.props.tableData) && !_isEmpty(this.props.tableData)) {
      const { tableData, resetInvoiceError } = this.props;

      if ((tableData.status || null) === null) { return false; }

      if ((tableData.status || false) === true) {

        this.setState({ tableData: _get(tableData, "data", []) });
      } else {
        showAlertMessage((tableData.message || "Something went wrong while fetching invoices."));

        if (typeof resetInvoiceError === "function") { resetInvoiceError(); }
      }
    }
  }*/

  _renderTableData = () => {
    const { intl } = this.props;
    const { tableData } = this.state;

    return (
      <div className="col-lg-12">
        <div className="card p-15">
          <div className="table-data position-relative h-500 overflow-x-auto">
            <AutoSizer className="table-autosizer-wrapper">
              {({ height, width }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={32}
                  rowHeight={32}
                  rowCount={(tableData || []).length}
                  rowGetter={({ index }) => (tableData[index] || {})}
                  noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
                >
                  {(this.columns || []).map((column, key) => (
                    <Column key={key} {...column} width={width / (column.columnAvrgWidth || 4)} />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.my_invoice", defaultMessage: "My Invoice - Leads United" })} </title>
        </Helmet>

        <div>
          <div className="section-heading mb-12">
            <h1 className="heading-03 fw-medium">
              <FormattedMessage id="invoices.title" defaultMessage="Invoices" />
            </h1>
          </div>

          <div className="box-wrapper">
            {this._renderTableData()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", ""),
  tableData: _get(state, "application.invoicesData", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: () => dispatch(fetchInvoices()),
  resetInvoiceError: () => dispatch(resetInvoiceError()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Invoices)));