import React, { Component } from "react";
import { get as _get, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { withRouter } from "../../../../components/hoc/withRouter";

import { quoteStatusLookup } from "../../../../helpers/constants";
import { IconInfoAlt } from "../../../../helpers/iconStyles";

import ViewQuote from "../../components/ViewQuote";

import { showAlertMessage, NoDataMessage } from "../../../../components/common/controls";

import { fetchDashboardProjectQuoteDetails, resetDashboardProjectQuote/*, setQuoteGroupID, setQuoteID*/ } from "../../../../actions/leads";

class ProjectQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortDirection: "ASC",
      sortBy: "project_name",
      tableData: [],
      showDetail: false,
    };
  }

  componentDidMount() {
    const { projectQuotes/*, setQuoteGroupID, setQuoteID*/ } = this.props;

    this._fetchProjectQuotes();

    this.setState({ tableData: _get(projectQuotes, "data", []) });

    //if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    //if (typeof setQuoteID === "function") { setQuoteID(null); }
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedBuyer.id", 0), _get(this.props, "dashboardFiltersSelection.selectedBuyer.id", 0)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.startDate", null), _get(this.props, "dashboardFiltersSelection.startDate", null)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.endDate", null), _get(this.props, "dashboardFiltersSelection.endDate", null)) ||
      !_isEqual(prevState.sortBy, this.state.sortBy) ||
      !_isEqual(prevState.sortDirection, this.state.sortDirection)
    ) {
      this._fetchProjectQuotes();
    }

    if (!_isEqual(prevProps.projectQuotes, this.props.projectQuotes)) {

      if (_get(this.props, "projectQuotes.status", null) === null) { return false; }

      if (_get(this.props, "projectQuotes.status", false) === true) {

        this.setState({ tableData: _get(this.props, "projectQuotes.data", []) });
      } else {
        showAlertMessage(_get(this.props, "projectQuotes.message", "Something went wrong while fetching project list."));
      }

      if (typeof this.props.resetDashboardProjectQuote === "function") { this.props.resetDashboardProjectQuote(); }
    }

    /*if (!_isEqual(prevProps.selectedQuoteGroupId, this.props.selectedQuoteGroupId) && (_get(this.props, "selectedQuoteGroupId", null) !== null)) {

      this.setState({ showDetail: true });
    }

    if (!_isEqual(prevState.showDetail, this.state.showDetail) && (_get(this.state, "showDetail", false) === false)) {
      const { setQuoteGroupID } = this.props;

      if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    }*/
  }

  showQuoteDetailPage = (row) => {
    const { /*setQuoteGroupID,*/ source = "", setStateFromChild } = this.props;

    if ((source === "dashboard") && (typeof setStateFromChild === "function")) {
      setStateFromChild({ activeTab: "quotes" });
    }/* else if (typeof setQuoteGroupID === "function") {
      setQuoteGroupID({ "id": _get(row, "quote_group_id", null), name: _get(row, "quote_group_id", null) });
    }*/
  }

  _fetchProjectQuotes = () => {
    const { fetchDashboardProjectQuoteDetails, dashboardFiltersSelection, quoteStatus = null } = this.props;
    const { sortDirection, sortBy } = this.state;

    if (typeof fetchDashboardProjectQuoteDetails === "function") {
      fetchDashboardProjectQuoteDetails({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        buyerId: (_get(dashboardFiltersSelection, "selectedBuyer.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedBuyer.id", 0),
        fromDate: _get(dashboardFiltersSelection, "startDate", null),
        toDate: _get(dashboardFiltersSelection, "endDate", null),
        sortBy: sortBy,
        sort: sortDirection,
        status: (quoteStatus || null),
        pageSize: "100",
        pageNo: "1"
      });
    }
  }

  _sort = ({ sortBy, sortDirection }) => {

    this.setState({ sortBy, sortDirection });
  };

  _renderProjectQuotes = () => {
    const { displayDateFormat, currencySymbol } = this.props;
    const { tableData } = this.state;

    return (
      <>
        {(tableData || []).map((quote, i) => {
          const value = _get(quote, "status", "").toUpperCase();
          const statusClass = _get(quoteStatusLookup, `[${value}.statusClass]`, "");

          return (
            <div key={`quotes-${i}`} className="row gx-12">
              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card">
                  <p className="text-capitalize"><FormattedMessage id="dashboard.table.total_volume_of_leads" defaultMessage="Total Volume of Leads Required" /></p>
                  <p>{_get(quote, "lead_volume", 0)}</p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card">
                  <p className="text-capitalize"><FormattedMessage id="dashboard.table.final_delivery_date" defaultMessage="Final Delivery Date" /></p>
                  <p>{(_get(quote, "delivery_date", null)) ? moment(_get(quote, "delivery_date", null)).format(displayDateFormat) : ""}</p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card">
                  <p className="text-capitalize"><FormattedMessage id="dashboard.table.multiple_batches" defaultMessage="Batches/Multiple Delivery Dates" /></p>
                  <p>
                    YES
                    <span className="position-absolute end-0 pe-12">
                      <IconInfoAlt height="14" width="14" color="#9D9B9B" />
                    </span>
                  </p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card">
                  <p className="text-capitalize"><FormattedMessage id="dashboard.table.cost_per_lead" defaultMessage="Cost per Lead" /></p>
                  <p>{`${currencySymbol}${_get(quote, "cost_per_lead", 0)}`}</p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card border-primary">
                  <p className="text-capitalize"><FormattedMessage id="dashboard.table.total_cost" defaultMessage="Total Cost" /></p>
                  <p>{`${currencySymbol}${_get(quote, "total_price", 0)}`}</p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4">
                <div className="project-list-card bg-danger-ff">
                  <p className="text-dark text-capitalize"><FormattedMessage id="dashboard.funds.tab.quote_status" defaultMessage="Quote Status" /></p>
                  <p className={statusClass}>{_get(quote, "status", 0)}</p>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="text-end mt-20">
                  <button className="btn btn-outline-primary mb-10" onClick={() => this.showQuoteDetailPage(quote)}>
                    <FormattedMessage id="dashboard.table.review_quote" defaultMessage="Review Quote" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    )
  }

  render() {
    const { source = "dashboard", intl } = this.props;
    const { tableData, showDetail } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12 mt-15">

          {((showDetail || false) === false) && (
            <div className="card p-15">
              <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
                <h4 className="heading-03 fw-medium text-primary text-capitalize">
                  {(["quoteTab", "workflow-accepted-quotes"].includes(source)) && (
                    <FormattedMessage id="dashboard.seller_quotes" defaultMessage="seller quotes" />
                  )}
                  {(source === "dashboard") && (
                    <FormattedMessage id="buyer_dashboard.tab.title.quote" defaultMessage="Quote" />
                  )}
                </h4>
              </div>

              {((tableData || []).length < 1) && (
                <NoDataMessage
                  className="text-center no-message-wrapper table-no-data-message"
                  message={intl.formatMessage({ id: "message.no_quote", defaultMessage: "No quote has been submitted yet" })}
                />
              )}

              {((tableData || []).length > 0) && (
                this._renderProjectQuotes()
              )}
            </div>
          )}

          {((showDetail || false) === true) && (
            <ViewQuote setStateFromChild={(args) => this.setState(args)} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  projectQuotes: _get(state, "leads.dashboard.projectQuotes", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", null),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardProjectQuoteDetails: (data) => dispatch(fetchDashboardProjectQuoteDetails(data)),
  resetDashboardProjectQuote: () => dispatch(resetDashboardProjectQuote()),
  //setQuoteGroupID: (id) => dispatch(setQuoteGroupID(id)),
  //setQuoteID: (id) => dispatch(setQuoteID(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectQuotes)));