import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { getAPIKey, resetAPIKey } from "../../services/application";
import { updateLoadingState } from "../../actions/application";

class APIKeys extends Component {
  constructor(props) {
    super(props);

    this.state = { apiKey: "" }
  }

  componentDidMount() {
    this._fetchAPIKeys();
  }

  _fetchAPIKeys = async () => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await getAPIKey();

      if ((response.flag || false) === true) {

        this.setState({ apiKey: _get(response, "data.api_key", "") });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching API key."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching API key."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _resetAPIKey = async () => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await resetAPIKey();
      
      if ((response.flag || false) === true) {
        showAlertMessage(_get(response, "data.message", "API key has been reset successfully."), "success");

        this.setState({ apiKey: _get(response, "data.data.api_key", "") });
      } else {
        showAlertMessage(_get(response, "data.message", "Something went wrong while reseting API key."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while reseting API key."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  render() {
    const { intl } = this.props;
    const { apiKey } = this.state;

    const isAPIConfigured = !_isEmpty(apiKey);

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.api_keys", defaultMessage: "API Keys - Leads United" })} </title>
        </Helmet>
        <div>
          <div className="mb-20">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="api" defaultMessage="API" />
            </h1>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <form autoComplete="off">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-15">
                      <label htmlFor="apikey" className="form-label heading-04 mb-12">
                        <FormattedMessage id="api_keys.api_key" defaultMessage="API Key" />
                      </label>
                      <input type="text" className="form-control" name="apikey" placeholder="API key" defaultValue={(apiKey || "")} readOnly />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-start">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this._resetAPIKey()}>
                        {(isAPIConfigured) ? (
                          <FormattedMessage id="btn.reset_section" defaultMessage="reset {section}" values={{ section: intl.formatMessage({ id: "api_keys.api_key", defaultMessage: "API Key" }) }} />
                        ) : (
                          <FormattedMessage id="btn.create_section" defaultMessage="create {section}" values={{ section: intl.formatMessage({ id: "api_keys.api_key", defaultMessage: "API Key" }) }} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader))
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(APIKeys)));