import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import {
  getApplication, getInvoices, getMyTeamData, getUserSetting, updateUserDetails, removeUploadedCloneFile, saveCloneMappingData,
  saveMarketPlaceSearch, fetchSavedSearchFilterList, fetchDataRejectedTypes
} from "../services/application";

import {
  UPDATE_APPLICATION_LOADING, FETCH_APPLICATION_REQUEST, FETCH_APPLICATION_SUCCESS,
  FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR,
  FETCH_MY_TEAM_DATA_REQUEST, FETCH_MY_TEAM_DATA_SUCCESS, FETCH_MY_TEAM_DATA_ERROR,
  FETCH_USER_SETTINGS_REQUEST, FETCH_USER_SETTINGS_SUCCESS, FETCH_USER_SETTINGS_ERROR,
  UPDATE_USER_DETAILS_REQUEST, UPDATE_USER_DETAILS_SUCCESS, UPDATE_USER_DETAILS_ERROR,
  SAVE_UPLOAD_CLONE_MAPPING_DATA_REQUEST, SAVE_UPLOAD_CLONE_MAPPING_SUCCESS, SAVE_UPLOAD_CLONE_MAPPING_ERROR,
  REMOVE_UPLOADED_CLONE_FILE_REQUEST, REMOVE_UPLOADED_CLONE_FILE_SUCCESS, REMOVE_UPLOADED_CLONE_FILE_ERROR,
  SAVE_AND_GET_FILE_MAPPING_DATA_REQUEST, SAVE_AND_GET_FILE_MAPPING_DATA_SUCCESS, SAVE_AND_GET_FILE_MAPPING_DATA_ERROR,
  REMOVE_UPLOADED_READ_ONLY_FILE_REQUEST, REMOVE_UPLOADED_READ_ONLY_FILE_SUCCESS, REMOVE_UPLOADED_READ_ONLY_FILE_ERROR,
  FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_REQUEST, FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_SUCCESS, FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR,
  SAVE_MARKETPLACE_SEARCH_REQUEST, SAVE_MARKETPLACE_SEARCH_SUCCESS, SAVE_MARKETPLACE_SEARCH_ERROR,
  FETCH_DATA_REJECTED_TYPES_REQUEST, FETCH_DATA_REJECTED_TYPES_SUCCESS, FETCH_DATA_REJECTED_TYPES_ERROR,
} from "../actions/application";

function* workerFetchApplication(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "oauth.uri", ""));

    const response = yield call(getApplication, uri);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_APPLICATION_SUCCESS,
        payload: (response.data || {})
      });
    }
  } catch (error) {

  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchInvoices() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getInvoices);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_INVOICES_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_INVOICES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching invoices.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_INVOICES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching invoices.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchMyTeamData() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getMyTeamData);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_MY_TEAM_DATA_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_MY_TEAM_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching my team details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_MY_TEAM_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching my team details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchUserSettings(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getUserSetting, action.payload);
    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_USER_SETTINGS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_USER_SETTINGS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching user settings.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_USER_SETTINGS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching user settings.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUpdateUserDetails({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(updateUserDetails, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: UPDATE_USER_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: UPDATE_USER_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while updating user details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: UPDATE_USER_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while updating user details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

// File upload
function* workerSaveUploadCloneMappingData({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(saveCloneMappingData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SAVE_UPLOAD_CLONE_MAPPING_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          payload: payload
        }
      });
    } else {
      yield put({
        type: SAVE_UPLOAD_CLONE_MAPPING_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
          errors: _get(response, "error", {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: SAVE_UPLOAD_CLONE_MAPPING_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerRemoveUploadedClonedFile({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(removeUploadedCloneFile, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: REMOVE_UPLOADED_CLONE_FILE_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: REMOVE_UPLOADED_CLONE_FILE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: REMOVE_UPLOADED_CLONE_FILE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

// File upload read only
function* workerSaveUploadReadOnlyMappingData({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(saveCloneMappingData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SAVE_AND_GET_FILE_MAPPING_DATA_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          payload: payload
        }
      });
    } else {
      yield put({
        type: SAVE_AND_GET_FILE_MAPPING_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while uploading file."),
          errors: _get(response, "error", {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: SAVE_AND_GET_FILE_MAPPING_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while uploading file.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerRemoveUploadedReadOnlyFile({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(removeUploadedCloneFile, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: REMOVE_UPLOADED_READ_ONLY_FILE_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: REMOVE_UPLOADED_READ_ONLY_FILE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while removing file."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: REMOVE_UPLOADED_READ_ONLY_FILE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while removing file.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerSaveMarketPlaceSearch({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(saveMarketPlaceSearch, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SAVE_MARKETPLACE_SEARCH_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: SAVE_MARKETPLACE_SEARCH_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while saving marketplace search list."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: SAVE_MARKETPLACE_SEARCH_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while saving marketplace search list.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetcheMarketPlaceSavedSearchFilterList({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchSavedSearchFilterList, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_SUCCESS,
        payload: {
          data: _get(response, "data.data", {}),
          message: (response.message || "")
        }
      });
    } else {
      yield put({
        type: FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching saved search filters details."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching saved search filters details.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDataRejectedTypes({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDataRejectedTypes, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DATA_REJECTED_TYPES_SUCCESS,
        payload: {
          data: _get(response, "data.data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: FETCH_DATA_REJECTED_TYPES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching rejected types."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: FETCH_DATA_REJECTED_TYPES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching rejected types.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchApplication() {
  yield takeLatest(FETCH_APPLICATION_REQUEST, workerFetchApplication);
}

function* watcherFetchInvoices() {
  yield takeLatest(FETCH_INVOICES_REQUEST, workerFetchInvoices);
}

function* watcherFetchMyTeamData() {
  yield takeLatest(FETCH_MY_TEAM_DATA_REQUEST, workerFetchMyTeamData);
}

function* watcherFetchUserSettings() {
  yield takeLatest(FETCH_USER_SETTINGS_REQUEST, workerFetchUserSettings);
}

function* watcherUpdateUserDetails() {
  yield takeLatest(UPDATE_USER_DETAILS_REQUEST, workerUpdateUserDetails);
}

function* watcherSaveUploadCloneMappingData() {
  yield takeLatest(SAVE_UPLOAD_CLONE_MAPPING_DATA_REQUEST, workerSaveUploadCloneMappingData);
}

function* watcherRemoveUploadedClonedFile() {
  yield takeLatest(REMOVE_UPLOADED_CLONE_FILE_REQUEST, workerRemoveUploadedClonedFile);
}

function* watcherSaveUploadReadOnlyMappingData() {
  yield takeLatest(SAVE_AND_GET_FILE_MAPPING_DATA_REQUEST, workerSaveUploadReadOnlyMappingData);
}

function* watcherRemoveUploadedReadOnlyFile() {
  yield takeLatest(REMOVE_UPLOADED_READ_ONLY_FILE_REQUEST, workerRemoveUploadedReadOnlyFile);
}

function* watcherSaveMarketPlaceSearch() {
  yield takeLatest(SAVE_MARKETPLACE_SEARCH_REQUEST, workerSaveMarketPlaceSearch);
}

function* watcherFetcheMarketPlaceSavedSearchFilterList() {

  yield takeLatest(FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_REQUEST, workerFetcheMarketPlaceSavedSearchFilterList);
}

function* watcherFetchDataRejectedTypes() {

  yield takeLatest(FETCH_DATA_REJECTED_TYPES_REQUEST, workerFetchDataRejectedTypes);
}

export default [
  fork(watcherFetchApplication),
  fork(watcherFetchInvoices),
  fork(watcherFetchMyTeamData),
  fork(watcherFetchUserSettings),
  fork(watcherUpdateUserDetails),
  fork(watcherSaveUploadCloneMappingData),
  fork(watcherRemoveUploadedClonedFile),
  fork(watcherSaveUploadReadOnlyMappingData),
  fork(watcherRemoveUploadedReadOnlyFile),
  fork(watcherSaveMarketPlaceSearch),
  fork(watcherFetcheMarketPlaceSavedSearchFilterList),
  fork(watcherFetchDataRejectedTypes)
];
