import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { IconSortingDown, IconSortingUp } from "../../../helpers/iconStyles";

class TableHeaderRenderer extends PureComponent {

  static defaultProps = {
    value: "",
    className: "align-items-left text-capitalize",
    sortable: false,
    sortIconClassName: "ms-4 icon-sorting",
    sortIconPosition: "after"
  };

  render() {
    const { value = "", className = "", sortable = false, sortIconClassName = "", sortIconPosition = "after", textClassName = "", ...restProps } = this.props;

    return (
      <div className={`${(className || "")} ${(sortable || false) === true ? "cursor-pointer" : ""}`} {...restProps}>

        {(((sortable || false) === true) && ((sortIconPosition || "") === "before")) && (
          <span className={(sortIconClassName || "")}>
            <IconSortingDown width="12" height="12" color="#b5b5b5" />
          </span>
        )}

        <span className={(textClassName || "")}>{(value || "")}</span>

        {(((sortable || false) === true) && ((sortIconPosition || "") === "after")) && (
          <span className={(sortIconClassName || "")}>
            <IconSortingUp width="12" height="12" color="#b5b5b5" />
          </span>
        )}

      </div>
    )
  }
}

TableHeaderRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  sortable: PropTypes.bool,
  sortIconPosition: PropTypes.string,
  sortIconClassName: PropTypes.string,
};

export default TableHeaderRenderer;
