import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage, Modal, ErrorMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { isValidPhone } from "../../../helpers/utils";

import { updateLoadingState, fetchUserSettings, resetUserSettingData, updateUserDetails, clearUpdateUserDetailsData } from "../../../actions/application";

class EditUser extends Component {

  constructor(props) {
    super(props);

    this.defaultUserDetails = {
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      address_1: "",
      city: "",
      country: "",
      postcode: "",
      state: "",
      billing_first_name: "",
      billing_last_name: "",
      address_2: "",
      display_name: "",
      email: ""
    };

    this.state = {
      userDetails: _cloneDeep(this.defaultUserDetails),
      errors: {},
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.userDetails, this.props.userDetails) && !_isEmpty(this.props.userDetails)) {
      const { userDetails, resetUserSettingData } = this.props;

      if (_get(userDetails, "status", null) === null) { return false; }

      if (_get(userDetails, "status", false) === true) {

        const tempUserDetails = {
          first_name: _get(userDetails, "data.first_name", ""),
          last_name: _get(userDetails, "data.last_name", ""),
          company: _get(userDetails, "data.company", ""),
          phone: _get(userDetails, "data.phone", ""),
          address_1: _get(userDetails, "data.address_1", null),
          city: _get(userDetails, "data.city", ""),
          country: _get(userDetails, "data.country", ""),
          postcode: _get(userDetails, "data.postcode", ""),
          state: _get(userDetails, "data.state", ""),
          billing_first_name: _get(userDetails, "data.billing_first_name", ""),
          billing_last_name: _get(userDetails, "data.billing_last_name", ""),
          address_2: _get(userDetails, "data.address_2", ""),
          display_name: _get(userDetails, "data.display_name", ""),
          email: _get(userDetails, "data.email", "")
        }

        this.setState({ userDetails: tempUserDetails });
      } else {
        showAlertMessage((userDetails.message || "Something went wrong while fetching user details."));

        if (typeof resetUserSettingData === "function") { resetUserSettingData(); }
      }
    }

    if (!_isEqual(prevState.userDetails, this.state.userDetails)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.updatedUserDetails, this.props.updatedUserDetails) && !_isEmpty(this.props.updatedUserDetails)) {
      const { updatedUserDetails, clearUpdateUserDetailsData, setState } = this.props;

      if (_get(updatedUserDetails, "status", false) === null) { return false; }

      if (_get(updatedUserDetails, "status", false) === true) {
        showAlertMessage(_get(updatedUserDetails, "data.message", "User details updated successfully."), "success");

        if (typeof setState === "function") { setState({ showEditModal: false, selectedUserId: null, reSyncTableData: true }); }

        this.setState({ userDetails: _cloneDeep(this.defaultUserDetails), errors: {} });
      } else {
        showAlertMessage(_get(updatedUserDetails, "data.message", "Something went wrong while updating user details."));
      }

      if (typeof clearUpdateUserDetailsData === "function") { clearUpdateUserDetailsData(); }
    }

    if (!_isEqual(prevProps.showEditModal, _get(this.props, "showEditModal", false)) && (_get(this.props, "showEditModal", false) === true)) {
      const { fetchUserSettings, selectedUserId } = this.props;

      if (typeof fetchUserSettings === "function") { fetchUserSettings({ userId: (selectedUserId || null) }); }
    }
  }

  _closeModal = () => {
    const { setState, resetUserSettingData } = this.props;

    if (typeof setState === "function") { setState({ showEditModal: false, selectedUserId: null }) }

    if (typeof resetUserSettingData === "function") { resetUserSettingData() }

    this.setState({ userDetails: _cloneDeep(this.defaultUserDetails), errors: {} });
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      userDetails: { ...prevState.userDetails, [name]: value }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { userDetails } = this.state;

    let errors = {};

    // validate profile Info

    if (_isEmpty(_get(userDetails, "first_name", ""))) {
      errors["first_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.first_name", defaultMessage: "First name" })
      });
    }

    if (_isEmpty(_get(userDetails, "last_name", ""))) {
      errors["last_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.last_name", defaultMessage: "Last name" })
      });
    }

    if (!isValidPhone(_get(userDetails, "phone", ""))) {
      errors["phone"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}." }, {
        field: intl.formatMessage({ id: "user_management.phone", defaultMessage: "Phone Number" })
      });
    }

    // validate Billing Info
    if (_isEmpty(_get(userDetails, "address_1", ""))) {
      errors["address_1"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.address_line_1", defaultMessage: "Address Line 1" })
      });
    }

    if (_isEmpty(_get(userDetails, "city", ""))) {
      errors["city"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.city", defaultMessage: "City" })
      });
    }

    if (_isEmpty(_get(userDetails, "country", ""))) {
      errors["country"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.country", defaultMessage: "Country" })
      });
    }

    if (_isEmpty(_get(userDetails, "postcode", ""))) {
      errors["postcode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.postcode", defaultMessage: "Post Code" })
      });
    }

    if (_isEmpty(_get(userDetails, "billing_first_name", ""))) {
      errors["billing_first_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.first_name", defaultMessage: "First Name" })
      });
    }

    if (_isEmpty(_get(userDetails, "billing_last_name", ""))) {
      errors["billing_last_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.last_name", defaultMessage: "Last Name" })
      });
    }

    if (_isEmpty(_get(userDetails, "state", ""))) {
      errors["state"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "user_management.state", defaultMessage: "State" })
      });
    }

    this.setState({ errors });
  }

  _onUpdateUserDetails() {
    const { updateUserDetails, selectedUserId } = this.props;
    const { userDetails, errors } = this.state;

    if (!_isEmpty(errors)) {
      this._handleValidation();
      return;
    }

    if (typeof updateUserDetails === "function") {
      updateUserDetails({
        firstName: _get(userDetails, "first_name", ""),
        lastName: _get(userDetails, "last_name", ""),
        phone: _get(userDetails, "phone", ""),
        address1: _get(userDetails, "address_1", ""),
        address2: _get(userDetails, "address_2", ""),
        city: _get(userDetails, "city", ""),
        state: _get(userDetails, "state", ""),
        postcode: _get(userDetails, "postcode", ""),
        country: _get(userDetails, "country", ""),
        billingFirstName: _get(userDetails, "billing_first_name", ""),
        billingLastName: _get(userDetails, "billing_last_name", ""),
        userId: (selectedUserId || 0),
      });
    }
  }

  _renderProfileDetails = () => {
    const { userDetails, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-20">
              <div className="col-lg-12">
                <h4 className="mb-20 text-primary heading-03 fw-medium"><FormattedMessage id="user_management.profile_details" defaultMessage="Profile Details" /></h4>
              </div>
              <div className="row gy-20">
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.first_name" defaultMessage="First Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="first_name"
                      placeholder="First Name"
                      value={_get(userDetails, "first_name", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.first_name || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.first_name || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.last_name" defaultMessage="Last Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="last_name"
                      placeholder="Last Name"
                      value={_get(userDetails, "last_name", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.last_name || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.last_name || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.company" defaultMessage="Company" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="company"
                      readOnly
                      disabled="disabled"
                      placeholder="Company"
                      value={_get(userDetails, "company", "")}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.email" defaultMessage="Email" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control border"
                      name="email"
                      readOnly
                      disabled="disabled"
                      placeholder="Email"
                      value={_get(userDetails, "email", "")}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.phone" defaultMessage="Phone Number" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="phone"
                      placeholder="Phone Number"
                      value={_get(userDetails, "phone", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.phone || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.phone || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.display_name" defaultMessage="Display Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="display_name"
                      readOnly
                      disabled="disabled"
                      placeholder="Display Name"
                      value={_get(userDetails, "display_name", null)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderBillingDetails = () => {
    const { userDetails, errors } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-20">
              <div className="col-lg-12">
                <h4 className="mb-20 text-primary heading-03 fw-medium">
                  <FormattedMessage id="user_management.billing_details" defaultMessage="Billing Details" />
                </h4>
              </div>
              <div className="row gy-20">
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.first_name" defaultMessage="First Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="billing_first_name"
                      placeholder="First Name"
                      value={_get(userDetails, "billing_first_name", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.billing_first_name || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.billing_first_name || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.last_name" defaultMessage="Last Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="billing_last_name"
                      placeholder="Last Name"
                      value={_get(userDetails, "billing_last_name", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.billing_last_name || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.billing_last_name || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.address_line_1" defaultMessage="Address Line 1" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="address_1"
                      placeholder="Address Line 1"
                      value={_get(userDetails, "address_1", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.address_1 || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.address_1 || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.address_line_2" defaultMessage="Address Line 2" />
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="address_2"
                      placeholder="Address Line 2"
                      value={_get(userDetails, "address_2", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.city" defaultMessage="City" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="city"
                      placeholder="City"
                      value={_get(userDetails, "city", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.city || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.city || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.state" defaultMessage="State" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="state"
                      placeholder="State"
                      value={_get(userDetails, "state", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.state || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.state || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.country" defaultMessage="Country" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="country"
                      placeholder="Country"
                      value={_get(userDetails, "country", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.country || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.country || "")} />)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="mb-12">
                      <FormattedMessage id="user_management.postcode" defaultMessage="Postcode" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="postcode"
                      placeholder="Post Code"
                      value={_get(userDetails, "postcode", "")}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {(errors.postcode || "") && (<ErrorMessage className="text-danger mt-5 ps-5 text-capitalize-first" message={(errors.postcode || "")} />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderEditUser = () => {
    const { showEditModal, intl } = this.props;

    return (
      <Modal
        size="lg"
        centered={true}
        showCloseButton={true}
        isOpen={(showEditModal || false)}
        modalTitle={intl.formatMessage({ id: "user_management.edit_user.title", defaultMessage: "Edit User" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
      >
        <div className="section-create-user">
          <div className="container">

            <div className="row">
              <div className="col-lg-12">
                <form autoComplete="off">
                  {this._renderProfileDetails()}

                  {this._renderBillingDetails()}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-end mt-20 position-relative">
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => this._onUpdateUserDetails()}>
                          <FormattedMessage id="btn.save" defaultMessage="Save" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { showEditModal } = this.props;

    return (
      <>
        {(showEditModal === true) && this._renderEditUser()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: _get(state, "application.settings.userDetails", {}),
  updatedUserDetails: _get(state, "application.settings.updateUserDetails", {})
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserSettings: (data) => dispatch(fetchUserSettings(data)),
  clearUpdateUserDetailsData: () => dispatch(clearUpdateUserDetailsData()),
  resetUserSettingData: () => dispatch(resetUserSettingData()),
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditUser)));