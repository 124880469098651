import { showAlertMessage as showAlertTmp } from "./AlertMessage";
import { ErrorMessage as ErrorMessageTmp } from "./ErrorMessage";
import { EmptyLabel as EmptyLabelTmp } from "./EmptyLabel";
import { NoDataMessage as NoDataMessageTmp } from "./EmptyLabel";
import { ReactModal as ReactModalTmp } from "./ReactModal";
import { CustomModal as ModalTmp } from "./CustomModal";
import { Textarea as TextareaTmp } from "./Textarea";
// import { Input as InputTmp } from "./Input";
import SingleDropzoneReadOnlyTmp from "./SingleDropzoneReadOnly";
import TableHeaderRendererTmp from "./TableHeaderRenderer";
import SingleDropzoneCloneTmp from "./SingleDropzoneClone";
import TableCellRendererTmp from "./TableCellRenderer";
import DateRangePickerTmp from "./DateRangePicker";
import SingleDropzoneTmp from "./SingleDropzone";
import ReactDatePickerTmp from "./ReactDatePicker";
import ConfirmBoxTmp from "./ConfirmBox";
import HighChartTmp from "./HighChart";
import SelectTmp from "./Select";

export const SingleDropzoneReadOnly = SingleDropzoneReadOnlyTmp;
export const TableHeaderRenderer = TableHeaderRendererTmp;
export const SingleDropzoneClone = SingleDropzoneCloneTmp;
export const TableCellRenderer = TableCellRendererTmp;
export const DateRangePicker = DateRangePickerTmp;
export const SingleDropzone = SingleDropzoneTmp;
export const NoDataMessage = NoDataMessageTmp;
export const showAlertMessage = showAlertTmp;
export const DatePicker = ReactDatePickerTmp;
export const ErrorMessage = ErrorMessageTmp;
export const ReactModal = ReactModalTmp;
export const EmptyLabel = EmptyLabelTmp;
export const ConfirmBox = ConfirmBoxTmp;
export const HighChart = HighChartTmp;
export const Textarea = TextareaTmp;
export const Select = SelectTmp;
// export const Input = InputTmp;
export const Modal = ModalTmp;