import React, { Component, Fragment } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, groupBy as _groupBy } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { ConfirmBox, EmptyLabel, showAlertMessage, NoDataMessage } from "../../../components/common/controls";
import { hasPermissions } from "../../../components/hoc/Permissions";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconDots, IconMinus } from "../../../helpers/iconStyles";
import { projectStatusLookup } from "../../../helpers/constants";
import { downloadAnyFile } from "../../../helpers/utils";

import { downloadFile, updateProjectStatus } from "../../../services/application";

import { fetchProjectDetails, resetDashboardError, resetPropsAfterProjectDeleted } from "../../../actions/leads";
import { updateLoadingState } from "../../../actions/application";

class ProjectDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCriteria: 0,
      autienceTargetsList: [],
      confirmMessage: "",
      confirmBoxOpen: false,
      projectStatus: null,
      toggleOnHoldButton: false
    }
  }

  componentDidMount() {
    const { targetAudienceData } = this.props;

    this._fetchProjectDetails();

    this.setState({ audienceTargetsList: !_isEmpty(targetAudienceData) ? _groupBy(targetAudienceData, "criteria_name") : [] });
  }

  componentDidUpdate(prevProps, prevStates) {

    if ((!_isEqual(prevProps.error, _get(this.props, "error", {}))) && (_get(this.props, "error.status", null) === false)) {
      const { error, resetDashboardError } = this.props;

      showAlertMessage((error.message || "Something went wrong while fetching project details."));
      if (typeof resetDashboardError === "function") { resetDashboardError(); }
    }

    if ((!_isEqual(prevProps.targetAudienceData, _get(this.props, "targetAudienceData", []))) && !_isEmpty(_get(this.props, "targetAudienceData", []))) {
      const { targetAudienceData } = this.props;

      this.setState({ audienceTargetsList: !_isEmpty(targetAudienceData) ? _groupBy(targetAudienceData, "criteria_name") : [] });
    }

    if ((!_isEqual(_get(prevProps, "projectId", 0), _get(this.props, "projectId", 0))) && (_get(this.props, "projectId", 0) !== 0)) {

      this._fetchProjectDetails();
    }

    if (((!_isEqual(_get(prevProps, "projectDetails.status_id", 0), _get(this.props, "projectDetails.status_id", 0))) && (_get(this.props, "projectDetails.status_id", 0) !== 0))) {

      this.setState({
        toggleOnHoldButton: (_get(this.props, "projectDetails.status_id", 0) === _get(projectStatusLookup, "ONHOLD.id", 0)) ? true : false
      });
    }
  }

  _handleEdit = () => {
    const { projectDetails, projectId, intl } = this.props;

    if (_get(projectDetails, "is_allow_edit", 1) === 0) {
      showAlertMessage(intl.formatMessage({ id: "project_details.buyer_dont_allow_to_edit_project", defaultMessage: "Seller has already submitted quote for this project, You won't be able to edit project" }));
    } else {

      this.props.navigate(`/leads/edit-buy-leads/${(projectId || 0)}`);
    }
  }

  _fetchProjectDetails = () => {
    const { fetchProjectDetails, projectId } = this.props;

    if (typeof fetchProjectDetails === "function") { fetchProjectDetails((projectId || 0)); }
  }

  downloadUploadedFile = async (fileURL) => {
    const { updateLoadingState } = this.props;

    if (_isEmpty(fileURL)) { return showAlertMessage("No file available."); }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await downloadFile({ fileUrl: fileURL });

      if ((response.flag || false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.contents || "");
        const filename = (downloadFileDetails.fileName || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadAnyFile(contentType, contents, filename);

          showAlertMessage("File downloaded successfully.", "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while downloading a file.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading a file.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  changeProjectStatus = async () => {
    const { intl, updateLoadingState, projectId, resetPropsAfterProjectDeleted } = this.props;
    const { projectStatus } = this.state;

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await updateProjectStatus({ projectId: projectId, status: projectStatus });

      const resData = _get(response, "data", {});

      if ((resData.status || false) === true) {

        showAlertMessage(_get(resData, "data.message", "Project status updated successfully."), "success");

        if ((projectStatus === _get(projectStatusLookup, "DELETED.id", 0)) && (typeof resetPropsAfterProjectDeleted === "function")) {
          resetPropsAfterProjectDeleted();
        }

        this._fetchProjectDetails();

        this.setState({
          toggleOnHoldButton: (projectStatus === _get(projectStatusLookup, "ONHOLD.id", 0)) ? true : false,
          confirmBoxOpen: false,
          projectStatus: null,
          confirmMessage: ""
        });
      } else {

        showAlertMessage(_get(resData, "data.message", "Something went wrong while updating project status."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while updating project status."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderAudienceTargeting = () => {
    const { intl } = this.props;
    const { audienceTargetsList, activeCriteria } = this.state;

    return (
      <Fragment>
        <p className="heading-04 fw-medium text-capitalize mb-8">
          <FormattedMessage id="project_details.audience_targeting" defaultMessage="Audience Targeting" />
        </p>
        {(_isEmpty(audienceTargetsList) && (
          <NoDataMessage message={intl.formatMessage({ id: "message.no_found_data", defaultMessage: "no data found" })} />
        ))}
        {!_isEmpty(audienceTargetsList) && Object.values(audienceTargetsList || []).map((audience, i) => {

          return (
            <div key={`audience-${i}`} className="accordion" id="detailBatchAccordion">
              <div className="border-bottom pb-12 mb-12">
                <div className="accordion-header" id="detailheadingOne">
                  <button className={`accordion-button heading-04 text-capitalize fw-medium mb-8 d-flex align-items-center gap-12 ${(activeCriteria !== i) ? "collapsed" : ""}`} type="button" onClick={() => (activeCriteria === i) ? this.setState({ activeCriteria: null }) : this.setState({ activeCriteria: i })}>
                    {(activeCriteria !== i) && (
                      <IconMinus height="14px" width="14px" color="#E94969" />
                    )}
                    {(activeCriteria === i) && (
                      <IconMinus height="14px" width="14px" color="#E94969" />
                    )}
                    <FormattedMessage id="project_details.criteria" defaultMessage="criteria {number}" values={{ number: (i + 1) }} />
                  </button>
                </div>
                <div className={`accordion-collapse ${(activeCriteria !== i) ? "collapse" : ""}`}>
                  <div className="accordion-body p-0 pb-12">
                    <div className="row gx-12">
                      {(audience || []).map((list, k) => {

                        return (
                          <div key={`list-${k}`} className="col-xl-2 col-lg-4">
                            <div className="project-list-card">
                              <p>{_get(list, "target_type", "")}</p>
                              <p title={_get(list, "target_value", <EmptyLabel />)}>{_get(list, "target_value", <EmptyLabel />)}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }

  _renderLeadInfo = () => {
    const { projectDetails, currencySymbol, displayDateFormat } = this.props;

    const projectStatus = _get(projectDetails, "project_status", "");

    return (
      <>
        <p className="heading-04 fw-medium mb-8 text-capitalize">
          <FormattedMessage id="project_details.lead_info" defaultMessage="Lead Info" />
        </p>

        <div className="row gx-12">
          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card text-capitalize">
              <p><FormattedMessage id="project_details.project_name" defaultMessage="Project Name" /></p>
              <p title={_get(projectDetails, "project_name", "")}>{_get(projectDetails, "project_name", "")}</p>
            </div>
          </div>

          {/*<div className="col-xl-2 col-lg-4">
            <div className="project-list-card text-capitalize">
              <p><FormattedMessage id="seller" defaultMessage="seller" /></p>
              <p>-</p>
            </div>
          </div>*/}

          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card text-capitalize">
              <p><FormattedMessage id="project_details.volume_of_leads_required" defaultMessage="Volume of Leads Required" /></p>
              <p>{_get(projectDetails, "leads_volume", "")}</p>
            </div>
          </div>

          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card text-capitalize">
              <p><FormattedMessage id="project_details.delivery_date" defaultMessage="delivery date" /></p>
              <p>{(_get(projectDetails, "delivery_date", "")) ? moment(_get(projectDetails, "delivery_date", "")).format(displayDateFormat) : ""}</p>
            </div>
          </div>

          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card text-capitalize">
              <p><FormattedMessage id="project_details.budget" defaultMessage="budget" /></p>
              <p>{`${currencySymbol}${_get(projectDetails, "budget", "")}`}</p>
            </div>
          </div>

          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card bg-success-f2 text-capitalize">
              <p className="text-dark"><FormattedMessage id="project_details.status" defaultMessage="status" /></p>
              <p className={_get(projectStatusLookup, `[${projectStatus.toUpperCase()}.statusClass]`, "")}>{projectStatus}</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderFileUplodation = () => {
    const { suppressionFile, talFile } = this.props;

    return (
      <>
        <p className="heading-04 fw-medium mb-8">
          <FormattedMessage id="project_details.tal_suppression" defaultMessage="TAL/Suppression" />
        </p>

        <div className="row gx-12">
          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card">
              <p><FormattedMessage id="project_details.tal_upload" defaultMessage="TAL Upload" /></p>
              {(_get(talFile, "file_path", "") !== "") ? (
                <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadUploadedFile(_get(talFile, "file_path", ""))}>
                  <FormattedMessage id="project_details.download_files" defaultMessage="Download Files" />
                </button>
              ) : (<p> - </p>)}
            </div>
          </div>

          <div className="col-xl-2 col-lg-4">
            <div className="project-list-card">
              <p><FormattedMessage id="project_details.suppression_upload" defaultMessage="Suppression Upload" /></p>
              {(_get(suppressionFile, "file_path", "") !== "") ? (
                <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadUploadedFile(_get(suppressionFile, "file_path", ""))}>
                  <FormattedMessage id="project_details.download_files" defaultMessage="Download Files" />
                </button>
              ) : (<p> - </p>)}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { projectDetails } = this.props;
    const { confirmBoxOpen, confirmMessage, toggleOnHoldButton } = this.state;

    const projectStatusId = _get(projectDetails, "status_id", "");

    return (

      <div className="col-lg-12">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">
              <FormattedMessage id="project_details.title" defaultMessage="Project Details" />
            </h4>

            <div className="ms-lg-auto">
              {(hasPermissions("dashboard", "project-detail", ["action-edit-project", "action-mark-as-complete", "action-mark-as-hold", "action-delete-project"], "OR") === true) && (
                <Dropdown className="navbar-nav navbar-nav-dots">
                  <Dropdown.Toggle id="dropdown-basic" variant="outline-primary">
                    <IconDots height="20" width="4" color="#e84968" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end position-absolute">

                    {(hasPermissions("dashboard", "project-detail", ["action-edit-project"]) === true) &&
                      (![_get(projectStatusLookup, "COMPLETED.id", 0), _get(projectStatusLookup, "DELETED.id", 0)].includes(projectStatusId)) && (
                        <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this._handleEdit()}>
                          <FormattedMessage id="edit" defaultMessage="Edit" />
                        </Dropdown.Item>
                      )}

                    {(hasPermissions("dashboard", "project-detail", ["action-mark-as-active"]) === true) && (toggleOnHoldButton === true) &&
                      ([_get(projectStatusLookup, "ONHOLD.id", 0), _get(projectStatusLookup, "INACTIVE.id", 0)].includes(projectStatusId)) && (
                        <Dropdown.Item
                          className="dropdown-item text-capitalize"
                          onClick={() => this.setState({
                            confirmBoxOpen: true,
                            projectStatus: _get(projectStatusLookup, "ACTIVE.id", 0),
                            confirmMessage: "Are you sure you want to mark as active."
                          })}
                        >
                          <FormattedMessage id="mark_as_active" defaultMessage="Mark as active" />
                        </Dropdown.Item>
                      )}

                    {(hasPermissions("dashboard", "project-detail", ["action-mark-as-complete"]) === true) &&
                      ([_get(projectStatusLookup, "ACTIVE.id", 0)].includes(projectStatusId)) && (
                        <Dropdown.Item
                          className="dropdown-item text-capitalize"
                          onClick={() => this.setState({
                            confirmBoxOpen: true,
                            projectStatus: _get(projectStatusLookup, "COMPLETED.id", 0),
                            confirmMessage: "Are you sure you want to complete this project."
                          })}
                        >
                          <FormattedMessage id="mark_as_complete" defaultMessage="Mark as complete" />
                        </Dropdown.Item>
                      )}

                    {(hasPermissions("dashboard", "project-detail", ["action-mark-as-hold"]) === true) &&
                      ([_get(projectStatusLookup, "ACTIVE.id", 0)].includes(projectStatusId)) &&
                      (toggleOnHoldButton === false) && (
                        <Dropdown.Item
                          className="dropdown-item text-capitalize"
                          onClick={() => this.setState({
                            confirmBoxOpen: true,
                            projectStatus: _get(projectStatusLookup, "ONHOLD.id", 0),
                            confirmMessage: "Are you sure you want onhold this project."
                          })}
                        >
                          <FormattedMessage id="mark_as_on_hold" defaultMessage="Mark as on hold" />
                        </Dropdown.Item>
                      )}

                    {(hasPermissions("dashboard", "project-detail", ["action-delete-project"]) === true) &&
                      ([_get(projectStatusLookup, "ACTIVE.id", 0)].includes(projectStatusId)) && (
                        <Dropdown.Item
                          className="dropdown-item text-capitalize"
                          onClick={() => this.setState({
                            confirmBoxOpen: true,
                            projectStatus: _get(projectStatusLookup, "DELETED.id", 0),
                            confirmMessage: "Are you sure you want to delete this project."
                          })}
                        >
                          <FormattedMessage id="delete" defaultMessage="Delete" />
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>

          <div className="project-status-wrap">

            {this._renderLeadInfo()}

            <div className="border-bottom border-secondary pb-12 mb-12"></div>

            {this._renderAudienceTargeting()}

            {this._renderFileUplodation()}

          </div>
          <ConfirmBox
            isOpen={(confirmBoxOpen || false)}
            content={(confirmMessage || "")}
            onConfirm={() => this.changeProjectStatus()}
            onClose={() => this.setState({ confirmBoxOpen: false, projectStatus: null, confirmMessage: "" })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currencySymbol: _get(state, "application.constants.currencySymbol", "$"),
  error: _get(state, "leads.dashboard.errorDashboard", {}),
  projectDetails: _get(state, "leads.dashboard.projectDetails.data.project[0]", {}),
  targetAudienceData: _get(state, "leads.dashboard.projectDetails.data.target_audience", []),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  talFile: _get(state, "leads.dashboard.projectDetails.data.tal[0]", {}),
  suppressionFile: _get(state, "leads.dashboard.projectDetails.data.suppression[0]", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectDetails: (data) => dispatch(fetchProjectDetails(data)),
  resetDashboardError: () => dispatch(resetDashboardError()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  resetPropsAfterProjectDeleted: () => dispatch(resetPropsAfterProjectDeleted()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectDetails)));