import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { fetchUsers } from "../services/userManagement";

import {
  FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR,
} from "../actions/userManagement";

import { UPDATE_APPLICATION_LOADING } from "../actions/application";

function* workerFetchUsers(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchUsers, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_USERS_SUCCESS,
        data: _get(response, "data", []),
      });
    } else {
      yield put({
        type: FETCH_USERS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching users.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_USERS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching users.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchUsers() {
  yield takeLatest(FETCH_USERS_REQUEST, workerFetchUsers);
}

export default [
  fork(watcherFetchUsers),
];