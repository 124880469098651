import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";

class UnderConstruction extends Component {

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.generic_page.comming_soon", defaultMessage: "Comming soon - Leads United" })} </title>
        </Helmet>

        <Row className="text-center mt-10">
          <Col className="text-uppercase" sm={12}>
            <h2 className="title--bold">
              <FormattedMessage id="generic_page.coming_soon.title" defaultMessage="coming soon" />
            </h2>
          </Col>
          <Col sm={12}>
            <h3><FormattedMessage id="generic_page.coming_soon.sub_title" defaultMessage="This page is under construction." /></h3>
          </Col>
        </Row>
      </>
    );
  }
}

export default injectIntl(UnderConstruction);