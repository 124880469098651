import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { withRouter } from "../../components/hoc/withRouter";

import { showAlertMessage, ErrorMessage } from "../../components/common/controls";

import { isValidPhone } from "../../helpers/utils";

import ChangePassword from "../ChangePassword";

import { updateLoadingState, fetchUserSettings, updateUserDetails, resetUserSettingData, clearUpdateUserDetailsData } from "../../actions/application";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.defaultUserDetails = {
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      address_1: "",
      city: "",
      country: "",
      postcode: "",
      state: "",
      billing_first_name: "",
      billing_last_name: "",
      address_2: "",
      display_name: "",
      email: ""
    };

    this.state = {
      userDetails: _cloneDeep(this.defaultUserDetails),
      errors: {}
    }
  }

  componentDidMount() {
    const { fetchUserSettings, userDetails } = this.props;

    if (typeof fetchUserSettings === "function") { fetchUserSettings(); }

    this.setState({ userDetails: _get(userDetails, "data", []) });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.userDetails, this.props.userDetails) && !_isEmpty(this.props.userDetails)) {
      const { userDetails, resetUserSettingData } = this.props;

      let tempUserDetails = {};

      if (_get(userDetails, "status", null) === null) { return false; }

      if (_get(userDetails, "status", false) === true) {

        tempUserDetails = {
          first_name: _get(userDetails, "data.first_name", ""),
          last_name: _get(userDetails, "data.last_name", ""),
          company: _get(userDetails, "data.company", ""),
          phone: _get(userDetails, "data.phone", ""),
          address_1: _get(userDetails, "data.address_1", ""),
          city: _get(userDetails, "data.city", ""),
          country: _get(userDetails, "data.country", ""),
          postcode: _get(userDetails, "data.postcode", ""),
          state: _get(userDetails, "data.state", ""),
          billing_first_name: _get(userDetails, "data.billing_first_name", ""),
          billing_last_name: _get(userDetails, "data.billing_last_name", ""),
          address_2: _get(userDetails, "data.address_2", ""),
          display_name: _get(userDetails, "data.display_name", ""),
          email: _get(userDetails, "data.email", "")
        }

        this.setState({ userDetails: tempUserDetails });
      } else {
        showAlertMessage((userDetails.message || "Something went wrong while fetching user details."));
        
        if (typeof resetUserSettingData === "function") { resetUserSettingData(); }
      }
    }

    if (!_isEqual(prevState.userDetails, this.state.userDetails)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.updatedUserDetails, this.props.updatedUserDetails) && !_isEmpty(this.props.updatedUserDetails)) {
      const { updatedUserDetails, clearUpdateUserDetailsData, fetchUserSettings } = this.props;

      if (_get(updatedUserDetails, "status", false) === null) { return false; }

      if (_get(updatedUserDetails, "status", false) === true) {
        showAlertMessage(_get(updatedUserDetails, "data.message", "User details updated successfully."), "success");
      } else {
        showAlertMessage(_get(updatedUserDetails, "data.message", "Something went wrong while updating user details."));
      }

      if (typeof clearUpdateUserDetailsData === "function") { clearUpdateUserDetailsData(); }

      if (typeof fetchUserSettings === "function") { fetchUserSettings(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      userDetails: { ...prevState.userDetails, [name]: value }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { userDetails } = this.state;

    let errors = {};

    // validate profile Info

    if (_isEmpty(_get(userDetails, "first_name", ""))) {
      errors["first_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.first_name", defaultMessage: "First name" })
      });
    }

    if (_isEmpty(_get(userDetails, "last_name", ""))) {
      errors["last_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.last_name", defaultMessage: "Last name" })
      });
    }

    if (!isValidPhone(_get(userDetails, "phone", ""))) {
      errors["phone"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}." }, {
        field: intl.formatMessage({ id: "settings.phone", defaultMessage: "Phone Number" })
      });
    }

    // validate Billing Info
    if (_isEmpty(_get(userDetails, "address_1", ""))) {
      errors["address_1"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.address_line_1", defaultMessage: "Address Line 1" })
      });
    }

    if (_isEmpty(_get(userDetails, "city", ""))) {
      errors["city"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.city", defaultMessage: "City" })
      });
    }

    if (_isEmpty(_get(userDetails, "country", ""))) {
      errors["country"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.country", defaultMessage: "Country" })
      });
    }

    if (_isEmpty(_get(userDetails, "postcode", ""))) {
      errors["postcode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.postcode", defaultMessage: "Post Code" })
      });
    }

    if (_isEmpty(_get(userDetails, "billing_first_name", ""))) {
      errors["billing_first_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.first_name", defaultMessage: "First Name" })
      });
    }

    if (_isEmpty(_get(userDetails, "billing_last_name", ""))) {
      errors["billing_last_name"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.last_name", defaultMessage: "Last Name" })
      });
    }

    if (_isEmpty(_get(userDetails, "state", ""))) {
      errors["state"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "settings.state", defaultMessage: "State" })
      });
    }

    // validate Card Info
    /*
    if (_isEmpty(_get(userDetails, "cardno", "")) || !isValidCheckNumber(_get(userDetails, "cardno", ""))) {
      errors["cardno"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "cardno", defaultMessage: "Card No" })
      });
    }

    if (_isEmpty(_get(userDetails, "expirydate", ""))) {
      errors["expirydate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "expirydate", defaultMessage: "Expire" })
      });
    }

    if (_isEmpty(_get(userDetails, "cvv", "")) || !isValidCheckNumber(_get(userDetails, "cvv", ""))) {
      errors["cvv"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "cvv", defaultMessage: "CVV" })
      });
    }
    */
    this.setState({ errors });
  }

  _onUpdateUserDetails() {
    const { updateUserDetails } = this.props;
    const { userDetails, errors } = this.state;

    if (!_isEmpty(errors)) {
      this._handleValidation();
      return;
    }

    if (typeof updateUserDetails === "function") {
      updateUserDetails({
        firstName: _get(userDetails, "first_name", ""),
        lastName: _get(userDetails, "last_name", ""),
        phone: _get(userDetails, "phone", ""),
        address1: _get(userDetails, "address_1", ""),
        address2: _get(userDetails, "address_2", ""),
        city: _get(userDetails, "city", ""),
        state: _get(userDetails, "state", ""),
        postcode: _get(userDetails, "postcode", ""),
        country: _get(userDetails, "country", ""),
        billingFirstName: _get(userDetails, "billing_first_name", ""),
        billingLastName: _get(userDetails, "billing_last_name", ""),
      });
    }
  }

  _renderProfileDetails = () => {
    const { userDetails, errors } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-20">
                <div className="col-lg-12">
                  <h4 className="mb-20 text-primary heading-03 fw-medium"><FormattedMessage id="settings.profile_details" defaultMessage="Profile Details" /></h4>
                </div>
                <div className="row gy-20">
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.first_name" defaultMessage="First Name" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="first_name"
                        placeholder="First Name"
                        value={_get(userDetails, "first_name", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.first_name || "") && (<ErrorMessage message={(errors.first_name || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.last_name" defaultMessage="Last Name" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="last_name"
                        placeholder="Last Name"
                        value={_get(userDetails, "last_name", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.last_name || "") && (<ErrorMessage message={(errors.last_name || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.company" defaultMessage="Company" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="company"
                        readOnly
                        disabled="disabled"
                        placeholder="Company"
                        defaultValue={_get(userDetails, "company", "")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.email" defaultMessage="Email" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control border"
                        name="email"
                        readOnly
                        disabled="disabled"
                        placeholder="Email"
                        defaultValue={_get(userDetails, "email", "")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.phone" defaultMessage="Phone Number" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="phone"
                        placeholder="Phone Number"
                        value={_get(userDetails, "phone", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.phone || "") && (<ErrorMessage message={(errors.phone || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.display_name" defaultMessage="Display Name" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="display_name"
                        readOnly
                        disabled="disabled"
                        placeholder="Display Name"
                        defaultValue={_get(userDetails, "display_name", null)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderBillingDetails = () => {
    const { userDetails, errors } = this.state;

    return (
      <>
        <div className="row mt-20">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body p-20">
                <div className="col-lg-12">
                  <h4 className="mb-20 text-primary heading-03 fw-medium">
                    <FormattedMessage id="settings.billing_details" defaultMessage="Billing Details" />
                  </h4>
                </div>
                <div className="row gy-20">
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.first_name" defaultMessage="First Name" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="billing_first_name"
                        placeholder="First Name"
                        value={_get(userDetails, "billing_first_name", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.billing_first_name || "") && (<ErrorMessage message={(errors.billing_first_name || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.last_name" defaultMessage="Last Name" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="billing_last_name"
                        placeholder="Last Name"
                        value={_get(userDetails, "billing_last_name", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.billing_last_name || "") && (<ErrorMessage message={(errors.billing_last_name || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.address_line_1" defaultMessage="Address Line 1" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="address_1"
                        placeholder="Address Line 1"
                        value={_get(userDetails, "address_1", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.address_1 || "") && (<ErrorMessage message={(errors.address_1 || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.address_line_2" defaultMessage="Address Line 2" />
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="address_2"
                        placeholder="Address Line 2"
                        value={_get(userDetails, "address_2", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.city" defaultMessage="City" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="city"
                        placeholder="City"
                        value={_get(userDetails, "city", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.city || "") && (<ErrorMessage message={(errors.city || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.state" defaultMessage="State" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="state"
                        placeholder="State"
                        value={_get(userDetails, "state", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.state || "") && (<ErrorMessage message={(errors.state || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.country" defaultMessage="Country" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="country"
                        placeholder="Country"
                        value={_get(userDetails, "country", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.country || "") && (<ErrorMessage message={(errors.country || "")} />)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div>
                      <label className="mb-12">
                        <FormattedMessage id="settings.postcode" defaultMessage="Postcode" />
                        <span className="text-danger heading-06">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border"
                        name="postcode"
                        placeholder="Post Code"
                        value={_get(userDetails, "postcode", "")}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.postcode || "") && (<ErrorMessage message={(errors.postcode || "")} />)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderCardDetails = () => {
    const { userDetails, errors } = this.state;

    return (
      <>
        <div className="col-lg-12">
          <h5 className="heading-03 text-primary fw-medium mb-20">
            <FormattedMessage id="settings.card_details" defaultMessage="Card Details" />
          </h5>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="settings.card_no" defaultMessage="Card No" />
              <span className="text-danger heading-06">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="cardno"
              placeholder="1234 1234 1234 1234"
              value={_get(userDetails, "cardno", "")}
              onChange={(e) => this._handleChange(e)}
            />
            {(errors.cardno || "") && (<ErrorMessage message={(errors.cardno || "")} />)}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="settings.expire_date" defaultMessage="Expire Date" />
              <span className="text-danger heading-06">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="expirydate"
              placeholder="Expire"
              value={_get(userDetails, "expirydate", "")}
              onChange={(e) => this._handleChange(e)}
            />
            {(errors.expirydate || "") && (<ErrorMessage message={(errors.expirydate || "")} />)}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="settings.cvv" defaultMessage="CVV" />
              <span className="text-danger heading-06">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="cvv"
              placeholder="CVV"
              value={_get(userDetails, "cvv", "")}
              onChange={(e) => this._handleChange(e)}
            />
            {(errors.cvv || "") && (<ErrorMessage message={(errors.cvv || "")} />)}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <>
        <Helmet>
          <title>{intl.formatMessage({ id: "LU.settings", defaultMessage: "Settings - Leads United" })} </title>
        </Helmet>
        <div className="row">
          <div className="col-lg-8">
            <div className="section-heading mb-20">
              <h1 className="heading-03 fw-medium"><FormattedMessage id="settings.title" defaultMessage="Settings" /></h1>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-end position-relative">
              <ChangePassword />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <form autoComplete="off">
              {this._renderProfileDetails()}

              {this._renderBillingDetails()}

              {/*<div className="row">
                  {this._renderCardDetails()}
                </div>*/}
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-end mt-20 position-relative">
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => this._onUpdateUserDetails()}>
                      <FormattedMessage id="btn.save" defaultMessage="Save" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: _get(state, "application.settings.userDetails", {}),
  updatedUserDetails: _get(state, "application.settings.updateUserDetails", {})
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserSettings: () => dispatch(fetchUserSettings()),
  clearUpdateUserDetailsData: () => dispatch(clearUpdateUserDetailsData()),
  resetUserSettingData: () => dispatch(resetUserSettingData()),
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Settings)));