import React, { Component, createRef } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, map as _map, size as _size } from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { withRouter } from "../../hoc/withRouter";

import { Modal, Select, ConfirmBox } from ".";
import { showAlertMessage } from "./AlertMessage";

import { IconClose, IconXls } from "../../../helpers/iconStyles";

import { uploadCloneReadFile } from "../../../services/application";

import { updateLoadingState, uploadReadOnlyFileSuccess, removeUploadedReadOnlyFile, resetRemoveUploadedReadOnlyFile, uploadReadOnlyFileError, saveAndGetFileMappingData, resetSaveAndGetFileMappingData, resetReadOnlyDataMappingConfig } from "../../../actions/application";

const dropzoneRef = createRef();

class SingleDropzoneReadOnly extends Component {

  static defaultProps = {
    displayReadOnlyUploadedFiles: false,
    displayReadOnlyUploadedRemoveBtn: false,
    preserveSelectedFile: false,
    uploadedFileName: "",
    headerComponent: null,
    saveRecordsInDB: false
  };

  constructor(props) {
    super(props);

    this.hideAutoMappingFiles = ["none"];

    this.state = {
      mappingData: [],
      selectedFile: {},
      progress: 0,
      fileName: "",
      fileUrl: "",
      errorMessage: "",
      requiredFieldsError: [],
      duplicateEmails: [],
      successMessage: "",
      showWorksheetModal: false,
      showAlertBox: true
    };
  }

  componentDidMount() {
    const { uploadedFileName } = this.props;

    if (!_isEmpty(uploadedFileName)) {
      this.setState({ selectedFile: { name: uploadedFileName } });
    }
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.mappingData, this.props.mappingData)) {

      if (!_isEmpty(this.props.mappingData)) {
        this.setState({
          mappingData: this.props.mappingData,
          fileName: _get(this.props, "mappingData.uploadedFileResult.fileName", ""),
          fileUrl: _get(this.props, "mappingData.uploadedFileResult.url", ""),
          showWorksheetModal: false
        });
      } else {

        let updatedState = { mappingData: [], fileName: "", fileUrl: "" };

        if ((this.props.preserveSelectedFile || false) === false) {
          updatedState = { ...updatedState, selectedFile: {}, progress: 1 };
        }

        if (_isEmpty(updatedState)) { this.setState(updatedState); }
      }
    }

    if (!_isEqual(prevProps.uploadedFileName, this.props.uploadedFileName)) {

      if (!_isEmpty(this.props.uploadedFileName)) {

        this.setState({ selectedFile: { name: this.props.uploadedFileName }, progress: 100 });
      } else {
        this.setState({ selectedFile: {}, progress: 0 });
      }
    }

    if (!_isEqual(prevProps.uploadedFileURL, this.props.uploadedFileURL)) {
      this.setState({ fileUrl: this.props.uploadedFileURL });
    }

    if (!_isEqual(prevProps.successMessage, this.props.successMessage) && !_isEmpty(this.props.successMessage)) {

      this.setState({ successMessage: _get(this, "props.successMessage", "") });

      if (typeof this.props.resetSaveAndGetFileMappingData === "function") { this.props.resetSaveAndGetFileMappingData(); }
    }

    if (!_isEqual(prevProps.errors, this.props.errors) && !_isEmpty(this.props.errors)) {
      this._handleErrors();
    }

    if (!_isEqual(prevProps.removeUpload, this.props.removeUpload) && !_isEmpty(this.props.removeUpload)) {
      const { removeUpload, resetRemoveUploadedReadOnlyFile, intl } = this.props;
      const { showAlertBox } = this.state;

      if (_get(removeUpload, "status", null) === null) { return false; }

      if (_get(removeUpload, "status", null) === true) {

        // Reset parent components state
        this.setState({ selectedFile: {}, fileName: "", fileUrl: "", progress: 1 }, () => this._confirmUplodation(true));

        if ((showAlertBox || false) === true) {
          showAlertMessage((removeUpload.message || intl.formatMessage({ id: "message.file_removed_successfully", defaultMessage: "File removed successfully." })), "success");
        }
      } else {

        showAlertMessage((removeUpload.message || intl.formatMessage({ id: "message.file_removed_error", defaultMessage: "Something went wrong while removing file." })));
      }

      if (typeof resetRemoveUploadedReadOnlyFile === "function") {
        resetRemoveUploadedReadOnlyFile();
      }
    }
  }

  _handleErrors = () => {
    const { errors, resetSaveAndGetFileMappingData } = this.props;

    const state = {};
    const requiredFieldsError = _get(errors, "errors.requiredFieldsError", []);
    const duplicateEmails = _get(errors, "errors.duplicateEmails", []);
    let displayErrorMessage = true;

    if (!_isEmpty(requiredFieldsError || [])) {
      state.requiredFieldsError = _cloneDeep(requiredFieldsError);
      displayErrorMessage = false;
    }
    if (!_isEmpty(duplicateEmails || [])) {
      state.duplicateEmails = _cloneDeep(duplicateEmails);
      displayErrorMessage = false;
    }

    if (displayErrorMessage === true) {
      state.errorMessage = _get(errors, "message", "");
    }

    if (!_isEmpty(state)) {
      this.setState(state);
    }

    if (typeof resetSaveAndGetFileMappingData === "function") {
      resetSaveAndGetFileMappingData();
    }
  }

  _onDrop = async (acceptedFiles) => {
    const { source = "", displayReadOnlyUploadedFiles = false, uploadReadOnlyFileSuccess, uploadReadOnlyFileError, updateLoadingState, intl } = this.props;

    if (_isEmpty(acceptedFiles)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "file", defaultMessage: "file" })
      }));

      return false;
    }

    const selectedFile = acceptedFiles[0];

    if (displayReadOnlyUploadedFiles === true) {
      this.setState({ selectedFile, progress: 1 });
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      let data = new FormData();
      data.append("formFile", selectedFile);

      const response = await uploadCloneReadFile({ uploadType: source, formFile: data }, ({ progress = 0 }) => this.setState({ progress }));

      if ((response.flag || false) === true) {
        const showModel = !(this.hideAutoMappingFiles || []).includes(source);

        if (typeof uploadReadOnlyFileSuccess === "function") {
          uploadReadOnlyFileSuccess({
            data: _get(response, "data", {}),
            showModel,
            message: _get(response, "message", "")
          });
        }
      } else {

        if (typeof uploadReadOnlyFileError === "function") {
          uploadReadOnlyFileError({
            title: _get(response, "title", "Alert"),
            message: _get(response, "message", "Something went wrong while uploading file."),
            errors: _get(response, "error", "")
          });
        }

        if (displayReadOnlyUploadedFiles === true) {
          this.setState({ selectedFile: {}, progress: 1 });
        }
      }
    } catch (error) {

      if (typeof uploadReadOnlyFileError === "function") {
        uploadReadOnlyFileError({
          title: _get(error, "title", "Alert"),
          message: _get(error, "message", "Something went wrong while uploading file.")
        });
      }

      if (displayReadOnlyUploadedFiles === true) {
        this.setState({ selectedFile: {}, progress: 1 });
      }
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleModalSubmit = (modalName) => {
    const { source = "None", saveAndGetFileMappingData, saveRecordsInDB, intl } = this.props;
    const { mappingData, fileName } = this.state;

    if (_isEmpty(fileName)) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
        field: intl.formatMessage({ id: "dropzone.file_name", defaultMessage: "file name" })
      }));
    }

    if (typeof saveAndGetFileMappingData === "function") {

      let fileResult = _get(mappingData, "uploadedFileResult", {});

      const uploadedFileResult = { ...fileResult, "fileName": fileName };

      const variable = {
        uploadType: source,
        payload: {
          uploadedFileResult: uploadedFileResult,
          fuzzyMatched: (mappingData.fuzzyMatched || []),
          saveRecordsInDB: saveRecordsInDB
        }
      };

      saveAndGetFileMappingData(variable);
    }
  }

  _confirmUplodation = (resetParentPropsOnly = false) => {
    const { setState, resetReadOnlyDataMappingConfig, resetSaveAndGetFileMappingData } = this.props;
    const { mappingData, fileName, fileUrl } = this.state;

    if (resetParentPropsOnly === false) {
      this.setState({ successMessage: "" });
    }

    let parentState = {
      uploadedFileNameReadOnly: (!_isEmpty(fileName) || resetParentPropsOnly === true) ? fileName : _get(mappingData, "uploadedFileResult.fileName", ""),
      uploadedFileURLReadOnly: (!_isEmpty(fileUrl) || resetParentPropsOnly === true) ? fileUrl : _get(mappingData, "uploadedFileResult.url", ""),
    };

    if (typeof setState === "function") {
      setState({ ...parentState, showModal: false });
    }

    if (typeof resetSaveAndGetFileMappingData === "function") {
      resetSaveAndGetFileMappingData();
    }

    if (typeof resetReadOnlyDataMappingConfig === "function") {
      resetReadOnlyDataMappingConfig()
    }

    //Remove uploaded file
    this._handleRemoveFile(false);
  }

  _handleCloseMappingModal = () => {
    const { resetReadOnlyDataMappingConfig } = this.props;

    if (typeof resetReadOnlyDataMappingConfig === "function") {
      resetReadOnlyDataMappingConfig()
    }

    this._handleRemoveFile();
  }

  showDataMappingModal = () => {
    const { showModal = [], intl } = this.props;
    const { mappingData, fileName } = this.state;

    if (showModal === false) {
      return null;
    }

    const headerList = _cloneDeep(mappingData.headers || []);
    const dropdownValues = _cloneDeep(mappingData.dropdownValues || []);
    let fuzzyMatched = _cloneDeep(mappingData.fuzzyMatched || []);

    return (
      <Modal
        isOpen={showModal}
        centered={false}
        size="xl"
        bodyClassName="data-mapping-modal"
        baseClassName="custom-popup-css"
        modalTitle={intl.formatMessage({ id: "app_buy_leads.column_relationship", defaultMessage: "column relationship" })}
        onHide={() => this._handleCloseMappingModal()}
        onClose={() => this._handleCloseMappingModal()}
      >
        <>
          <div className="row gutters-5">
            <div className="col-lg-5">
              <div className="mb-16 d-flex align-items-center gap-16">
                <label className="heading-05 text-capitalize text-dark flex-shrink-0">
                  <FormattedMessage id="dropzone.file_name" defaultMessage="file name" />
                </label>
                <input
                  type="text"
                  className="form-control border"
                  name="uploadFileName"
                  defaultValue={(fileName || "")}
                  onChange={(e) => this.setState({ fileName: _get(e, "target.value", "") })}
                />
              </div>
            </div>
          </div>
          <div className="row gutters-5">
            <div className="col-lg-12">
              <div className="custom_table_data mh-500 table-responsive px-0">
                <table>
                  <thead>
                    <tr className="custom_tr_heading">
                      {(headerList || []).map((h, i) => (<th key={`map-header-${i}`}>{(h || "")}</th>))}
                    </tr>
                    <tr className="custom_tr_drpdwn">
                      {(fuzzyMatched || []).map((f, j) => {

                        return (
                          <th key={`map-dropdown-${j}`}>
                            <Select
                              className="text-capitalize form-custom-select form-custom-select-sm border"
                              placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                                { field: intl.formatMessage({ id: "fields", defaultMessage: "fields" }) })}
                              value={(f.matchedDropdownValue || null)}
                              options={dropdownValues}
                              isMulti={false}
                              getOptionValue={(option) => (option.key)}
                              getOptionLabel={(option) => (option.value)}
                              onChange={(e) => {
                                let tmp = fuzzyMatched;
                                tmp[j].matchedDropdownValue = e;

                                this.setState({ mappingData: { ...mappingData, fuzzyMatched: tmp } });
                              }}
                            />
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(mappingData.sampleData || []).map((v, l) => {
                      return (
                        <tr key={`map-data-${l}`}>
                          {Object.values(v || []).map((w, k) => (<td key={`map-content-${k}`}>{(w || "")}</td>))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row gutters-5">
            <div className="col-lg-12">
              <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
                <button className="btn btn-primary" onClick={() => this._handleModalSubmit()} >
                  <FormattedMessage id="btn.submit" defaultMessage="submit" />
                </button>
                <button className="btn btn-secondary" onClick={() => this._handleCloseMappingModal()} >
                  <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    )
  }

  _handleCloseErrorDataModal = () => {
    this.setState({
      errorMessage: "",
      requiredFieldsError: [],
      duplicateEmails: []
    });
  }

  _renderFailedDataModal = () => {
    const { intl } = this.props;
    const { requiredFieldsError, duplicateEmails, errorMessage } = this.state;

    if (_isEmpty(requiredFieldsError) && _isEmpty(duplicateEmails) && _isEmpty(errorMessage)) {
      return null;
    }

    const onlyErrorMessage = (_isEmpty(requiredFieldsError) && _isEmpty(duplicateEmails));

    let modalTitle = intl.formatMessage({ id: "app_buy_leads.required_fields", defaultMessage: "Required Fields" });

    if (!_isEmpty(duplicateEmails)) {
      modalTitle = intl.formatMessage({ id: "app_buy_leads.invalid_values", defaultMessage: "invalid values" });
    }

    return (
      <Modal
        size={(onlyErrorMessage === true) ? "md" : "lg"}
        modalTitle={modalTitle}
        onHide={() => this._handleCloseErrorDataModal()}
        onClose={() => this._handleCloseErrorDataModal()}
        isOpen={true}
        centered={true}
      >
        <>
          <div className="errorDataModal mb-16">
            {(!_isEmpty(errorMessage)) && (
              <p className="fw-bold">{(errorMessage || "")}</p>
            )}

            {(onlyErrorMessage === false) && (
              <>
                {(_size(requiredFieldsError) > 0) && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 pb-2 text-capitalize fw-bold">
                        <FormattedMessage id="dropzone.error_modal.required_field_title" defaultMessage="Required fields" />
                      </div>
                    </div>

                    {_map((requiredFieldsError || {}), (colms, email) => {

                      return (
                        <div key={email} className="row border p-2 mb-1 rounded">
                          <div className="col-sm-12 pb-2 fw-bold">
                            <FormattedMessage id="dropzone.error_modal.email" defaultMessage="Email: {email}" values={{ email: (email || "") }} />
                          </div>
                          <div className="col-sm-12 pb-2 fw-bold">
                            <FormattedMessage id="dropzone.error_modal.reason" defaultMessage="Reason:" />
                          </div>
                          <div className="col-sm-12 pb-2">
                            <FormattedMessage
                              id="dropzone.error_modal.columns_does_not_have_any_values_added"
                              defaultMessage="{columns} columns does not have any values added."
                              values={{ columns: (colms || []).join(", ") }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}

                {(_size(requiredFieldsError) > 0) && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 pb-2 text-capitalize fw-bold">
                        <FormattedMessage id="dropzone.error_modal.duplicate_email_title" defaultMessage="Duplicate Emails" />
                      </div>
                    </div>

                    <div className="row border p-2 mb-1 rounded">
                      {_map((duplicateEmails || {}), (emailIds, i) => <div key={i} className="col-sm-12 pb-2"> {(emailIds || "")} </div>)}
                    </div>
                  </>
                )}
              </>
            )}

          </div>
          <div className="m-t-15 p-r-15 p-l-15 text-end">
            <button className="btn btn-primary" onClick={() => this._handleCloseErrorDataModal()} >
              <FormattedMessage id="btn.close" defaultMessage="close" />
            </button>
          </div>
        </>
      </Modal>
    );
  }

  _handleRemoveFile = (showAlertBox = true) => {
    const { removeUploadedReadOnlyFile, saveRecordsInDB, source = "", success } = this.props;
    const { fileUrl } = this.state;

    if (!fileUrl) { return false; }

    this.setState({ showAlertBox });

    if (typeof removeUploadedReadOnlyFile === "function") {
      removeUploadedReadOnlyFile({ fileUrl: fileUrl, deleteRecordsInDB: saveRecordsInDB, fileType: source, fileId: _get(success, "inserted_id", null) });
    }
  }

  render() {
    const { acceptedFormat = "image/*", allowedExtensions = "", displayReadOnlyUploadedFiles = false, displayReadOnlyUploadedRemoveBtn = false, headerComponent, source } = this.props;
    const { successMessage, progress, selectedFile = {} } = this.state;

    return (
      <div className="position-relative">
        {(displayReadOnlyUploadedFiles === false || _isEmpty(selectedFile || {})) && (
          <>
            {(headerComponent) && (headerComponent)}

            <Dropzone
              maxFiles={1}
              multiple={false}
              accept={acceptedFormat}
              onDrop={(f, fileRejections) => this._onDrop(f, fileRejections)}
              maxSize={Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 10000000)}
              ref={dropzoneRef}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section>
                  <div {...getRootProps({ className: "dropzone" })} className="custom-file" data-text="Drop Files Here" data-button="Attach File">
                    <input {...getInputProps()} />
                  </div>
                  <label htmlFor="singleUpload" className="AlloweFilesLabel mt-8 heading-05">
                    <FormattedMessage
                      id="dropzone.allowed_files"
                      defaultMessage="Allowed file extensions : {allowedExtensions}"
                      values={{ allowedExtensions }}
                    />
                  </label>
                </section>
              )}
            </Dropzone>
          </>
        )}

        {(displayReadOnlyUploadedFiles === true && !_isEmpty(selectedFile || {})) && (
          <div className="d-flex align-items-center">
            <div>
              <IconXls width="42px" height="48px" color="#9B9B9B" />
            </div>
            <div className="flex-grow-1 ps-24">
              <div className="d-flex align-items-center justify-content-between mb-10 heading-03">
                <span className="text-grey-9d">{(selectedFile.name || "")}</span>
                <span className={`${(progress >= 100) ? "text-primary" : "text-warning"} fw-semibold`}>{progress}%</span>
              </div>
              <div className="progress progress-lg">
                <div className={`progress-bar ${(progress >= 100) ? "bg-primary" : "bg-warning"}`} style={{ width: `${progress}%` }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            {((displayReadOnlyUploadedRemoveBtn === true) && (progress >= 100)) && (
              <div className="flex-shrink-1 ps-18 align-self-end cursor-pointer" onClick={() => this._handleRemoveFile()}>
                <IconClose height="23px" width="23px" color="#BCBCBC" />
              </div>
            )}
          </div>
        )}

        {(!(this.hideAutoMappingFiles || []).includes(source)) && (
          <>
            {this.showDataMappingModal()}

            {this._renderFailedDataModal()}
          </>
        )}

        <ConfirmBox
          key="success-confirm-box"
          isOpen={(!_isEmpty(successMessage)) ? true : false}
          content={(successMessage || "")}
          onConfirm={() => this._confirmUplodation()}
          onClose={() => this._confirmUplodation()}
        />

      </div >
    );
  }
}

const mapStateToProps = (state, props) => ({
  mappingData: _get(state, "application.fileUploadReadOnly.dataMapping.data", {}),
  success: _get(state, "application.fileUploadReadOnly.dataMapping.success", {}),
  successMessage: _get(state, "application.fileUploadReadOnly.dataMapping.successMessage", ""),
  showModal: _get(state, "application.fileUploadReadOnly.dataMapping.showModal", false),
  removeUpload: _get(state, "application.fileUploadReadOnly.removeUpload", {}),
  errors: _get(state, "application.fileUploadReadOnly.errors", {}),
});

const mapDispatchToProps = (dispatch) => ({
  uploadReadOnlyFileSuccess: (data) => dispatch(uploadReadOnlyFileSuccess(data)),
  uploadReadOnlyFileError: (data) => dispatch(uploadReadOnlyFileError(data)),
  saveAndGetFileMappingData: (data) => dispatch(saveAndGetFileMappingData(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  removeUploadedReadOnlyFile: (data) => dispatch(removeUploadedReadOnlyFile(data)),
  resetRemoveUploadedReadOnlyFile: () => dispatch(resetRemoveUploadedReadOnlyFile()),
  resetSaveAndGetFileMappingData: () => dispatch(resetSaveAndGetFileMappingData()),
  resetReadOnlyDataMappingConfig: () => dispatch(resetReadOnlyDataMappingConfig()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SingleDropzoneReadOnly)));