import React from "react";
import { get as _get, isEmpty as _isEmpty, intersection as _intersection, isEqual as _isEqual } from "lodash";
import { Navigate } from "react-router-dom";

import { showAlertMessage } from "../../../components/common/controls";

import { store } from "../../../store/configureStore";

// hoc which return component and can be like connect from react-redux.
export function withPermissions(controller, method, operations, pattern = "AND") {

  return function (Component) {

    function WrapperComponent(props) {

      let allowed = hasPermissions(controller, method, operations, pattern);

      if (allowed === true) {
        return (<Component {...props} />);
      }

      const state = store.getState();

      const roleWiseRedirections = _get(state, "application.constants.roleWiseRedirections", []);
      const roleId = _get(state, "oauth.token.role_id", null);

      let redirectUrl = "/";

      (roleWiseRedirections || []).forEach((role) => {
        if ((role.roleIds || []).includes(roleId) && role.redirectionPath !== null) {
          redirectUrl = (role.redirectionPath || "/");
        }
      });

      setTimeout(() => {
        showAlertMessage("You don't have permission to visit this page.");
      }, 100);

      return (<Navigate to={redirectUrl} />);
    }

    return WrapperComponent;
  }

}

// hoc can be used within render
export const WithPermissions = ({ controller, method, operations, children, pattern = "AND" }) => {

  const Component = withPermissions(controller, method, operations, pattern)(() => (<>{children}</>));

  return (<Component />);
}

// utility function used to identify if current user is having permission or not.
export const hasPermissions = (controller, method, operations, pattern = "AND") => {
  const state = store.getState();

  const permissions = _get(state, "application.permissions", []);

  const filteredPermission = ((permissions || []).find((p) => (p.controller === controller && p.method === method && p.allowed === true)) || {});

  if (_isEmpty(filteredPermission)) {
    return false;
  }

  let allowed = false;

  if (_isEmpty(operations) && filteredPermission.allowed === true) {
    return true;
  }

  const matchedPermissions = _intersection((filteredPermission.operations || []), operations);

  if (pattern === "OR") {
    allowed = !_isEmpty(matchedPermissions);
  } else {
    allowed = _isEqual(matchedPermissions, operations)
  }

  return allowed;
}
