import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { showAlertMessage } from "../../components/common/controls";
import { hasPermissions } from "../../components/hoc/Permissions";
import { withRouter } from "../../components/hoc/withRouter";
import { appRoutes } from "../../helpers/constants";

import PaymentSuccess from "../Dashboard/components/PaymentSuccess";
import PaymentFailed from "../Dashboard/components/PaymentFailed";
import UnderConstruction from "../GenericPages/UnderConstruction";
import Sidebar from "../../components/common/adminLayout/Sidebar";
import ViewQuoteData from "../Dashboard/components/ViewQuoteData";
import Header from "../../components/common/adminLayout/Header";
import Footer from "../../components/common/adminLayout/Footer";
import SellerProfile from "../Dashboard/SellerProfile";
import UserManagement from "../UserManagement";
import MarketPlace from "../MarketPlace";
import APIKeys from "../API/APIKeys";
import Dashboard from "../Dashboard";
import SellLeads from "../SellLeads";
import Settings from "../Settings";
import BuyLeads from "../BuyLeads";
import Invoices from "../Invoices";
import MyTeam from "../MyTeam";

import { fetchApplication } from "../../actions/application";

const components = {
  underConstruction: UnderConstruction,
  paymentSuccess: PaymentSuccess,
  userManagement: UserManagement,
  paymentFailed: PaymentFailed,
  sellerProfile: SellerProfile,
  viewQuoteData: ViewQuoteData,
  marketPlace: MarketPlace,
  dashboard: Dashboard,
  sellLeads: SellLeads,
  settings: Settings,
  buyLeads: BuyLeads,
  invoices: Invoices,
  APIKeys: APIKeys,
  myTeam: MyTeam,
}

class Application extends Component {

  componentDidMount() {
    const { fetchApplication } = this.props;

    if (typeof fetchApplication === "function") { fetchApplication(); }
  }

  render() {
    const { token, roleWiseRedirections, roleId, permissions, intl, ...rest } = this.props;

    const from = _get(rest, "location.pathname", "/");

    if (!_isEmpty(token) && !_isEmpty(permissions)) {
      const path = (from || "").split("/").filter((s) => !_isEmpty(s));

      if ((path || []).length >= 1) {
        const checkPermission = hasPermissions(path[0], (path[1] || "index"));

        if (checkPermission === false) {
          let redirectUrl = "/";

          (roleWiseRedirections || []).forEach((role) => {
            if ((role.roleIds || []).includes(roleId) && role.redirectionPath !== null) {
              redirectUrl = (role.redirectionPath || "/");
            }
          });

          setTimeout(() => {
            showAlertMessage(intl.formatMessage({ id: "permission.page_error", defaultMessage: "You don't have permission to visit this page." }));
          }, 100);

          if (from !== redirectUrl) {
            return <Navigate to={{ pathname: redirectUrl }} />;
          }
        }
      }
    }

    return (
      <>
        <Header />
        <div className="wrapper d-lg-flex">
          <Sidebar />
          <div className="bg-white-f2 pt-12 px-lg-24 px-12 pb-24 flex-grow-1">
            <Routes>
              {
                appRoutes.map(({ component, ...rest }, key) => {
                  const RouteComponent = (components[component] || "");

                  if (typeof RouteComponent !== "undefined" && RouteComponent !== "") {
                    return <Route key={key} {...rest} element={<RouteComponent />} />
                  } else {
                    return null;
                  }
                })
              }
              <Route path="*" element={<Navigate from="*" to="/" replace />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: _get(state, "oauth.token", {}),
  roleId: _get(state, "oauth.user.role_id", null),
  permissions: _get(state, "application.permissions", []),
  roleWiseRedirections: _get(state, "application.constants.roleWiseRedirections", [])
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplication: () => dispatch(fetchApplication())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Application)));
