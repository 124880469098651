import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Select, DateRangePicker, showAlertMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconTabDiscussion, IconTabWorkflow, IconBuyerDashboard, IconInfo, IconQuote, IconDots, IconDownloadAlt } from "../../../helpers/iconStyles";

import { fetchDashboardTopFilters, resetDashboardTopFiltersData, UpdateDashboardFilterSelection, fetchProjectDashboardOverallRecords, setQuoteGroupID, setQuoteID, resetAllTabsData } from "../../../actions/leads";

import ProjectDetails from "../components/ProjectDetails";
import OverallDashboard from "./OverallDashboard";
import Funds from "../components/Funds";
import Data from "../components/Data";
import Dashboard from "./Dashboard";
import ViewQuote from "../components/ViewQuote";
import AdminDashboard from "../Admin/Dashboard";
import ProjectQuotes from "../Admin/components/ProjectQuotes";

import { USER_ROLES } from "../../../helpers/constants";

class Buyer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "dashboard",
      activeTabForData: "data_check",
      filterStatus: null,
      dashboardTopFilters: {}
    };
  }

  componentDidMount() {
    //const { setQuoteGroupID, setQuoteID } = this.props;

    this._fetchDashboardTopFilters();
    this._setDashboardTopFilters();

    //if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    //if (typeof setQuoteID === "function") { setQuoteID(null); }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dashboardTopFilters, this.props.dashboardTopFilters) && !_isEmpty(this.props.dashboardTopFilters)) {
      const { dashboardTopFilters, resetDashboardTopFiltersData } = this.props;

      if ((dashboardTopFilters.status || null) === null) { return false; }

      if ((dashboardTopFilters.status || false) === true) {

        this._setDashboardTopFilters();
      } else {
        showAlertMessage((dashboardTopFilters.message || "Something went wrong while fetching dashboard top filters details."));

        if (typeof resetDashboardTopFiltersData === "function") { resetDashboardTopFiltersData(); }
      }
    }

    if (!_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0))) {
      const { resetAllTabsData } = this.props;

      this._fetchDashboardTopFilters();

      this._fetchProjectDashboardOverallRecords();

      this._updateDashboardFilters("selectedSeller", { id: 0, user_name: "Select" });

      if (typeof resetAllTabsData === "function") { resetAllTabsData(); }
      this.setState({ activeTab: "dashboard" })
    }

    if (!_isEqual(prevProps.activeBuyerTab, this.props.activeBuyerTab) && (this.props.activeBuyerTab !== null)) {
      this._fetchDashboardTopFilters();
    }

    if (!_isEqual(prevState.activeTab, this.state.activeTab) && (this.state.activeTab === "dashboard")) {
      this._fetchProjectDashboardOverallRecords();
    }
  }

  _setDashboardTopFilters = () => {
    const { dashboardTopFilters } = this.props;

    this.setState({ dashboardTopFilters: _get(dashboardTopFilters, "data", {}) });
  }

  _fetchDashboardTopFilters = () => {
    const { fetchDashboardTopFilters, dashboardFiltersSelection, activeBuyerTab } = this.props;

    if (typeof fetchDashboardTopFilters === "function") {
      fetchDashboardTopFilters({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        roleFlag: (activeBuyerTab || null)
      });
    }
  }

  _fetchProjectDashboardOverallRecords = () => {
    const { fetchProjectDashboardOverallRecords, dashboardFiltersSelection } = this.props;

    if (typeof fetchProjectDashboardOverallRecords === "function") {
      fetchProjectDashboardOverallRecords({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        fromDate: null,
        toDate: null,
        sellerId: null
      });
    }
  }

  _setDateRange = (startDate, endDate) => {

    this._updateDashboardFilters("startDate", (startDate || null));
    this._updateDashboardFilters("endDate", (endDate || null));
  }

  _updateDashboardFilters = (key, val) => {
    const { UpdateDashboardFilterSelection, setQuoteGroupID, setQuoteID } = this.props;

    if (typeof UpdateDashboardFilterSelection === "function") {
      UpdateDashboardFilterSelection({
        key: (key || null),
        value: (val || null)
      });
    }

    if (key === "selectedProject" && (_get(val, "quote_group_id", null) !== null) && (_get(val, "quote_group_id", null) !== null)) {

      setQuoteGroupID({ "id": _get(val, "quote_group_id", null), name: _get(val, "quote_group_id", null) });
      setQuoteID({ "id": _get(val, "quote_id", null), name: _get(val, "quote_id", null) });
    }
  }

  _renderTopFilter = () => {
    const { dashboardTopFilters, dashboardFiltersSelection, roleId, intl } = this.props;

    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4">
            <div className="mb-20">
              <label className="mb-12 heading-04 text-capitalize"><FormattedMessage id="dashboard.project" defaultMessage="project" /></label>
              <div className="position-relative">
                <Select
                  className="text-capitalize form-custom-select"
                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                  options={_get(dashboardTopFilters, "data.projects", [])}
                  value={_get(dashboardFiltersSelection, "selectedProject", null)}
                  getOptionLabel={(option) => (option.project_name || "")}
                  getOptionValue={(option) => (option.id || 0)}
                  onChange={(selectedOption) => this._updateDashboardFilters("selectedProject", (selectedOption || null))}
                />
              </div>
            </div>
          </div>

          {(roleId === _get(USER_ROLES, "ADMIN.id", 0)) && (
            <div className="col-lg-4">
              <div className="mb-20">
                <label className="mb-12 heading-04 text-capitalize"><FormattedMessage id="dashboard.seller" defaultMessage="Seller" /></label>
                <div className="position-relative">
                  <Select
                    className="text-capitalize form-custom-select"
                    placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                    options={_get(dashboardTopFilters, "data.users", [])}
                    value={_get(dashboardFiltersSelection, "selectedSeller", null)}
                    getOptionLabel={(option) => (option.user_name || "")}
                    getOptionValue={(option) => (option.id || 0)}
                    onChange={(selectedOption) => this._updateDashboardFilters("selectedSeller", (selectedOption || null))}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-4">
            <div className="mb-20">
              <label className="mb-12 heading-04 text-capitalize">
                <FormattedMessage id="dashboard.date_range" defaultMessage="date range" />
              </label>
              <div className="position-relative">
                <DateRangePicker
                  onChange={(startDate, endDate) => this._setDateRange(startDate, endDate)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderProjectDetailsFundsDataNo = () => {
    return (
      <div className="col-lg-12 mt-20">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">Data: </h4>

            <p className="heading-05 pt-2">Data is not yet available</p>

            <div className="ms-lg-auto">
              <IconDots height="20" width="4" color="#e84968" />
            </div>
          </div>

          <div className="project-status-wrap">
            <p className="heading-04 fw-medium mb-8 d-flex align-items-center gap-12">Data Download</p>

            <div className="row gx-12">
              <div className="col-lg-8 mb-24">
                <h4 className="heading-06 fw-medium text-primary text-capitalize">Batch 1</h4>

                <div className="border-bottom border-secondary pb-8 mb-8"></div>

                <div className="row gx-12">
                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Batch 1 Volume of Leads Required</p>
                      <p>500</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Batch 1 Delivery Date</p>
                      <p>01/10/2023</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Download Data</p>
                      <p>Not Available</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Available</p>
                      <p>Not Checked</p>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-16 mt-12">
                  <button className="btn btn-md btn-grey disabled"><IconDownloadAlt height="15px" width="13px" color="#fff" /> DOWNLOAD</button>
                  <button className="btn btn-md btn-success disabled">APPROVE DATA</button>
                  <button className="btn btn-md btn-danger disabled">reject data</button>
                </div>
              </div>

              <div className="col-lg-8 mb-24">
                <h4 className="heading-06 fw-medium text-primary text-capitalize">Batch 2 </h4>

                <div className="border-bottom border-secondary pb-8 mb-8"></div>

                <div className="row gx-12">
                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Batch 2 Volume of Leads Required</p>
                      <p>500</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Batch 2 Delivery Date</p>
                      <p>01/10/2023</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Download Data</p>
                      <p>Not Available</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4">
                    <div className="project-list-card">
                      <p>Available</p>
                      <p>Not Checked</p>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-16 mt-12">
                  <button className="btn btn-md btn-grey disabled"><IconDownloadAlt height="15px" width="13px" color="#fff" /> DOWNLOAD</button>
                  <button className="btn btn-md btn-success disabled">APPROVE DATA</button>
                  <button className="btn btn-md btn-danger disabled">reject data</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { dashboardFiltersSelection, activeBuyerTab, roleId } = this.props;
    const { activeTab, activeTabForData, filterStatus } = this.state;

    return (
      <div className="row">

        {this._renderTopFilter()}

        {((_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0)) ? (
          <OverallDashboard activeBuyerTab={activeBuyerTab} />
        ) : (
          <>

            <div className="col-md-12">
              <ul className="nav nav-tabs nav-tabs-secondary mb-20" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link text-capitalize ${(activeTab === "dashboard") ? "active" : ""}`} id="dashboard-tab" onClick={() => this.setState({ activeTab: "dashboard" })}>
                    <IconBuyerDashboard />
                    <FormattedMessage id="buyer_dashboard.tab.title.dashboard" defaultMessage="Project Dashboard" />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link text-capitalize ${(activeTab === "projectDetails") ? "active" : ""}`} id="summary-tab" onClick={() => this.setState({ activeTab: "projectDetails" })}>
                    <IconInfo />
                    <FormattedMessage id="buyer_dashboard.tab.title.project_details" defaultMessage="Project Details" />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link text-capitalize ${(activeTab === "quote") ? "active" : ""}`} id="quote-tab" onClick={() => this.setState({ activeTab: "quote" })}>
                    <IconQuote />
                    <FormattedMessage id="buyer_dashboard.tab.title.quote" defaultMessage="Quote" /> </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link text-capitalize ${(activeTab === "funds") ? "active" : ""}`} id="funds-tab" onClick={() => this.setState({ activeTab: "funds" })}>
                    <IconTabWorkflow />
                    <FormattedMessage id="buyer_dashboard.tab.title.funds" defaultMessage="funds" />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link text-capitalize ${(activeTab === "data") ? "active" : ""}`} id="data-tab" onClick={() => this.setState({ activeTab: "data" })}>
                    <IconTabDiscussion />
                    <FormattedMessage id="buyer_dashboard.tab.title.data" defaultMessage="Data" />
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="dashboard" role="tabpanel">
                {(activeTab === "dashboard") && (roleId === _get(USER_ROLES, "ADMIN.id", 0)) && (
                  <AdminDashboard setStateFromChild={(args) => this.setState(args)} />
                )}

                {(activeTab === "dashboard") && (roleId === _get(USER_ROLES, "BUYER.id", 0)) && (
                  <Dashboard activeTabMain={activeTab} setStateFromChild={(args) => this.setState(args)} />
                )}

                {(activeTab === "projectDetails") && (
                  <ProjectDetails />
                )}

                {(activeTab === "quote") && (roleId === _get(USER_ROLES, "ADMIN.id", 0)) && (
                  <ProjectQuotes source="quoteTab" />
                )}

                {(activeTab === "quote") && (roleId !== _get(USER_ROLES, "ADMIN.id", 0)) && (
                  <ViewQuote source="quoteTab" setStateFromChild={(args) => this.setState(args)} />
                )}

                {(activeTab === "funds") && (
                  <Funds source="buyer" />
                )}

                {(activeTab === "data") && (
                  <Data tabToActive={(activeTabForData || "")} filterStatus={filterStatus} />
                )}
              </div>
            </div>
          </>
        )}

        {/*this._renderProjectDetailsQuote()}

                {this._renderProjectDetailsFundsDataNo()*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardTopFilters: _get(state, "leads.dashboard.dashboardTopFilters", {}),
  projectDashboardOverallRecords: _get(state, "leads.dashboard.projectDashboardOverallRecords", {}),
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", "$ "),
  roleId: _get(state, "oauth.user.role_id", null),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardTopFilters: (data) => dispatch(fetchDashboardTopFilters(data)),
  resetDashboardTopFiltersData: () => dispatch(resetDashboardTopFiltersData()),
  fetchProjectDashboardOverallRecords: (data) => dispatch(fetchProjectDashboardOverallRecords(data)),
  UpdateDashboardFilterSelection: (data) => dispatch(UpdateDashboardFilterSelection(data)),
  setQuoteGroupID: (id) => dispatch(setQuoteGroupID(id)),
  setQuoteID: (id) => dispatch(setQuoteID(id)),
  resetAllTabsData: () => dispatch(resetAllTabsData()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Buyer)));