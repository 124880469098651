import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default React.memo(function Footer(props) {

  return (
    <footer className="footer py-24">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg">
            <div className="footer-copyright text-center">
              <p>
                <FormattedMessage id="footer.admin.copyright_text" defaultMessage="Copyright © 2023 Leads United Ltd. All rights reserved | " />
                <NavLink to="#" className="text-capitalize">
                  <FormattedMessage id="footer.admin.terms_and_conditions" defaultMessage="Terms & Condition" />
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});