import React, { Component, Fragment } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { EmptyLabel, showAlertMessage, NoDataMessage } from "../../../components/common/controls";
import { hasPermissions } from "../../../components/hoc/Permissions";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconDots, IconInfoAlt, IconMinus } from "../../../helpers/iconStyles";
import { USER_ROLES, quoteStatusLookup } from "../../../helpers/constants";

import { fetchQuoteDetails, resetQuoteDetails, setEditQuoteGroupId, fetchProjectDetails, resetEditQuoteGroupId, approveSellerQuote, resetApproveSellerQuote } from "../../../actions/leads";
import { updateLoadingState } from "../../../actions/application";

import RejectQuotes from "../Buyer/components/workflowComponents/RejectQuotes";

class ViewQuote extends Component {

  constructor(props) {
    super(props);

    this.state = {
      quoteGroupId: (props.quoteId !== null) ? props.quoteId : _get(props, "selectedQuoteGroupId", ""),
      showDetail: (_get(props, "source", "") !== "quoteTab") ? true : false,
      isOpenRejectQuoteModal: false,
    }
  }

  componentDidMount() {
    const { resetEditQuoteGroupId, selectedQuoteGroupId } = this.props;

    if ((selectedQuoteGroupId || "") !== "") {
      this._fetchQuoteDetails();
      this._fetchProjectDetails();
    }

    if (typeof resetEditQuoteGroupId === "function") { resetEditQuoteGroupId(); }
  }

  componentDidUpdate(prevProps, prevStates) {

    if ((!_isEqual(prevProps.quoteDetails, _get(this.props, "quoteDetails", {}))) && (_get(this.props, "quoteDetails.status", null) === false)) {
      const { quoteDetails, resetQuoteDetails } = this.props;

      showAlertMessage((quoteDetails.message || "Something went wrong while fetching quote details."));
      if (typeof resetQuoteDetails === "function") { resetQuoteDetails(); }
    }

    if (
      (!_isEqual(_get(prevProps, "projectId", 0), _get(this.props, "projectId", 0)) && (_get(this.props, "projectId", 0) !== 0)) ||
      (!_isEqual(_get(prevProps, "selectedQuoteGroupId", null), _get(this.props, "selectedQuoteGroupId", null)) && (_get(this.props, "selectedQuoteGroupId", null) !== null))) {

      if ((_get(this.props, "selectedQuoteGroupId", "") !== "")) {
        this._fetchQuoteDetails();
      }

      this._fetchProjectDetails();
    }

    if (!_isEqual(prevProps.approveSellerQuoteData, this.props.approveSellerQuoteData)) {

      if (_get(this.props, "approveSellerQuoteData.status", null) === null) { return false; }

      if (_get(this.props, "approveSellerQuoteData.status", false) === true) {

        showAlertMessage(_get(this.props, "approveSellerQuoteData.data.message", "Quote assigned to buyer successfully."), "success");
        this._fetchQuoteDetails();
        this._fetchProjectDetails();
      } else {
        showAlertMessage(_get(this.props, "approveSellerQuoteData.message", "Something went wrong while assigning quote to buyer successfully."));
      }

      if (typeof this.props.resetApproveSellerQuote === "function") { this.props.resetApproveSellerQuote(); }
    }

    // After Rejecting Quote
    if (!_isEqual(prevProps.updateQuoteDetails, this.props.updateQuoteDetails) && !_isEmpty(this.props.updateQuoteDetails)) {

      if (_get(this.props, "updateQuoteDetails.status", null) === null) { return false; }

      if (_get(this.props, "updateQuoteDetails.status", false) === true) {

        if (typeof this.props.setStateFromChild === "function") {
          this.setState({ isOpenRejectQuoteModal: false });
        }

        this._fetchQuoteDetails();
        this._fetchProjectDetails();
        showAlertMessage(_get(this.props, "updateQuoteDetails.message", "Quote has been rejected successfully."), "success");
      } else {
        showAlertMessage(_get(this.props, "updateQuoteDetails.message", "Something went wrong while rejecting quote."));
        if (typeof this.props.resetUpdateQuoteStatusData === "function") { this.props.resetUpdateQuoteStatusData(); }
      }
    }
  }

  _fetchProjectDetails = () => {
    const { fetchProjectDetails, projectId } = this.props;

    if (typeof fetchProjectDetails === "function") { fetchProjectDetails((projectId || 0)); }
  }

  _sendQuoteToBuyer = () => {
    const { approveSellerQuote, selectedQuoteGroupId } = this.props;

    if (typeof approveSellerQuote === "function") {
      approveSellerQuote({ quoteGroupId: (selectedQuoteGroupId || ""), });
    }
  }

  _fetchQuoteDetails = () => {
    const { fetchQuoteDetails, projectId, selectedQuoteGroupId, intl } = this.props;

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if ((selectedQuoteGroupId || "") === "") {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof fetchQuoteDetails === "function") {
      fetchQuoteDetails({
        projectId: (projectId || null),
        quoteId: (selectedQuoteGroupId || ""),
        returnBillingInfo: 0
      });
    }
  }

  _renderBatchDetails = () => {
    const { quoteDetails, displayDateFormat } = this.props;

    return (
      <Fragment>
        {(_get(quoteDetails, "data.quotes", [])).map((batch, i) => {

          return (
            <Fragment key={`batch-${i}`}>
              <div className="col-lg-4">
                <h4 className="heading-06 fw-medium text-primary text-capitalize">
                  <FormattedMessage id="seller.workflow.accepted_quote.batch" defaultMessage="batch {count}" values={{ count: (i + 1) }} />
                </h4>

                <div className="border-bottom border-secondary pb-8 mb-8"></div>

                <div className="row gx-12">
                  <div className="col-lg-6">
                    <div className="project-list-card text-capitalize">
                      <p><FormattedMessage id="seller.workflow.table.volume_of_leads" defaultMessage="volume of leads" /></p>
                      <p>{_get(batch, "lead_volume", 0)}</p>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="project-list-card text-capitalize">
                      <p><FormattedMessage id="seller.workflow.table.delivery_date" defaultMessage="delivery date" /></p>
                      <p>{moment(_get(batch, "delivery_date", "")).format(displayDateFormat)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  _renderProjectDetails() {
    const { quoteDetails, projectDetails, currencySymbol, displayDateFormat } = this.props;

    let statusText = "";
    let totalBatches = 0;

    if (!_isEmpty(_get(quoteDetails, "data.quotes", []))) {
      totalBatches = _get(quoteDetails, "data.quotes", []).length;
      statusText = _get(quoteDetails, "data.quotes[0].status_text", "");
    }

    return (
      <div className="row gx-12">
        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.total_volume_of_leads" defaultMessage="Total Volume of Leads Required" /></p>
            <p>{_get(projectDetails, "leads_volume", 0)}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.final_delivery_date" defaultMessage="Final Delivery Date" /></p>
            <p>{(_get(projectDetails, "delivery_date", null)) ? moment(_get(projectDetails, "delivery_date", null)).format(displayDateFormat) : ""}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.multiple_batches" defaultMessage="Batches/Multiple Delivery Dates" /></p>
            <p>
              {(totalBatches > 1) ? "YES" : "NO"}
              <span className="position-absolute end-0 pe-12">
                <IconInfoAlt height="14" width="14" color="#9D9B9B" />
              </span>
            </p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.cost_per_lead" defaultMessage="Cost per Lead" /></p>
            <p>{`${currencySymbol}${_get(projectDetails, "average_cost_per_lead", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.total_cost" defaultMessage="Total Cost" /></p>
            <p>{`${currencySymbol}${_get(projectDetails, "total_project_cost", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className={`project-list-card text-capitalize ${_get(quoteStatusLookup, `[${(statusText.toUpperCase())}.background]`, "")}`}>
            <p className="text-dark"><FormattedMessage id="dashboard.funds.tab.quote_status" defaultMessage="Quote Status" /></p>
            <p className={_get(quoteStatusLookup, `[${(statusText.toUpperCase())}.statusClass]`, "")}> {(statusText || <EmptyLabel />)}</p>
          </div>
        </div>
      </div>
    )
  }

  _renderCriteria = () => {
    const { projectCriteria } = this.props;

    return (
      <div className="row gx-12">
        {(projectCriteria || []).map((criteria, i) => {
          return (
            <div key={`criteria-${i}`} className="col-xl-2 col-lg-4">
              <div className="project-list-card">
                <p className="text-capitalize">{_get(criteria, "target_type", "")}</p>
                <p className="text-capitalize" title={_get(criteria, "target_value", "-")}>{_get(criteria, "target_value", "-")}</p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  _backToAllQuotes = () => {
    const { setStateFromChild } = this.props;

    if (typeof setStateFromChild === "function") { setStateFromChild({ showDetail: false }); }
  }

  _renderQuoteDetail = () => {
    const { projectDetails, quoteDetails, currencySymbol, roleId, setStateFromChild } = this.props;
    const { showDetail, isOpenRejectQuoteModal } = this.state;

    const quoteStatus = _get(quoteDetails, "data.quotes[0].status", 0);

    return (
      <Fragment>
        <div className="project-status-wrap">
          <p className="heading-04 fw-medium mb-8 text-capitalize">
            <FormattedMessage id="view_quote.quote_details" defaultMessage="Quote Details" />
          </p>

          {this._renderProjectDetails()}

          <div className="border-bottom border-secondary pb-12 mb-12"></div>

          <p className="heading-04 fw-medium mb-8 d-flex align-items-center gap-12 text-capitalize">
            <IconMinus height="14px" width="14px" color="#E94969" />
            <FormattedMessage id="view_quote.criteria" defaultMessage="Criteria" />
          </p>

          {this._renderCriteria()}

          <div className="border-bottom border-secondary pb-12 mb-12"></div>

          <p className="heading-04 fw-medium mb-8 d-flex align-items-center gap-12 text-capitalize">
            <IconMinus height="14px" width="14px" color="#E94969" />
            <FormattedMessage id="view_quote.batches" defaultMessage="Batches" />
          </p>

          <div className="row gx-12">
            {this._renderBatchDetails()}
          </div>

          {(roleId !== _get(USER_ROLES, "SELLER.id", 0)) && (
            <Fragment>

              <div className="border-bottom border-secondary pb-12 mb-12"></div>

              <p className="heading-02">
                <FormattedMessage
                  id="view_quote.total_cost"
                  defaultMessage="Total Cost: {currency_symbol}{number}"
                  values={{ currency_symbol: currencySymbol, number: _get(projectDetails, "total_project_cost", 0) }}
                />
              </p>

              <div className="border-bottom border-secondary pb-12 mb-12"></div>

              <div className="d-flex gap-16 mt-24">
                {((showDetail || false) === true) && (
                  <button className="btn btn-md btn-outline-primary text-uppercase" onClick={() => this._backToAllQuotes()}>
                    <FormattedMessage id="view_quote.back_to_all_quotes" defaultMessage="back to all quotes" />
                  </button>
                )}

                {(
                  (hasPermissions("dashboard", "view-quote", ["approve"]) === true) &&
                  (![_get(quoteStatusLookup, "ACCEPTED.id", 0), _get(quoteStatusLookup, "REJECTED.id", 0), _get(quoteStatusLookup, "CANCELLED.id", 0)].includes(quoteStatus))
                ) && (
                    <button
                      className="btn btn-md btn-success text-uppercase"
                      onClick={() => {
                        const { source, setStateFromChildToParent } = this.props;

                        //Set Dashboard activeTab state from View Quote Page after clicking Approve Quote
                        if (((source || "") === "project_quote") && (typeof setStateFromChildToParent === "function")) {
                          setStateFromChildToParent({ activeTab: "funds" })
                        } else if (((source || "") === "quoteTab") && (typeof setStateFromChild === "function")) {
                          setStateFromChild({ activeTab: "funds" });
                        }
                      }
                      }
                    >
                      <FormattedMessage id="view_quote.approve_quote" defaultMessage="approve quote" />
                    </button>
                  )}

                {(
                  (hasPermissions("dashboard", "view-quote", ["reject"]) === true) &&
                  (![_get(quoteStatusLookup, "ACCEPTED.id", 0), _get(quoteStatusLookup, "REJECTED.id", 0), _get(quoteStatusLookup, "CANCELLED.id", 0)].includes(quoteStatus))
                ) && (
                    <Fragment>
                      <button className="btn btn-md btn-danger text-uppercase" onClick={() => this.setState({ isOpenRejectQuoteModal: true })}>
                        <FormattedMessage id="view_quote.reject_quote" defaultMessage="reject quote" />
                      </button>
                      <RejectQuotes isOpenRejectQuoteModal={(isOpenRejectQuoteModal || false)} setStateFromChild={(args) => this.setState(args)} />
                    </Fragment>
                  )}

                {(hasPermissions("dashboard", "view-quote", ["btn-send-quote-to-buyer"]) === true) && ((_get(projectDetails, "is_quote_send_to_buyer", 0) === 0)) && (
                  ![_get(quoteStatusLookup, "ACCEPTED.id", 0), _get(quoteStatusLookup, "REJECTED.id", 0), _get(quoteStatusLookup, "CANCELLED.id", 0)].includes(quoteStatus)) && (
                    <button className="btn btn-md btn-primary text-uppercase" onClick={() => this._sendQuoteToBuyer()}>
                      <FormattedMessage id="view_quote.send_quote_to_buyer" defaultMessage="send quote to buyer" />
                    </button>
                  )}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }


  render() {
    const { selectedProject, roleId, intl } = this.props;

    return (
      <div className="col-lg-12">
        <div className="card p-15">

          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">
              <FormattedMessage id="view_quote.title" defaultMessage="Quote" />
            </h4>

            <div className="ms-lg-auto">
              <IconDots height="20" width="4" color="#e84968" />
            </div>
          </div>
          {(_get(selectedProject, "quote_group_id", "") !== "") && (roleId !== _get(USER_ROLES, "ADMIN.id", 0)) && (
            this._renderQuoteDetail()
          )}

          {(roleId === _get(USER_ROLES, "ADMIN.id", 0)) && (
            this._renderQuoteDetail()
          )}

          {(_get(selectedProject, "quote_group_id", "") === "") && (roleId !== _get(USER_ROLES, "ADMIN.id", 0)) && (
            <NoDataMessage
              className="text-center no-message-wrapper table-no-data-message"
              message={intl.formatMessage({ id: "message.no_quote", defaultMessage: "No quote has been submitted yet" })}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  approveSellerQuoteData: _get(state, "leads.dashboard.approveSellerQuote", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", "$"),
  quoteDetails: _get(state, "leads.dashboard.quoteDetails", {}),
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", null),
  selectedQuoteGroup: _get(state, "leads.dashboard.selectedQuoteGroupId", null),
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  projectDetails: _get(state, "leads.dashboard.projectDetails.data.project[0]", {}),
  projectCriteria: _get(state, "leads.dashboard.projectDetails.data.target_audience", []),
  selectedProject: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject", {}),
  roleId: _get(state, "oauth.user.role_id", null),
  updateQuoteDetails: _get(state, "leads.dashboard.updateQuoteDetails", {}),
});

const mapDispatchToProps = (dispatch) => ({
  approveSellerQuote: (data) => dispatch(approveSellerQuote(data)),
  fetchQuoteDetails: (data) => dispatch(fetchQuoteDetails(data)),
  resetQuoteDetails: () => dispatch(resetQuoteDetails()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  setEditQuoteGroupId: (id) => dispatch(setEditQuoteGroupId(id)),
  fetchProjectDetails: (data) => dispatch(fetchProjectDetails(data)),
  resetEditQuoteGroupId: () => dispatch(resetEditQuoteGroupId()),
  resetApproveSellerQuote: () => dispatch(resetApproveSellerQuote()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ViewQuote)));