import React, { Component, Fragment } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, cloneDeep as _cloneDeep, set as _set } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, ErrorMessage, DatePicker, EmptyLabel } from "../../../../../components/common/controls";
import { withRouter } from "../../../../../components/hoc/withRouter";

import ViewQuote from "../../../components/ViewQuote";

import { getLastIndexOfFromURL, isValidIntegerNumber } from "../../../../../helpers/utils";
import { IconDots, IconInfoAlt, IconMinus, IconClose } from "../../../../../helpers/iconStyles";
import { quoteStatusLookup } from "../../../../../helpers/constants";

import { fetchProjectDetails, resetDashboardError, submitQuoteRequest, resetSubmitQuoteData, fetchQuoteDetails, resetQuoteDetails, resetEditQuoteGroupId } from "../../../../../actions/leads";
import { updateLoadingState } from "../../../../../actions/application";

class SubmitQuote extends Component {
  constructor(props) {
    super(props);

    this.defaultBatchValues = {
      "volume_of_leads": "",
      "delivery_date": null,
      "cost_per_leads": "",
      "total_cost": "",
    };

    this.defaultErrorMessage = {
      criteriaName: "",
      deliveryDate: "",
      volumeOfLeads: "",
      costPerLeads: "",
      totalCost: ""
    }

    this.defaultStates = {
      activeBatch: 0,
      batches: [{ ...this.defaultBatchValues }],
      audienceTargetsList: [],
      note: "",
      batchErrors: [],
      quoteId: _get(props, "selectedProject.quote_group_id", ""),
      showQuoteDetail: false,
      isOpenCriteriaModal: false,
      selectedFile: {},
      progress: 0,
      fileName: "",
      fileUrl: "",
    };

    this.state = {
      ...this.defaultStates,
      criteriaModalData: [],
      isProjectDetailsModal: false
    };
  }

  componentDidMount() {
    const { selectEditQuoteGroupId } = this.props;

    this.fetchProjectDetailsData();
    this.setBatches();

    if (!_isEmpty(_get(selectEditQuoteGroupId, "data", {}))) { this._fetchQuoteDetails(); }
  }

  componentDidUpdate(prevProps, prevStates) {

    if ((!_isEqual(prevProps.errorProjectDetails, _get(this.props, "errorProjectDetails", {}))) && (_get(this.props, "errorProjectDetails.status", false) === false)) {
      const { errorProjectDetails, resetDashboardError } = this.props;

      showAlertMessage((errorProjectDetails.message || "Something went wrong while fetching project details."));
      if (typeof resetDashboardError === "function") { resetDashboardError(); }
    }

    // Fetch Project Details
    if ((!_isEqual(prevProps.projectId, _get(this.props, "projectId", 0))) && (_get(this.props, "projectId", 0) !== 0)) {
      this.fetchProjectDetailsData();
    }

    if ((!_isEqual(prevProps.targetAudienceData, _get(this.props, "targetAudienceData", []))) && !_isEmpty(_get(this.props, "targetAudienceData", []))) {
      this.setBatches();
    }

    if ((!_isEqual(prevProps.submitQuote, _get(this.props, "submitQuote", {}))) && !_isEmpty(_get(this.props, "submitQuote", {}))) {
      const { submitQuote, resetSubmitQuoteData } = this.props;

      if (_get(submitQuote, "status", null) === null) { return false; }

      if (_get(submitQuote, "status", false) === true) {
        showAlertMessage(_get(submitQuote, "data.message", "Quote submitted successfully."), "success");

        this.setState({ ...this.defaultStates, quoteId: _get(submitQuote, "data.quote_id", null), showQuoteDetail: true });
        if (typeof resetEditQuoteGroupId === "function") { resetEditQuoteGroupId(); }
      } else {
        showAlertMessage(_get(submitQuote, "message", "Something went wrong while submitting quote."));
      }

      if (typeof resetSubmitQuoteData === "function") { resetSubmitQuoteData(); }
    }

    if (!_isEqual(prevProps.selectEditQuoteGroupId, _get(this.props, "selectEditQuoteGroupId", {}))) {

      if (!_isEmpty(_get(this.props, "selectEditQuoteGroupId.data", {}))) {
        this._fetchQuoteDetails();
        this.setState({ showQuoteDetail: false });
      } else {
        this.setState({ showQuoteDetail: true });
      }
    }

    if (!_isEqual(prevProps.quoteDetails, _get(this.props, "quoteDetails", {}))) {
      const { quoteDetails, resetQuoteDetails } = this.props;

      if (_get(this.props, "quoteDetails.status", null) === null) { return false; }

      if (_get(this.props, "quoteDetails.status", null) === true) {

        this._setQuoteDetails();
      } else {

        showAlertMessage((quoteDetails.message || "Something went wrong while fetching quote details."));
        if (typeof resetQuoteDetails === "function") { resetQuoteDetails(); }
      }
    }
  }

  _fetchQuoteDetails = () => {
    const { fetchQuoteDetails, projectId, selectEditQuoteGroupId, intl } = this.props;

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if (_isEmpty(_get(selectEditQuoteGroupId, "data", {}))) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof fetchQuoteDetails === "function") {
      fetchQuoteDetails({
        projectId: (projectId || null),
        quoteId: _get(selectEditQuoteGroupId, "data.id", null),
        returnBillingInfo: 0
      });
    }
  }

  _setQuoteDetails = () => {
    const { quoteDetails } = this.props;

    let tmpBatches = [{ ...this.defaultBatchValues }];
    let targetAudiences = {};
    const sampleLeadsFilePath = _get(quoteDetails, "data.quotes[0].sample_leads_file_path", "");

    if (!_isEmpty(_get(quoteDetails, "data.quotes", []))) {
      tmpBatches = [];

      _get(quoteDetails, "data.quotes", []).forEach(element => {
        tmpBatches.push({
          cost_per_leads: _get(element, "cost_per_lead", ""),
          delivery_date: _get(element, "delivery_date", null),
          total_cost: _get(element, "total_cost", ""),
          volume_of_leads: _get(element, "lead_volume", "")
        })
      });
    }

    this.setState({
      audienceTargetsList: targetAudiences,
      batches: tmpBatches,
      note: _get(quoteDetails, "data.quotes[0].note", ""),
      selectedFile: (sampleLeadsFilePath !== "") ? { name: getLastIndexOfFromURL(_get(quoteDetails, "data.quotes[0].sample_leads_file_path", null)) } : {},
      fileUrl: sampleLeadsFilePath,
      progress: (sampleLeadsFilePath !== "") ? 100 : 0,
      activeBatch: (tmpBatches.length - 1)
    });
  }

  fetchProjectDetailsData = () => {
    const { fetchProjectDetails, projectId } = this.props;

    if (typeof fetchProjectDetails === "function") { fetchProjectDetails((projectId || 0)); }
  }

  setBatches = () => {

    let tmpBatches = [{ ...this.defaultBatchValues }];

    this.setState({
      batches: tmpBatches
    });
  }

  addNewBatch = () => {
    const { batches, activeBatch } = this.state;

    let tmpBatches = _cloneDeep(batches || []);
    let defaultBatchValues = _cloneDeep(this.defaultBatchValues);

    tmpBatches.push(_cloneDeep(defaultBatchValues));

    this.setState({ batches: tmpBatches, activeBatch: (activeBatch + 1) });
  }

  setBatchValues = (e, index, field) => {
    const { batches } = this.state;

    let tmpBatches = _cloneDeep(batches || []);

    if ((field || "") === "delivery_date") {
      _set(tmpBatches, `[${index}].${field}`, (e || null));
    } else {
      _set(tmpBatches, `[${index}].${field}`, isValidIntegerNumber(_get(e, "target.value", null)));
    }

    let totalCostValue = _get(tmpBatches, `[${index}].${field}`, null);
    if (["volume_of_leads", "cost_per_leads"].includes(field || "")) {

      totalCostValue = (parseInt(_get(tmpBatches, `[${index}].volume_of_leads`, 0)) * parseFloat(_get(tmpBatches, `[${index}].cost_per_leads`, 0)));
      _set(tmpBatches, `[${index}].total_cost`, totalCostValue);
    }

    this.setState({ batches: tmpBatches });
  }

  handleValidation = (isReturnFlag = false) => {
    const { intl } = this.props;
    const { batches } = this.state;

    let tmpBatchErrors = [];
    let isValid = true;
    let activateCriteriaRow = 0;
    (batches || []).forEach((element, i) => {
      _set(tmpBatchErrors, `[${i}]`, _cloneDeep(this.defaultErrorMessage));

      if (moment(_get(element, "delivery_date", null)).isValid() === false) {
        tmpBatchErrors[i]["deliveryDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
          field: intl.formatMessage({ id: "seller.workflow.submit_quote.delivery_date", defaultMessage: "delivery date" }),
        });

        isValid = false;
      }

      if (_get(element, "volume_of_leads", "") === "") {
        tmpBatchErrors[i]["volumeOfLeads"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
          field: intl.formatMessage({ id: "seller.workflow.submit_quote.vol_of_leads", defaultMessage: "vol. of leads" }),
        });

        isValid = false;
      }

      if (_get(element, "cost_per_leads", "") === "") {
        tmpBatchErrors[i]["costPerLeads"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
          field: intl.formatMessage({ id: "seller.workflow.submit_quote.cost_per_leads", defaultMessage: "cost per leads" }),
        });

        isValid = false;
      }

      if (_get(element, "total_cost", "") === "") {
        tmpBatchErrors[i]["totalCost"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
          field: intl.formatMessage({ id: "seller.workflow.submit_quote.total_cost", defaultMessage: "total cost" }),
        });

        isValid = false;
      }

      if ((isValid === false) && (activateCriteriaRow === 0)) { activateCriteriaRow = i; }
    });

    this.setState({ batchErrors: tmpBatchErrors, activeBatch: activateCriteriaRow });

    if (isReturnFlag) { return isValid };
  }

  submitQuoteData = () => {
    const { projectId, submitQuoteRequest, quoteDetails } = this.props;
    const { batches, note, fileUrl } = this.state;

    const isValid = this.handleValidation(true);

    if (((isValid || false) === true) && (typeof submitQuoteRequest === "function")) {
      submitQuoteRequest({
        buyLeadId: (projectId || null),
        batches: (batches || []),
        sampleFileUrl: (fileUrl || ""),
        note: (note || ""),
        quoteGroupId: _get(quoteDetails, "data.quotes[0].quote_group_id", null),
      });
    }
  }

  _deleteBach = (index) => {
    const { batches, activeBatch } = this.state;
    let tmpBatches = _cloneDeep(batches);

    const indexBatch = tmpBatches.indexOf(index);
    if (index > -1) { tmpBatches.splice(indexBatch, 1); }

    this.setState({
      batches: (tmpBatches || []).sort(a => a.index),
      activeBatch: (activeBatch - 1)
    });
  }

  renderBatches = () => {
    const { currencySymbol, intl } = this.props;
    const { activeBatch, batches, batchErrors } = this.state;

    return (
      <Fragment>
        {(batches || []).map((batch, i) => {

          return (
            <div key={`vatch-${i}`} className="accordion position-relative" id="batchAccordion">
              {(i > 0) && (
                <span className="cursor-pointer position-absolute top-0 end-0 mt-8 icon-20 z-index-4 text-end d-block me-28 pt-1" onClick={() => this._deleteBach(i)}>
                  <IconClose height="16" width="16" />
                </span>
              )}
              <div className="accordion-item border-bottom mb-0">
                <div className="accordion-header" id="headingOne">
                  <button className={`accordion-button heading-05 fw-medium px-0 py-10  text-capitalize ${(activeBatch !== i) ? "collapsed" : ""}`} type="button" onClick={() => (activeBatch === i) ? this.setState({ activeBatch: null }) : this.setState({ activeBatch: i })}>
                    {((batches || []).length > 1) ? intl.formatMessage({ id: "seller.workflow.submit_quote.batch_count", defaultMessage: "batch {count}" }, { count: (i + 1) }) : intl.formatMessage({ id: "seller.workflow.submit_quote.batch", defaultMessage: "batch" })}
                  </button>
                </div>
                <div id="collapseOne" className={`accordion-collapse ${(activeBatch !== i) ? "collapse" : ""}`} aria-labelledby="headingOne" data-bs-parent="#batchAccordion">
                  <div className="accordion-body px-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <label className="mb-8 heading-06 text-capitalize text-dark"><FormattedMessage id="seller.workflow.submit_quote.vol_of_leads" defaultMessage="Vol. of Leads" /></label>
                            <input type="number" className="form-control border" value={_get(batches, `[${i}].volume_of_leads`, "")} onChange={(e) => this.setBatchValues(e, i, "volume_of_leads")} />
                            {(_get(batchErrors, `[${i}].volumeOfLeads`, "")) && (<ErrorMessage message={(_get(batchErrors, `[${i}].volumeOfLeads`, ""))} />)}
                          </div>
                          <div className="col-lg-3">
                            <label className="mb-8 heading-06 text-capitalize text-dark"><FormattedMessage id="seller.workflow.submit_quote.delivery_date" defaultMessage="Delivery Date" /></label>
                            <DatePicker
                              className="form-control dateLabel datepicker border"
                              defaultValue={_get(batches, `[${i}].volume_of_leads`, "")}
                              selected={(_get(batches, `[${i}].delivery_date`, null))}
                              onChange={(deliveryDate) => this.setBatchValues(deliveryDate, i, "delivery_date")}
                              placeholder={intl.formatMessage({ id: "seller.workflow.submit_quote.delivery_date", defaultMessage: "delivery date" })}
                              popperPlacement="bottom-end"
                              dateFormat={"dd/MM/yyyy"}
                              minDate={moment().toDate()}
                            />
                            {(_get(batchErrors, `[${i}].deliveryDate`, "")) && (<ErrorMessage message={(_get(batchErrors, `[${i}].deliveryDate`, ""))} />)}
                          </div>
                          <div className="col-lg-3">
                            <label className="mb-8 heading-06 text-capitalize text-dark"><FormattedMessage id="seller.workflow.submit_quote.cost_per_leads" defaultMessage="Cost per Leads" /></label>
                            <div className="input-group">
                              <span className="input-group-text">{currencySymbol}</span>
                              <input type="number" className="form-control border" value={_get(batches, `[${i}].cost_per_leads`, "")} onChange={(e) => this.setBatchValues(e, i, "cost_per_leads")} />
                            </div>
                            {(_get(batchErrors, `[${i}].costPerLeads`, "")) && (<ErrorMessage message={(_get(batchErrors, `[${i}].costPerLeads`, ""))} />)}
                          </div>
                          <div className="col-lg-3">
                            <label className="mb-8 heading-06 text-capitalize text-dark"><FormattedMessage id="seller.workflow.submit_quote.total_cost" defaultMessage="Total Cost" /></label>
                            <div className="input-group">
                              <span className="input-group-text">{currencySymbol}</span>
                              <input type="number" className="form-control border" value={_get(batches, `[${i}].total_cost`, "")} disabled="disabled" />
                            </div>
                            {(_get(batchErrors, `[${i}].totalCost`, "")) && (<ErrorMessage message={(_get(batchErrors, `[${i}].totalCost`, ""))} />)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-lg-3">
            <div className="mt-16">
              <button type="button" className="btn btn-primary px-20 text-capitalize" onClick={() => this.addNewBatch()}>
                <FormattedMessage id="seller.workflow.submit_quote.add_new_batch" defaultMessage="add new batch" />
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  renderSubmitQuoteForm = () => {

    return (
      <div className="col-lg-12">
        <div className="card p-15 mb-20">
          <div className="d-flex align-items-center justify-content-between gap-15 mb-15">
            <h4 className="heading-03 fw-medium text-primary text-capitalize"><FormattedMessage id="seller.workflow.submit_quote.submit_quotes" defaultMessage="submit quotes" /></h4>
            <button className="btn btn-primary ms-auto text-capitalize" type="button" onClick={() => this.addNewBatch()}>
              <FormattedMessage id="seller.workflow.submit_quote.add_new_batch" defaultMessage="add new batch" />
            </button>
            <button className="btn btn-primary" type="button" onClick={() => this.setState({ isProjectDetailsModal: true })}>
              <FormattedMessage id="seller.workflow.submit_quote.view_project_details" defaultMessage="view project details" />
            </button>
            {/*<div className="dropdown">
              <button className="btn btn-primary" type="button" id="selectMoreQuote" data-bs-toggle="dropdown" aria-expanded="false">
                <FormattedMessage id="seller.workflow.submit_quote.more" defaultMessage="more" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="selectMoreQuote">
                <li className="text-capitalize"><FormattedMessage id="edit" defaultMessage="edit" /></li>
                <li className="text-capitalize"><FormattedMessage id="delete" defaultMessage="delete" /></li>
              </ul>
            </div>*/}
          </div>

          {this.renderBatches()}

          <div>
            <div className="row">
              <div className="col-lg-5">
                <div className="mt-20">
                  <button type="button" className="btn btn-sm btn-primary text-capitalize" onClick={() => this.submitQuoteData()}>
                    <FormattedMessage id="btn.send" defaultMessage="send" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderProjectDetails() {
    const { quoteDetails, projectDetails, currencySymbol, displayDateFormat } = this.props;

    let statusText = _get(quoteStatusLookup, "PENDING.status", "");
    let totalBatches = 0;

    if (!_isEmpty(_get(quoteDetails, "data.quotes", []))) {
      totalBatches = _get(quoteDetails, "data.quotes", []).length;
      statusText = _get(quoteDetails, "data.quotes[0].status_text", "");
    }

    return (
      <div className="row gx-12">
        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.total_volume_of_leads" defaultMessage="Total Volume of Leads Required" /></p>
            <p>{_get(projectDetails, "leads_volume", 0)}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.final_delivery_date" defaultMessage="Final Delivery Date" /></p>
            <p>{(_get(projectDetails, "delivery_date", null)) ? moment(_get(projectDetails, "delivery_date", null)).format(displayDateFormat) : ""}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.multiple_batches" defaultMessage="Batches/Multiple Delivery Dates" /></p>
            <p>
              {(totalBatches > 1) ? "YES" : "NO"}
              <span className="position-absolute end-0 pe-12">
                <IconInfoAlt height="14" width="14" color="#9D9B9B" />
              </span>
            </p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.cost_per_lead" defaultMessage="Cost per Lead" /></p>
            <p>{`${currencySymbol}${_get(projectDetails, "cost_per_lead", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card text-capitalize">
            <p><FormattedMessage id="dashboard.table.total_cost" defaultMessage="Total Cost" /></p>
            <p>{`${currencySymbol}${_get(projectDetails, "total_cost", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4">
          <div className="project-list-card bg-danger-ff text-capitalize">
            <p className="text-dark"><FormattedMessage id="dashboard.funds.tab.quote_status" defaultMessage="Quote Status" /></p>
            <p className={_get(quoteStatusLookup, `[${(statusText.toUpperCase())}.statusClass]`, "")}> {(statusText || <EmptyLabel />)}</p>
          </div>
        </div>
      </div>
    )
  }

  _renderCriteria() {
    const { projectCriteria } = this.props;

    return (
      <div className="row gx-12">
        {(projectCriteria || []).map((criteria, i) => {
          return (
            <div key={`criteria-${i}`} className="col-xl-2 col-lg-4">
              <div className="project-list-card">
                <p className="text-capitalize">{_get(criteria, "target_type", "")}</p>
                <p className="text-capitalize" title={_get(criteria, "target_value", "-")}>{_get(criteria, "target_value", "-")}</p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  _renderSubmitQuote = () => {
    const { projectDetails } = this.props;

    return (
      <div className="col-lg-12 mt-20">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">
              <FormattedMessage id="submit_quote.title" defaultMessage="Quote" />
            </h4>

            <div className="ms-lg-auto">
              <IconDots height="20" width="4" color="#e84968" />
            </div>
          </div>

          <div className="project-status-wrap">
            <p className="heading-04 fw-medium mb-8 text-capitalize">
              <FormattedMessage id="submit_quote.project_details" defaultMessage="Project Details" />
            </p>

            {this._renderProjectDetails()}

            <div className="border-bottom border-secondary pb-12 mb-12"></div>

            <p className="heading-04 fw-medium mb-8 d-flex align-items-center gap-12 text-capitalize">
              <IconMinus height="14px" width="14px" color="#E94969" />
              <FormattedMessage id="view_quote.criteria" defaultMessage="Criteria" />
            </p>

            {this._renderCriteria()}
            {(_get(projectDetails, "is_seller_quote", 1) === 0) && (
              this.renderSubmitQuoteForm()
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { selectedProject } = this.props;
    const { showQuoteDetail, quoteId } = this.state;

    return (
      <Fragment>
        {(((showQuoteDetail || false) === false) && (_get(selectedProject, "quote_group_id", null) === null)) && (
          this._renderSubmitQuote()
        )}

        {(((showQuoteDetail || false) === true) || (_get(selectedProject, "quote_group_id", null) !== null)) && (
          <ViewQuote quoteId={(quoteId || null)} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  submitQuote: _get(state, "leads.dashboard.submitQuote", {}),
  removeUpload: _get(state, "leads.fileUpload.removeUpload", {}),
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  errorProjectDetails: _get(state, "leads.dashboard.errorDashboard", {}),
  projectDetails: _get(state, "leads.dashboard.projectDetails.data.project[0]", {}),
  projectCriteria: _get(state, "leads.dashboard.projectDetails.data.target_audience", []),
  targetAudienceData: _get(state, "leads.dashboard.projectDetails.data.target_audience", []),
  APIRequestDateFormat: _get(state, "application.constants.APIRequestDateFormat", "YYYY-MM-DD"),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  quoteDetails: _get(state, "leads.dashboard.quoteDetails", {}),
  selectEditQuoteGroupId: _get(state, "leads.workflowDetails.selectEditQuoteGroupId", {}),
  selectedProject: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectDetails: (data) => dispatch(fetchProjectDetails(data)),
  resetDashboardError: () => dispatch(resetDashboardError()),
  submitQuoteRequest: (data) => dispatch(submitQuoteRequest(data)),
  resetSubmitQuoteData: () => dispatch(resetSubmitQuoteData()),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  fetchQuoteDetails: (data) => dispatch(fetchQuoteDetails(data)),
  resetQuoteDetails: () => dispatch(resetQuoteDetails()),
  resetEditQuoteGroupId: () => dispatch(resetEditQuoteGroupId()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubmitQuote)));