import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { IconLeftCaret, IconStarEmpty, IconStarFill } from "../../helpers/iconStyles";

class sellerProfile extends Component {

  _renderVenderProfile = () => {
    return (
      <div>
        <div>
          <h1 className="heading-02 fw-medium text-capitalize mb-20">Vendor Profile</h1>

          <h2 className="heading-03 fw-medium text-capitalize text-primary mb-12">Seller Username</h2>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card p-20 mb-20 align-items-center justify-content-center">
              <p className="heading-03 fw-medium text-primary">Total Score</p>

              <div className="text-center d-flex align-items-center gap-12 my-8">
                <IconStarFill height="24" width="24" color="#e84968" />
                <IconStarFill height="24" width="24" color="#e84968" />
                <IconStarFill height="24" width="24" color="#e84968" />
                <IconStarFill height="24" width="24" color="#e84968" />
                <IconStarEmpty height="24" width="24" color="#e84968" />
              </div>

              <h5 className="heading-04 fw-semibold mt-8">87/100</h5>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card p-20 mb-20 align-items-center justify-content-center">
              <p className="heading-03 fw-medium text-primary">Leads Sold</p>
              <h5 className="heading-01 fw-semibold mt-10">10000</h5>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card p-20 mb-20 align-items-center justify-content-center">
              <p className="heading-03 fw-medium text-primary">Leads Rejected</p>
              <h5 className="heading-01 fw-semibold mt-10">3000</h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card p-20">
              <p className="lh-lg">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>

              <div className="mt-36">
                <h4 className="heading-03 fw-medium text-primary mb-12 pb-6 border-bottom">Score</h4>

                <div className="d-flex flex-column gap-16">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <p>Quality</p>
                    </div>

                    <div className="col-lg-auto">
                      <div className="text-center d-flex align-items-center gap-12">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <p className="ps-lg-12 mt-lg-0 mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <p>Data Delivery</p>
                    </div>

                    <div className="col-lg-auto">
                      <div className="text-center d-flex align-items-center gap-12">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <p className="ps-lg-12 mt-lg-0 mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <p>Volumn</p>
                    </div>

                    <div className="col-lg-auto">
                      <div className="text-center d-flex align-items-center gap-12">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <p className="ps-lg-12 mt-lg-0 mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <p>Budget</p>
                    </div>

                    <div className="col-lg-auto">
                      <div className="text-center d-flex align-items-center gap-12">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <p className="ps-lg-12 mt-lg-0 mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <p>Communication</p>
                    </div>

                    <div className="col-lg-auto">
                      <div className="text-center d-flex align-items-center gap-12">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>
                    </div>

                    <div className="col-lg-auto">
                      <p className="ps-lg-12 mt-lg-0 mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-36">
                <h4 className="heading-03 fw-medium text-primary mb-12 pb-6 border-bottom">Feedback</h4>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-24">
                      <div className="text-center d-flex align-items-center gap-12 my-8">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>

                      <p className="heading-05 fw-medium mt-12">Buyer profile name</p>

                      <p className="heading-06 my-6">10 November 2022</p>

                      <p className="lh-lg">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>

                    <div className="mb-24">
                      <div className="text-center d-flex align-items-center gap-12 my-8">
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarFill height="16" width="16" color="#e84968" />
                        <IconStarEmpty height="16" width="16" color="#e84968" />
                      </div>

                      <p className="heading-05 fw-medium mt-12">Buyer profile name</p>

                      <p className="heading-06 my-6">10 November 2022</p>

                      <p className="lh-lg">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-36 text-center d-flex flex-lg-row flex-column gap-10">
                <span className="btn btn-light">
                  <span className="position-absolute top-50 start-0 translate-middle-y ms-20">
                    <IconLeftCaret width="8px" height="15px" color="#FFFFFF" />
                  </span>
                  <FormattedMessage id="btn.back" defaultMessage="Back" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {

    return (
      <div className="col-lg-12">
        {this._renderVenderProfile()}
      </div>
    );
  }
}

export default injectIntl(sellerProfile);