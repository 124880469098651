import { takeLatest, call, fork, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import {
  getDropdownLookupData, addBuyLeads, fetchProjectDetails, submitQuoteRequest, getDashboardTopFiltersData, removeUploadedFile, saveMappingData, getOverallDashboardRecords,
  getOverallDashboardProjects, getDashboardProjectQuotesData, getDashboardProjectTimelineData, getDashboardCostPerLeadData, fetchQuoteDetails, updateQuoteStatus, fetchQuoteBatchList,
  saveUploadLeadData, fetchDataUploadHistory, fetchDataSummaryLog, fetchDataAcceptanceDetails, fetchQuoteGroupList, fetchUploadedDataCheckFile, updateQuoteBatchUploadStatus, fetchDataNumbers,
  fetchProjectDashboardOverallRecords, approveSellerQuote, fetchQuoteLeads, approveRejectQuoteLeads, exportQuoteLeads, fetchLeadRejectionReasons, updateLead, fetchDiscussionMessages, fetchLeadsRequiredFieldsLookupValues,
  fetchProjectRequiredFields
} from "../services/leads";

import {
  FETCH_DROPDOWN_LOOKUP_REQUEST, FETCH_DROPDOWN_LOOKUP_SUCCESS, FETCH_DROPDOWN_LOOKUP_ERROR,
  ADD_BUY_LEADS_REQUEST, ADD_BUY_LEADS_SUCCESS, ADD_BUY_LEADS_ERROR,
  SUBMIT_QUOTE_REQUEST, SUBMIT_QUOTE_SUCCESS, SUBMIT_QUOTE_ERROR,
  FETCH_DASHBOARD_TOP_FILTERS_REQUEST, FETCH_DASHBOARD_TOP_FILTERS_SUCCESS, FETCH_DASHBOARD_TOP_FILTERS_ERROR,
  FETCH_PROJECT_DETAILS_REQUEST, FETCH_PROJECT_DETAILS_SUCCESS, FETCH_PROJECT_DETAILS_ERROR,
  SAVE_UPLOAD_MAPPING_DATA_REQUEST, SAVE_UPLOAD_MAPPING_SUCCESS, SAVE_UPLOAD_MAPPING_ERROR,
  REMOVE_UPLOADED_FILE_REQUEST, REMOVE_UPLOADED_FILE_SUCCESS, REMOVE_UPLOADED_FILE_ERROR,
  FETCH_OVERALL_DASHBOARD_RECORDS_REQUEST, FETCH_OVERALL_DASHBOARD_RECORDS_SUCCESS, FETCH_OVERALL_DASHBOARD_RECORDS_ERROR,
  FETCH_OVERALL_DASHBOARD_PROJECTS_REQUEST, FETCH_OVERALL_DASHBOARD_PROJECTS_SUCCESS, FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR,
  FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_REQUEST, FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_SUCCESS, FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR,
  FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_REQUEST, FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_SUCCESS, FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR,
  FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_REQUEST, FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_SUCCESS, FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR,
  FETCH_QUOTE_DETAILS_REQUEST, FETCH_QUOTE_DETAILS_SUCCESS, FETCH_QUOTE_DETAILS_ERROR,
  UPDATE_QUOTE_STATUS_REQUEST, UPDATE_QUOTE_STATUS_SUCCESS, UPDATE_QUOTE_STATUS_ERROR,
  FETCH_QUOTE_GROUP_LIST_REQUEST, FETCH_QUOTE_GROUP_LIST_SUCCESS, FETCH_QUOTE_GROUP_LIST_ERROR,
  FETCH_QUOTE_BATCH_LIST_REQUEST, FETCH_QUOTE_BATCH_LIST_SUCCESS, FETCH_QUOTE_BATCH_LIST_ERROR,
  SAVE_UPLOAD_LEAD_DATA_REQUEST, SAVE_UPLOAD_LEAD_DATA_SUCCESS, SAVE_UPLOAD_LEAD_DATA_ERROR,
  FETCH_DATA_UPLOAD_HISTORY_REQUEST, FETCH_DATA_UPLOAD_HISTORY_SUCCESS, FETCH_DATA_UPLOAD_HISTORY_ERROR,
  FETCH_DATA_SUMMARY_LOG_REQUEST, FETCH_DATA_SUMMARY_LOG_SUCCESS, FETCH_DATA_SUMMARY_LOG_ERROR,
  FETCH_DATA_ACCEPTANCE_DETAILS_REQUEST, FETCH_DATA_ACCEPTANCE_DETAILS_SUCCESS, FETCH_DATA_ACCEPTANCE_DETAILS_ERROR,
  FETCH_UPLOADED_DATA_CHECK_FILE_REQUEST, FETCH_UPLOADED_DATA_CHECK_FILE_SUCCESS, FETCH_UPLOADED_DATA_CHECK_FILE_ERROR,
  UPDATE_QUOTE_BATCH_UPLOAD_STATUS_REQUEST, UPDATE_QUOTE_BATCH_UPLOAD_STATUS_SUCCESS, UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR,
  FETCH_DATA_NUMBERS_REQUEST, FETCH_DATA_NUMBERS_SUCCESS, FETCH_DATA_NUMBERS_ERROR,
  FETCH_DISCUSSION_MESSAGES_REQUEST, FETCH_DISCUSSION_MESSAGES_SUCCESS, FETCH_DISCUSSION_MESSAGES_ERROR,
  FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_REQUEST, FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_SUCCESS, FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR,
  APPROVE_SELLER_QUOTE_REQUEST, APPROVE_SELLER_QUOTE_SUCCESS, APPROVE_SELLER_QUOTE_ERROR,
  GET_QUOTE_LEADS_REQUEST, GET_QUOTE_LEADS_SUCCESS, GET_QUOTE_LEADS_ERROR,
  APPROVE_REJECT_QUOTE_LEADS_REQUEST, APPROVE_REJECT_QUOTE_LEADS_SUCCESS, APPROVE_REJECT_QUOTE_LEADS_ERROR,
  EXPORT_QUOTE_LEADS_REQUEST, EXPORT_QUOTE_LEADS_SUCCESS, EXPORT_QUOTE_LEADS_ERROR,
  FETCH_LEAD_REJECTION_REASONS_REQUEST, FETCH_LEAD_REJECTION_REASONS_SUCCESS, FETCH_LEAD_REJECTION_REASONS_ERROR,
  UPDATE_LEAD_REQUEST, UPDATE_LEAD_SUCCESS, UPDATE_LEAD_ERROR,
  FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_REQUEST, FETCHLEADS_REQUIRED_FIELDS_LOOKUP_VALUES_SUCCESS, FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR,
  FETCH_PROJECT_REQUIRED_FIELDS_REQUEST, FETCH_PROJECT_REQUIRED_FIELDS_SUCCESS, FETCH_PROJECT_REQUIRED_FIELDS_ERROR,
} from "../actions/leads";

import { UPDATE_APPLICATION_LOADING } from "../actions/application";

function* workerFetchDropdownLookup() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getDropdownLookupData);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DROPDOWN_LOOKUP_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DROPDOWN_LOOKUP_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching dropdown lookup details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DROPDOWN_LOOKUP_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching dropdown lookup details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerAddBuyLeads(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(addBuyLeads, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: ADD_BUY_LEADS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: ADD_BUY_LEADS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while adding buy leads.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: ADD_BUY_LEADS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while adding buy leads.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchProjectDetails(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchProjectDetails, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_PROJECT_DETAILS_SUCCESS,
        data: _get(response, "data.data", {}),
      });
    } else {
      yield put({
        type: FETCH_PROJECT_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_PROJECT_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerfetchDashboardTopFilters(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getDashboardTopFiltersData, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DASHBOARD_TOP_FILTERS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DASHBOARD_TOP_FILTERS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching dashboard top filters details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DASHBOARD_TOP_FILTERS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching dashboard top filters details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerSaveUploadMappingData({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(saveMappingData, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SAVE_UPLOAD_MAPPING_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          payload: payload
        }
      });
    } else {
      yield put({
        type: SAVE_UPLOAD_MAPPING_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while saving uploaded mapping data."),
          errors: _get(response, "error", {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: SAVE_UPLOAD_MAPPING_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerRemoveUploadedFile({ payload = {} }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(removeUploadedFile, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: REMOVE_UPLOADED_FILE_SUCCESS,
        payload: {
          data: _get(response, "data", {}),
          message: (response.message || ""),
          uploadType: (payload.uploadType || "")
        }
      });
    } else {
      yield put({
        type: REMOVE_UPLOADED_FILE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong, Try again later."),
          errors: (response.error || {})
        }
      });
    }

  } catch (error) {

    yield put({
      type: REMOVE_UPLOADED_FILE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong, Try again later.")
      }
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerSubmitQuote(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(submitQuoteRequest, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: SUBMIT_QUOTE_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: SUBMIT_QUOTE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while submitting quote.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: SUBMIT_QUOTE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while submitting quote.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchOverallDashboardProjects(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getOverallDashboardProjects, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_OVERALL_DASHBOARD_PROJECTS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project list.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchOverallDashboardRecords(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getOverallDashboardRecords, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_OVERALL_DASHBOARD_RECORDS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_OVERALL_DASHBOARD_RECORDS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching dashboard details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DROPDOWN_LOOKUP_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching dashboard details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDashboardProjectQuoteDetails(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getDashboardProjectQuotesData, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project list.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDashboardProjectTimelineDetails(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getDashboardProjectTimelineData, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project timeline details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project timeline details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDashboardCostPerLeadDetails(action) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getDashboardCostPerLeadData, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching cost per lead details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching cost per lead details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchQuoteDetails(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchQuoteDetails, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_QUOTE_DETAILS_SUCCESS,
        data: _get(response, "data.data", {}),
      });
    } else {
      yield put({
        type: FETCH_QUOTE_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching quote details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_QUOTE_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching quote details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUpdateQuoteDetail(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(updateQuoteStatus, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: UPDATE_QUOTE_STATUS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: UPDATE_QUOTE_STATUS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while updating quote status.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_QUOTE_STATUS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while updating quote status.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchQuoteGroupList(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchQuoteGroupList, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_QUOTE_GROUP_LIST_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_QUOTE_GROUP_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching Quote list.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_QUOTE_GROUP_LIST_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching Quote list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchQuoteBatchList(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchQuoteBatchList, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_QUOTE_BATCH_LIST_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_QUOTE_BATCH_LIST_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching batch list.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_QUOTE_BATCH_LIST_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching batch list.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerSaveUploadLeadData(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(saveUploadLeadData, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: SAVE_UPLOAD_LEAD_DATA_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: SAVE_UPLOAD_LEAD_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while uploading data.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: SAVE_UPLOAD_LEAD_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while uploading data.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDataUploadHistory(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDataUploadHistory, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_DATA_UPLOAD_HISTORY_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DATA_UPLOAD_HISTORY_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching data upload history.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_DATA_UPLOAD_HISTORY_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching data upload history.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDataSummaryLog(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDataSummaryLog, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_DATA_SUMMARY_LOG_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DATA_SUMMARY_LOG_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching data summary.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_DATA_SUMMARY_LOG_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching data summary.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDataAcceptanceDetails(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDataAcceptanceDetails, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_DATA_ACCEPTANCE_DETAILS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DATA_ACCEPTANCE_DETAILS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching data acceptance details.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_DATA_ACCEPTANCE_DETAILS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching data acceptance details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchUploadedDataCheckFile(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchUploadedDataCheckFile, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_UPLOADED_DATA_CHECK_FILE_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_UPLOADED_DATA_CHECK_FILE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching uploaded data check file.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_UPLOADED_DATA_CHECK_FILE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching uploaded data check file.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUpdateQuoteBatchUploadStatus(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(updateQuoteBatchUploadStatus, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: UPDATE_QUOTE_BATCH_UPLOAD_STATUS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while updating data check file status.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while updating data check file status.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerfetchDataNumbers(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDataNumbers, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_DATA_NUMBERS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_DATA_NUMBERS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching data numbers.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_DATA_NUMBERS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching data numbers.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchDiscussionMessages({ payload }) {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchDiscussionMessages, payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_DISCUSSION_MESSAGES_SUCCESS,
        payload: {
          data: _get(response, "data", []),
          message: _get(response, "message", ""),
          payload: payload
        }
      });
    } else {
      yield put({
        type: FETCH_DISCUSSION_MESSAGES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching discussions."),
          errors: _get(response, "error", {})
        }
      });
    }
  } catch (error) {

    yield put({
      type: FETCH_DISCUSSION_MESSAGES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching discussions.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchProjectDashboardOverallRecords(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchProjectDashboardOverallRecords, action.payload);

    if ((response.flag || false) === true) {

      yield put({
        type: FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project overall dashboard data.")
        }
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project overall dashboard data.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerApproveSellerQuote(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(approveSellerQuote, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: APPROVE_SELLER_QUOTE_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: APPROVE_SELLER_QUOTE_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while assigning quote to seller.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: APPROVE_SELLER_QUOTE_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while assigning quote to seller.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchQuoteLeads(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchQuoteLeads, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: GET_QUOTE_LEADS_SUCCESS,
        data: _get(response, "data", {}),
        oldData: _get(action, "payload.oldData", [])
      });
    } else {
      yield put({
        type: GET_QUOTE_LEADS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching quote leads.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: GET_QUOTE_LEADS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching quote leads.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerApproveRejectQuoteLeads(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(approveRejectQuoteLeads, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: APPROVE_REJECT_QUOTE_LEADS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: APPROVE_REJECT_QUOTE_LEADS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while approving/rejecting lead(s).")
        }
      });
    }

  } catch (error) {
    yield put({
      type: APPROVE_REJECT_QUOTE_LEADS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while approving/rejecting lead(s).")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerExportQuoteLeads(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(exportQuoteLeads, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: EXPORT_QUOTE_LEADS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: EXPORT_QUOTE_LEADS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while exporting lead(s).")
        }
      });
    }

  } catch (error) {
    yield put({
      type: EXPORT_QUOTE_LEADS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while exporting lead(s).")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchLeadRejectionReasons(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchLeadRejectionReasons, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_LEAD_REJECTION_REASONS_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_LEAD_REJECTION_REASONS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching lead rejection reasons.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_LEAD_REJECTION_REASONS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching lead rejection reasons.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerUpdateLead(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(updateLead, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: UPDATE_LEAD_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: UPDATE_LEAD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while updating lead.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: UPDATE_LEAD_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while updating lead.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchLeadsRequiredFieldsLookupValues() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchLeadsRequiredFieldsLookupValues);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCHLEADS_REQUIRED_FIELDS_LOOKUP_VALUES_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching leads required fields lookup values.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching leads required fields lookup values.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchProjectRequiredFields(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(fetchProjectRequiredFields, action.payload);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_PROJECT_REQUIRED_FIELDS_SUCCESS,
        data: _get(response, "data.data", {}),
      });
    } else {
      yield put({
        type: FETCH_PROJECT_REQUIRED_FIELDS_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching project required fields.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_PROJECT_REQUIRED_FIELDS_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching project required fields.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherAddBuyLeads() {
  yield takeLatest(ADD_BUY_LEADS_REQUEST, workerAddBuyLeads);
}

function* watcherFetchDropdownLookup() {
  yield takeLatest(FETCH_DROPDOWN_LOOKUP_REQUEST, workerFetchDropdownLookup);
}

function* watcherfetchDashboardTopFilters() {
  yield takeLatest(FETCH_DASHBOARD_TOP_FILTERS_REQUEST, workerfetchDashboardTopFilters);
}

function* watcherFetchProjectDetails() {
  yield takeLatest(FETCH_PROJECT_DETAILS_REQUEST, workerFetchProjectDetails);
}

function* watcherSaveUploadMappingData() {
  yield takeLatest(SAVE_UPLOAD_MAPPING_DATA_REQUEST, workerSaveUploadMappingData);
}

function* watcherRemoveUploadedFile() {
  yield takeLatest(REMOVE_UPLOADED_FILE_REQUEST, workerRemoveUploadedFile);
}

function* watcherSubmitQuote() {
  yield takeLatest(SUBMIT_QUOTE_REQUEST, workerSubmitQuote);
}

function* watcherFetchOverallDashboardRecords() {
  yield takeLatest(FETCH_OVERALL_DASHBOARD_RECORDS_REQUEST, workerFetchOverallDashboardRecords);
}

function* watcherFetchOverallDashboardProjects() {
  yield takeLatest(FETCH_OVERALL_DASHBOARD_PROJECTS_REQUEST, workerFetchOverallDashboardProjects);
}

function* watcherFetchDashboardProjectQuoteDetails() {
  yield takeLatest(FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_REQUEST, workerFetchDashboardProjectQuoteDetails);
}

function* watcherFetchDashboardProjectTimelineDetails() {
  yield takeLatest(FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_REQUEST, workerFetchDashboardProjectTimelineDetails);
}

function* watcherFetchDashboardCostPerLeadDetails() {
  yield takeLatest(FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_REQUEST, workerFetchDashboardCostPerLeadDetails);
}

function* watcherFetchQuoteDetails() {
  yield takeLatest(FETCH_QUOTE_DETAILS_REQUEST, workerFetchQuoteDetails);
}

function* watcherUpdateQuoteDetail() {
  yield takeLatest(UPDATE_QUOTE_STATUS_REQUEST, workerUpdateQuoteDetail);
}

function* watcherFetchQuoteGroupList() {
  yield takeLatest(FETCH_QUOTE_GROUP_LIST_REQUEST, workerFetchQuoteGroupList);
}

function* watcherFetchQuoteBatchList() {
  yield takeLatest(FETCH_QUOTE_BATCH_LIST_REQUEST, workerFetchQuoteBatchList);
}

function* watcherSaveUploadLeadData() {
  yield takeLatest(SAVE_UPLOAD_LEAD_DATA_REQUEST, workerSaveUploadLeadData);
}

function* watcherFetchDataUploadHistory() {
  yield takeLatest(FETCH_DATA_UPLOAD_HISTORY_REQUEST, workerFetchDataUploadHistory);
}

function* watcherFetchDataSummaryLog() {
  yield takeLatest(FETCH_DATA_SUMMARY_LOG_REQUEST, workerFetchDataSummaryLog);
}

function* watcherFetchDataAcceptanceDetails() {
  yield takeLatest(FETCH_DATA_ACCEPTANCE_DETAILS_REQUEST, workerFetchDataAcceptanceDetails);
}

function* watcherFetchUploadedDataCheckFile() {
  yield takeLatest(FETCH_UPLOADED_DATA_CHECK_FILE_REQUEST, workerFetchUploadedDataCheckFile);
}

function* watcherUpdateQuoteBatchUploadStatus() {
  yield takeLatest(UPDATE_QUOTE_BATCH_UPLOAD_STATUS_REQUEST, workerUpdateQuoteBatchUploadStatus);
}

function* watcherfetchDataNumbers() {
  yield takeLatest(FETCH_DATA_NUMBERS_REQUEST, workerfetchDataNumbers);
}

function* watcherFetchDiscussionMessages() {
  yield takeLatest(FETCH_DISCUSSION_MESSAGES_REQUEST, workerFetchDiscussionMessages);
}

function* watcherFetchProjectDashboardOverallRecords() {
  yield takeLatest(FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_REQUEST, workerFetchProjectDashboardOverallRecords);
}

function* watcherApproveSellerQuote() {
  yield takeLatest(APPROVE_SELLER_QUOTE_REQUEST, workerApproveSellerQuote);
}

function* watcherFetchQuoteLeads() {
  yield takeLatest(GET_QUOTE_LEADS_REQUEST, workerFetchQuoteLeads);
}

function* watcherApproveRejectQuoteLeads() {
  yield takeLatest(APPROVE_REJECT_QUOTE_LEADS_REQUEST, workerApproveRejectQuoteLeads);
}

function* watcherExportQuoteLeads() {
  yield takeLatest(EXPORT_QUOTE_LEADS_REQUEST, workerExportQuoteLeads);
}

function* watcherFetchLeadRejectionReasons() {
  yield takeLatest(FETCH_LEAD_REJECTION_REASONS_REQUEST, workerFetchLeadRejectionReasons);
}

function* watcherUpdateLead() {
  yield takeLatest(UPDATE_LEAD_REQUEST, workerUpdateLead);
}

function* watcherFetchLeadsRequiredFieldsLookupValues() {
  yield takeLatest(FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_REQUEST, workerFetchLeadsRequiredFieldsLookupValues);
}

function* watcherFetchProjectRequiredFields() {
  yield takeLatest(FETCH_PROJECT_REQUIRED_FIELDS_REQUEST, workerFetchProjectRequiredFields);
}

export default [
  fork(watcherAddBuyLeads),
  fork(watcherFetchDropdownLookup),
  fork(watcherfetchDashboardTopFilters),
  fork(watcherFetchProjectDetails),
  fork(watcherSaveUploadMappingData),
  fork(watcherRemoveUploadedFile),
  fork(watcherSubmitQuote),
  fork(watcherFetchOverallDashboardRecords),
  fork(watcherFetchOverallDashboardProjects),
  fork(watcherFetchDashboardProjectQuoteDetails),
  fork(watcherFetchDashboardProjectTimelineDetails),
  fork(watcherFetchDashboardCostPerLeadDetails),
  fork(watcherFetchQuoteDetails),
  fork(watcherUpdateQuoteDetail),
  fork(watcherFetchQuoteGroupList),
  fork(watcherFetchQuoteBatchList),
  fork(watcherSaveUploadLeadData),
  fork(watcherFetchDataUploadHistory),
  fork(watcherFetchDataSummaryLog),
  fork(watcherFetchDataAcceptanceDetails),
  fork(watcherFetchUploadedDataCheckFile),
  fork(watcherUpdateQuoteBatchUploadStatus),
  fork(watcherfetchDataNumbers),
  fork(watcherFetchDiscussionMessages),
  fork(watcherFetchProjectDashboardOverallRecords),
  fork(watcherApproveSellerQuote),
  fork(watcherFetchQuoteLeads),
  fork(watcherApproveRejectQuoteLeads),
  fork(watcherExportQuoteLeads),
  fork(watcherFetchLeadRejectionReasons),
  fork(watcherUpdateLead),
  fork(watcherFetchLeadsRequiredFieldsLookupValues),
  fork(watcherFetchProjectRequiredFields),
];
