import { get as _get } from "lodash";

import { objectToParams } from "../helpers/utils";
import api from "../helpers/api";

export const doLogin = (variables) => {
	return api().post("/authenticate/token", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while login!"),
			data: {}
		};
	});
}

export const doSignUp = (variables) => {
	return api().post("users/signup", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while signup!"),
			data: {}
		};
	});
}

export const doForgotPassword = (variables) => {
	return api().patch("/reset-password/initiate", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", false),
			message: _get(err, "response.data.message", "Something went wrong while generating forgot password link."),
			data: {},
		};
	});
};

export const checkResetPassword = (variables) => {
	const params = objectToParams(variables);

	return api().get(`/reset-password/validate-link?${params}`).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while check reset password criteria."),
			data: {},
		};
	});
};

export const doResetPassword = (variables) => {
	return api().patch("/reset-password", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", false),
			message: _get(err, "response.data.message", "Something went wrong while reseting password."),
			data: {},
		};
	});
};

export const doChangePassword = (variables) => {
	return api().put("/user/change-password", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", false),
			message: _get(err, "response.data.message", "Something went wrong while changing password!"),
			data: {},
		};
	});
};

export const createContactUs = (variables) => {
	return api().post("/contact-us", variables).then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while submitting contact us request."),
			data: {}
		};
	});
};

export const doValidateDisplayName = (variables) => {
	return api().post("users/check-username-availability", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while checking display name."),
			data: {}
		};
	});
}