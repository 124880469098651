import { get as _get } from "lodash";

import { objectToParams } from "../helpers/utils";
import api from "../helpers/api";

export const getDropdownLookupData = () => {
  return api().get("/leads/get-dropdown-lookup-values").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching dropdown lookup details."),
      data: {}
    };
  });
}

export const addBuyLeads = (variables) => {
  return api().post("/leads/save-buy-leads-request", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while adding buy leads."),
      data: {}
    };
  });
}

export const fetchProjectDetails = (projectId) => {
  return api().get(`leads/get-project-details?projectId=${(projectId || 0)}`).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project details."),
      data: {}
    };
  });
}

export const getDashboardTopFiltersData = (variables) => {
  return api().put("/leads/get-dashboard-top-filters", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching dashboard top filters details."),
      data: {}
    };
  });
}

//Upload and read file and fetch data mapping columns
export const uploadReadFile = (variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post(`/application/file-upload/${(variables.uploadType || "")}/uploads`, (variables.formFile || {}), config).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong uploading file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      statusCode: (statusCode === 422) ? statusCode : 401,
      data: _get(err, "response.data.data.work_sheet_names", {})
    };
  });
}

export const removeUploadedFile = (variables) => {
  return api().delete("/application/delete-file", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while deleting file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

//Pass mapping data and store in DB
export const saveMappingData = (variables) => {

  return api().post(`/application/file-upload/${(variables.uploadType || "")}/validate-and-uploads`, variables.payload).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while submitting details."),
      error: _get(err, "response.data.errors", {}),
      data: {}
    };
  });
}

export const submitQuoteRequest = (variables) => {
  return api().post("/leads/submit-quote", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while submitting quote."),
      data: {}
    };
  });
}

export const getOverallDashboardRecords = (variables) => {
  return api().put("/leads/get-overall-dashboard-records", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching dashboard details."),
      data: {}
    };
  });
}

export const getOverallDashboardProjects = (variables) => {
  return api().put("/leads/get-overall-dashboard-projects", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project list."),
      data: {}
    };
  });
}


export const getDashboardProjectQuotesData = (variables) => {
  return api().put("/leads/get-dashboard-seller-quotes-data", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project list."),
      data: {}
    };
  });
}

export const getDashboardProjectTimelineData = (variables) => {

  return api().put("leads/get-project-timeline", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project timeline details."),
      data: {}
    };
  });
}

export const getDashboardCostPerLeadData = (variables) => {

  return api().put("leads/get-project-cost-per-lead", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching cost per lead details."),
      data: {}
    };
  });
}

export const testOptions = (variables) => {
  return api().options("/test-api", { data: (variables || {}) }).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while calling test API."),
      data: {}
    };
  });
}

export const fetchQuoteDetails = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/get-quote-details?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching quote details."),
      data: {}
    };
  });
}

export const updateQuoteStatus = (variables) => {

  return api().put("/leads/update-quote-status", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating quote status"),
      data: {}
    };
  });
}

export const fetchCheckoutPageDetails = (variables) => {

  return api().put("leads/initiate-checkout-payment", variables).then((res) => {

    return {
      flag: true,
      message: _get(res, "message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching payment details."),
      data: {}
    };
  });
}

export const completeCheckoutPayment = (variables) => {

  return api().put("leads/complete-checkout-payment", variables).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching payment details."),
      data: {}
    };
  });
}

export const fetchQuoteGroupList = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-quote-list?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching quote list."),
      data: {}
    };
  });
}

export const fetchQuoteBatchList = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-batch-list?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching batch list."),
      data: {}
    };
  });
}

export const saveUploadLeadData = (variables) => {
  return api().post("/leads/quote/upload-batch-data", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while uploading data."),
      data: {}
    };
  });
}

export const fetchDataUploadHistory = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-data-supply-history?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching data upload history."),
      data: {}
    };
  });
}

export const fetchDataSummaryLog = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-data-summary?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching data summary."),
      data: {}
    };
  });
}

export const fetchDataAcceptanceDetails = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-release-fund-details?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching data acceptance details."),
      data: {}
    };
  });
}

export const releaseFunds = (variables) => {

  return api().put("leads/quote/release-funds", variables).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating release funds details."),
      data: {}
    };
  });
}

export const addQuoteRevisionNote = (variables) => {

  return api().post("/leads/add-quote-revision-note", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while requesting for revision."),
      data: {}
    };
  });
}

export const fetchUploadedDataCheckFile = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-uploaded-data-file?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching uploaded data check file."),
      data: {}
    };
  });
}

export const updateQuoteBatchUploadStatus = (variables) => {

  return api().put("leads/update-quote-batch-upload-status", variables).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating data check file status."),
      data: {}
    };
  });
}

export const fetchDataNumbers = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/quote/get-data-numbers?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching data numbers."),
      data: {}
    };
  });
}

export const fetchProjectCompletionDetails = (variables) => {
  const params = objectToParams(variables);

  return api().get(`leads/get-project-completion-details?${params}`).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project completion details."),
      data: {}
    };
  });
}

export const fetchDiscussionMessages = (variables) => {
  const params = objectToParams(variables);

  return api().get(`messages/get-messages?${params}`).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching discussions."),
      data: {}
    };
  });
}

export const fetchProjectDashboardOverallRecords = (variables) => {
  return api().put("/leads/get-project-dashboard-overall-records", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project overall dashboard data."),
      data: {}
    };
  });
}

export const approveSellerQuote = (variables) => {
  return api().post("leads/quote/approve-seller-quote", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while assigning quote to seller."),
      data: {}
    };
  });
}

export const fetchQuoteLeads = (variables) => {

  return api().post("leads/quote/get-quote-leads", (variables.payload || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching quote leads."),
      data: {}
    };
  });
}

export const approveRejectQuoteLeads = (variables) => {
  return api().post("leads/quote/approve-reject-quote-leads", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while approving/rejecting lead(s)."),
      data: {}
    };
  });
}

export const exportQuoteLeads = (variables) => {
  return api().post("leads/quote/export-leads", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while exporting lead(s)."),
      data: {}
    };
  });
}

export const fetchLeadRejectionReasons = () => {

  return api().get('leads/quote/get-seller-leads-rejection-reasons').then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching lead rejection reasons."),
      data: {}
    };
  });
}

export const updateLead = (variables) => {
  return api().put("/leads/quote/update-lead", (variables || {})).then((res) => {

    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching dashboard top filters details."),
      data: {}
    };
  });
}

export const fetchLeadsRequiredFieldsLookupValues = () => {
  return api().get("/leads/get-leads-required-fields-lookup-values").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching leads required fields lookup values."),
      data: {}
    };
  });
}

export const fetchProjectRequiredFields = (projectId) => {
  return api().get(`leads/quote/get-project-required-fields?projectId=${(projectId || 0)}`).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching project required fields."),
      data: {}
    };
  });
}