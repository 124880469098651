// Buy leads
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const fetchUsers = (payload) => {
  return ({
    type: FETCH_USERS_REQUEST,
    payload
  });
}

export const RESET_USERS_DATA = "RESET_USERS_DATA";

export const resetUsersData = () => {
  return ({ type: RESET_USERS_DATA });
}