export const UPDATE_APPLICATION_LOADING = "UPDATE_APPLICATION_LOADING";

export const updateLoadingState = (loading) => {
  return ({
    type: UPDATE_APPLICATION_LOADING,
    loading
  });
}

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const updateLanguage = (language) => {
  return ({
    type: UPDATE_LANGUAGE,
    language
  });
}

export const FETCH_APPLICATION_REQUEST = "FETCH_APPLICATION_REQUEST";
export const FETCH_APPLICATION_SUCCESS = "FETCH_APPLICATION_SUCCESS";

export const fetchApplication = () => {
  return ({
    type: FETCH_APPLICATION_REQUEST,
  });
}

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

export const setActiveMenuRequest = (payload) => {
  return ({
    type: SET_ACTIVE_MENU,
    payload
  });
}

export const SET_ADMIN_MENU_COLLAPSE = "SET_ADMIN_MENU_COLLAPSE";
export const setAdminMenuCollapse = (payload) => {
  return ({ type: SET_ADMIN_MENU_COLLAPSE, payload });
}

//Leads Inited Invoice Data
export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR";

export const fetchInvoices = (payload) => {

  return ({
    type: FETCH_INVOICES_REQUEST,
    payload
  });
}

export const RESET_INVOICES_ERROR = "RESET_INVOICES_ERROR";

export const resetInvoiceError = () => {
  return ({ type: RESET_INVOICES_ERROR });
}

// My Team
export const FETCH_MY_TEAM_DATA_REQUEST = "FETCH_MY_TEAM_DATA_REQUEST";
export const FETCH_MY_TEAM_DATA_SUCCESS = "FETCH_MY_TEAM_DATA_SUCCESS";
export const FETCH_MY_TEAM_DATA_ERROR = "FETCH_MY_TEAM_DATA_ERROR";

export const fetchMyTeamData = (payload) => {
  return ({
    type: FETCH_MY_TEAM_DATA_REQUEST,
    payload
  });
}

export const RESET_MY_TEAM_DATA_ERROR = "RESET_MY_TEAM_DATA_ERROR";

export const resetMyTeamDataError = () => {
  return ({ type: RESET_MY_TEAM_DATA_ERROR });
}

export const FETCH_USER_SETTINGS_REQUEST = "FETCH_USER_SETTINGS_REQUEST";
export const FETCH_USER_SETTINGS_SUCCESS = "FETCH_USER_SETTINGS_SUCCESS";
export const FETCH_USER_SETTINGS_ERROR = "FETCH_USER_SETTINGS_ERROR";

export const fetchUserSettings = (payload) => {
  return ({
    type: FETCH_USER_SETTINGS_REQUEST,
    payload
  });
}

export const RESET_USER_SETTINGS_DATA = "RESET_USER_SETTINGS_DATA";

export const resetUserSettingData = () => {
  return ({ type: RESET_USER_SETTINGS_DATA });
}

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";

export const updateUserDetails = (payload) => {
  return ({
    type: UPDATE_USER_DETAILS_REQUEST,
    payload
  });
}

export const CLEAR_UPDATE_USER_DETAILS_DATA = "CLEAR_UPDATE_USER_DETAILS_DATA";

export const clearUpdateUserDetailsData = () => {
  return ({
    type: CLEAR_UPDATE_USER_DETAILS_DATA
  });
}

// File upload clone
export const UPLOAD_CLONE_READ_FILE_SUCCESS = "UPLOAD_CLONE_READ_FILE_SUCCESS";

export const uploadCloneReadFileSuccess = (payload) => {
  return ({
    type: UPLOAD_CLONE_READ_FILE_SUCCESS,
    payload
  });
}

export const UPLOAD_CLONE_READ_FILE_ERROR = "UPLOAD_CLONE_READ_FILE_ERROR";

export const uploadCloneReadFileError = (payload) => {
  return ({
    type: UPLOAD_CLONE_READ_FILE_ERROR,
    payload
  });
}

export const SAVE_UPLOAD_CLONE_MAPPING_DATA_REQUEST = "SAVE_UPLOAD_CLONE_MAPPING_DATA_REQUEST";
export const SAVE_UPLOAD_CLONE_MAPPING_SUCCESS = "SAVE_UPLOAD_CLONE_MAPPING_SUCCESS";
export const SAVE_UPLOAD_CLONE_MAPPING_ERROR = "SAVE_UPLOAD_CLONE_MAPPING_ERROR";

export const saveUploadCloneMappingData = (payload) => {
  return ({
    type: SAVE_UPLOAD_CLONE_MAPPING_DATA_REQUEST,
    payload
  });
}

export const RESET_CLONE_DATA_MAPPING_ERROR = "RESET_CLONE_DATA_MAPPING_ERROR";

export const resetCloneDataMappingError = () => {
  return ({
    type: RESET_CLONE_DATA_MAPPING_ERROR
  });
}

export const RESET_CLONE_DATA_MAPPING_CONFIG = "RESET_CLONE_DATA_MAPPING_CONFIG";

export const resetCloneDataMappingConfig = () => {
  return ({
    type: RESET_CLONE_DATA_MAPPING_CONFIG
  });
}

export const REMOVE_UPLOADED_CLONE_FILE_REQUEST = "REMOVE_UPLOADED_CLONE_FILE_REQUEST";
export const REMOVE_UPLOADED_CLONE_FILE_SUCCESS = "REMOVE_UPLOADED_CLONE_FILE_SUCCESS";
export const REMOVE_UPLOADED_CLONE_FILE_ERROR = "REMOVE_UPLOADED_CLONE_FILE_ERROR";

export const removeUploadedCloneFile = (payload) => {
  return ({ type: REMOVE_UPLOADED_CLONE_FILE_REQUEST, payload });
}

export const RESET_REMOVE_UPLOADED_CLONE_FILE = "RESET_REMOVE_UPLOADED_CLONE_FILE";

export const resetRemoveUploadedCloneFile = () => {
  return ({
    type: RESET_REMOVE_UPLOADED_CLONE_FILE,
  });
}

// File upload read only
export const UPLOAD_READ_ONLY_FILE_SUCCESS = "UPLOAD_READ_ONLY_FILE_SUCCESS";

export const uploadReadOnlyFileSuccess = (payload) => {
  return ({
    type: UPLOAD_READ_ONLY_FILE_SUCCESS,
    payload
  });
}

export const UPLOAD_READ_ONLY_FILE_ERROR = "UPLOAD_READ_ONLY_FILE_ERROR";

export const uploadReadOnlyFileError = (payload) => {
  return ({
    type: UPLOAD_READ_ONLY_FILE_ERROR,
    payload
  });
}

export const SAVE_AND_GET_FILE_MAPPING_DATA_REQUEST = "SAVE_AND_GET_FILE_MAPPING_DATA_REQUEST";
export const SAVE_AND_GET_FILE_MAPPING_DATA_SUCCESS = "SAVE_AND_GET_FILE_MAPPING_DATA_SUCCESS";
export const SAVE_AND_GET_FILE_MAPPING_DATA_ERROR = "SAVE_AND_GET_FILE_MAPPING_DATA_ERROR";

export const saveAndGetFileMappingData = (payload) => {
  return ({
    type: SAVE_AND_GET_FILE_MAPPING_DATA_REQUEST,
    payload
  });
}

export const RESET_SAVE_AND_GET_FILE_MAPPING_DATA_ERROR = "RESET_SAVE_AND_GET_FILE_MAPPING_DATA_ERROR";

export const resetSaveAndGetFileMappingData = () => {
  return ({
    type: RESET_SAVE_AND_GET_FILE_MAPPING_DATA_ERROR
  });
}

export const RESET_READ_ONLY_DATA_MAPPING_CONFIG = "RESET_READ_ONLY_DATA_MAPPING_CONFIG";

export const resetReadOnlyDataMappingConfig = () => {
  return ({
    type: RESET_READ_ONLY_DATA_MAPPING_CONFIG
  });
}

export const REMOVE_UPLOADED_READ_ONLY_FILE_REQUEST = "REMOVE_UPLOADED_READ_ONLY_FILE_REQUEST";
export const REMOVE_UPLOADED_READ_ONLY_FILE_SUCCESS = "REMOVE_UPLOADED_READ_ONLY_FILE_SUCCESS";
export const REMOVE_UPLOADED_READ_ONLY_FILE_ERROR = "REMOVE_UPLOADED_READ_ONLY_FILE_ERROR";

export const removeUploadedReadOnlyFile = (payload) => {
  return ({ type: REMOVE_UPLOADED_READ_ONLY_FILE_REQUEST, payload });
}

export const RESET_REMOVE_UPLOADED_READ_ONLY_FILE = "RESET_REMOVE_UPLOADED_READ_ONLY_FILE";

export const resetRemoveUploadedReadOnlyFile = () => {
  return ({
    type: RESET_REMOVE_UPLOADED_READ_ONLY_FILE,
  });
}

export const SAVE_MARKETPLACE_SEARCH_REQUEST = "SAVE_MARKETPLACE_SEARCH_REQUEST";
export const SAVE_MARKETPLACE_SEARCH_SUCCESS = "SAVE_MARKETPLACE_SEARCH_SUCCESS";
export const SAVE_MARKETPLACE_SEARCH_ERROR = "SAVE_MARKETPLACE_SEARCH_ERROR";

export const saveMarketPlaceSearch = (payload) => {
  return ({
    type: SAVE_MARKETPLACE_SEARCH_REQUEST,
    payload
  });
}

export const RESET_SAVE_MARKETPLACE_SEARCH = "RESET_SAVE_MARKETPLACE_SEARCH";

export const resetSaveMarketPlaceSearch = () => {
  return ({
    type: RESET_SAVE_MARKETPLACE_SEARCH,
  });
}

export const FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_REQUEST = "FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_REQUEST";
export const FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_SUCCESS = "FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_SUCCESS";
export const FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR = "FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR";

export const fetchMarketplaceSavedCriteriaAndList = (payload) => {
  return ({
    type: FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_REQUEST,
    payload
  });
}

export const RESET_MARKETPLACE_SAVED_CRITERIA_AND_LIST_DATA = "RESET_MARKETPLACE_SAVED_CRITERIA_AND_LIST_DATA";

export const resetMarketplaceSavedCriteriaAndListData = () => {
  return ({
    type: RESET_MARKETPLACE_SAVED_CRITERIA_AND_LIST_DATA,
  });
}

export const FETCH_DATA_REJECTED_TYPES_REQUEST = "FETCH_DATA_REJECTED_TYPES_REQUEST";
export const FETCH_DATA_REJECTED_TYPES_SUCCESS = "FETCH_DATA_REJECTED_TYPES_SUCCESS";
export const FETCH_DATA_REJECTED_TYPES_ERROR = "FETCH_DATA_REJECTED_TYPES_ERROR";

export const fetchDataRejectedTypes = (payload) => {
  return ({
    type: FETCH_DATA_REJECTED_TYPES_REQUEST,
    payload
  });
}