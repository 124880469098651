import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { get as _get } from "lodash";
import Helmet from "react-helmet";

import { EmptyLabel, showAlertMessage } from "../../../components/common/controls";

import { withRouter } from "../../../components/hoc/withRouter";

import { IconLgDone } from "../../../helpers/iconStyles";

import { completeCheckoutPayment } from "../../../services/leads";
import { updateLoadingState } from "../../../actions/application";

class PaymentSuccess extends Component {

  constructor(props) {
    super(props);

    const { location: { search } } = this.props;

    this.state = {
      sessionId: new URLSearchParams(search).get("session_id"),
      paymentDetails: {}
    };
  }

  componentDidMount() {
    this._completePayment();
  }

  _completePayment = async () => {
    const { updateLoadingState } = this.props;
    const { sessionId } = this.state;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await completeCheckoutPayment({ sessionId: sessionId, isSuccess: 1 });

      if (_get(response, "flag", false) === true) {

        showAlertMessage(_get(response, "message", "Payment completed successfully."), "success");

        this.setState({ paymentDetails: _get(response, "data", {}) });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching payment details."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching payment details."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  render() {
    const { currencySymbol, intl } = this.props;
    const { paymentDetails } = this.state;

    return (
      <>
        <Helmet>
          <title>{intl.formatMessage({ id: "LU.payment_success", defaultMessage: "Payment Success - Leads United" })}</title>
        </Helmet>
        <div className="mb-20 d-sm-flex justify-content-between align-items-center">
          <h1 className="heading-02 fw-medium text-capitalize">
            <FormattedMessage id="payment.success.page_title" defaultMessage="Payment Success" />
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card p-15">
              <div className="my-30 text-center">
                <IconLgDone height="110" width="110" color="#e84968" />
                <div className="heading-02 text-primary fw-medium mt-10 text-capitalize" role="alert">
                  <FormattedMessage id="payment.success.title" defaultMessage="Success" />
                </div>
                <div className="px-lg-50">
                  <p className="heading-04 mb-30 mt-20">
                    <FormattedMessage id="payment.success.sub_title" defaultMessage="Payment completed successfully." />
                  </p>
                  <p className="heading-04 mt-20">
                    <FormattedMessage id="payment.success.payment_status" defaultMessage="Payment Status: " />
                    <span className="fw-bold text-capitalize">
                      {_get(paymentDetails, "paymentStatus", <EmptyLabel />)}
                    </span>
                  </p>
                  <p className="heading-04 mt-20">
                    <FormattedMessage id="payment.success.reference_id" defaultMessage="Reference ID: " />
                    <span className="fw-bold">
                      {_get(paymentDetails, "referenceId", <EmptyLabel />)}
                    </span>
                  </p>
                  <p className="heading-04 mb-50 mt-20">
                    <FormattedMessage id="payment.success.amount" defaultMessage="Amount: " />
                    <span className="fw-bold">
                      {`${currencySymbol}${_get(paymentDetails, "amountTotal", <EmptyLabel />)}`}
                    </span>
                  </p>
                  <div>
                    <NavLink to="/dashboard" className="btn btn-md btn-primary text-capitalize">
                      <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentSuccess)));