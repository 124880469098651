import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { get as _get } from "lodash";
import storage from "redux-persist/lib/storage";

import { getDeviceMode } from "../helpers/utils";
import { DEFAULT_LANGUAGE, sidebarMenuConfig, tmpConstant } from "../helpers/constants";
import configs from "../helpers/config";

import actionTypes from "../actions";

const persistConfig = {
  key: `${configs.store_name}__language`,
  storage,
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APPLICATION_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const language = (state = DEFAULT_LANGUAGE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LANGUAGE:
      return action.language;

    default:
      return state;
  }
}

const defaultConfig = {
  menu: sidebarMenuConfig,
}

const config = (state = defaultConfig, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      return (action.payload.config || {});

    default:
      return state;
  }
}

const permissions = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      return _get(action, "payload.permissions", []);

    case actionTypes.DELETE_OAUTH_TOKEN_REQUEST:
      return [];

    default:
      return state;
  }
}

const initialSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  mode: getDeviceMode(window.innerWidth)
}

const viewport = (state = initialSize, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEWPORT:
      return ({
        ...action.size,
        mode: getDeviceMode(action.size.width)
      });

    default:
      return state;
  }
}

const languages = combineReducers({
  defaultLang: language
});

const constants = (state = tmpConstant, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      // return action.payload.constants || {};
      return state;

    default:
      return state;
  }
}

const appActiveMenu = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return (action.payload || "");

    default:
      return state;
  }
}

const adminMenuCollapse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_MENU_COLLAPSE:
      return (action.payload || false);

    default:
      return state;
  }
}

const initialInvoices = { status: null, data: [], message: "" };

const invoicesData = (state = initialInvoices, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_INVOICES_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_INVOICES_ERROR:
      return initialInvoices;

    default:
      return state;
  }
}

const initialMyTeamData = { status: null, data: [], message: "" };

const myTeamData = (state = initialMyTeamData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_TEAM_DATA_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_MY_TEAM_DATA_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_MY_TEAM_DATA_ERROR:
      return initialMyTeamData;

    default:
      return state;
  }
}

const myTeam = combineReducers({
  data: myTeamData
});

const initialUserDetails = { status: null, message: "", data: {} };

const getUserDetails = (state = initialUserDetails, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SETTINGS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_USER_SETTINGS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_USER_SETTINGS_DATA:
      return initialUserDetails;

    default:
      return state;
  }
}

const initialUpdateUserDetails = { status: null, message: "", data: {} };

const updateUserDetails = (state = initialUpdateUserDetails, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.UPDATE_USER_DETAILS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.CLEAR_UPDATE_USER_DETAILS_DATA:
      return initialUpdateUserDetails;

    default:
      return state;
  }
}

// File upload clone
const initialMapping = { showModal: false, data: {}, success: {}, successMessage: "" };

const dataMapping = (state = initialMapping, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_CLONE_READ_FILE_SUCCESS:
      const showModal = _get(action, "payload.showModel", true)
      return { showModal, data: _get(action, "payload.data", {}), success: {}, successMessage: "" };

    case actionTypes.SAVE_UPLOAD_CLONE_MAPPING_SUCCESS:
      return { showModal: false, data: {}, success: _get(action, "payload.data", {}), successMessage: _get(action, "payload.message", {}) };

    case actionTypes.RESET_CLONE_DATA_MAPPING_CONFIG:
      return initialMapping;

    default:
      return state;
  }
}

const dataMappingErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_CLONE_READ_FILE_ERROR:
    case actionTypes.SAVE_UPLOAD_CLONE_MAPPING_ERROR:
      return (action.error || {});

    case actionTypes.RESET_CLONE_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_CLONE_DATA_MAPPING_ERROR:
      return {};

    default:
      return state;
  }
}

const workSheets = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_CLONE_READ_FILE_ERROR:
      return _get(action, "error.data", {});

    default:
      return state;
  }
}

const initialRemoveUploadState = { status: null, message: "" };

const removeUpload = (state = initialRemoveUploadState, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_UPLOADED_CLONE_FILE_SUCCESS:
      return { status: true, message: _get(action, "payload.message", "") };

    case actionTypes.REMOVE_UPLOADED_CLONE_FILE_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_REMOVE_UPLOADED_CLONE_FILE:
      return initialRemoveUploadState;

    default:
      return state;
  }
}

// File upload read only
const initialMappingReadOnly = { showModal: false, data: {}, success: {}, successMessage: "" };

const dataMappingReadOnly = (state = initialMappingReadOnly, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_ONLY_FILE_SUCCESS:
      const showModal = _get(action, "payload.showModel", true)
      return { showModal, data: _get(action, "payload.data", {}), success: {}, successMessage: "" };

    case actionTypes.SAVE_AND_GET_FILE_MAPPING_DATA_SUCCESS:
      return { showModal: false, data: {}, success: _get(action, "payload.data", {}), successMessage: _get(action, "payload.message", {}) };

    case actionTypes.RESET_READ_ONLY_DATA_MAPPING_CONFIG:
      return initialMappingReadOnly;

    default:
      return state;
  }
}

const dataMappingReadOnlyErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SAVE_AND_GET_FILE_MAPPING_DATA_ERROR:
    case actionTypes.UPLOAD_READ_ONLY_FILE_ERROR:
      return (action.error || {});

    case actionTypes.RESET_READ_ONLY_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_READ_ONLY_DATA_MAPPING_ERROR:
      return {};

    default:
      return state;
  }
}

const workSheetsReadOnly = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_ONLY_FILE_ERROR:
      return _get(action, "error.data", {});

    default:
      return state;
  }
}

const initialRemoveUploadReadOnly = { status: null, message: "" };

const removeUploadReadOnly = (state = initialRemoveUploadReadOnly, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_UPLOADED_READ_ONLY_FILE_SUCCESS:
      return { status: true, message: _get(action, "payload.message", "") };

    case actionTypes.REMOVE_UPLOADED_READ_ONLY_FILE_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_REMOVE_UPLOADED_READ_ONLY_FILE:
      return initialRemoveUploadReadOnly;

    default:
      return state;
  }
}

const initialSaveMarketPlaceSearch = { status: null, message: "" };
const saveMarketPlaceSearch = (state = initialSaveMarketPlaceSearch, action) => {
  switch (action.type) {
    case actionTypes.SAVE_MARKETPLACE_SEARCH_SUCCESS:
      return { status: true, message: _get(action, "payload.data.message", "") };

    case actionTypes.SAVE_MARKETPLACE_SEARCH_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_SAVE_MARKETPLACE_SEARCH:
      return initialSaveMarketPlaceSearch;

    default:
      return state;
  }
}

const initialFetchMarketplaceSavedCriteria = { status: null, data: {}, message: "" };
const fetchMarketplaceSavedCriteriaAndList = (state = initialFetchMarketplaceSavedCriteria, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_SUCCESS:
      return { status: true, data: _get(action, "payload.data", {}), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_MARKETPLACE_SAVED_CRITERIA_AND_LIST_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_MARKETPLACE_SAVED_CRITERIA_AND_LIST_DATA:
      return initialFetchMarketplaceSavedCriteria;

    default:
      return state;
  }
}

const initialFetchDataRejectedTypes = { status: null, data: [], message: "" };
const dataRejectedTypes = (state = initialFetchDataRejectedTypes, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_REJECTED_TYPES_SUCCESS:
      return { status: true, data: _get(action, "payload.data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_DATA_REJECTED_TYPES_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_ALL_TABS_DATA:
      return initialFetchMarketplaceSavedCriteria;

    default:
      return state;
  }
}

const settings = combineReducers({
  userDetails: getUserDetails,
  updateUserDetails: updateUserDetails
});

const fileUpload = combineReducers({
  dataMapping,
  errors: dataMappingErrors,
  removeUpload,
  workSheets
});

const fileUploadReadOnly = combineReducers({
  dataMapping: dataMappingReadOnly,
  errors: dataMappingReadOnlyErrors,
  removeUpload: removeUploadReadOnly,
  workSheets: workSheetsReadOnly
});

const marketPlace = combineReducers({
  savedSearch: saveMarketPlaceSearch,
  getSavedCriteriaAndList: fetchMarketplaceSavedCriteriaAndList
});

export default {
  application: combineReducers({
    loading,
    language: persistReducer(persistConfig, languages),
    config,
    viewport,
    constants,
    permissions,
    appActiveMenu,
    adminMenuCollapse,
    invoicesData,
    myTeam,
    settings,
    fileUpload,
    fileUploadReadOnly,
    marketPlace,
    dataRejectedTypes
  })
}
