import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Collapse, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { withRouter } from "../../components/hoc/withRouter";

import { Modal, Select, showAlertMessage, ErrorMessage } from "../../components/common/controls";

import LatestOffering from "./components/LatestOffering";

import { IconSearch, IconSave, IconReset, IconFilter, IconPlus } from "../../helpers/iconStyles";
import { isValidIntegerNumber } from "../../helpers/utils";
import { USER_ROLES } from "../../helpers/constants";

import { fetchSavedSearchList } from "../../services/application";

import { saveMarketPlaceSearch, resetSaveMarketPlaceSearch, resetMarketplaceSavedCriteriaAndListData, updateLoadingState } from "../../actions/application";
import { fetchDropdownLookup, resetDropdownLookupData } from "../../actions/leads";

class MarketPlace extends Component {
  constructor(props) {
    super(props);

    const { roleId } = props;

    this.defaultFilterStates = {
      region: null,
      countriesToTarget: null,
      languages: null,
      industries: null,
      employeeSizes: null,
      leadsPerOrg: null,
      jobFunction: null,
      jobLevel: null,
      jobTitle: "",
      jobKeyword: null,
      customCriteria: ""
    };

    this.state = {
      filterData: _cloneDeep(this.defaultFilterStates),
      dropdownLookup: {},
      errors: {},
      showMarketFiters: false,
      selLeadType: "people",
      activeBuyerTab: (
        [_get(USER_ROLES, "SELLER.id", 0), _get(USER_ROLES, "SELLER_MEMBER.id", 0)].includes(roleId)
      ) ? 2 : (
        (
          [_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "BUYER.id", 0), _get(USER_ROLES, "BUYER_MEMBER.id", 0)].includes(roleId)
        ) ? 1 : null
      ),
      showSearchModal: false,
      searchName: "",
      savedSearchList: [],
      selectedSearchId: null,
      searchText: ""
    };
  }

  componentDidMount() {
    const { fetchDropdownLookup, dropdownLookup } = this.props;

    if (typeof fetchDropdownLookup === "function") { fetchDropdownLookup(); }

    this._fetchSaveSearchList();

    this.setState({ dropdownLookup: _get(dropdownLookup, "data", {}) });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dropdownLookup, this.props.dropdownLookup) && !_isEmpty(this.props.dropdownLookup)) {
      const { dropdownLookup, resetDropdownLookupData } = this.props;

      if (_get(dropdownLookup, "status", null) === null) { return false; }

      if (_get(dropdownLookup, "status", false) === true) {

        this.setState({ dropdownLookup: _get(dropdownLookup, "data", {}), });
      } else {
        showAlertMessage(dropdownLookup.message || "Something went wrong while fetching dropdown lookup details.");

        if (typeof resetDropdownLookupData === "function") { resetDropdownLookupData(); }
      }
    }

    if (!_isEqual(prevState.searchName, this.state.searchName)) { this._handleValidation(); }

    if (!_isEqual(prevProps.savedSearch, this.props.savedSearch) && !_isEmpty(this.props.savedSearch)) {
      const { savedSearch, resetSaveMarketPlaceSearch } = this.props;

      if (_get(savedSearch, "status", null) === null) { return false; }

      if (_get(savedSearch, "status", false) === true) {

        this._fetchSaveSearchList();

        this.setState({ filterData: _cloneDeep(this.defaultFilterStates), showSearchModal: false, searchName: "", showMarketFiters: false })

        showAlertMessage((savedSearch.message || "Details saved successfully"), "success");
      } else {

        showAlertMessage(savedSearch.message || "Something went wrong while saving marketplace search list.");
      }
      if (typeof resetSaveMarketPlaceSearch === "function") { resetSaveMarketPlaceSearch(); }
    }

    if (!_isEqual(prevState.selectedSearchId, _get(this.state, "selectedSearchId", null))) {

      if (_get(this.state, "selectedSearchId", null) !== null) { this.setState({ showMarketFiters: true }); }
    }

    if (!_isEqual(prevProps.getSavedCriteriaAndList, this.props.getSavedCriteriaAndList) && !_isEmpty(this.props.getSavedCriteriaAndList)) {
      const { getSavedCriteriaAndList, resetMarketplaceSavedCriteriaAndListData } = this.props;

      if (_get(getSavedCriteriaAndList, "status", null) === null) { return false; }

      if (_get(getSavedCriteriaAndList, "status", false) === true) {
        this._setSavedSearchFiltersList();

      } else {
        showAlertMessage(getSavedCriteriaAndList.message || "Something went wrong while fetching saved search filters details.");
        if (typeof resetMarketplaceSavedCriteriaAndListData === "function") { resetMarketplaceSavedCriteriaAndListData(); }
      }
    }
  }

  _setSavedSearchFiltersList = () => {
    const { getSavedCriteriaAndList } = this.props;

    let tmpRegion = [];
    let tmpCountriesToTarget = [];
    let tmpLanguages = [];
    let tmpIndustries = [];
    let tmpEmployeeSizes = [];
    let tmpLeadsPerOrg = "";
    let tmpJobFunction = [];
    let tmpJobLevel = [];
    let tmpJobTitle = "";
    let tmpJobKeyword = "";
    let tmpCustomCriteria = "";

    _get(getSavedCriteriaAndList, "data.search_filter", []).forEach(element => {

      if (_get(element, "parameter_name", "").toLowerCase() === "industry") {
        tmpIndustries.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "country") {
        tmpCountriesToTarget.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "language") {
        tmpLanguages.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "employee size") {
        tmpEmployeeSizes.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "region") {
        tmpRegion.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "leads per org") {
        tmpLeadsPerOrg = _get(element, "parameter_value", "");
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "job function") {
        tmpJobFunction.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "job level") {
        tmpJobLevel.push({ id: element.id, name: element.parameter_value });
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "job keyword") {
        tmpJobKeyword = _get(element, "parameter_value", "");
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "job title") {
        tmpJobTitle = _get(element, "parameter_value", "");
      }

      if (_get(element, "parameter_name", "").toLowerCase() === "custom criteria") {
        tmpCustomCriteria = _get(element, "parameter_value", "");
      }
    })

    this.setState({
      filterData: {
        region: tmpRegion,
        countriesToTarget: tmpCountriesToTarget,
        languages: tmpLanguages,
        industries: tmpIndustries,
        employeeSizes: tmpEmployeeSizes,
        leadsPerOrg: tmpLeadsPerOrg,
        jobFunction: tmpJobFunction,
        jobLevel: tmpJobLevel,
        jobTitle: tmpJobTitle,
        jobKeyword: tmpJobKeyword,
        customCriteria: tmpCustomCriteria
      }
    });
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { searchName } = this.state;

    let errors = {};

    if (_isEmpty(searchName)) {
      errors["searchName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "marketplace.search_name", defaultMessage: "search name" })
      });
    }

    this.setState({ errors });
  }

  _handleSaved = async () => {
    const { intl } = this.props;
    const { filterData: { region, countriesToTarget, languages, industries, employeeSizes, leadsPerOrg, jobFunction, jobLevel, jobTitle, jobKeyword, customCriteria } } = this.state;

    if (_isEmpty(leadsPerOrg) && _isEmpty(region) && _isEmpty(languages) && _isEmpty(jobTitle) && _isEmpty(jobLevel) && _isEmpty(countriesToTarget) && _isEmpty(customCriteria) && _isEmpty(jobKeyword) && _isEmpty(employeeSizes) && _isEmpty(industries) && _isEmpty(jobFunction)) {

      showAlertMessage(intl.formatMessage({ id: "marketplace.atleast_fill_one_filter", defaultMessage: "Please fill atleast one filter" }));

      return false;
    }

    this.setState({ showSearchModal: true });
  }

  _setFilterValues = (e, fieldType) => {

    // Set Textbox value & Multiple dropdown value
    if (["jobTitle", "jobKeyword", "customCriteria", "leadsPerOrg", "employeeSizes", "industries", "languages", "countriesToTarget"].includes(fieldType)) {
      this.setState((prevState) => ({ filterData: { ...prevState.filterData, [fieldType]: e } }));
    }

    // Set single dropdown value
    if (["jobLevel", "jobFunction", "region"].includes(fieldType)) {
      this.setState((prevState) => ({ filterData: { ...prevState.filterData, [fieldType]: (e.id !== 0) ? e : null } }));
    }
  }

  _closeSearchModal = (ev) => {
    if (typeof ev === "object") { ev.preventDefault(); }

    this.setState({ showSearchModal: false, searchName: "" });
  }

  _doSavedSearch = () => {
    const { saveMarketPlaceSearch } = this.props;
    const { searchName, filterData: { region, countriesToTarget, languages, industries, employeeSizes, leadsPerOrg, jobFunction, jobLevel, jobTitle, jobKeyword, customCriteria }, errors } = this.state;

    if (_isEmpty(searchName) || !_isEmpty(errors)) { this._handleValidation(); return false; }

    if (typeof saveMarketPlaceSearch === "function") {
      saveMarketPlaceSearch({
        "searchName": (searchName || ""),
        "searchId": null,
        "searchCriteria": {
          "region": _get(region, "name", null),
          "countries": (countriesToTarget !== null) ? (countriesToTarget || []).map(c => (c.name || "")) : null,
          "languages": (languages !== null) ? (languages || []).map(c => (c.name || "")) : null,
          "industries": (industries !== null) ? (industries || []).map(c => (c.name || "")) : null,
          "employee_sizes": (employeeSizes !== null) ? (employeeSizes || []).map(c => (c.name || "")) : null,
          "leads_per_org_per_country": (leadsPerOrg || null),
          "job_function": _get(jobFunction, "name", null),
          "job_titles": (jobTitle || null),
          "job_level": _get(jobLevel, "name", null),
          "job_keyword": (jobKeyword || null),
          "custom_criteria": (customCriteria || null)
        }
      });
    }
  }

  _fetchSaveSearchList = async () => {
    const { updateLoadingState } = this.props;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await fetchSavedSearchList();

      if (_get(response, "flag", false) === true) {

        this.setState({ savedSearchList: _get(response, "data.data", []) });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching marketplace saved search list."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching marketplace saved search list."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderMarketFilter = () => {
    const { intl } = this.props;
    const { filterData: { region, countriesToTarget, languages, industries, employeeSizes, leadsPerOrg, jobFunction, jobLevel, jobTitle, jobKeyword, customCriteria }, dropdownLookup } = this.state;

    return (
      <div className="row mb-20">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-16">
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.region" defaultMessage="region" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "region", [])}
                        value={(region || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "region")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8">
                      <FormattedMessage id="marketplace.countries_to_target" defaultMessage="Countries to Target" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "country", [])}
                        value={(countriesToTarget || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "countriesToTarget")}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.language" defaultMessage="language" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "language", [])}
                        value={(languages || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "languages")}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.industries" defaultMessage="Industries" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "industry", [])}
                        value={(industries || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "industries")}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.employee_sizes" defaultMessage="employee sizes" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "employee_size", [])}
                        value={(employeeSizes || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "employeeSizes")}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8">
                      <FormattedMessage id="marketplace.leads_per_org_per_country" defaultMessage="Leads per Org per Country" />
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control border"
                        name="leadsPerOrg"
                        value={(leadsPerOrg || "")}
                        placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                          field: intl.formatMessage({ id: "marketplace.leads_per_org_per_country", defaultMessage: "Leads per Org per Country" })
                        })}
                        onChange={(e) => this._setFilterValues(isValidIntegerNumber(_get(e, "target.value", "")), "leadsPerOrg")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.job_functions" defaultMessage="job functions" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "job_functions", [])}
                        value={(jobFunction || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "jobFunction")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.job_levels" defaultMessage="job levels" />
                    </label>
                    <div className="position-relative">
                      <Select
                        className="text-capitalize form-custom-select border"
                        placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                        options={_get(dropdownLookup, "job_seniority", [])}
                        value={(jobLevel || null)}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(selectedOption) => this._setFilterValues(selectedOption, "jobLevel")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8">
                      <FormattedMessage id="marketplace.job_titles" defaultMessage="Job Titles" />
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control border"
                        name="jobTitle"
                        value={(jobTitle || "")}
                        placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                          field: intl.formatMessage({ id: "marketplace.job_titles", defaultMessage: "job Titles" })
                        })}
                        onChange={(e) => this._setFilterValues(_get(e, "target.value", ""), "jobTitle")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-8 text-capitalize">
                      <FormattedMessage id="marketplace.job_keywords" defaultMessage="job keyword" />
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control border"
                        name="jobKeyword"
                        value={(jobKeyword || "")}
                        placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                          field: intl.formatMessage({ id: "marketplace.job_keywords", defaultMessage: "job keyword" })
                        })}
                        onChange={(e) => this._setFilterValues(_get(e, "target.value", ""), "jobKeyword")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <label className="heading-05 mb-8 text-capitalize text-dark">
                      <FormattedMessage id="marketplace.custom_criteria" defaultMessage="custom criteria" />
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="customCriteria"
                      value={(customCriteria || "")}
                      placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                        field: intl.formatMessage({ id: "marketplace.free_type", defaultMessage: "Free Type" })
                      })}
                      onChange={(e) => this._setFilterValues(_get(e, "target.value", ""), "customCriteria")}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-end d-flex flex-lg-row flex-column align-items-center justify-content-end gap-20 mb-30">
                    <button type="button" className="btn btn-md btn-light position-relative" onClick={() => this.setState({ filterData: _cloneDeep(this.defaultFilterStates), selectedSearchId: null, showMarketFiters: false, searchName: "" })}>
                      <span className="img-fluid position-absolute top-0 start-0 mt-12 ms-15">
                        <IconReset height={22} width={22} color="#FFFFFF" />
                      </span>
                      <FormattedMessage id="btn.reset" defaultMessage="reset" />
                    </button>
                    <button type="button" className="btn btn-md btn-primary position-relative" onClick={() => this._handleSaved()}>
                      <span className="img-fluid position-absolute top-0 start-0 mt-12 ms-15">
                        <IconSave height={24} width={24} color="#FFFFFF" />
                      </span>
                      <FormattedMessage id="btn.save" defaultMessage="save" />
                    </button>
                    {/* <button type="button" className="btn btn-md btn-primary position-relative" onClick={() => this._handleSaved()}>
                <span className="img-fluid position-absolute top-0 end-0 mt-12 me-15">
                  <IconUpdate height={20} width={20} color="#FFFFFF" />
                </span>
                <FormattedMessage id="btn.update" defaultMessage="update" /> 
              </button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderOfferingData = () => {
    const { selectedSearchId, searchText } = this.state;

    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="card p-15">
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-15 gap-15">
                <h4 className="heading-03 fw-medium text-primary text-capitalize"><FormattedMessage id="marketplace.latest_offerings" defaultMessage="Latest Offerings" /></h4>
              </div>
              <LatestOffering selectedSearchId={(selectedSearchId || null)} searchText={(searchText || null)} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderTopFilter = () => {
    const { intl } = this.props;
    const { selectedSearchId, savedSearchList, searchText } = this.state;

    return (
      <>
        {/* <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12 text-capitalize"><FormattedMessage id="marketplace.lead_type" defaultMessage="lead type" /></label>
            <div className="btn-group d-flex">
              <span
                className={`btn btn-md text-capitalize fw-normal ${(selLeadType === "people") ? "btn-primary" : "btn-secondary"}`}
                onClick={() => this.setState({ selLeadType: "people" })}
              >
                <FormattedMessage id="marketplace.people" defaultMessage="people" />
              </span>
              <span
                className={`btn btn-md text-capitalize fw-normal ${(selLeadType === "company") ? "btn-primary" : "btn-secondary"}`}
                onClick={() => this.setState({ selLeadType: "company" })}
              >
                <FormattedMessage id="marketplace.companies" defaultMessage="companies" />
              </span>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4">
          <div className="mb-20">
            <label className="mb-12 text-capitalize"><FormattedMessage id="marketplace.saved_searches" defaultMessage="saved searches" /></label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(savedSearchList || [])}
                value={(selectedSearchId || null)}
                getOptionLabel={(option) => (option.search_name || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selectedOption) => this.setState({ selectedSearchId: (selectedOption.id !== 0) ? (selectedOption || null) : null })}
                isMulti={false}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-20 position-relative">
            <label className="mb-12 text-capitalize"><FormattedMessage id="marketplace.search" defaultMessage="search" /></label>
            <span className="img-fluid position-absolute top-0 end-0 mt-45 me-20">
              <IconSearch height="21" width="21" color="#BCBCBC" />
            </span>
            <input
              type="text"
              className="form-control"
              name="searchText"
              placeholder={intl.formatMessage({ id: "marketplace.search", defaultMessage: "search" })}
              value={(searchText || "")}
              onChange={(e) => this.setState({ searchText: _get(e, "target.value", null) })}
            />
          </div>
        </div>
      </>
    )
  }

  _renderModal() {
    const { intl } = this.props;
    const { showSearchModal, searchName, errors } = this.state;

    return (
      <Modal
        isOpen={(showSearchModal || false)}
        bodyClassName="bg-white-f2"
        modalTitleClassName="col-md-10"
        modalTitle={(<FormattedMessage id="marketplace.saved_search" defaultMessage="Saved Search" />)}
        onHide={() => this._closeSearchModal()}
        onClose={() => this._closeSearchModal()}
        showCloseButton={true}
      >
        <Form className="form-search" autoComplete="off">
          <div className="tab-content">
            <div className="cardBox bg-gray-f8">
              <div className="cardBox-content">
                <div className="card">
                  <div className="card-body p-20">
                    <div className="form-group required mb-35">
                      <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                        <FormattedMessage id="marketplace.search_name" defaultMessage="search name" />
                      </label>
                      <input
                        type="text"
                        name="searchName"
                        className="form-control text-capitalize border"
                        id="searchName"
                        placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                          field: intl.formatMessage({ id: "marketplace.search_name", defaultMessage: "search name" })
                        })}
                        value={(searchName || "")}
                        onChange={(e) => this.setState({ searchName: _get(e, "target.value", "") })}
                      />
                      {(errors.searchName || "") && (
                        <ErrorMessage message={(errors.searchName || "")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="row gutters-5">
          <div className="col-lg-12">
            <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
              <button className="btn btn-primary" onClick={() => this._doSavedSearch()}>
                <FormattedMessage id="btn.submit" defaultMessage="submit" />
              </button>
              <button className="btn btn-secondary" onClick={(e) => this._closeSearchModal(e)}>
                <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { intl } = this.props;
    const { showMarketFiters } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.market_place", defaultMessage: "Marketplace - Leads United" })} </title>
        </Helmet>
        <div className="mb-20">
          <h1 className="heading-02 fw-medium text-capitalize"><FormattedMessage id="marketplace.title" defaultMessage="marketplace" /></h1>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Form className="form-search" autoComplete="off">
              <div className="row">
                {this._renderTopFilter()}

                <div className="col-lg-3">
                  <div className="mb-20 pe-lg-50">
                    <label className="mb-12 d-block text-capitalize"><FormattedMessage id="marketplace.filters" defaultMessage="filters" /></label>
                    <button
                      className="btn btn-md btn-dark text-capitalize fw-normal w-100 position-relative collapsed"
                      type="button"
                      aria-expanded="false"
                      onClick={() => (this.setState({ showMarketFiters: !showMarketFiters }))}
                    >
                      <span className="img-fluid position-absolute top-0 start-0 mt-11 ms-20">
                        <IconFilter height={20} width={20} color="#BCBCBC" />
                      </span>
                      <FormattedMessage id="marketplace.show_filters" defaultMessage="show filters" />
                      <span className="img-fluid position-absolute top-0 end-0 mt-10 me-12">
                        <IconPlus height={17} width={17} color="#FFFFFF" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <Collapse in={(showMarketFiters || false)}>{this._renderMarketFilter()}</Collapse>
            </Form>
          </div>
          {this._renderOfferingData()}
        </div>
        {this._renderModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "oauth.user.role_id", 0),
  dropdownLookup: _get(state, "leads.buyLeads.dropdownLookup", {}),
  savedSearch: _get(state, "application.marketPlace.savedSearch", {}),
  getSavedCriteriaAndList: _get(state, "application.marketPlace.getSavedCriteriaAndList", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDropdownLookup: () => dispatch(fetchDropdownLookup()),
  resetDropdownLookupData: () => dispatch(resetDropdownLookupData()),
  saveMarketPlaceSearch: (data) => dispatch(saveMarketPlaceSearch(data)),
  resetSaveMarketPlaceSearch: () => dispatch(resetSaveMarketPlaceSearch()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  resetMarketplaceSavedCriteriaAndListData: () => dispatch(resetMarketplaceSavedCriteriaAndListData())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarketPlace)));