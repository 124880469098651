import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual, set as _set, omit as _omit, groupBy as _groupBy, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { ErrorMessage, Select, showAlertMessage, DatePicker, SingleDropzone, SingleDropzoneClone, SingleDropzoneReadOnly } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import Preview from "./components/preview";

import { IconRightCaret, IconLgDone, IconClose } from "../../helpers/iconStyles";
import { downloadAnyFile } from "../../helpers/utils";

import { fetchProjectDetails } from "../../services/leads";
import { downloadFile } from "../../services/application";

import { fetchDropdownLookup, resetDropdownLookupData, addBuyLeadsRequest, resetBuyLeadsData, fetchLeadsRequiredFieldsLookupValues } from "../../actions/leads";
import { updateLoadingState } from "../../actions/application";

class BuyLeads extends Component {
  constructor(props) {
    super(props);

    this.defaultAudienceTargeting = {
      region: null,
      countriesToTarget: null,
      languages: null,
      industries: null,
      employeeSizes: null,
      leadsPerOrg: null,
      jobFunction: null,
      jobLevel: null,
      jobTitle: "",
      jobKeyword: "",
      customCriteria: ""
    };

    this.defaultStates = {
      projectName: "",
      volOfLeads: null,
      deliveryDate: null,
      budget: null,
      uploadedFileName: "",
      uploadedFileURL: "",
      uploadedFileNameCloned: "",
      uploadedFileURLCloned: "",
      talId: 0,
      suppressionId: 0,
      uploadedFileNameReadAsString: "",
      uploadedFileURLReadAsString: "",
      buyLeadsId: null,
      selDataFieldIds: [],
      selDataFieldNames: [],
      selectAllDataFields: false
    };

    this.state = {
      ...this.defaultStates,
      dropdownLookup: {},
      errors: {},
      showForm: true,
      showPreview: false,
      showSuccess: false,
      indexNo: 1,
      showAudience: 0,
      audienceTargeting: [_cloneDeep(this.defaultAudienceTargeting)],
      formData: {},
      isAllowEditTalFile: false,
      isAllowEditSuppressionFile: false,
      projectId: _get(this.props, "params.id", 0)
    };
  }

  componentDidMount() {
    const { fetchDropdownLookup, dropdownLookup, fetchLeadsRequiredFieldsLookupValues } = this.props;
    const { projectId } = this.state;

    if (typeof fetchDropdownLookup === "function") { fetchDropdownLookup(); }

    if (typeof fetchLeadsRequiredFieldsLookupValues === "function") { fetchLeadsRequiredFieldsLookupValues(); }

    this.setState({ dropdownLookup: _get(dropdownLookup, "data", []) });

    if (projectId !== 0) { this._fetchProjectDetails(); }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dropdownLookup, this.props.dropdownLookup) && !_isEmpty(this.props.dropdownLookup)) {
      const { dropdownLookup, resetDropdownLookupData } = this.props;

      if (_get(dropdownLookup, "status", null) === null) { return false; }

      if (_get(dropdownLookup, "status", false) === true) {

        this.setState({ dropdownLookup: _get(dropdownLookup, "data", []) });
      } else {
        showAlertMessage((dropdownLookup.message || "Something went wrong while fetching dropdown lookup details."));

        if (typeof resetDropdownLookupData === "function") { resetDropdownLookupData(); }
      }
    }

    if (!_isEqual(prevProps.addBuyLeads, this.props.addBuyLeads) && !_isEmpty(this.props.addBuyLeads)) {
      const { addBuyLeads, resetBuyLeadsData } = this.props;

      if (_get(addBuyLeads, "status", null) === null) { return false; }

      if (_get(addBuyLeads, "status", false) === true) {
        this._resetParentAllStates();
      } else {
        showAlertMessage((addBuyLeads.message || "Something went wrong while adding buy leads."));
      }

      if (typeof resetBuyLeadsData === "function") { resetBuyLeadsData(); }
    }

    if (
      !_isEqual(prevState.projectName, this.state.projectName) ||
      !_isEqual(prevState.volOfLeads, this.state.volOfLeads) ||
      !_isEqual(prevState.deliveryDate, this.state.deliveryDate) ||
      !_isEqual(prevState.budget, this.state.budget)
    ) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.readOnlyFileContents, this.props.readOnlyFileContents) && !_isEmpty(this.props.readOnlyFileContents)) {
      const { readOnlyFileContents } = this.props;
      const { showAudience } = this.state;

      this.setState(prevState => ({
        audienceTargeting: {
          ...prevState.audienceTargeting,
          [showAudience]: {
            ...prevState.audienceTargeting[showAudience],
            "jobTitle": readOnlyFileContents.map(v => Object.values(v)).join(",")
          },
        }
      }));
    }

    if (!_isEqual(_get(prevProps, "params.id", 0), _get(this.props, "params.id", 0)) && _get(this.props, "params.id", 0) !== 0) {

      this._fetchProjectDetails();
    }
  }

  _fetchProjectDetails = async () => {
    const { updateLoadingState } = this.props;
    const { projectId } = this.state;

    let tempAudienceTargeting = [];
    let finalAudienceTargeting = [];

    if (projectId === null || projectId <= 0) {
      showAlertMessage("Something went wrong while fetching project details.");

      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await fetchProjectDetails(projectId || 0);

      if (_get(response, "flag", false) === true) {

        const projectDetails = _get(response, "data.data", []);

        tempAudienceTargeting = !_isEmpty(_get(projectDetails, "target_audience", [])) ? _groupBy(_get(projectDetails, "target_audience", []), "criteria_name") : [];

        if (!_isEmpty(tempAudienceTargeting)) {

          Object.values(tempAudienceTargeting || []).forEach((criteria, i) => {

            let tmpRegion = {};
            let tmpCountriesToTarget = [];
            let tmpLanguages = [];
            let tmpIndustries = [];
            let tmpEmployeeSizes = [];
            let tmpLeadsPerOrg = "";
            let tmpJobFunction = {};
            let tmpJobLevel = {};
            let tmpJobTitle = [];
            let tmpJobKeyword = "";
            let tmpCustomCriteria = "";
            let count = 0;

            if (_get(criteria, `[${count}]criteria_name`, "").toLowerCase() === `criteria ${i + 1}`) {

              Object.values(criteria || []).forEach((element, ind) => {

                if (_get(element, "target_type", "").toLowerCase() === "region") {
                  tmpRegion = { id: element.id, name: element.target_value };
                }

                if (_get(element, "target_type", "").toLowerCase() === "industry") {
                  tmpIndustries.push({ id: element.id, name: element.target_value });
                }

                if (_get(element, "target_type", "").toLowerCase() === "country") {
                  tmpCountriesToTarget.push({ id: element.id, name: element.target_value });
                }

                if (_get(element, "target_type", "").toLowerCase() === "language") {
                  tmpLanguages.push({ id: element.id, name: element.target_value });
                }

                if (_get(element, "target_type", "").toLowerCase() === "employee size") {
                  tmpEmployeeSizes.push({ id: element.id, name: element.target_value });
                }

                if (_get(element, "target_type", "").toLowerCase() === "leads per org") {
                  tmpLeadsPerOrg = parseInt(_get(element, "target_value", 0));
                }

                if (_get(element, "target_type", "").toLowerCase() === "job function") {
                  tmpJobFunction = { id: element.id, name: element.target_value };
                }

                if (_get(element, "target_type", "").toLowerCase() === "job level") {
                  tmpJobLevel = { id: element.id, name: element.target_value };
                }

                if (_get(element, "target_type", "").toLowerCase() === "job keyword") {
                  tmpJobKeyword = _get(element, "target_value", "");
                }

                if (_get(element, "target_type", "").toLowerCase() === "job title") {
                  tmpJobTitle.push(_get(element, "target_value", ""));
                }

                if (_get(element, "target_type", "").toLowerCase() === "custom criteria") {
                  tmpCustomCriteria = _get(element, "target_value", "");
                }
              });
            }

            count += 1;

            finalAudienceTargeting.push({
              region: !_isEmpty(tmpRegion) ? tmpRegion : null,
              countriesToTarget: tmpCountriesToTarget,
              languages: tmpLanguages,
              industries: tmpIndustries,
              employeeSizes: tmpEmployeeSizes,
              leadsPerOrg: tmpLeadsPerOrg,
              jobFunction: !_isEmpty(tmpJobFunction) ? tmpJobFunction : null,
              jobLevel: !_isEmpty(tmpJobLevel) ? tmpJobLevel : null,
              jobTitle: !_isEmpty(tmpJobTitle) ? tmpJobTitle : "",
              jobKeyword: tmpJobKeyword,
              customCriteria: tmpCustomCriteria
            })
          })
        }

        this.setState({
          projectName: _get(projectDetails, "project[0].project_name", ""),
          volOfLeads: _get(projectDetails, "project[0].leads_volume", null),
          deliveryDate: _get(projectDetails, "project[0].delivery_date", null),
          budget: _get(projectDetails, "project[0].budget", null),
          audienceTargeting: finalAudienceTargeting,
          showAudience: finalAudienceTargeting.length - 1,
          indexNo: finalAudienceTargeting.length,
          uploadedFileURLCloned: _get(projectDetails, "tal[0].file_path", ""),
          uploadedFileURL: _get(projectDetails, "suppression[0].file_path", ""),
          uploadedFileNameCloned: _get(projectDetails, "tal[0].file_name", ""),
          uploadedFileName: _get(projectDetails, "suppression[0].file_name", ""),
          buyLeadsId: (projectId || null),
          talId: _get(projectDetails, "tal[0].tal_id", null),
          suppressionId: _get(projectDetails, "suppression[0].suppression_id", null),
          isAllowEditTalFile: _get(projectDetails, "tal[0].tal_id", null) !== null ? true : false,
          isAllowEditSuppressionFile: _get(projectDetails, "suppression[0].suppression_id", null) !== null ? true : false,
        });
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while fetching project details."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while fetching project details."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { projectName, volOfLeads, deliveryDate, budget } = this.state;

    let errors = {};

    if (_isEmpty(projectName)) {
      errors["projectName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_buy_leads.project_name", defaultMessage: "Project Name" }),
      });
    }

    if (volOfLeads <= 0) {
      errors["volOfLeads"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_buy_leads.volume_of_leads_required", defaultMessage: "Volume of Leads Required" }),
      });
    }

    if (moment(deliveryDate).isValid() === false) {
      errors["deliveryDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_buy_leads.delivery_date", defaultMessage: "Delivery Date" }),
      });
    }

    if (budget <= 0) {
      errors["budget"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_buy_leads.budget", defaultMessage: "Budget" }),
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errors });
  }

  _checkEmptyAudianceTarget = () => {
    const { audienceTargeting } = this.state;

    let isCriteriaFlag = false;
    let checkAudianceLength = 0;

    Object.values(audienceTargeting || []).forEach((col, index) => {

      if (!_isEmpty(col.leadsPerOrg) || !_isEmpty(col.region) || !_isEmpty(col.languages) || !_isEmpty(col.jobTitle) || !_isEmpty(col.jobLevel) || !_isEmpty(col.countriesToTarget) || !_isEmpty(col.customCriteria) || !_isEmpty(col.jobKeyword) || !_isEmpty(col.employeeSizes) || !_isEmpty(col.industries) || !_isEmpty(col.jobFunction)) {
        checkAudianceLength = checkAudianceLength + 1;
        isCriteriaFlag = true;
      }

      if (checkAudianceLength !== Object.values(audienceTargeting || []).length) {
        isCriteriaFlag = false;
      }
    });

    if (isCriteriaFlag === false) {

      return false;
    }
    return true;
  }

  _handleNext = async () => {
    const { APIRequestDateFormat, intl } = this.props;
    const { projectName, volOfLeads, deliveryDate, budget, audienceTargeting, errors, talId, suppressionId, uploadedFileName, uploadedFileURL, uploadedFileNameCloned, uploadedFileURLCloned, buyLeadsId, selDataFieldIds, selDataFieldNames } = this.state;

    let audienceTargetingPayload = [];
    let tempAudienceTargeting = [];

    if (_isEmpty(projectName) || (volOfLeads <= 0) || (moment(deliveryDate).isValid() === false) || (budget <= 0) || !_isEmpty(errors)) {
      this._handleValidation();

      return false;
    }

    let isCriteria = this._checkEmptyAudianceTarget();

    if (isCriteria === false) {
      showAlertMessage(intl.formatMessage({ id: "app_buy_leads.atleast_fill_one_criteria_list", defaultMessage: "Please fill atleast one criteria list" }));
      return false;
    }

    tempAudienceTargeting = _cloneDeep(audienceTargeting || []);

    if (Object.keys(tempAudienceTargeting || []).length > 0) {

      audienceTargetingPayload = Object.values(tempAudienceTargeting || []).map((e, i) => (
        {
          group_name: `criteria ${i + 1}`,
          criteria: {
            "region": _get(e, "region", "") ? _get(e, "region.name", "") : null,
            "countries": _get(e, "countriesToTarget", null) ? _get(e, "countriesToTarget", []).map(c => (c.name || "")) : null,
            "languages": _get(e, "languages", null) ? _get(e, "languages", []).map(c => (c.name || "")) : null,
            "industries": _get(e, "industries", null) ? _get(e, "industries", []).map(c => (c.name || "")) : null,
            "employee_sizes": _get(e, "employeeSizes", null) ? _get(e, "employeeSizes", []).map(c => (c.name || "")) : null,
            "leads_per_org_per_country": _get(e, "leadsPerOrg", null),
            "job_function": _get(e, "jobFunction", "") ? _get(e, "jobFunction.name", null) : null,
            "job_level": _get(e, "jobLevel", "") ? _get(e, "jobLevel.name", null) : null,
            "job_titles": _get(e, "jobTitle", ""),
            "job_keyword": _get(e, "jobKeyword", ""),
            "custom_criteria": _get(e, "customCriteria", "")
          }
        }
      ));
    }

    let previewData = {
      "projectName": (projectName || ""),
      "deliveryDate": moment(deliveryDate).format(APIRequestDateFormat),
      "leadsVolume": (volOfLeads || "").toString(),
      "budget": (budget || "").toString(),
      "batches": 1,
      "audienceTargeting": audienceTargetingPayload,
      "talId": talId,
      "suppressionId": suppressionId,
      "uploadedFileName": (uploadedFileName || ""),
      "uploadedFileURL": (uploadedFileURL || ""),
      "uploadedFileNameCloned": (uploadedFileNameCloned || ""),
      "uploadedFileURLCloned": (uploadedFileURLCloned || ""),
      "buyLeadsId": (buyLeadsId || null),
      "requiredFields": (selDataFieldIds || []),
      "selDataFieldNames": (selDataFieldNames || []),
    };

    this.setState({ formData: previewData, showPreview: true, showForm: false, showSuccess: false });
  }

  _submitBuyLeadsRequest = () => {
    const { addBuyLeadsRequest } = this.props;
    const { projectName, volOfLeads, deliveryDate, budget, formData, errors } = this.state;

    if (_isEmpty(projectName) || (volOfLeads <= 0) || (moment(deliveryDate).isValid() === false) || (budget <= 0) || !_isEmpty(errors)) {
      this._handleValidation();

      return false;
    }

    if (typeof addBuyLeadsRequest === "function") {
      let tmpFormData = _cloneDeep(formData);
      delete tmpFormData['selDataFieldNames'];

      const finalPayload = _omit(tmpFormData, ["uploadedFileName", "uploadedFileURL", "uploadedFileNameCloned", "uploadedFileURLCloned"]);

      addBuyLeadsRequest(finalPayload);
    }
  }

  _resetParentAllStates = () => {

    this.setState({
      ...this.defaultStates, errors: {}, showForm: false, showPreview: false, showSuccess: true, indexNo: 1,
      showAudience: 0, audienceTargeting: [_cloneDeep(this.defaultAudienceTargeting)], formData: {}
    });
  }

  _addAudienceTargeting = (e) => {
    e.preventDefault();

    const { intl } = this.props;
    const { indexNo } = this.state;

    let isCriteria = this._checkEmptyAudianceTarget();

    if (isCriteria === false) {
      showAlertMessage(intl.formatMessage({ id: "app_buy_leads.atleast_fill_one_criteria_list", defaultMessage: "Please fill atleast one criteria list" }));
      return false;
    }

    this.setState(prevState => ({
      audienceTargeting: {
        ...prevState.audienceTargeting,
        [indexNo]: {
          ...this.defaultAudienceTargeting
        },
      },
      showAudience: indexNo,
      indexNo: indexNo + 1
    }))
  };

  _addDuplicateTargeting = (e) => {
    e.preventDefault();

    const { intl } = this.props;
    const { audienceTargeting, indexNo } = this.state;

    let tempAudienceTargeting = _cloneDeep(audienceTargeting);

    let isCriteria = this._checkEmptyAudianceTarget();

    if (isCriteria === false) {
      showAlertMessage(intl.formatMessage({ id: "app_buy_leads.atleast_fill_one_criteria_list", defaultMessage: "Please fill atleast one criteria list" }));
      return false;
    }

    tempAudienceTargeting = Object.values(tempAudienceTargeting).slice(-1);

    this.setState(prevState => ({
      audienceTargeting: {
        ...prevState.audienceTargeting,
        [indexNo]: tempAudienceTargeting[0]
      },
      showAudience: indexNo,
      indexNo: indexNo + 1
    }))
  }

  resetJobTitleBox = async (key) => {

    this.setState(prevState => ({
      audienceTargeting: {
        ...prevState.audienceTargeting,
        [key]: {
          ...prevState.audienceTargeting[key],
          "jobTitle": ""
        },
      }
    }));
  }

  _checkNumString(e, index, key) {
    const valueInt = /^[0-9\b]+$/;
    const { audienceTargeting } = this.state;
    let tempAudienceTargeting = _cloneDeep(audienceTargeting);

    if ((_get(e, "target.value", "") === "") || valueInt.test(_get(e, "target.value", ""))) {
      _set(tempAudienceTargeting, `[${index}].${key}`, (_get(e, "target.value", "")));

      this.setState({ audienceTargeting: tempAudienceTargeting });
    }
  }

  _handleUpdateFormState = (e, index, key) => {
    const { audienceTargeting } = this.state;
    let tempAudienceTargeting = _cloneDeep(audienceTargeting);

    // Set Textbox value
    if ((key === "jobTitle") || (key === "jobKeyword") || (key === "customCriteria")) {
      _set(tempAudienceTargeting, `[${index}].${key}`, (_get(e, "target.value", "") || ""));
    }

    // Set Multiple dropdown value
    if (["employeeSizes", "industries", "languages", "countriesToTarget"].includes(key)) {
      _set(tempAudienceTargeting, `[${index}].${key}`, e);
    }

    // Set single dropdown value
    if ((key === "jobLevel") || (key === "jobFunction") || (key === "region")) {
      _set(tempAudienceTargeting, `[${index}].${key}`, ((e.id !== 0) ? e : null));
    }

    this.setState({ audienceTargeting: tempAudienceTargeting });
  }

  _deleteAudianceTargeting = (index) => {
    const { audienceTargeting, indexNo } = this.state;
    let tempAudienceTargeting = _cloneDeep(audienceTargeting);

    delete tempAudienceTargeting[index];
    let resetIndexNo = indexNo - 1;

    this.setState({ audienceTargeting: Object.values((tempAudienceTargeting || [])).sort(a => a.index), indexNo: resetIndexNo });
  }

  _isValidNum = (key, val) => {
    const valueInt = (key === "volOfLeads") ? (/^[0-9\b]+$/) : (/^\d*\.?\d*$/);

    if ((val === "") || valueInt.test(val)) {
      this.setState({ [key]: val });
    }
  }

  downloadFile = async (fileURL) => {
    const { updateLoadingState } = this.props;

    if (_isEmpty(fileURL)) { showAlertMessage("No file available."); return false; }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await downloadFile({ fileUrl: fileURL });

      if ((response.flag || false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.contents || "");
        const filename = (downloadFileDetails.fileName || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadAnyFile(contentType, contents, filename);

          showAlertMessage("File downloaded successfully.", "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while downloading template file.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading file.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _selectDataFieldIds = (id = null, name = "", source = "single") => {
    const { leadsRequiredFieldsLookup } = this.props;
    const { selDataFieldIds, selDataFieldNames, selectAllDataFields } = this.state;

    let updatedState = null;
    let dataFields = _get(leadsRequiredFieldsLookup, "data", []);
    let tmpSelectIds = _cloneDeep(selDataFieldIds || []);
    let tmpSelectNames = _cloneDeep(selDataFieldNames || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(selectAllDataFields);

        if (tmpSelectIds.includes(id)) {
          tmpSelectIds = (tmpSelectIds).filter(c => (c !== id));
          tmpSelectNames = (tmpSelectNames).filter(c => (c !== name));
          tmpCheckAllIds = false;
        } else {
          tmpSelectIds.push(id);
          tmpSelectNames.push(name);
        }
        updatedState = { selDataFieldIds: tmpSelectIds, selDataFieldNames: tmpSelectNames, selectAllDataFields: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selDataFieldIds: (selectAllDataFields === true) ? [] : _map(dataFields, "id"),
          selDataFieldNames: (selectAllDataFields === true) ? [] : _map(dataFields, "friendly_name"),
          selectAllDataFields: !selectAllDataFields
        };
        break;

      default:
        break;
    }

    this.setState((prevState) => ({
      selDataFieldIds: _get(updatedState, "selDataFieldIds", []),
      selDataFieldNames: _get(updatedState, "selDataFieldNames", []),
      selectAllDataFields: _get(updatedState, "selectAllDataFields", false)
    }));
  }

  _renderLeadInformation = () => {
    const { currencySymbol, intl } = this.props;
    const { projectName, volOfLeads, deliveryDate, budget, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-16">
              <h4 className="mb-12 text-primary heading-03 fw-medium"><FormattedMessage id="app_buy_leads.title.lead_information" defaultMessage="Lead Information" /></h4>
              <div className="row gy-20">
                <div className="col-lg-4">
                  <div>
                    <label className="heading-05 mb-8">
                      <FormattedMessage id="app_buy_leads.project_name" defaultMessage="project name" />
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="projectName"
                      value={(projectName || "")}
                      placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                        field: intl.formatMessage({ id: "app_buy_leads.project_name", defaultMessage: "project name" })
                      })}
                      onChange={(e) => this.setState({ projectName: _get(e, "target.value", "") })}
                    />
                    {(errors.projectName || "") && (<ErrorMessage message={(errors.projectName || "")} />)}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div>
                    <label className="heading-05 mb-8">
                      <FormattedMessage id="app_buy_leads.volume_of_leads_required" defaultMessage="Volume of Leads Required" />
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border"
                      name="volOfLeads"
                      value={(volOfLeads || "")}
                      placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                        field: intl.formatMessage({ id: "app_buy_leads.volume_of_leads_required", defaultMessage: "Volume of Leads Required" })
                      })}
                      onChange={(e) => this._isValidNum("volOfLeads", _get(e, "target.value", ""))}
                    />
                    {(errors.volOfLeads || "") && (<ErrorMessage message={(errors.volOfLeads || "")} />)}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div>
                    <label className="heading-05 mb-8">
                      <FormattedMessage id="app_buy_leads.delivery_date" defaultMessage="Delivery Date" />
                      <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      className="form-control dateLabel datepicker border"
                      dropdownMode="select"
                      selected={(deliveryDate || null)}
                      name="deliveryDate"
                      onChange={(deliveryDate) => this.setState({ deliveryDate: deliveryDate })}
                      locale="en-gb"
                      placeholder={intl.formatMessage({ id: "app_buy_leads.delivery_date", defaultMessage: "delivery date" })}
                      popperPlacement="bottom-end"
                      autoComplete="off"
                      dateFormat={"dd/MM/yyyy"}
                      minDate={moment().toDate()}
                    />
                    {((errors.deliveryDate || "") !== "") && <ErrorMessage message={errors.deliveryDate || ""} />}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div>

                    <label className="heading-05 mb-8">
                      <FormattedMessage id="app_buy_leads.budget" defaultMessage="budget" />
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">{currencySymbol}</span>
                      <input
                        type="text"
                        className="form-control border"
                        name="budget"
                        value={(budget || "")}
                        placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                          field: intl.formatMessage({ id: "app_buy_leads.budget", defaultMessage: "budget" })
                        })}
                        onChange={(e) => this._isValidNum("budget", _get(e, "target.value", ""))}
                      />
                    </div>
                    {(errors.budget || "") && (<ErrorMessage message={(errors.budget || "")} />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderAudienceTargeting = () => {
    const { intl, jobTitleFiles, jobTitleFilesLabel } = this.props;
    const { dropdownLookup, showAudience, audienceTargeting, uploadedFileNameReadAsString, uploadedFileURLReadAsString } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-16">
              <h4 className="mb-12 text-primary heading-03 fw-medium"><FormattedMessage id="app_buy_leads.title.audience_targeting" defaultMessage="Audience Target" /></h4>
              {(Object.values(audienceTargeting || []).map((col, index) => {
                let num = index + 1;

                return (
                  <div className="border-bottom accordion accordion-secondary d-flex flex-column gap-5 position-relative pb-8" key={`accodian${num}`} >
                    {(index >= 1) && (
                      <span className="cursor-pointer position-absolute top-0 end-0 mt-8 icon-20 z-index-2 text-end d-block" onClick={() => this._deleteAudianceTargeting(index)}>
                        <IconClose height="16" width="16" />
                      </span>
                    )}
                    <div className="accordion-item bg-transparent">
                      <h6 className="accordion-header pe-30" key={`headingOne${num}`}>
                        <button className="accordion-button bg-transparent collapsed" type="button" /*onClick={() => (showAudience === index) ? this.setState({ showAudience: null }) : this.setState({ showAudience: index })}*/>
                          <FormattedMessage id="app_buy_leads.criteria" defaultMessage="Criteria" />
                        </button>
                      </h6>
                      <div key={`subaccodian${num}`} className={`accordion-collapse collapse ${(showAudience === index) ? "show" : ""}`}>
                        <div className="accordion-body px-0">
                          <div className="row gy-20">
                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8">
                                  <FormattedMessage id="app_buy_leads.region" defaultMessage="region" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "region", [])}
                                  value={(col.region || null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "region")}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8">
                                  <FormattedMessage id="app_buy_leads.countries_to_target" defaultMessage="Countries to Target" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "country", [])}
                                  value={(!_isEmpty(_get(col, "countriesToTarget", null)) ? (col.countriesToTarget || null) : null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "countriesToTarget")}
                                  isMulti={true}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8">
                                  <FormattedMessage id="app_buy_leads.languages" defaultMessage="languages" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "language", [])}
                                  value={(!_isEmpty(_get(col, "languages", null)) ? (col.languages || null) : null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "languages")}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8 text-capitalize text-dark">
                                  <FormattedMessage id="app_buy_leads.industries" defaultMessage="industries" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "industry", [])}
                                  value={(!_isEmpty(_get(col, "industries", null)) ? (col.industries || null) : null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "industries")}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8">
                                  <FormattedMessage id="app_buy_leads.employee_sizes" defaultMessage="employee sizes" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "employee_size", [])}
                                  value={(!_isEmpty(_get(col, "employeeSizes", null)) ? (col.employeeSizes || null) : null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "employeeSizes")}
                                  isMulti={true}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8">
                                  <FormattedMessage id="app_buy_leads.leads_per_org_per_country" defaultMessage="Leads per Org per Country" />
                                </label>
                                <input
                                  type="text"
                                  className="form-control border"
                                  name="LeadsPerOrg"
                                  value={(col.leadsPerOrg || "")}
                                  placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                                    field: intl.formatMessage({ id: "app_buy_leads.leads_per_org_per_country", defaultMessage: "Leads per Org per Country" })
                                  })}
                                  onChange={(e) => this._checkNumString(e, index, "leadsPerOrg")}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8 text-capitalize text-dark">
                                  <FormattedMessage id="app_buy_leads.job_function" defaultMessage="job function" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "job_functions", [])}
                                  value={(col.jobFunction || null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "jobFunction")}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8 text-capitalize text-dark">
                                  <FormattedMessage id="app_buy_leads.job_level" defaultMessage="job level" />
                                </label>
                                <Select
                                  className="text-capitalize form-custom-select border"
                                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                                  options={_get(dropdownLookup, "job_seniority", [])}
                                  value={(col.jobLevel || null)}
                                  getOptionLabel={(option) => (option.name || "")}
                                  getOptionValue={(option) => (option.id || null)}
                                  onChange={(selected) => this._handleUpdateFormState(selected, index, "jobLevel")}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              {(showAudience === index) && (
                                <div>
                                  <label className="heading-05 mb-8 text-capitalize text-dark">
                                    <FormattedMessage id="app_buy_leads.upload_job_titles" defaultMessage="upload job titles" />
                                  </label>
                                  <div className="dropzon-chip-list-full-width">
                                    <SingleDropzoneReadOnly
                                      source="job_title"
                                      acceptedFormat={jobTitleFiles}
                                      allowedExtensions={jobTitleFilesLabel}
                                      setState={(args) => this.setState(args)}
                                      uploadedFileName={uploadedFileNameReadAsString}
                                      uploadedFileURL={uploadedFileURLReadAsString}
                                      preserveSelectedFile={false}
                                      displayReadOnlyUploadedFiles={false}
                                      displayReadOnlyUploadedRemoveBtn={false}
                                      saveRecordsInDB={false}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8 text-capitalize text-dark">
                                  <FormattedMessage id="app_buy_leads.job_keyword" defaultMessage="job keyword" />
                                </label>
                                <input
                                  type="text"
                                  className="form-control border"
                                  name="jobKeyword"
                                  value={(col.jobKeyword || "")}
                                  placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                                    field: intl.formatMessage({ id: "app_buy_leads.job_keyword", defaultMessage: "job keyword" })
                                  })}
                                  onChange={(e) => this._handleUpdateFormState(e, index, "jobKeyword")}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div>
                                <label className="heading-05 mb-8 text-capitalize text-dark">
                                  <FormattedMessage id="app_buy_leads.custom_criteria" defaultMessage="custom criteria" />
                                </label>
                                <input
                                  type="text"
                                  className="form-control border"
                                  name="customCriteria"
                                  value={(col.customCriteria || "")}
                                  placeholder={intl.formatMessage({ id: "placeholder.please_enter_field", defaultMessage: "Please enter {field}" }, {
                                    field: intl.formatMessage({ id: "app_buy_leads.free_type", defaultMessage: "Free Type" })
                                  })}
                                  onChange={(e) => this._handleUpdateFormState(e, index, "customCriteria")}
                                />
                              </div>
                            </div>

                            {(!_isEmpty(col.jobTitle)) && (
                              <div className="col-lg-4">
                                <div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="heading-05 mb-8 text-capitalize text-dark">
                                        <FormattedMessage id="app_buy_leads.job_titles" defaultMessage="Job Titles" />
                                      </label>
                                    </div>
                                    <div className="col-lg-6 text-end position-relative cursor-pointer text-danger" onClick={() => this.resetJobTitleBox(index)}>
                                      <FormattedMessage id="btn.reset" defaultMessage="reset" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control border"
                                    readOnly
                                    disabled
                                    name="jobTitle"
                                    value={(col.jobTitle || "")}
                                    onChange={(e) => this._handleUpdateFormState(e, index, "jobKeyword")}
                                  />
                                  <p className="small fw-italic mt-8">
                                    <FormattedMessage id="app_buy_leads.Separate_words_with_a_comma" defaultMessage="Separate words with a comma" />
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }))}

              {/*<div className="row mt-12 justify-content-end gx-12">
                <div className="col-auto">
                  <button type="button" className="btn btn-light px-lg-16" onClick={(e) => this._addAudienceTargeting(e)}>
                    <FormattedMessage id="app_buy_leads.add_new_audience" defaultMessage="add new audience" />
                  </button>
                </div>

                <div className="col-auto">
                  <button type="button" className="btn btn-light px-lg-16" onClick={(e) => this._addDuplicateTargeting(e)}>
                    <FormattedMessage id="app_buy_leads.duplicate_audience" defaultMessage="duplicate audience" />
                  </button>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSelectDataFields = () => {
    const { leadsRequiredFieldsLookup } = this.props;
    const { selectAllDataFields, selDataFieldIds, errors } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-16">
              <h4 className="mb-12 text-primary heading-03 fw-medium">
                <FormattedMessage id="app_buy_leads.data_fields.title" defaultMessage="Select the data you would like to buy" />
              </h4>
              <div className="col-lg-12">
                <div className="card p-15 mb-32">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-check mb-16" onClick={(e) => this._selectDataFieldIds(null, null, "all")}>
                        <input
                          className="form-check-input border"
                          type="checkbox"
                          name="selectAllDataFields"
                          checked={(selectAllDataFields || false)}
                          key="selectall"
                          onChange={() => { }}
                        />
                        <label className="form-check-label heading-05" htmlFor="selectall">
                          <FormattedMessage id="app_sell_leads.data_fields.select_all" defaultMessage="Select All" />
                        </label>
                      </div>
                    </div>

                    {_map(_get(leadsRequiredFieldsLookup, "data", []), (df, i) => {
                      const id = (df.id || null);
                      const name = _get(df, "friendly_name", "");

                      return (
                        <div className="col-lg-3" key={id}>
                          <div className="form-check mb-16" onClick={(e) => this._selectDataFieldIds(id, name)}>
                            <input
                              className="form-check-input border"
                              type="checkbox"
                              name="dataFields"
                              checked={((selDataFieldIds || []).includes(id))}
                              key={`checkbox-${id}`}
                              onChange={() => { }}
                            />
                            <label className="form-check-label heading-05" htmlFor={`checkbox-${id}`}>{(name || "")}</label>
                          </div>
                        </div>
                      );
                    })}

                    {(errors.selDataFieldIds || "") && (<ErrorMessage message={(errors.selDataFieldIds || "")} />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  _renderTAL = () => {
    const { allowedSuppressionFiles, allowedSuppressionFilesLabel, allowedTALFiles, allowedTALFilesLabel } = this.props;
    const { uploadedFileNameCloned, uploadedFileURLCloned, uploadedFileName, uploadedFileURL, suppressionId, talId, isAllowEditTalFile, isAllowEditSuppressionFile } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-16">
              <h4 className="mb-12 text-primary heading-03 fw-medium">
                <FormattedMessage id="app_buy_leads.title.tal_suppression" defaultMessage="TAL/Suppression" />
              </h4>
              <div className="row gy-20 mb-30">
                <div className="col-lg-6">
                  <div className="d-flex align-items-center justify-content-between mb-8">
                    <label className="heading-05">
                      <FormattedMessage id="app_buy_leads.tal_suppression.tal_upload" defaultMessage="TAL Upload" />
                    </label>
                    {(uploadedFileNameCloned === "") && (
                      <div>
                        <button type="button" className="btn text-decoration-underline text-primary p-0" onClick={() => this.downloadFile(process.env.REACT_APP_SUPPRESSION_SAMPLE_FILE_PATH || "")}>
                          <FormattedMessage id="app_buy_leads.tal_suppression.download_tal_template" defaultMessage="Download TAL Template" />
                        </button>
                      </div>
                    )}
                    {((uploadedFileNameCloned !== "") && (isAllowEditTalFile === true)) && (
                      <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadFile(uploadedFileURLCloned || "")}>
                        <FormattedMessage id="app_buy_leads.download_uploaded_file" defaultMessage="Download uploaded file" />
                      </button>
                    )}
                  </div>

                  <div className="dropzon-chip-list-full-width">
                    <SingleDropzoneClone
                      source="tal"
                      acceptedFormat={allowedTALFiles}
                      allowedExtensions={allowedTALFilesLabel}
                      setState={(args) => this.setState(args)}
                      uploadedFileName={uploadedFileNameCloned}
                      uploadedFileURL={uploadedFileURLCloned}
                      preserveSelectedFile={true}
                      displayUploadedFiles={true}
                      displayUploadedRemoveBtn={true}
                      insertedId={(isAllowEditTalFile === true) ? talId : null}
                    />
                  </div>
                </div>
              </div>

              <div className="row gy-20">
                <div className="col-lg-6">
                  <div className="d-flex align-items-center justify-content-between mb-8">
                    <label className="heading-05">
                      <FormattedMessage id="app_buy_leads.tal_suppression.suppression_upload" defaultMessage="Suppression Upload" />
                    </label>
                    {(uploadedFileName === "") && (
                      <div>
                        <button type="button" className="btn text-decoration-underline text-primary p-0" onClick={() => this.downloadFile(process.env.REACT_APP_TAL_SAMPLE_FILE_PATH || "")}>
                          <FormattedMessage id="app_buy_leads.tal_suppression.download_suppression_template" defaultMessage="Download Suppression Template" />
                        </button>
                      </div>
                    )}

                    {((uploadedFileName !== "") && (isAllowEditSuppressionFile === true)) && (
                      <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadFile(uploadedFileURL || "")}>
                        <FormattedMessage id="app_buy_leads.download_uploaded_file" defaultMessage="Download uploaded file" />
                      </button>
                    )}
                  </div>

                  <div className="dropzon-chip-list-full-width">
                    <SingleDropzone
                      source="suppressions"
                      acceptedFormat={allowedSuppressionFiles}
                      allowedExtensions={allowedSuppressionFilesLabel}
                      setState={(args) => this.setState(args)}
                      uploadedFileName={uploadedFileName}
                      uploadedFileURL={uploadedFileURL}
                      preserveSelectedFile={true}
                      displayUploadedFiles={true}
                      displayUploadedRemoveBtn={true}
                      insertedId={(isAllowEditSuppressionFile === true) ? suppressionId : null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSuccessMessage = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card p-15">
            <div className="my-30 text-center">
              <IconLgDone height="110" width="110" color="#e84968" />
              <div className="heading-02 text-primary fw-medium mt-10 text-capitalize" role="alert">
                <FormattedMessage id="app_buy_leads.success.title" defaultMessage="Success" />
              </div>
              <div className="px-lg-50">
                <p className="heading-04 mb-50 mt-20">
                  <FormattedMessage id="app_buy_leads.success.sub_title1" defaultMessage="Success, your request has been submitted" />
                </p>
                <p className="heading-04 mb-50 mt-20">
                  <FormattedMessage id="app_buy_leads.success.sub_title2" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                </p>
                <div>
                  <NavLink to="/dashboard" className="btn btn-md btn-primary text-capitalize">
                    <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { showForm, showPreview, showSuccess, formData } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.buy_leads", defaultMessage: "Buy Leads - Leads United" })} </title>
        </Helmet>
        <div className="section-heading mb-12">
          <h1 className="heading-03 fw-medium"><FormattedMessage id="app_buy_leads.title" defaultMessage="Buy Leads" /></h1>
        </div>

        {(showForm === true) && (
          <div className="row">
            <div className="col-lg-12">
              <form autoComplete="off">
                {this._renderLeadInformation()}

                {this._renderAudienceTargeting()}
              </form>

              {this._renderTAL()}

              {this._renderSelectDataFields()}

              <div className="row">
                <div className="col-lg-12">
                  <div className="text-end mt-30 position-relative">
                    <span className="btn btn-md btn-primary" onClick={() => this._handleNext()}>
                      <FormattedMessage id="btn.next" defaultMessage="next" />
                      <div className="img-fluid position-absolute top-50 end-0 translate-middle-y me-20">
                        <IconRightCaret height="15" width="8" color="#FFFFFF" />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(showPreview === true) && (
          <Preview formData={(formData || {})} setState={(e) => this.setState(e)} submitBuyLeadsRequest={() => this._submitBuyLeadsRequest()} />
        )}

        {(showSuccess === true) && (
          this._renderSuccessMessage()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  leadsRequiredFieldsLookup: _get(state, "leads.buyLeads.leadsRequiredFieldsLookup", {}),
  dropdownLookup: _get(state, "leads.buyLeads.dropdownLookup", {}),
  addBuyLeads: _get(state, "leads.buyLeads.addBuyLeads", {}),
  jobTitleFiles: _get(state, "application.constants.allowedJobTitleFiles", ""),
  jobTitleFilesLabel: _get(state, "application.constants.allowedJobTitleFilesLabel", ""),
  allowedTALFiles: _get(state, "application.constants.allowedTALFiles", ""),
  allowedTALFilesLabel: _get(state, "application.constants.allowedTALFilesLabel", ""),
  allowedSuppressionFiles: _get(state, "application.constants.allowedSuppressionFiles", ""),
  allowedSuppressionFilesLabel: _get(state, "application.constants.allowedSuppressionFilesLabel", ""),
  APIRequestDateFormat: _get(state, "application.constants.APIRequestDateFormat", "YYYY-MM-DD"),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  readOnlyFileContents: _get(state, "application.fileUploadReadOnly.dataMapping.success.records", []),
});

const mapDispatchToProps = (dispatch) => ({
  fetchLeadsRequiredFieldsLookupValues: () => dispatch(fetchLeadsRequiredFieldsLookupValues()),
  fetchDropdownLookup: () => dispatch(fetchDropdownLookup()),
  resetDropdownLookupData: () => dispatch(resetDropdownLookupData()),
  addBuyLeadsRequest: (data) => dispatch(addBuyLeadsRequest(data)),
  resetBuyLeadsData: () => dispatch(resetBuyLeadsData()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(BuyLeads)));