import { get as _get } from "lodash";

import { objectToParams } from "../helpers/utils";
import api from "../helpers/api";

export const getApplication = (uri) => {
  return api().get("/application/configuration").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching application configuration."),
      data: {}
    };
  });
}


export const getAPIKey = () => {
  return api().get("/user/get-api-key").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching API key."),
      data: {}
    };
  });
}

export const resetAPIKey = () => {
  return api().put("/user/reset-api-key", {}).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: (res.data || {})
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong rest API key."),
      data: {}
    };
  });
}

export const getInvoices = () => {
  return api().get("/user/user_payment_receipt").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching invoices."),
      data: {}
    };
  });
}

export const addTeamMember = (variables) => {
  return api().post("/user/add-team-member", variables).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: (res.data || {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.data.message", "Something went wrong while adding team member."),
      data: {}
    };
  });
}

export const getMyTeamData = () => {
  return api().get("/user/get-team-members").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching my team details."),
      data: {}
    };
  });
}

export const deleteTeamMember = (variables) => {
  return api().delete("/user/delete-team-member", { data: variables }).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while deleting team member."),
      data: {}
    };
  });
}

export const getUserSetting = (variables) => {
  const params = (typeof variables !== "undefined") ? objectToParams(variables) : "";

  return api().get(`/user/get-user-details?${params}`).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching user details."),
      data: {}
    };
  });
}

export const updateUserDetails = (variables) => {
  return api().put("/user/update-profile", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: (res.data || {})
    };
  }).catch((err) => {

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating user details."),
      data: {}
    };
  });
}

//Upload and read file and fetch data mapping columns
export const uploadCloneReadFile = (variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post(`/application/file-upload/${(variables.uploadType || "")}/uploads`, (variables.formFile || {}), config).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong uploading file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      statusCode: (statusCode === 422) ? statusCode : 401,
      data: _get(err, "response.data.data.work_sheet_names", {})
    };
  });
}

export const removeUploadedCloneFile = (variables) => {
  return api().delete("/application/delete-file", { data: variables }).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while deleting file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      data: {}
    };
  });
}

//Pass mapping data and store in DB
export const saveCloneMappingData = (variables) => {

  return api().post(`/application/file-upload/${(variables.uploadType || "")}/validate-and-uploads`, variables.payload).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while submitting details."),
      error: _get(err, "response.data.data.errors", {}),
      data: {}
    };
  });
}

//Upload Single File Only
export const singleFileUpload = (variables, setParentState = null) => {

  const config = {
    onUploadProgress: (data) => {
      if (typeof setParentState === "function") {
        setParentState({
          progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
        });
      }
    }
  };

  return api().post("/application/file-upload/single-upload", (variables || {}), config).then((res) => {

    return {
      flag: true,
      message: _get(res, "data.message", ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    const statusCode = _get(err, "response.status", 401);

    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong uploading file."),
      error: (statusCode === 400) ? _get(err, "response.data.errors", {}) : {},
      statusCode: (statusCode === 422) ? statusCode : 401,
      data: _get(err, "response.data.data.work_sheet_names", {})
    };
  });
}

export const downloadFile = (variables) => {

  return api().put("/application/download-file", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data.data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while downloading file."),
      data: {}
    };
  });
}

export const updateProjectStatus = (variables) => {

  return api().put("leads/update-project-status", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while updating project status."),
      data: {}
    };
  });
}

export const saveMarketPlaceSearch = (variables) => {

  return api().post("user/marketplace/save-search", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while saving marketplace search list."),
      data: {}
    };
  });
}

export const fetchSavedSearchList = () => {

  return api().get("user/marketplace/get-saved-search-list").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching marketplace saved search list."),
      data: {}
    };
  });
}

export const fetchSavedSearchFilterList = (variables) => {

  return api().put("/user/marketplace/search-by-id", (variables || {})).then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while saving marketplace search list."),
      data: {}
    };
  });
}

export const fetchDataRejectedTypes = () => {

  return api().get("application/data-rejection-types").then((res) => {
    return {
      flag: true,
      message: (res.message || ""),
      data: _get(res, "data", {})
    };
  }).catch((err) => {
    return {
      flag: false,
      title: _get(err, "response.data.title", "Alert"),
      message: _get(err, "response.data.message", "Something went wrong while fetching rejected types."),
      data: {}
    };
  });
}