import { all } from "redux-saga/effects";

import application from "./application";
import users from "./userManagement";
import leads from "./leads";
import oauth from "./oauth";

export default function* () {
  yield all([
    ...application,
    ...leads,
    ...oauth,
    ...users,
  ]);
}