export const UPDATE_URI = "UPDATE_URI";

export const updateUri = (uri) => {
  return ({
    type: UPDATE_URI,
    uri
  });
}

export const OAUTH_SET_TOKEN = "OAUTH_SET_TOKEN";

export const setToken = (token) => {
  return ({
    type: OAUTH_SET_TOKEN,
    token
  });
}

export const OAUTH_SIGNUP_REQUEST = "OAUTH_SIGNUP_REQUEST";
export const OAUTH_SIGNUP_SUCCESS = "OAUTH_SIGNUP_SUCCESS";
export const OAUTH_SIGNUP_ERROR = "OAUTH_SIGNUP_ERROR";

export const doSignUp = (data) => {
  return ({
    type: OAUTH_SIGNUP_REQUEST,
    data
  });
}

export const OAUTH_LOGIN_REQUEST = "OAUTH_LOGIN_REQUEST";
export const OAUTH_LOGIN_SUCCESS = "OAUTH_LOGIN_SUCCESS";
export const OAUTH_LOGIN_ERROR = "OAUTH_LOGIN_ERROR";

export const doLogin = (data) => {
  return ({
    type: OAUTH_LOGIN_REQUEST,
    data
  });
}

export const CLEAR_OAUTH_ERROR = "CLEAR_OAUTH_ERROR";

export const clearOauthError = () => {
  return ({
    type: CLEAR_OAUTH_ERROR
  });
}

export const CLEAR_OAUTH_SUCCESS = "CLEAR_OAUTH_SUCCESS";

export const clearOauthSuccess = () => {
  return ({
    type: CLEAR_OAUTH_SUCCESS
  });
}

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";

export const getAccount = (payload) => {
  return ({
    type: UPDATE_ACCOUNT_REQUEST,
    payload
  });
}

export const CLEAR_ACCOUNT_ERROR = "CLEAR_ACCOUNT_ERROR";

export const clearAccountError = () => {
  return ({
    type: CLEAR_ACCOUNT_ERROR
  });
}

/*
 * delete oauth token
 */
export const DELETE_OAUTH_TOKEN_SUCCESS = "DELETE_OAUTH_TOKEN_SUCCESS";
export const DELETE_OAUTH_TOKEN_REQUEST = "DELETE_OAUTH_TOKEN_REQUEST";

export const deleteOauthToken = () => {
  return ({
    type: DELETE_OAUTH_TOKEN_REQUEST
  });
}

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const doForgotPassword = (data) => {
  return ({
    type: FORGOT_PASSWORD_REQUEST,
    data
  });
}

export const CLEAR_FORGOT_ERROR = "CLEAR_FORGOT_ERROR";

export const clearForgotError = () => {
  return ({
    type: CLEAR_FORGOT_ERROR
  });
}

export const CLEAR_FORGOT_RESPONSE = "CLEAR_FORGOT_RESPONSE";

export const clearForgotResponse = () => {
  return ({
    type: CLEAR_FORGOT_RESPONSE
  });
}

export const CHECK_RESET_PASSWORD_CRITERIA = "CHECK_RESET_PASSWORD_CRITERIA";
export const RESET_PASSWORD_VALID = "RESET_PASSWORD_VALID";
export const RESET_PASSWORD_INVALID = "RESET_PASSWORD_INVALID";

export const checkResetPasswordCriteria = (data) => {
  return ({
    type: CHECK_RESET_PASSWORD_CRITERIA,
    data
  });
}

export const CLEAR_CHECK_RESET_RESPONSE = "CLEAR_CHECK_RESET_RESPONSE";

export const clearCheckResetResponse = () => {
  return ({
    type: CLEAR_CHECK_RESET_RESPONSE
  });
}

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const doResetPassword = (data) => {
  return ({
    type: RESET_PASSWORD_REQUEST,
    data
  });
}

export const CLEAR_RESET_RESPONSE = "CLEAR_RESET_RESPONSE";

export const clearResetResponse = () => {
  return ({
    type: CLEAR_RESET_RESPONSE
  });
}

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const doChangePassword = (data) => {
  return ({
    type: CHANGE_PASSWORD_REQUEST,
    data
  });
}

export const CLEAR_CHANGE_PASSWORD_RESPONSE = "CLEAR_CHANGE_PASSWORD_RESPONSE";

export const clearChangePasswordResponse = () => {
  return ({
    type: CLEAR_CHANGE_PASSWORD_RESPONSE
  });
}

export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";

export const clearChangePasswordError = () => {
  return ({
    type: CLEAR_CHANGE_PASSWORD_ERROR
  });
}

export const CREATE_CONTACT_US_REQUEST = "CREATE_CONTACT_US_REQUEST";
export const CREATE_CONTACT_US_SUCCESS = "CREATE_CONTACT_US_SUCCESS";
export const CREATE_CONTACT_US_ERROR = "CREATE_CONTACT_US_ERROR";

export const createContactUs = (data) => {
  return ({
    type: CREATE_CONTACT_US_REQUEST,
    data
  });
}

export const CLEAR_CREATE_CONTACT_US_RESPONSE = "CLEAR_CREATE_CONTACT_US_RESPONSE";

export const clearCreateContactUsResponse = () => {
  return ({
    type: CLEAR_CREATE_CONTACT_US_RESPONSE
  });
}

export const CREATE_VALIDATE_DISPLAY_NAME_REQUEST = "CREATE_VALIDATE_DISPLAY_NAME_REQUEST";
export const CREATE_VALIDATE_DISPLAY_NAME_SUCCESS = "CREATE_VALIDATE_DISPLAY_NAME_SUCCESS";
export const CREATE_VALIDATE_DISPLAY_NAME_ERROR = "CREATE_VALIDATE_DISPLAY_NAME_ERROR";

export const doValidateDisplayName = (data) => {
  return ({
    type: CREATE_VALIDATE_DISPLAY_NAME_REQUEST,
    data
  });
}

export const CLEAR_VALIDATE_DISPLAY_NAME = "CLEAR_VALIDATE_DISPLAY_NAME";

export const clearValidateDisplayName = () => {
  return ({
    type: CLEAR_VALIDATE_DISPLAY_NAME
  });
}