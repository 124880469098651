// Language configuration

import moment from 'moment';
export const LANGUAGES = {
  "en-gb": {
    id: "en-gb",
    name: "english",
    file_name: "en.js"
  }
};

//Default web language
export const DEFAULT_LANGUAGE = "en-gb";

// Sidebar Menu
export const sidebarMenuConfig = {
  "marketplace": {
    height: "14px",
    width: "14px",
    name: "marketplace",
    group: "marketplace",
    route: "marketplace",
    icon: "iconMarketplace",
    containSubMenu: false,
    menuLanguageKey: "marketplace",
  },
  "dashboard": {
    height: "12px",
    width: "14px",
    name: "dashboard",
    group: "dashboard",
    route: "dashboard",
    icon: "iconDashboard",
    containSubMenu: false,
    menuLanguageKey: "dashboard",
  },
  "buy_leads": {
    height: "19px",
    width: "19px",
    name: "buy_leads",
    group: "buy_leads",
    route: "leads/buy-leads",
    icon: "iconBuyLeads",
    containSubMenu: false,
    menuLanguageKey: "buy_leads",
  },
  "sell_leads": {
    height: "19px",
    width: "19px",
    name: "sell_leads",
    group: "sell_leads",
    route: "leads/sell-leads",
    icon: "iconSellLeads",
    containSubMenu: false,
    menuLanguageKey: "sell_leads",
  },
  "enrich": {
    height: "15px",
    width: "15px",
    name: "enrich",
    group: "enrich",
    route: "leads/enrich",
    icon: "iconMagic",
    containSubMenu: false,
    menuLanguageKey: "enrich",
  },
  "api": {
    height: "17px",
    width: "18px",
    icon: "iconSmShare",
    name: "api",
    group: "api",
    route: "api",
    containSubMenu: false,
    menuLanguageKey: "api",
  },
  "my_team": {
    height: "15px",
    width: "15px",
    icon: "iconTeam",
    name: "my_team",
    group: "my_team",
    route: "team",
    containSubMenu: false,
    menuLanguageKey: "my_team",
  },
  "my_invoice": {
    height: "16px",
    width: "13px",
    icon: "iconInvoice",
    name: "my_invoice",
    group: "my_invoice",
    route: "invoice",
    containSubMenu: false,
    menuLanguageKey: "my_invoice",
  },
  "user_management": {
    height: "15px",
    width: "15px",
    icon: "iconUser",
    name: "user_management",
    group: "user_management",
    route: "user-management",
    containSubMenu: false,
    menuLanguageKey: "user_management",
  },
  "settings": {
    height: "15px",
    width: "15px",
    icon: "iconSetting",
    name: "settings",
    group: "settings",
    route: "settings",
    containSubMenu: false,
    menuLanguageKey: "settings",
  },
};

export const tmpConstant = {
  "currencySymbol": "$ ",
  "datepickerFormat": "dd/MM/yyyy",
  "displayDateFormat": "DD/MM/YYYY",
  "APIRequestDateFormat": "YYYY-MM-DD",
  "APIRequestFullDateFormat": "YYYY-MM-DD hh:mm:ss",
  "displayDateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "allowedDataUploadFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedDataUploadFilesLabel": ".xlsx, .xls, .csv",
  "allowedSuppressionFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedSuppressionFilesLabel": ".xlsx, .xls, .csv",
  "allowedJobTitleFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedJobTitleFilesLabel": ".xlsx, .xls, .csv",
  "allowedTALFiles": {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"]
  },
  "allowedTALFilesLabel": ".xlsx, .xls, .csv",
  "dateRangeSlots": {
    "No Date": [moment().toDate(), moment().subtract(1, "days").toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "Last Week": [
      moment().subtract(1, "weeks").startOf("week"),
      moment().subtract(1, "weeks").endOf("week")
    ],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 3 Month": [
      moment().subtract(3, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 6 Month": [
      moment().subtract(6, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    "Last 12 Month": [
      moment().subtract(12, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  },
  "roleWiseRedirections": [
    {
      roleIds: [1, 2, 3, 4, 5],
      roles: ["admin", "buyer", "buyer_member", "seller", "seller_member"],
      redirectionPath: "/dashboard"
    }
  ],
};

// Application routes
export const appRoutes = [
  {
    path: "/marketplace",
    exact: true,
    component: "marketPlace",
    activeMenu: { group: "marketplace", name: "marketplace" }
  }, {
    path: "/dashboard",
    exact: true,
    component: "dashboard",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/leads/buy-leads",
    exact: true,
    component: "buyLeads",
    activeMenu: { group: "buy_leads", name: "buy_leads" }
  },
  {
    path: "leads/edit-buy-leads/:id",
    exact: true,
    component: "buyLeads",
    activeMenu: { group: "buy_leads", name: "buy_leads" }
  },
  {
    path: "/leads/sell-leads",
    exact: true,
    component: "sellLeads",
    activeMenu: { group: "sell_leads", name: "sell_leads" }
  }, {
    path: "/leads/enrich",
    exact: true,
    component: "underConstruction",
    activeMenu: { group: "enrich", name: "enrich" }
  }, {
    path: "/api",
    exact: true,
    component: "APIKeys",
    activeMenu: { group: "api", name: "api" }
  }, {
    path: "/team",
    exact: true,
    component: "myTeam",
    activeMenu: { group: "my_team", name: "my_team" }
  },
  // Auth/User
  {
    path: "/invoice",
    exact: true,
    component: "invoices",
    activeMenu: { group: "my_invoice", name: "my_invoice" }
  }, {
    path: "/settings",
    exact: true,
    component: "settings",
    activeMenu: { group: "settings", name: "settings" }
  }, {
    path: "/payment-success",
    exact: true,
    component: "paymentSuccess",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/payment-failed",
    exact: true,
    component: "paymentFailed",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/seller/profile/:id",
    exact: true,
    component: "sellerProfile",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/leads/view-data/:id",
    exact: true,
    component: "viewQuoteData",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/user-management",
    exact: true,
    component: "userManagement",
    activeMenu: { group: "user_management", name: "user_management" }
  }
];

export const USER_ROLES = {
  ADMIN: { id: 1, role: "admin" },
  BUYER: { id: 2, role: "buyer" },
  SELLER: { id: 3, role: "seller" },
  BUYER_MEMBER: { id: 4, role: "buyer_member" },
  SELLER_MEMBER: { id: 5, role: "seller_member" }
};

export const USER_MANAGEMENT_ROLES = [
  { id: null, name: "Select" },
  { id: 1, name: "Admin" },
  { id: 2, name: "Buyer" },
  { id: 3, name: "Seller" }
];

export const quoteStatusLookup = {
  PENDING: { id: 1, status: "pending", statusClass: "text-light", background: "" },
  SUBMITTED: { id: 2, status: "submitted", statusClass: "text-primary", background: "" },
  ACCEPTED: { id: 3, status: "accepted", statusClass: "text-success", background: "bg-success-f2" },
  REJECTED: { id: 4, status: "rejected", statusClass: "text-danger", background: "bg-danger-ff" },
  CANCELLED: { id: 5, status: "cancelled", statusClass: "text-warning", background: "" }
};

export const projectStatusLookup = {
  ACTIVE: { id: 1, status: "active", statusClass: "text-primary" },
  INACTIVE: { id: 2, status: "inactive", statusClass: "text-light" },
  ONHOLD: { id: 3, status: "onhold", statusClass: "text-warning" },
  DELETED: { id: 4, status: "deleted", statusClass: "text-danger" },
  COMPLETED: { id: 5, status: "completed", statusClass: "text-success" }
};

export const quoteDataStatusLookup = {
  PENDING: { id: 0, status: "pending", statusClass: "text-light" },
  ACCEPTED: { id: 1, status: "accepted", statusClass: "text-success" },
  REJECTED: { id: 2, status: "rejected", statusClass: "text-danger" },
};

export const quoteLeadStatusLookup = {
  PENDING: { id: 0, status: "pending", statusClass: "text-light", statusBgClass: "bg-warning" },
  APPROVED: { id: 1, status: "approved", statusClass: "text-success", statusBgClass: "bg-success" },
  REJECTED: { id: 2, status: "rejected", statusClass: "text-danger", statusBgClass: "bg-danger" },
};

export const quoteLeadStatuses = [
  { id: 0, name: "Pending" },
  { id: 1, name: "Accepted" },
  { id: 2, name: "Rejected" },
];

export const userProfileStatuses = [
  { id: null, name: "All Status" },
  { id: 0, name: "Under Review" },
  { id: 1, name: "Approved" },
  { id: 2, name: "Rejected" },
  { id: 3, name: "Suspended" },
  { id: 4, name: "Locked" },
  { id: 5, name: "Unlocked" },
  { id: 6, name: "Deleted" },
];

export const userProfileStatusLookup = {
  UNDER_REVIEW: { id: 0, status: "under review", statusClass: "text-light" },
  APPROVED: { id: 1, status: "approved", statusClass: "text-success" },
  REJECTED: { id: 2, status: "rejected", statusClass: "text-danger" },
  SUSPENDED: { id: 3, status: "suspended", statusClass: "text-danger" }
};

export const userStatusesLookup = {
  UNLOCKED: { id: 0, status: "unlocked", statusClass: "text-success" },
  DELETED: { id: 1, status: "deleted", statusClass: "text-danger" },
  LOCKED: { id: 5, status: "locked", statusClass: "text-warning" },
};