import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, cloneDeep as _cloneDeep, chain as _chain, find as _find, groupBy as _groupBy } from "lodash";
import { Column, Table, AutoSizer } from "react-virtualized";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, Modal, Select, NoDataMessage, TableHeaderRenderer, TableCellRenderer, SingleDropzone } from "../../../components/common/controls";
import { hasPermissions } from "../../../components/hoc/Permissions";
import { withRouter } from "../../../components/hoc/withRouter";

import { USER_ROLES, quoteDataStatusLookup, quoteLeadStatusLookup, quoteLeadStatuses } from "../../../helpers/constants";
import { IconDownloadAlt } from "../../../helpers/iconStyles";
import { downloadAnyFile } from "../../../helpers/utils";

import { downloadFile } from "../../../services/application";

import { fetchProjectRequiredFields, fetchUploadedDataCheckFile, resetUploadedDataCheckFileData, resetUpdateQuoteBatchUploadStatusData, updateQuoteBatchUploadStatus, fetchQuoteLeads, approveRejectQuoteLeads, exportQuoteLeads, resetExportQuoteLeads, fetchLeadRejectionReasons, resetLeadRejectionReasons, resetApproveRejectQuoteLeads, updateLead, resetUpdateLeadData } from "../../../actions/leads";
import { updateLoadingState, fetchDataRejectedTypes } from "../../../actions/application";

import DataUploadHistory from "./DataUploadHistory";

class Data extends Component {
  constructor(props) {
    super(props);

    const { tabToActive, filterStatus = null, intl } = props;

    this.defaultLeadDataPayload = {
      quoteGroupId: _get(props, "selectedQuoteGroupId", ""),
      quoteId: null,
      isApproved: null,
      pageNo: 1,
      orderBy: null,
      order: null,
      pageSize: 100
    }

    const finalLeadPayload = {
      ...this.defaultLeadDataPayload,
      isApproved: filterStatus,
    }
    const defaulLeadStatustFilter = (_find(quoteLeadStatuses, (q) => (q.id === filterStatus)) || null);

    this.state = {
      activeTab: ((tabToActive || "") !== "") ? (tabToActive || "data_check") : "data_check",
      activeBatch: 0,
      isOpenRejectDataModal: false,
      rejectReason: null,
      note: "",
      refreshDataSummary: false,
      showUpdateLeadModal: false,
      showRejectLeadsModal: false,
      showFilterLeadsModal: false,
      selectedIndex: null,
      selectAllLead: false,
      selectedLeads: [],
      selectedLeadToUpdate: {},
      selectedRejectReasonId: null,
      selectedRejectReason: null,
      leadRejectedReasonRemark: null,
      selectedBatchFilter: null,
      selectedLeadStatusFilter: defaulLeadStatustFilter,
      viewLeadPayload: _cloneDeep(finalLeadPayload),
      uploadedFileName: "",
      uploadedFileURL: "",
    };

    this.viewDataColumns = [
      {
        id: "id",
        width: 0,
        columnAvrgWidth: 40,
        dataKey: "id",
        headerRenderer: () => (
          <div className="custom-control custom-checkbox d-flex m-l-6">
            <input type="checkbox" id="leadId" className="form-check-input" name="checkbox" checked={this.state.selectAllLead} onChange={(e) => this._handleSelectAllLead(e.currentTarget.checked)} />
            <label className="form-check-label" htmlFor="leadId"></label>
          </div>
        ),
        cellRenderer: (row) => {
          const leadId = _get(row, "cellData", "");
          const isChecked = this.state.selectedLeads.includes(leadId) ? true : false;

          return (
            <div className="title title--sm text-break" >
              <div className="custom-control custom-checkbox d-flex m-l-6">
                <input type="checkbox" id={leadId} className="form-check-input" name="checkbox" checked={isChecked} value={leadId} onChange={(e) => this._handleSelectLead(e)} />
                <label className="form-check-label" htmlFor={leadId}></label>
              </div>
            </div>
          )
        },
      },
      {
        id: "created_at",
        width: 0,
        columnAvrgWidth: 11,
        dataKey: "created_at",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.date", defaultMessage: "date" })} />),
        cellRenderer: (row) => {
          const { displayDateFormat } = this.props;

          return (<TableCellRenderer value={(_get(row, "cellData", null)) ? moment(_get(row, "cellData", null)).format(displayDateFormat) : ""} tooltip={true} />);
        },
      },
      {
        id: "company",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "company",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.company", defaultMessage: "company" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "full_name",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "full_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.full_name", defaultMessage: "full ame" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "email",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "email",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.email", defaultMessage: "email" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "employees",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "employees",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.employee_size", defaultMessage: "employee size" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "language",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "language",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.language", defaultMessage: "language" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "lead_reject_reason",
        width: 0,
        columnAvrgWidth: 8,
        dataKey: "lead_reject_reason",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.rejection_reason", defaultMessage: "rejection reason" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "status",
        disableSort: true,
        headerRenderer: () => (<TableHeaderRenderer value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.status", defaultMessage: "status" })} />),
        cellRenderer: (row) => {
          const statusText = _get(row, "cellData", "").toUpperCase();
          const statusClass = _get(quoteLeadStatusLookup, `[${statusText}.statusBgClass]`, "");

          return (
            <div className={`badge ${statusClass}`}> {statusText} </div>
          )
        }
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 30,
        dataKey: "status",
        disableSort: true,
        headerRenderer: () => (<TableHeaderRenderer value={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.action", defaultMessage: "action" })} />),
        cellRenderer: (row) => (
          <>
            {(hasPermissions("dashboard", "view-leads", ["btn-approve-lead"]) === true) && (hasPermissions("dashboard", "view-leads", ["btn-reject-lead"]) === true) && (
              <Dropdown className="navbar-nav action-menu-dropdown">
                <Dropdown.Toggle>
                  <span>
                    <FormattedMessage id="dropdown.dots" defaultMessage="•••" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end position-absolute">
                  <Dropdown.Item
                    className="dropdown-item text-capitalize"
                    onClick={(e) => this.setState({ selectedLeads: [_get(row, "rowData.id", null)] }, function () { this._approveRejectLeads(1); })}>
                    <FormattedMessage id="seller.workflow.accepted_quote.btn.approve" defaultMessage="approve" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item text-capitalize"
                    onClick={(e) => this.setState({ selectedLeads: [_get(row, "rowData.id", null)], showRejectLeadsModal: true })}>
                    <FormattedMessage id="seller.workflow.accepted_quote.btn.reject" defaultMessage="reject" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {(hasPermissions("dashboard", "view-leads", ["btn-update-lead"]) === true) && (
              <Dropdown className="navbar-nav action-menu-dropdown">
                <Dropdown.Toggle>
                  <span>
                    <FormattedMessage id="dropdown.dots" defaultMessage="•••" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end position-absolute">
                  <Dropdown.Item className="dropdown-item text-capitalize" onClick={(e) => this.setState({ showUpdateLeadModal: true, selectedLeadToUpdate: _get(row, "rowData", {}) })}>
                    <FormattedMessage id="btn.update" defaultMessage="update" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
    ];
  }

  componentDidMount() {
    const { projectId, fetchDataRejectedTypes, fetchUploadedDataCheckFile, fetchLeadRejectionReasons, selectedQuoteGroupId, fetchProjectRequiredFields } = this.props;
    const { activeTab } = this.state;

    if (typeof fetchUploadedDataCheckFile === "function" && ((activeTab || null) === "data_check") && ((selectedQuoteGroupId || null) !== null)) {
      fetchUploadedDataCheckFile({ quoteGroupId: selectedQuoteGroupId });
    }

    if ((activeTab || null) === "view_data") {
      if ((typeof fetchDataRejectedTypes === "function") && ((selectedQuoteGroupId || null) !== null)) {
        fetchDataRejectedTypes();
      }

      if ((typeof fetchLeadRejectionReasons === "function")) { fetchLeadRejectionReasons(); }

      if ((typeof fetchProjectRequiredFields === "function")) { fetchProjectRequiredFields({ projectId: projectId }); }

      this._fetchQuoteLeads();
    }
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevStates.activeTab, _get(this.state, "activeTab", null))) {
      const { projectId, fetchDataRejectedTypes, fetchUploadedDataCheckFile, fetchLeadRejectionReasons, selectedQuoteGroupId, fetchProjectRequiredFields } = this.props;
      const { activeTab } = this.state;

      if (typeof fetchUploadedDataCheckFile === "function" && ((activeTab || null) === "data_check") && ((selectedQuoteGroupId || null) !== null)) {
        fetchUploadedDataCheckFile({ quoteGroupId: selectedQuoteGroupId });
      }

      if ((activeTab || null) === "view_data") {
        if ((typeof fetchDataRejectedTypes === "function") && ((selectedQuoteGroupId || null) !== null)) {
          fetchDataRejectedTypes();
        }

        if ((typeof fetchLeadRejectionReasons === "function")) { fetchLeadRejectionReasons(); }

        if ((typeof fetchProjectRequiredFields === "function")) { fetchProjectRequiredFields(projectId); }

        this._fetchQuoteLeads();
      }
    }

    if (!_isEqual(prevProps.dataCheckFileData, _get(this.props, "dataCheckFileData", {}))) {
      const { dataCheckFileData, resetUploadedDataCheckFileData } = this.props;

      if (_get(dataCheckFileData, "status", null) === null) { return false; }

      if (_get(dataCheckFileData, "status", false) === false) {
        showAlertMessage(_get(dataCheckFileData, "message", "Something went wrong while fetching uploaded data check file."));

        if (typeof resetUploadedDataCheckFileData === "function") { resetUploadedDataCheckFileData(); }
      }
    }

    if (!_isEqual(prevProps.selectedQuoteGroupId, _get(this.props, "selectedQuoteGroupId", null))) {
      const { fetchUploadedDataCheckFile, selectedQuoteGroupId, resetUploadedDataCheckFileData } = this.props;

      if (((selectedQuoteGroupId || null) !== null) && (typeof fetchUploadedDataCheckFile === "function")) {
        fetchUploadedDataCheckFile({ quoteGroupId: selectedQuoteGroupId });
      } else if (typeof resetUploadedDataCheckFileData === "function") {
        resetUploadedDataCheckFileData();
      }
    }

    if (!_isEqual(prevProps.updateQuoteBatchUpload, _get(this.props, "updateQuoteBatchUpload", {}))) {
      const { updateQuoteBatchUpload, resetUpdateQuoteBatchUploadStatusData, fetchUploadedDataCheckFile, selectedQuoteGroupId } = this.props;

      if (_get(updateQuoteBatchUpload, "status", null) === null) { return false; }

      if (_get(updateQuoteBatchUpload, "status", false) === true) {
        showAlertMessage(_get(updateQuoteBatchUpload, "data.message", "Data check file status updated successfully."), "success");

        if (typeof fetchUploadedDataCheckFile === "function" && (_get(selectedQuoteGroupId, "id", null) !== null)) {
          fetchUploadedDataCheckFile({ quoteGroupId: selectedQuoteGroupId });
        }
        this.setState({ isOpenRejectDataModal: false, rejectReason: null, note: "" });
      } else {
        showAlertMessage(_get(updateQuoteBatchUpload, "message", "Something went wrong while updating data check file status."));
      }

      if (typeof resetUpdateQuoteBatchUploadStatusData === "function") { resetUpdateQuoteBatchUploadStatusData(); }
    }

    if (!_isEqual(prevProps.quoteLeadsData, _get(this.props, "quoteLeadsData", null))) {
      const { quoteLeadsData } = this.props;

      if (_get(quoteLeadsData, "status", null) === null) { return false; }

      if (_get(this.props, "quoteLeadsData.status", false) === true) {
        this.setState({ showFilterLeadsModal: false });
      } else {
        showAlertMessage(_get(quoteLeadsData, "message", "Something went wrong while fetching quote leads."));
      }
    }

    if (!_isEqual(prevProps.approveRejectQuoteLeadsData, this.props.approveRejectQuoteLeadsData)) {

      if (_get(this.props, "approveRejectQuoteLeadsData.status", null) === null) { return false; }

      if (_get(this.props, "approveRejectQuoteLeadsData.status", false) === true) {

        showAlertMessage(_get(this.props, "approveRejectQuoteLeadsData.data.message", "Lead(s) approved/rejected successfully."), "success");

        this.setState({ selectedLeads: [], showRejectLeadsModal: false, selectedRejectReason: null, leadRejectedReasonRemark: null });
        this._fetchQuoteLeads();
      } else {
        showAlertMessage(_get(this.props, "approveRejectQuoteLeadsData.message", "Something went wrong while approving/rejecting lead(s)."));
      }

      if (typeof this.props.resetApproveRejectQuoteLeads === "function") { this.props.resetApproveRejectQuoteLeads(); }
    }

    if (!_isEqual(prevProps.exportQuoteLeadsData, this.props.exportQuoteLeadsData)) {

      if (_get(this.props, "exportQuoteLeadsData.status", null) === null) { return false; }

      if (_get(this.props, "exportQuoteLeadsData.status", false) === true) {

        //this.downloadFile();
      } else {
        showAlertMessage(_get(this.props, "exportQuoteLeadsData.message", "Something went wrong while approving/rejecting lead(s)."));
      }

      if (typeof this.props.resetExportQuoteLeads === "function") { this.props.resetExportQuoteLeads(); }
    }

    if (!_isEqual(prevProps.updateLeadData, _get(this.props, "updateLeadData", null))) {
      const { updateLeadData } = this.props;

      if (_get(updateLeadData, "status", null) === null) { return false; }

      if (_get(updateLeadData, "status", false) === true) {
        showAlertMessage(_get(updateLeadData, "data.message", "Lead updated successfully."), "success");

        this.setState({ showUpdateLeadModal: false, selectedLeadToUpdate: {} });
        this._fetchQuoteLeads();
      } else {
        showAlertMessage(_get(updateLeadData, "message", "Something went wrong while updating lead."));
      }

      if (typeof this.props.resetUpdateLeadData === "function") { this.props.resetUpdateLeadData(); }
    }

    if (!_isEqual(prevStates.viewLeadPayload, _get(this.state, "viewLeadPayload", null))) {
      this._fetchQuoteLeads();
    }
  }

  _fetchQuoteLeads = () => {
    const { quoteLeadsData, selectedQuoteGroupId, fetchQuoteLeads } = this.props;
    const { viewLeadPayload } = this.state;

    if (typeof fetchQuoteLeads === "function" && ((selectedQuoteGroupId || null) !== null)) {
      fetchQuoteLeads({ payload: viewLeadPayload, oldData: _get(quoteLeadsData, "data.data.leads", []) });
    }
  }

  _handleSelectAllLead = (checked) => {
    const { quoteLeadsData } = this.props;

    if (checked) {
      const selectedLeads = _chain(quoteLeadsData).get("data.data.leads", []).map(d => (d.id || 0)).value();
      this.setState({ selectAllLead: true, selectedLeads });
    } else {
      this.setState({ selectAllLead: false, selectedLeads: [] });
    }
  }

  _handleSelectLead = ({ currentTarget }) => {
    const { quoteLeadsData } = this.props;
    const { selectedLeads } = this.state;
    const { checked, value } = currentTarget;

    let selectedLeadsValue = [...selectedLeads];
    const currentCampaigns = _chain(quoteLeadsData).get("data.data.leads", []).map(d => (d.id || 0)).value();
    const leadId = parseInt(value);
    let selectAllLead = false;

    if (checked) {
      // Check if already exists
      if (!selectedLeadsValue.includes(leadId)) {
        selectedLeadsValue.push(leadId);
      } else {
        return false;
      }
    } else {
      selectedLeadsValue = selectedLeadsValue.filter((n) => n !== leadId);
    }

    // Check Main checkbox if all are selected
    if (currentCampaigns.length === selectedLeadsValue.length) {
      selectAllLead = true;
    }

    this.setState({ selectAllLead, selectedLeads: selectedLeadsValue });
  }

  downloadFile = async (fileURL) => {
    const { updateLoadingState } = this.props;

    if (_isEmpty(fileURL)) { showAlertMessage("No file available."); return false; }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await downloadFile({ fileUrl: fileURL });

      if ((response.flag || false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.contents || "");
        const filename = (downloadFileDetails.fileName || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadAnyFile(contentType, contents, filename);

          showAlertMessage("File downloaded successfully.", "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while downloading template file.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading file.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _approveRejectLeads = (status = 0) => {
    const { approveRejectQuoteLeads, intl } = this.props;
    const { selectedLeads, selectedRejectReason, leadRejectedReasonRemark } = this.state;

    if (_isEmpty(selectedLeads)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));
      return false;
    }

    if ((status === 2) && (_get(selectedRejectReason, "id", null) === null)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.rejection_reason", defaultMessage: "rejection reason" })
      }));
      return false;
    }

    if (typeof approveRejectQuoteLeads === "function") {
      approveRejectQuoteLeads({
        leadIds: (selectedLeads || []),
        status: status,
        rejectedReasonId: _get(selectedRejectReason, "id", null),
        rejectedReasonRemark: (leadRejectedReasonRemark || null)
      });
    }
  }

  _exportLeads = () => {
    const { exportQuoteLeads, selectedQuoteGroupId, intl } = this.props;
    const { selectedLeads } = this.state;

    if (_isEmpty(selectedLeads)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.rejection_reason", defaultMessage: "rejection reason" })
      }));
      return false;
    }

    if (typeof approveRejectQuoteLeads === "function") {
      exportQuoteLeads({
        quoteGroupId: (selectedQuoteGroupId || null),
        quoteId: null, //(filterQuoteID || null),
        leadIds: (selectedLeads || []),
        status: null
      });
    }

  }

  updateDataCheckFileStatus = (status) => {
    const { dataCheckFileData, updateQuoteBatchUploadStatus, intl } = this.props;
    const { note, rejectReason, selectedIndex } = this.state;

    if (_get(dataCheckFileData, `data.data_check_file[${selectedIndex}].id`, null) === null) {
      showAlertMessage(intl.formatMessage({ id: "buyer_dashboard.workflow.data_check.no_file_found_to_approve", defaultMessage: "No file found to approve/reject." }));
      return false;
    }

    if ((status || 0) === _get(quoteDataStatusLookup, "REJECTED.id", 2)) {
      if (_isEmpty(rejectReason)) {
        showAlertMessage(intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}." }, {
          field: intl.formatMessage({ id: "buyer_dashboard.workflow.data_check.rejection_type", defaultMessage: "rejection type" })
        }));
        return false;
      }

      if ((note || null) === null) {
        showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
          field: intl.formatMessage({ id: "note", defaultMessage: "note" })
        }));
        return false;
      }
    }

    if (typeof updateQuoteBatchUploadStatus === "function") {
      updateQuoteBatchUploadStatus({
        quoteFileUploadId: _get(dataCheckFileData, `data.data_check_file[${selectedIndex}].id`, null),
        status: status,
        notes: (note || null),
        rejectionReason: (!_isEmpty(rejectReason)) ? (rejectReason.pk_id || null) : null,
      });
    }
  }

  _closeRejectDataModal = () => {
    this.setState({ isOpenRejectDataModal: false, rejectReason: null, note: "" });
  }

  rejectDataModal() {
    const { rejectedTypes, intl } = this.props;
    const { isOpenRejectDataModal, rejectReason, note } = this.state;

    return (
      <Modal
        isOpen={isOpenRejectDataModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "buyer_dashboard.workflow.data_check.reject_data", defaultMessage: "Reject data" })}
        onHide={() => this._closeRejectDataModal()}
        onClose={() => this._closeRejectDataModal()}
      >
        <div className="row gy-20">
          <div className="col-lg-12">
            <div>
              <label className="heading-05 mb-8 text-capitalize">
                <FormattedMessage id="label.select_field" defaultMessage="select {field}" values={{ field: intl.formatMessage({ id: "buyer_dashboard.workflow.data_check.rejection_type", defaultMessage: "rejection type" }) }} />
              </label>
              <Select
                className="text-capitalize form-custom-select border"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={_get(rejectedTypes, "data", [])}
                value={(rejectReason || null)}
                getOptionLabel={(option) => (option.rejection_type || "")}
                getOptionValue={(option) => (option.pk_id || null)}
                onChange={(selected) => this.setState({ rejectReason: selected })}
              />
            </div>

            <div className="col-lg-12 mt-15">
              <label className="heading-05 mb-12 text-capitalize"><FormattedMessage id="note" defaultMessage="note" /></label>
              <textarea
                className="form-control border rounded-3"
                placeholder={intl.formatMessage({ id: "note", defaultMessage: "note" })}
                rowSpan="5"
                value={(note || "")}
                onChange={(e) => this.setState({ note: _get(e, "target.value", "") })}
              />
            </div>
            <div className="row gutters-5">
              <div className="col-lg-12">
                <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
                  <button className="btn btn-primary" onClick={() => this.updateDataCheckFileStatus(_get(quoteDataStatusLookup, "REJECTED.id", 2))} >
                    <FormattedMessage id="btn.submit" defaultMessage="submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  _renderUploadBatchData = (currentQuoteId = null) => {
    const { allowedDataUploadFiles, allowedDataUploadFilesLabel, projectId, selectedQuoteGroupId } = this.props;
    const { uploadedFileName, uploadedFileURL } = this.state;

    return (
      <div className="mb-8">
        <div className="dropzon-chip-list-full-width col-md-6 mt-20">
          <SingleDropzone
            source="leads"
            projectId={projectId}
            quoteId={currentQuoteId}
            quoteGroupId={selectedQuoteGroupId}
            acceptedFormat={allowedDataUploadFiles}
            allowedExtensions={allowedDataUploadFilesLabel}
            setState={(args) => this.setState(args)}
            uploadedFileName={uploadedFileName}
            uploadedFileURL={uploadedFileURL}
            preserveSelectedFile={false}
            displayUploadedFiles={false}
            displayUploadedRemoveBtn={false}
          />
        </div>
      </div>
    );
  }

  _renderDataCheckTab = () => {
    const { displayDateFormat, displayDateTimeFormat, dataCheckFileData, roleId, intl } = this.props;
    const { activeBatch } = this.state;

    const uploadedDataBatchWise = _groupBy(_get(dataCheckFileData, "data.data_check_file", []), "quote_id");

    return (
      <div className="col-lg-12">

        {((_get(dataCheckFileData, "data.quote", [])).length < 1) && (
          <NoDataMessage
            className="text-center no-message-wrapper table-no-data-message"
            message={intl.formatMessage({ id: "message.no_quote", defaultMessage: "No quote has been submitted yet" })}
          />
        )}

        {((_get(dataCheckFileData, "data.quote", [])).length > 0) && (
          <div className="project-status-wrap">

            {(_get(dataCheckFileData, "data.quote", [])).map((batch, i) => {

              const hasUploadedAnyData = (_get(uploadedDataBatchWise, `${[batch.quote_id]}`, [])).length > 0;

              return (
                <div className="accordion-item border-bottom mb-0" key={`batch-${i}`}>
                  <div className="accordion-header">
                    <button className={`accordion-button heading-06 fw-medium px-0 py-10 text-primary text-capitalize ${(activeBatch !== i) ? "collapsed" : ""}`} type="button" onClick={() => (activeBatch === i) ? this.setState({ activeBatch: null }) : this.setState({ activeBatch: i })}>
                      <FormattedMessage id="seller.workflow.accepted_quote.batch" defaultMessage="batch {count}" values={{ count: (i + 1) }} />
                    </button>
                  </div>
                  <div className={`accordion-collapse collapse ${(activeBatch === i) ? "show" : ""}`}>
                    <div className="accordion-body px-0">
                      <div className="row gx-12">
                        <div className="col-lg-12 mb-24">
                          <div className="row gx-12 mb-10">
                            <div className="project-list-card me-5 text-capitalize">
                              <p>
                                <FormattedMessage
                                  id="buyer_dashboard.data.tab.batch_volume_of_leads_required"
                                  defaultMessage="batch {no} volume of leads required"
                                  values={{ no: 1 }}
                                />
                              </p>
                              <p>{_get(batch, "lead_volume", 0)}</p>
                            </div>

                            <div className="project-list-card me-5 text-capitalize">
                              <p>
                                <FormattedMessage
                                  id="buyer_dashboard.data.tab.batch_delivery_date"
                                  defaultMessage="batch {no} delivery date"
                                  values={{ no: 1 }}
                                />
                              </p>
                              <p>{moment(_get(batch, "delivery_date", "")).format(displayDateFormat)}</p>
                            </div>

                            <div className="project-list-card me-5 bg-success-f2 text-capitalize">
                              <p className="text-dark"><FormattedMessage id="buyer_dashboard.data.tab.download_data" defaultMessage="download data" /></p>
                              <p className="text-success">
                                {(hasUploadedAnyData) ?
                                  <FormattedMessage id="buyer_dashboard.data.tab.available" defaultMessage="available" />
                                  : <FormattedMessage id="buyer_dashboard.data.tab.not_available" defaultMessage="not available" />
                                }
                              </p>
                            </div>

                            <div className="project-list-card me-5 text-capitalize">
                              <p><FormattedMessage id="buyer_dashboard.data.tab.data_status" defaultMessage="data status" /></p>
                              <p>{_get(dataCheckFileData, `data.data_check_file[${i}].status_text`, "")}</p>
                            </div>

                            <div className="project-list-card me-5 bg-warning bg-opacity-10 text-capitalize">
                              <p className="text-dark"><FormattedMessage id="buyer_dashboard.data.tab.pending_leads" defaultMessage="Pending leads" /></p>
                              <p>{_get(batch, "pending_leads", 0)}</p>
                            </div>

                            <div className="project-list-card me-5 bg-success-f2 text-capitalize">
                              <p className="text-dark"><FormattedMessage id="buyer_dashboard.data.tab.approved_leads" defaultMessage="Approved leads" /></p>
                              <p>{_get(batch, "approved_leads", 0)}</p>
                            </div>

                            <div className="project-list-card bg-danger-ff text-capitalize">
                              <p className="text-dark"><FormattedMessage id="buyer_dashboard.data.tab.rejected_leads" defaultMessage="Rejected leads" /></p>
                              <p>{_get(batch, "rejected_leads", 0)}</p>
                            </div>
                          </div>

                          {(hasUploadedAnyData) && (
                            <div className="mb-8">
                              <p>
                                <span className="fw-semibold text-capitalize"><FormattedMessage id="buyer_dashboard.data.tab.uploaded_on" defaultMessage="Uploaded On: " /></span>
                                <span>{moment(_get(uploadedDataBatchWise, `${[batch.quote_id]}[0].uploaded_date`, "")).format(displayDateTimeFormat)}</span>
                              </p>
                              <p>
                                <span className="fw-semibold text-capitalize"><FormattedMessage id="buyer_dashboard.data.tab.note" defaultMessage="Note: " /></span>
                                <span>
                                  <FormattedMessage
                                    id="buyer_dashboard.data.tab.max_date_notice"
                                    defaultMessage="Approve/Reject before {max_action_date}, failed to respond will be mark as approved data and payment will be released to seller"
                                    values={{ max_action_date: moment(_get(uploadedDataBatchWise, `${[batch.quote_id]}[0].max_action_date`, "")).format(displayDateFormat) }}
                                  />
                                </span>
                              </p>
                            </div>
                          )}

                          {(
                            [_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "SELLER.id", 0), _get(USER_ROLES, "SELLER_MEMBER.id", 0)].includes((roleId || 0)) &&
                            (activeBatch === i)
                          ) && this._renderUploadBatchData(batch.quote_id)}

                          {[_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "BUYER.id", 0), _get(USER_ROLES, "BUYER_MEMBER.id", 0)].includes((roleId || 0)) && (
                            <div className="d-flex align-items-center gap-16 mt-12">
                              <button
                                className="btn btn-md btn-grey tex-uppercase"
                                onClick={() => this.downloadFile(_get(dataCheckFileData, `data.data_check_file[${i}].file_path`, ""))}
                                disabled={!(hasUploadedAnyData || false)}
                              >
                                <IconDownloadAlt height="15px" width="13px" color="#fff" />
                                <FormattedMessage id="btn.download_data" defaultMessage="download data" />
                              </button>

                              <button
                                className="btn btn-md btn-success tex-uppercase"
                                disabled={!(hasUploadedAnyData || false)}
                                onClick={() => {
                                  this.setState(
                                    { selectedIndex: i },
                                    () => this.updateDataCheckFileStatus(_get(quoteDataStatusLookup, "ACCEPTED.id", 1))
                                  );
                                }}
                              >
                                <FormattedMessage id="btn.approve_data" defaultMessage="approve data" />
                              </button>

                              <button
                                className="btn btn-md btn-danger tex-uppercase"
                                onClick={() => this.setState({ selectedIndex: i, isOpenRejectDataModal: true })}
                                disabled={!(hasUploadedAnyData || false)}
                              >
                                <FormattedMessage id="btn.reject_data" defaultMessage="reject data" />
                              </button>
                              <button className="btn btn-md btn-outline-primary tex-uppercase" disabled={true}>
                                <FormattedMessage id="btn.raise_support_ticket" defaultMessage="raise support ticket" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  _handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    const { quoteLeadsData } = this.props;

    const flag = scrollTop + clientHeight >= scrollHeight - 10;
    const currentPage = _get(quoteLeadsData, "data.data.leads_page_info[0].current_page", 1);
    const totalPages = _get(quoteLeadsData, "data.data.leads_page_info[0].total_pages", 1);

    if ((flag === true) && (currentPage < totalPages)) {
      this.setState((prevState) => ({
        viewLeadPayload: {
          ...prevState.viewLeadPayload,
          pageNo: (currentPage + 1),
        }
      }));
    }
  }

  _sortData = (sort) => {
    this.setState((prevState) => ({
      viewLeadPayload: {
        ...prevState.viewLeadPayload,
        orderBy: _get(sort, "sortBy", "id"),
        order: _get(sort, "sortDirection", "desc"),
      }
    }));
  }

  _showRejectLeadsModal = () => {
    const { intl } = this.props;
    const { selectedLeads } = this.state;

    if (_isEmpty(selectedLeads)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));
      return false;
    }

    this.setState({ showRejectLeadsModal: true })
  }

  _renderViewDataTab = () => {
    const { quoteLeadsData, intl } = this.props;
    const { viewLeadPayload, selectedBatchFilter, selectedLeadStatusFilter } = this.state;

    const tableData = _get(quoteLeadsData, "data.data.leads", []);
    const tablePageInfo = _get(quoteLeadsData, "data.data.leads_page_info[0]", {});

    return (
      <div className="project-data-content-item" id="viewData">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-data-content mb-15">
              <div className="project-data-content-batch" id="viewDataBatch01">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center gap-16">
                      {(hasPermissions("dashboard", "view-leads", ["btn-approve-lead"]) === true) && (
                        <button className="btn btn-sm btn-success text-uppercase" onClick={() => this._approveRejectLeads(1)}>
                          <FormattedMessage id="btn.approve_leads" defaultMessage="approve leads" />
                        </button>
                      )}
                      {(hasPermissions("dashboard", "view-leads", ["btn-reject-lead"]) === true) && (
                        <button className="btn btn-sm btn-danger text-uppercase" onClick={() => this._showRejectLeadsModal()}>
                          <FormattedMessage id="btn.reject_leads" defaultMessage="reject leads" />
                        </button>
                      )}
                      {/*<button className="btn btn-sm btn-outline-primary text-uppercase" onClick={() => this._exportLeads()}>
                        <FormattedMessage id="btn.export" defaultMessage="export" />
                      </button>*/}
                      <button className="btn btn-sm btn-outline-primary text-uppercase" onClick={() => this.setState({ showFilterLeadsModal: true })}>
                        <FormattedMessage id="btn.filters" defaultMessage="filters" />
                      </button>

                      {(((selectedBatchFilter || null) !== null) || ((selectedLeadStatusFilter || null) !== null)) && (
                        <button className="btn btn-sm btn-outline-primary text-uppercase" onClick={() => this.setState({ selectedBatchFilter: null, selectedLeadStatusFilter: null, viewLeadPayload: _cloneDeep(this.defaultLeadDataPayload) })}>
                          <FormattedMessage id="btn.reset_filters" defaultMessage="reset filters" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="project-data-content-batch" id="viewDataBatch02"></div>
            </div>

            <div className="table-responsive">
              <div className="table-data position-relative h-250 overflow-x-auto">
                <AutoSizer className="table-autosizer-wrapper">
                  {({ height, width }) => (
                    <Table
                      width={width}
                      height={height}
                      headerHeight={32}
                      rowHeight={32}
                      sort={this._sortData}
                      sortBy={_get(viewLeadPayload, "orderBy", "")}
                      onScroll={this._handleScroll}
                      sortDirection={_get(viewLeadPayload, "order", "")}
                      rowCount={(tableData || []).length}
                      rowGetter={({ index }) => (tableData[index] || {})}
                      noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
                    >
                      {(this.viewDataColumns || []).map((column, key) => (
                        <Column key={key} {...column} width={width / (column.columnAvrgWidth || 10)} />
                      ))}
                    </Table>
                  )}
                </AutoSizer>
              </div>
              <div className="text-right mt-5">
                <FormattedMessage
                  id="buyer_dashboard.data.tab.view_data.leads.table_summary"
                  defaultMessage="{records} out of {total}"
                  values={{
                    records: ((tablePageInfo.total_records || 0) > 100) ? ((tablePageInfo.current_page || 1) * 100) : ((tablePageInfo.total_records || 0)),
                    total: (tablePageInfo.total_records || 0)
                  }}
                />
              </div>
            </div>

          </div>
        </div>

        {this._renderUpdateLeadModal()}
        {this.rejectLeadsModal()}
        {this._filterLeadsModal()}
      </div>
    )
  }

  _renderDataHistoryTab = () => {

    return (
      <div className="project-data-content-item" id="dataHistory">
        <div className="row">
          <div className="col-lg-12">
            <DataUploadHistory />
          </div>
        </div>
      </div>
    )
  }

  _renderRejectedDataTab = () => {

    return (
      <div className="project-data-content-item" id="rejectedData">
        <div className="row">
          <div className="col-lg-12">
            <p className="mb-36">You have rejected data, please select from the 3 options below</p>

            <div className="p-12">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="fullrefund" />
                <label className="form-check-label ps-25" htmlFor="fullrefund">
                  <span className="fw-medium text-primary">Full refund</span> - Your funds will be returned but you won’t be able to download the data
                </label>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group mt-12 ps-50">
                    <input type="text" className="form-control border" placeholder="$ 2000" />

                    {/* ADD disabled CLASS TO BELOW BUTTON FOR DISABLE */}
                    <button className="input-group-text btn btn-grey">RELEASE FUNDS</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-12 bg-danger-ff">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="partialrefund" />
                <label className="form-check-label ps-25" htmlFor="partialrefund">
                  <span className="fw-medium text-primary">Partial refund</span> - Rejected leads percentage will be refunded and you will be able to download all approved data
                </label>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group mt-12 ps-50">
                    <input type="text" className="form-control border" placeholder="$ 2000" />

                    {/* ADD disabled CLASS TO BELOW BUTTON FOR DISABLE */}
                    <button className="input-group-text btn btn-grey">RELEASE FUNDS</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-12 opacity-25">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="requestmoredata" />
                <label className="form-check-label ps-25" htmlFor="requestmoredata">
                  <span className="fw-medium text-primary">Request more data</span> - A request will be sent for more leads to replace your rejected data, no additional funds will be needed
                </label>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="mt-24">
              <button className="btn btn-md btn-outline-primary disabled">RAISE SUPPORT TICKET</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _updateLeads = () => {
    const { updateLead, intl } = this.props;
    const { selectedLeadToUpdate } = this.state;

    if (_isEmpty(selectedLeadToUpdate) || (_get(selectedLeadToUpdate, "id", null) === null)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "lead", defaultMessage: "lead" })
      }));
      return false;
    }

    if (typeof updateLead === "function") {
      updateLead({
        leadId: _get(selectedLeadToUpdate, "id", null),
        leadData: (selectedLeadToUpdate || {})
      });
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = _get(e, "target", {});

    this.setState((prevState) => ({
      selectedLeadToUpdate: {
        ...prevState.selectedLeadToUpdate,
        [name]: value,
      }
    }));
  }

  _renderUpdateLeadModal = () => {
    const { intl } = this.props;
    const { showUpdateLeadModal, selectedLeadToUpdate } = this.state;

    return (
      <Modal
        isOpen={(showUpdateLeadModal || false)}
        bodyClassName="bg-white-f2"
        size="xl"
        modalTitleClassName="col-md-11"
        modalTitle={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.update_lead", defaultMessage: "update lead" })}
        onHide={() => this.setState({ showUpdateLeadModal: false })}
        onClose={() => this.setState({ showUpdateLeadModal: false })}
      >
        <div className="cardBox bg-gray-f8">
          <div className="cardBox-content">
            <div className="card">
              <div className="card-body p-20">
                <div className="row">
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.full_name" defaultMessage="Full Name" />
                    </label>
                    <input
                      type="full_name"
                      name="full_name"
                      className="form-control text-capitalize border"
                      id="full_name"
                      value={_get(selectedLeadToUpdate, "full_name", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.full_name", defaultMessage: "Full Name" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.email" defaultMessage="Email" />
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control text-capitalize border"
                      id="email"
                      value={_get(selectedLeadToUpdate, "email", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.email", defaultMessage: "Email" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>

                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.phone" defaultMessage="Phone" />
                    </label>
                    <input
                      type="phone"
                      name="phone"
                      className="form-control text-capitalize border"
                      id="phone"
                      value={_get(selectedLeadToUpdate, "phone", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.phone", defaultMessage: "Phone" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.company" defaultMessage="company" />
                    </label>
                    <input
                      type="company"
                      name="company"
                      className="form-control text-capitalize border"
                      id="company"
                      value={_get(selectedLeadToUpdate, "company", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.company", defaultMessage: "Company" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.industry" defaultMessage="Industry" />
                    </label>
                    <input
                      type="industry"
                      name="industry"
                      className="form-control text-capitalize border"
                      id="industry"
                      value={_get(selectedLeadToUpdate, "industry", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.industry", defaultMessage: "Industry" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.department" defaultMessage="Department" />
                    </label>
                    <input
                      type="department"
                      name="department"
                      className="form-control text-capitalize border"
                      id="department"
                      value={_get(selectedLeadToUpdate, "department", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.department", defaultMessage: "Department" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.employee_size" defaultMessage="Employee Size" />
                    </label>
                    <input
                      type="employees"
                      name="employees"
                      className="form-control text-capitalize border"
                      id="employees"
                      value={_get(selectedLeadToUpdate, "employees", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.employee_size", defaultMessage: "Employee Size" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.language" defaultMessage="Language" />
                    </label>
                    <input
                      type="language"
                      name="language"
                      className="form-control text-capitalize border"
                      id="language"
                      value={_get(selectedLeadToUpdate, "language", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.language", defaultMessage: "Language" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.job_level" defaultMessage="Job Level" />
                    </label>
                    <input
                      type="job_level"
                      name="job_level"
                      className="form-control text-capitalize border"
                      id="job_level"
                      value={_get(selectedLeadToUpdate, "job_level", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.job_level", defaultMessage: "Job Level" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.website" defaultMessage="Website" />
                    </label>
                    <input
                      type="website"
                      name="website"
                      className="form-control text-capitalize border"
                      id="website"
                      value={_get(selectedLeadToUpdate, "website", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.website", defaultMessage: "Website" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.revenue" defaultMessage="Revenue" />
                    </label>
                    <input
                      type="revenue"
                      name="revenue"
                      className="form-control text-capitalize border"
                      id="revenue"
                      value={_get(selectedLeadToUpdate, "revenue", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.revenue", defaultMessage: "Revenue" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.job_title" defaultMessage="Job Title" />
                    </label>
                    <input
                      type="job_title"
                      name="job_title"
                      className="form-control text-capitalize border"
                      id="job_title"
                      value={_get(selectedLeadToUpdate, "job_title", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.job_title", defaultMessage: "Job Title" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.address_1" defaultMessage="Address 1" />
                    </label>
                    <input
                      type="address_1"
                      name="address_1"
                      className="form-control text-capitalize border"
                      id="address_1"
                      value={_get(selectedLeadToUpdate, "address_1", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.address_1", defaultMessage: "Address 1" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.address_2" defaultMessage="Address 2" />
                    </label>
                    <input
                      type="address_2"
                      name="address_2"
                      className="form-control text-capitalize border"
                      id="address_2"
                      value={_get(selectedLeadToUpdate, "address_2", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.address_2", defaultMessage: "Address 2" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-4 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.address_3" defaultMessage="Address 3" />
                    </label>
                    <input
                      type="address_3"
                      name="address_3"
                      className="form-control text-capitalize border"
                      id="address_3"
                      value={_get(selectedLeadToUpdate, "address_3", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.address_3", defaultMessage: "Address 3" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-3 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.city" defaultMessage="City" />
                    </label>
                    <input
                      type="city"
                      name="city"
                      className="form-control text-capitalize border"
                      id="city"
                      value={_get(selectedLeadToUpdate, "city", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.city", defaultMessage: "City" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-3 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.state" defaultMessage="State" />
                    </label>
                    <input
                      type="state"
                      name="state"
                      className="form-control text-capitalize border"
                      id="state"
                      value={_get(selectedLeadToUpdate, "state", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.state", defaultMessage: "State" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-3 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.country" defaultMessage="Country" />
                    </label>
                    <input
                      type="country"
                      name="country"
                      className="form-control text-capitalize border"
                      id="country"
                      value={_get(selectedLeadToUpdate, "country", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.country", defaultMessage: "Country" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                  <div className="form-group required col-3 mb-15">
                    <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                      <FormattedMessage id="buyer_dashboard.data.tab.view_data.column.postcode" defaultMessage="Postcode" />
                    </label>
                    <input
                      type="postcode"
                      name="postcode"
                      className="form-control text-capitalize border"
                      id="postcode"
                      value={_get(selectedLeadToUpdate, "postcode", "")}
                      placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.column.postcode", defaultMessage: "Postcode" })}
                      onChange={(e) => this._handleChange(e)}
                    />
                    {/*(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gutters-5">
          <div className="col-lg-12">
            <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
              <button className="btn btn-primary" onClick={() => this._updateLeads()}>
                <FormattedMessage id="btn.update" defaultMessage="Update" />
              </button>
              <button className="btn btn-secondary" onClick={(e) => this.setState({ showUpdateLeadModal: false })}>
                <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  rejectLeadsModal() {
    const { leadRejectionReasonsData, intl } = this.props;
    const { showRejectLeadsModal, selectedRejectReason, leadRejectedReasonRemark } = this.state;

    return (
      <Modal
        isOpen={showRejectLeadsModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.reject_leads", defaultMessage: "Reject leads" })}
        onHide={() => this.setState({ showRejectLeadsModal: false })}
        onClose={() => this.setState({ showRejectLeadsModal: false })}
      >
        <div className="row gy-20">
          <div className="col-lg-12">
            <div>
              <label className="heading-05 mb-8 text-capitalize">
                <FormattedMessage id="label.select_field" defaultMessage="select {field}" values={{ field: intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.rejection_reason", defaultMessage: "rejection reason" }) }} />
              </label>
              <Select
                className="text-capitalize form-custom-select border"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={_get(leadRejectionReasonsData, "data.data", [])}
                value={(selectedRejectReason || null)}
                getOptionLabel={(option) => (option.rejection_reason || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.setState({ selectedRejectReason: selected })}
              />
            </div>

            <div className="col-lg-12 mt-15">
              <label className="heading-05 mb-12 text-capitalize"><FormattedMessage id="buyer_dashboard.data.tab.view_data.modal.title.remark" defaultMessage="remark" /></label>
              <textarea
                className="form-control border rounded-3"
                placeholder={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.remark", defaultMessage: "Remark" })}
                rowSpan="5"
                value={(leadRejectedReasonRemark || "")}
                onChange={(e) => this.setState({ leadRejectedReasonRemark: _get(e, "target.value", null) })}
              />
            </div>
            <div className="row gutters-5">
              <div className="col-lg-12">
                <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
                  <button className="btn btn-primary" onClick={() => this._approveRejectLeads(2)} >
                    <FormattedMessage id="btn.submit" defaultMessage="submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  _filterLeadsModal() {
    const { dataCheckFileData, intl } = this.props;
    const { showFilterLeadsModal, selectedLeadStatusFilter, selectedBatchFilter } = this.state;

    return (
      <Modal
        isOpen={showFilterLeadsModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.title.filter_leads", defaultMessage: "Filter Leads" })}
        onHide={() => this.setState({ showFilterLeadsModal: false })}
        onClose={() => this.setState({ showFilterLeadsModal: false })}
      >
        <div className="row gy-20">
          <div className="col-lg-12">
            <div>
              <label className="heading-05 mb-8 text-capitalize">
                <FormattedMessage id="label.select_field" defaultMessage="select {field}" values={{ field: intl.formatMessage({ id: "batch", defaultMessage: "batch" }) }} />
              </label>
              <Select
                className="text-capitalize form-custom-select border"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={_get(dataCheckFileData, "data.quote", [])}
                value={(selectedBatchFilter || null)}
                getOptionLabel={(option, i) => (`Batch #${option.quote_id}` || "")}
                getOptionValue={(option) => (option.quote_id || null)}
                onChange={(selected) => this.setState({ selectedBatchFilter: selected })}
              />
            </div>

            <div>
              <label className="heading-05 mb-8 text-capitalize mt-15">
                <FormattedMessage id="label.select_field" defaultMessage="select {field}" values={{ field: intl.formatMessage({ id: "buyer_dashboard.data.tab.view_data.modal.status", defaultMessage: "status" }) }} />
              </label>
              <Select
                className="text-capitalize form-custom-select border"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(quoteLeadStatuses || [])}
                defaultValue={(selectedLeadStatusFilter || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                onChange={(selected) => this.setState({ selectedLeadStatusFilter: selected })}
              />
            </div>
            <div className="row gutters-5">
              <div className="col-lg-12">
                <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
                  <button className="btn btn-secondary" onClick={(e) => this.setState({ showFilterLeadsModal: false })}>
                    <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setState((prevState) => ({
                      viewLeadPayload: {
                        ...prevState.viewLeadPayload,
                        quoteId: _get(selectedBatchFilter, "quote_id", null),
                        isApproved: _get(selectedLeadStatusFilter, "id", null),
                      }
                    }))}
                  >
                    <FormattedMessage id="btn.submit" defaultMessage="submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  _renderTabs = () => {
    const { activeTab } = this.state;

    return (
      <div className="project-data-tabs">

        <div className={`project-data-tabs-item text-capitalize cursor-pointer ${((activeTab || "") === "data_check") ? "active" : ""}`} onClick={() => this.setState({ activeTab: "data_check" })}>
          <FormattedMessage id="buyer_dashboard.data.tab.sub_tab.title.data_check" defaultMessage="Data Check" />
        </div>
        <div className={`project-data-tabs-item text-capitalize cursor-pointer ${((activeTab || "") === "view_data") ? "active" : ""}`} onClick={() => this.setState({ activeTab: "view_data" })}>
          <FormattedMessage id="buyer_dashboard.data.tab.sub_tab.title.view_data" defaultMessage="View Data" />
        </div>
        <div className={`project-data-tabs-item text-capitalize cursor-pointer ${((activeTab || "") === "data_history") ? "active" : ""}`} onClick={() => this.setState({ activeTab: "data_history" })}>
          <FormattedMessage id="buyer_dashboard.data.tab.sub_tab.title.data_history" defaultMessage="Data History" />
        </div>
        <div className={`project-data-tabs-item text-capitalize cursor-pointer ${((activeTab || "") === "release_funds") ? "active" : ""}`} onClick={() => this.setState({ activeTab: "release_funds" })}>
          <FormattedMessage id="buyer_dashboard.data.tab.sub_tab.title.release_funds" defaultMessage="rejected data" />
        </div>
      </div>
    )
  }

  _renderDataTab = () => {
    const { activeTab } = this.state;

    return (
      <div className="col-lg-12">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">
              <FormattedMessage id="buyer_dashboard.data.tab.title" defaultMessage="Data" />
            </h4>
          </div>

          {this._renderTabs()}

          <div className="project-data-content">

            {((activeTab || "") === "data_check") && (
              this._renderDataCheckTab()
            )}

            {((activeTab || "") === "view_data") && (
              this._renderViewDataTab()
            )}

            {((activeTab || "") === "data_history") && (
              this._renderDataHistoryTab()
            )}

            {((activeTab || "") === "release_funds") && (
              this._renderRejectedDataTab()
            )}

          </div>
        </div>
      </div>
    )
  }

  render() {

    return (
      <div className="col-lg-12">
        {this._renderDataTab()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "oauth.user.role_id", 0),
  quoteLeadsData: _get(state, "leads.workflowDetails.quoteLeads", {}),
  updateLeadData: _get(state, "leads.workflowDetails.updateLead", {}),
  leadRejectionReasonsData: _get(state, "leads.workflowDetails.leadRejectionReasons", {}),
  exportQuoteLeadsData: _get(state, "leads.workflowDetails.exportQuoteLeads", {}),
  dataCheckFileData: _get(state, "leads.workflowDetails.dataCheckFile", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", "$"),
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", null),
  selectedQuoteGroup: _get(state, "leads.dashboard.selectedQuoteGroupId", null),
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", "DD/MM/YYYY HH:mm:ss"),
  rejectedTypes: _get(state, "application.dataRejectedTypes", {}),
  updateQuoteBatchUpload: _get(state, "leads.workflowDetails.updateQuoteBatchUpload", {}),
  approveRejectQuoteLeadsData: _get(state, "leads.dashboard.approveRejectQuoteLeads", {}),
  allowedDataUploadFiles: _get(state, "application.constants.allowedDataUploadFiles", ""),
  allowedDataUploadFilesLabel: _get(state, "application.constants.allowedDataUploadFilesLabel", ""),
  projectRequiredFields: _get(state, "leads.workflowDetails.projectRequiredFields", {}),
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  fetchQuoteLeads: (data) => dispatch(fetchQuoteLeads(data)),
  fetchUploadedDataCheckFile: (data) => dispatch(fetchUploadedDataCheckFile(data)),
  updateQuoteBatchUploadStatus: (data) => dispatch(updateQuoteBatchUploadStatus(data)),
  approveRejectQuoteLeads: (data) => dispatch(approveRejectQuoteLeads(data)),
  exportQuoteLeads: (data) => dispatch(exportQuoteLeads(data)),
  resetExportQuoteLeads: () => dispatch(resetExportQuoteLeads()),
  fetchLeadRejectionReasons: (data) => dispatch(fetchLeadRejectionReasons(data)),
  resetLeadRejectionReasons: () => dispatch(resetLeadRejectionReasons()),
  fetchProjectRequiredFields: (data) => dispatch(fetchProjectRequiredFields(data)),
  updateLead: (data) => dispatch(updateLead(data)),
  resetUpdateLeadData: () => dispatch(resetUpdateLeadData()),
  fetchDataRejectedTypes: () => dispatch(fetchDataRejectedTypes()),
  resetUploadedDataCheckFileData: () => dispatch(resetUploadedDataCheckFileData()),
  resetApproveRejectQuoteLeads: () => dispatch(resetApproveRejectQuoteLeads()),
  resetUpdateQuoteBatchUploadStatusData: () => dispatch(resetUpdateQuoteBatchUploadStatusData()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Data)));