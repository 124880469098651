import React, { Component, Fragment } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";

import { showAlertMessage, ErrorMessage, NoDataMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { quoteStatusLookup } from "../../../helpers/constants";
import { IconDots } from "../../../helpers/iconStyles";

import config from "../../../helpers/config";

import { fetchCheckoutPageDetails } from "../../../services/leads";

import { updateLoadingState, updateUserDetails, clearUpdateUserDetailsData } from "../../../actions/application";
import { fetchQuoteDetails, resetQuoteDetails, fetchDataAcceptanceDetails } from "../../../actions/leads";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class Funds extends Component {
  constructor(props) {
    super(props);

    this.billingDefaultState = {
      firstName: "",
      lastName: "",
      company: "",
      billingFirstName: "",
      billingLastName: "",
      address1: "",
      address2: "",
      phone: "",
      city: "",
      state: "",
      country: "",
      postcode: ""
    };

    this.paymentInfoDefault = {
      cardNo: "",
      expiryDate: "",
      cvv: "",
    };

    this.state = {
      activeTab: ((props.source || "seller") === "seller") ? "funds_deposited" : "pending_funds",
      billingDetails: {
        ...this.billingDefaultState
      },
      paymentInfo: {
        ...this.paymentInfoDefault
      },
      errors: {},
      errorPayment: {}
    };
  }

  componentDidMount() {
    const { dataAcceptanceDetails, selectedQuoteGroupId } = this.props;

    if ((selectedQuoteGroupId || "") !== "") {
      this._fetchQuoteDetails();
      this._setBillingDetails();
      this._fetchDataAcceptanceDetails();
    }

    if (_get(dataAcceptanceDetails, "data[0].pending_funds_amount", 0) > 0) {
      this.setState({ activeTab: "pending_funds" });
    } else if (_get(dataAcceptanceDetails, "data[0].funds_deposited", 0) > 0) {
      this.setState({ activeTab: "funds_deposited" });
    } else if (_get(dataAcceptanceDetails, "data[0].funds_released_amount", 0) > 0) {
      this.setState({ activeTab: "funds_released" });
    }
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.quoteDetails, _get(this.props, "quoteDetails", {}))) {
      const { quoteDetails, resetQuoteDetails } = this.props;

      if (_get(this.props, "quoteDetails.status", null) === null) { return false; }

      if (_get(this.props, "quoteDetails.status", null) === true) { this._setBillingDetails(); }

      if (_get(this.props, "quoteDetails.status", null) === false) {
        showAlertMessage((quoteDetails.message || "Something went wrong while fetching quote details."));
        if (typeof resetQuoteDetails === "function") { resetQuoteDetails(); }
      }
    }

    if (
      (!_isEqual(_get(prevProps, "projectId", 0), _get(this.props, "projectId", 0)) && (_get(this.props, "projectId", 0) !== 0)) ||
      (!_isEqual(_get(prevProps, "selectedQuoteGroupId", null), _get(this.props, "selectedQuoteGroupId", null)) && (_get(this.props, "selectedQuoteGroupId", null) !== null))
    ) {

      this._fetchQuoteDetails();
    }

    if (!_isEqual(prevStates.billingDetails, this.state.billingDetails) || !_isEqual(prevStates.paymentInfo, this.state.paymentInfo)) {
      this._handleValidation();
    }

    if (!_isEqual(prevProps.dataAcceptanceDetails, this.props.dataAcceptanceDetails)) {
      const { dataAcceptanceDetails } = this.props;

      if (_get(dataAcceptanceDetails, "status", null) === null) { return false; }

      if (_get(dataAcceptanceDetails, "status", null) === true) {
        if (_get(dataAcceptanceDetails, "data[0].pending_funds_amount", 0) > 0) {
          this.setState({ activeTab: "pending_funds" });
        } else if (_get(dataAcceptanceDetails, "data[0].funds_deposited", 0) > 0) {
          this.setState({ activeTab: "funds_deposited" });
        } else if (_get(dataAcceptanceDetails, "data[0].funds_released_amount", 0) > 0) {
          this.setState({ activeTab: "funds_released" });
        }
      }

      if (_get(dataAcceptanceDetails, "status", null) === false) {
        showAlertMessage((dataAcceptanceDetails.message || "Something went wrong while fetching fund details."));
      }
    }

    /*if (!_isEqual(prevProps.updatedUserDetails, this.props.updatedUserDetails) && !_isEmpty(this.props.updatedUserDetails)) {
      const { updatedUserDetails, clearUpdateUserDetailsData } = this.props;

      if (_get(updatedUserDetails, "status", false) === null) { return false; }

      if (_get(updatedUserDetails, "status", false) === true) {
        showAlertMessage(_get(updatedUserDetails, "data.message", "Billing details updated successfully."), "success");
      } else {
        if (typeof clearUpdateUserDetailsData === "function") { clearUpdateUserDetailsData(); }

        showAlertMessage(_get(updatedUserDetails, "data.message", "Something went wrong while billing details."));
      }
    }*/
  }

  _fetchDataAcceptanceDetails = () => {
    const { fetchDataAcceptanceDetails, projectId, selectedQuoteGroupId, intl } = this.props;

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if ((selectedQuoteGroupId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof fetchDataAcceptanceDetails === "function") {
      fetchDataAcceptanceDetails({
        projectId: projectId,
        quoteId: null,
        quoteGroupId: (selectedQuoteGroupId || null),
      });
    }
  }

  _setBillingDetails = () => {
    const { quoteDetails } = this.props;
    const { billingDetails } = this.state;

    const billingInfo = _get(quoteDetails, "data.billing_info[0]", {});

    this.setState({
      billingDetails: {
        ...billingDetails,
        firstName: _get(billingInfo, "first_name", ""),
        lastName: _get(billingInfo, "last_name", ""),
        billingFirstName: _get(billingInfo, "billing_first_name", ""),
        billingLastName: _get(billingInfo, "billing_last_name", ""),
        company: _get(billingInfo, "company", ""),
        address1: _get(billingInfo, "address_1", ""),
        address2: _get(billingInfo, "address_2", ""),
        //phone: _get(billingInfo, "phone", ""),
        city: _get(billingInfo, "city", ""),
        state: _get(billingInfo, "state", ""),
        country: _get(billingInfo, "country", ""),
        postcode: _get(billingInfo, "postcode", ""),
      }
    });
  }

  _fetchQuoteDetails = () => {
    const { fetchQuoteDetails, projectId, selectedQuoteGroupId, intl } = this.props;

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if ((selectedQuoteGroupId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof fetchQuoteDetails === "function") {
      fetchQuoteDetails({
        projectId: (projectId || null),
        quoteId: (selectedQuoteGroupId || null),
        returnBillingInfo: 1
      });
    }
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { billingDetails } = this.state;

    let errors = {};

    if (_isEmpty(_get(billingDetails, "billingFirstName", ""))) {
      errors["billingFirstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.first_name", defaultMessage: "First name" })
      });
    }

    if (_isEmpty(_get(billingDetails, "billingLastName", ""))) {
      errors["billingLastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.last_name", defaultMessage: "Last name" })
      });
    }

    /*if (!isValidPhone(_get(billingDetails, "phone", ""))) {
      errors["phone"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.phone_number", defaultMessage: "Phone Number" })
      });
    }*/

    if (_isEmpty(_get(billingDetails, "company", ""))) {
      errors["company"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.company", defaultMessage: "Company" })
      });
    }

    if (_isEmpty(_get(billingDetails, "address1", ""))) {
      errors["address1"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.address1", defaultMessage: "Address Line 1" })
      });
    }

    if (_isEmpty(_get(billingDetails, "address2", ""))) {
      errors["address2"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.address2", defaultMessage: "Address Line 1" })
      });
    }

    if (_isEmpty(_get(billingDetails, "city", ""))) {
      errors["city"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.city", defaultMessage: "City" })
      });
    }

    if (_isEmpty(_get(billingDetails, "country", ""))) {
      errors["country"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.country", defaultMessage: "Country" })
      });
    }

    if (_isEmpty(_get(billingDetails, "postcode", ""))) {
      errors["postcode"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "seller.workflow.accepted_quote.postcode", defaultMessage: "Post Code" })
      });
    }

    this.setState({ errors });
  }

  _handlePaymentValidation = () => {
    const { intl } = this.props;
    const { paymentInfo } = this.state;

    let errorPayment = {};

    if (_isEmpty(_get(paymentInfo, "cardNo", ""))) {
      errorPayment["cardNo"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "card_no", defaultMessage: "Card No" })
      });
    }

    if (_isEmpty(_get(paymentInfo, "expiryDate", ""))) {
      errorPayment["expiryDate"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "expiry_date", defaultMessage: "Expiry Date" })
      });
    }

    if (_isEmpty(_get(paymentInfo, "cvv", ""))) {
      errorPayment["cvv"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "cvv", defaultMessage: "CVV" })
      });
    }

    this.setState({ errorPayment });
    return (_isEmpty(errorPayment)) ? true : false;
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = _get(e, "target", {});

    this.setState((prevState) => ({
      billingDetails: { ...prevState.billingDetails, [name]: value }
    }));
  }

  _handlePaymentInfoChange = (e) => {
    e.preventDefault();

    const { name, value } = _get(e, "target", {});

    this.setState((prevState) => ({
      paymentInfo: { ...prevState.paymentInfo, [name]: value }
    }));
  }

  _initiatePayment = async (e) => {
    e.preventDefault();

    const { updateLoadingState, projectId, selectedQuoteGroupId, intl } = this.props;

    /*let errorPayment = this._handlePaymentValidation();

    if (errorPayment === false) {
      return;
    }*/

    if ((projectId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "project", defaultMessage: "project" })
      }));

      return false;
    }

    if ((selectedQuoteGroupId || null) === null) {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await fetchCheckoutPageDetails({
        projectId: (projectId || null),
        quoteGroupId: (selectedQuoteGroupId || null),
        requestFromUrl: config.getAdminURL(true, true)
      });

      if ((response.flag || false) === true) {

        const stripe = await stripePromise;

        const sessionId = _get(response, "data.session_id", null);

        if (_isEmpty(sessionId)) {
          showAlertMessage("Something went wrong while generate payment session");
          return false;
        }

        const result = await stripe.redirectToCheckout({ sessionId: sessionId });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          showAlertMessage(_get(result, "error.message", "Something went wrong while loading payment gateway."));
        }

      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while loading payment gateway."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while loading payment gateway."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderFundsTabs = () => {
    const { source, currencySymbol, dataAcceptanceDetails, quoteDetails } = this.props;
    const { activeTab } = this.state;

    const value = _get(quoteDetails, "data.quote_status[0].status_text", "").toUpperCase();
    const statusClass = _get(quoteStatusLookup, `[${value}.statusClass]`, "");

    return (
      <div className="row gx-12">
        <div className="col-xl-2 col-lg-4 cursor-pointer" onClick={() => {
          if ((source || "seller") === "buyer") {
            this.setState({ activeTab: "pending_funds" })
          }
        }
        } >
          <div className={`project-list-card text-capitalize ${(activeTab === "pending_funds") ? "border-primary" : ""}`}>
            <p><FormattedMessage id="buyer_dashboard.funds.tab.pending_funds" defaultMessage="Pending Funds" /></p>
            <p>{`${currencySymbol}${_get(dataAcceptanceDetails, "data[0].pending_funds_amount", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4 cursor-pointer" onClick={() => this.setState({ activeTab: "funds_deposited" })} >
          <div className={`project-list-card text-capitalize ${(activeTab === "funds_deposited") ? "border-primary" : ""}`}>
            <p><FormattedMessage id="buyer_dashboard.funds.tab.funds_deposited" defaultMessage="Funds Deposited" /></p>
            <p>{`${currencySymbol}${_get(dataAcceptanceDetails, "data[0].funds_deposited", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4 cursor-pointer" onClick={() => this.setState({ activeTab: "funds_released" })} >
          <div className={`project-list-card text-capitalize ${(activeTab === "funds_released") ? "border-primary" : ""}`}>
            <p><FormattedMessage id="buyer_dashboard.funds.tab.funds_released" defaultMessage="Funds Released" /></p>
            <p>{`${currencySymbol}${_get(dataAcceptanceDetails, "data[0].funds_released_amount", 0)}`}</p>
          </div>
        </div>

        <div className="col-xl-2 col-lg-4 cursor-pointer">
          <div className="project-list-card bg-success-f2 text-capitalize">
            <p className="text-dark"><FormattedMessage id="dashboard.funds.tab.quote_status" defaultMessage="Quote Status" /></p>
            <p className={statusClass}>{_get(quoteDetails, `data.quotes[0].status_text`, "")}</p>
          </div>
        </div>
      </div>
    );
  }

  _renderProjectDetailsFunds = () => {
    const { quoteDetails, currencySymbol, dataAcceptanceDetails, selectedQuoteGroupId, intl } = this.props;
    const { billingDetails, /*paymentInfo, errorPayment,*/ errors } = this.state;

    return (
      <Fragment>
        <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
          <h4 className="heading-03 fw-medium text-primary text-capitalize">
            <FormattedMessage id="buyer_dashboard.funds.tab.pending.title" defaultMessage="Funds" />
          </h4>

          <div className="ms-lg-auto">
            <IconDots height="20" width="4" color="#e84968" />
          </div>
        </div>


        {(((selectedQuoteGroupId || null) === null)) && (
          <div className="col-lg-12">
            <NoDataMessage
              className="text-center no-message-wrapper table-no-data-message"
              message={intl.formatMessage({ id: "message.no_quote", defaultMessage: "No quote has been submitted yet" })}
            />
          </div>
        )}

        {(((selectedQuoteGroupId || null) !== null)) && (
          <div className="project-status-wrap">
            {this._renderFundsTabs()}

            <p className="heading-03 fw-medium mt-8 text-primary">
              <FormattedMessage id="buyer_dashboard.funds.tab.billing_details" defaultMessage="Billing Details" />
            </p>

            <div className="border-bottom border-secondary pb-12 mb-16"></div>

            <div className="row">
              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="first_name" defaultMessage="first name" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="billingFirstName"
                    placeholder="First Name"
                    defaultValue={_get(billingDetails, "billingFirstName", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.billingFirstName || "") && (<ErrorMessage message={(errors.billingFirstName || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="last_name" defaultMessage="last name" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="billingLastName"
                    placeholder="Last Name"
                    defaultValue={_get(billingDetails, "billingLastName", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.billingLastName || "") && (<ErrorMessage message={(errors.billingLastName || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="company" defaultMessage="company" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="company"
                    placeholder="Company"
                    readOnly
                    disabled="disabled"
                    defaultValue={_get(billingDetails, "company", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.company || "") && (<ErrorMessage message={(errors.company || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="address_line_1" defaultMessage="Address Line 1" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="addressline1"
                    placeholder="Address Line 1"
                    defaultValue={_get(billingDetails, "address1", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.address1 || "") && (<ErrorMessage message={(errors.address1 || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="address_line_2" defaultMessage="Address Line 2" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="addressline2"
                    placeholder="Address Line 2"
                    defaultValue={_get(billingDetails, "address2", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.address2 || "") && (<ErrorMessage message={(errors.address2 || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="city" defaultMessage="city" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="city"
                    placeholder="City"
                    defaultValue={_get(billingDetails, "city", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.city || "") && (<ErrorMessage message={(errors.city || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="state" defaultMessage="State" />
                    <span className="text-danger heading-06">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="state"
                    placeholder="State"
                    defaultValue={_get(billingDetails, "state", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.state || "") && (<ErrorMessage message={(errors.state || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="county" defaultMessage="county" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="county"
                    placeholder="County"
                    defaultValue={_get(billingDetails, "country", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.country || "") && (<ErrorMessage message={(errors.country || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="postcode" defaultMessage="postcode" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="postcode"
                    placeholder="Post Code"
                    defaultValue={_get(billingDetails, "postcode", "")}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {(errors.postcode || "") && (<ErrorMessage message={(errors.postcode || "")} />)}
                </div>
              </div>
            </div>

            {/*<p className="heading-03 fw-medium mt-8 text-primary">
              <FormattedMessage id="buyer_dashboard.funds.tab.card_details" defaultMessage="Card Details" />
            </p>

            <div className="border-bottom border-secondary pb-12 mb-16"></div>

            <div className="row">
              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="card_no" defaultMessage="Card No" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="cardNo"
                    placeholder="XXXX XXXX XXXX XXXX"
                    defaultValue={_get(paymentInfo, "cardNo", "")}
                    onChange={(e) => this._handlePaymentInfoChange(e)}
                  />
                  {(errorPayment.cardNo || "") && (<ErrorMessage message={(errorPayment.cardNo || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8 text-capitalize">
                    <FormattedMessage id="expiry_date" defaultMessage="Expiry Date" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="expiryDate"
                    placeholder="Expiry"
                    defaultValue={_get(paymentInfo, "expiryDate", "")}
                    onChange={(e) => this._handlePaymentInfoChange(e)}
                  />
                  {(errorPayment.expiryDate || "") && (<ErrorMessage message={(errorPayment.expiryDate || "")} />)}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="mb-24">
                  <label className="heading-05 mb-8">
                    <FormattedMessage id="cvv" defaultMessage="CVV" />
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm border"
                    name="cvv"
                    placeholder="CCV"
                    defaultValue={_get(paymentInfo, "cvv", "")}
                    onChange={(e) => this._handlePaymentInfoChange(e)}
                  />
                  {(errorPayment.cvv || "") && (<ErrorMessage message={(errorPayment.cvv || "")} />)}
                </div>
              </div>
            </div>*/}

            <div className="border-bottom border-secondary pb-12 mb-12"></div>

            <p className="heading-02">
              <FormattedMessage
                id="buyer_dashboard.funds.tab.add_funds"
                defaultMessage="Add Funds: {currency_symbol}{section}"
                values={{ currency_symbol: currencySymbol, section: _get(dataAcceptanceDetails, "data[0].pending_funds_amount", 0) }}
              />
            </p>

            <div className="border-bottom border-secondary pb-12 mb-12"></div>

            {(_get(dataAcceptanceDetails, "data[0].pending_funds_amount", 0) > 0) &&
              ((![_get(quoteStatusLookup, "REJECTED.id", 0), _get(quoteStatusLookup, "CANCELLED.id", 0)].includes(_get(quoteDetails, "data.quotes[0].status", 0)))) && (
                <Fragment>
                  <div className="form-check">
                    <input
                      className="form-check-input border"
                      type="checkbox"
                      id="totalcosttnc"
                    />
                    <label className="form-check-label" htmlFor="totalcosttnc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</label>
                  </div>

                  <div className="d-flex gap-16 mt-24">
                    <button className="btn btn-md btn-primary" onClick={(e) => this._initiatePayment(e)}>
                      <FormattedMessage id="buyer_dashboard.funds.btn.add_funds" defaultMessage="Add funds" />
                    </button>
                  </div>
                </Fragment>
              )}
          </div>
        )}
      </Fragment>
    )
  }

  _renderProjectDetailsFundsDeposite = () => {
    const { currencySymbol, dataAcceptanceDetails, intl } = this.props;

    return (
      <Fragment>
        <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
          <h4 className="heading-03 fw-medium text-primary text-capitalize">
            <FormattedMessage id="buyer_dashboard.funds.tab.pending.title" defaultMessage="Funds" />
          </h4>

          <p className="heading-05 pt-2">
            <FormattedMessage id="buyer_dashboard.funds.tab.funds_deposited.subtitle" defaultMessage="Funds have been deposited, waiting for data to be sent" />
          </p>

          <div className="ms-lg-auto">
            <IconDots height="20" width="4" color="#e84968" />
          </div>
        </div>

        <div className="project-status-wrap">

          {this._renderFundsTabs()}

          <p className="heading-03 fw-medium mt-8 text-primary text-capitalize">
            <FormattedMessage id="buyer_dashboard.funds.tab.funds_deposited" defaultMessage="Funds Deposited" />
          </p>

          <div className="border-bottom border-secondary pb-12 mb-16"></div>

          {(_get(dataAcceptanceDetails, "data[0].funds_deposited", 0) === 0) && (
            <NoDataMessage
              className="text-center no-message-wrapper table-no-data-message"
              message={intl.formatMessage({ id: "message.no_funds_deposit", defaultMessage: "No funds has been deposited yet" })}
            />
          )}

          {(_get(dataAcceptanceDetails, "data[0].funds_deposited", 0) > 0) && (
            <div className="row">
              <div className="col-lg-12">
                <p className="text-success mb-8">Payment completed successfully</p>

                <p className="mb-8">
                  <FormattedMessage
                    id="buyer_dashboard.funds.tab.funds_deposited.payment_status"
                    defaultMessage="Payment Status: {status}"
                    values={{ status: _get(dataAcceptanceDetails, "data[0].payment_status", "Unpaid") }}
                  />
                </p>

                <p className="mb-8">
                  <FormattedMessage
                    id="buyer_dashboard.funds.tab.funds_deposited.reference_id"
                    defaultMessage="Reference ID: {reference_id}"
                    values={{ reference_id: _get(dataAcceptanceDetails, "data[0].fund_deposited_reference_id", "") }}
                  />
                </p>

                <p className="mb-8">
                  <FormattedMessage
                    id="buyer_dashboard.funds.tab.funds_deposited.amount"
                    defaultMessage="Amount: {currency_symbol}{amount}"
                    values={{ currency_symbol: currencySymbol, amount: _get(dataAcceptanceDetails, "data[0].funds_deposited", 0) }}
                  />
                </p>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }

  _renderProjectDetailsFundsRelease = () => {
    const { dataAcceptanceDetails, intl } = this.props;

    return (
      <Fragment>
        <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
          <h4 className="heading-03 fw-medium text-primary text-capitalize">
            <FormattedMessage id="buyer_dashboard.funds.tab.pending.title" defaultMessage="Funds" />
          </h4>

          <p className="heading-05 pt-2">
            <FormattedMessage id="buyer_dashboard.funds.tab.funds_released.subtitle" defaultMessage="Data has been checked, funds have been released" />
          </p>

          <div className="ms-lg-auto">
            <IconDots height="20" width="4" color="#e84968" />
          </div>
        </div>

        <div className="project-status-wrap">

          {this._renderFundsTabs()}

          <p className="heading-03 fw-medium mt-8 text-primary text-capitalize">
            <FormattedMessage id="buyer_dashboard.funds.tab.funds_released" defaultMessage="Funds Released" />
          </p>

          <div className="border-bottom border-secondary pb-12 mb-16"></div>

          {(_get(dataAcceptanceDetails, "data[0].funds_released_amount", 0) === 0) && (
            <NoDataMessage
              className="text-center no-message-wrapper table-no-data-message"
              message={intl.formatMessage({ id: "message.no_funds_deposit", defaultMessage: "No funds has been deposited yet" })}
            />
          )}

          {(_get(dataAcceptanceDetails, "data[0].funds_released_amount", 0) !== 0) && (
            <div className="row">
              <div className="col-lg-12">
                <p className="text-success mb-8">Data has been checked and approved</p>

                <p className="mb-8">
                  <FormattedMessage id="buyer_dashboard.funds.tab.funds_released.fund_released_notice" defaultMessage="Funds have been automatically released" />
                </p>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }

  _renderProjectDetailsFundsQuote = () => {
    return (
      <div className="col-lg-12 mt-20">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">Funds: </h4>

            <p className="heading-05 pt-2">Quotes have been received, you need to <a href={config.getAdminURL(true, true, "/dashboard")} className="text-primary text-decoration-underline">review quote</a></p>

            <div className="ms-lg-auto">
              <IconDots height="20" width="4" color="#e84968" />
            </div>
          </div>

          <div className="project-status-wrap">
            {this._renderFundsTabs()}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className="col-lg-12">
        <div className="card p-15">
          <div className="col-lg-12">
            {((activeTab || "pending_funds") === "pending_funds") && (
              this._renderProjectDetailsFunds()
            )}

            {((activeTab || "pending_funds") === "funds_deposited") && (
              this._renderProjectDetailsFundsDeposite()
            )}

            {((activeTab || "pending_funds") === "funds_released") && (
              this._renderProjectDetailsFundsRelease()
            )}

            {/*this._renderProjectDetailsFundsQuote()*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  quoteDetails: _get(state, "leads.dashboard.quoteDetails", {}),
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", ""),
  dataAcceptanceDetails: _get(state, "leads.workflowDetails.dataAcceptanceDetails", {}),
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  updatedUserDetails: _get(state, "application.settings.updateUserDetails", {}),
  selectedProject: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuoteDetails: (data) => dispatch(fetchQuoteDetails(data)),
  resetQuoteDetails: () => dispatch(resetQuoteDetails()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
  clearUpdateUserDetailsData: () => dispatch(clearUpdateUserDetailsData()),
  fetchDataAcceptanceDetails: (data) => dispatch(fetchDataAcceptanceDetails(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Funds)));
