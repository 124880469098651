import React, { Component } from "react";
import { Column, Table, AutoSizer } from "react-virtualized";
import { get as _get, isEqual as _isEqual } from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { withRouter } from "../../../components/hoc/withRouter";

import { NoDataMessage, TableHeaderRenderer, TableCellRenderer, showAlertMessage, EmptyLabel } from "../../../components/common/controls";
import { downloadAnyFile } from "../../../helpers/utils";

import { downloadFile } from "../../../services/application";

import { fetchDataUploadHistory, resetDataUploadHistory } from "../../../actions/leads";
import { updateLoadingState } from "../../../actions/application";

class DataUploadHistory extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.state = {
      tableData: []
    };

    this.columns = [
      {
        id: "data_history_id",
        width: 0,
        columnAvrgWidth: 22,
        dataKey: "data_history_id",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.id", defaultMessage: "ID" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "quote_id",
        width: 0,
        columnAvrgWidth: 13,
        dataKey: "quote_id",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.batch_id", defaultMessage: "Batch ID" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "file_name",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "file_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.file_name", defaultMessage: "file name" })} />),
        cellRenderer: (row) => {
          const filePath = _get(row, "rowData.file_path", "");

          return (
            <>
              {(filePath !== "") ? (
                <span className="d-block text-blue-1a text-decoration-underline text-capitalize cursor-pointer" tooltip="true" onClick={() => this.downloadUploadedFile(filePath)}>
                  {_get(row, "cellData", "")}
                </span>
              ) : <EmptyLabel />}
            </>
          );
        },
      },
      {
        id: "records",
        width: 0,
        columnAvrgWidth: 14,
        dataKey: "records",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.records", defaultMessage: "records" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "processed",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "processed",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.processed", defaultMessage: "processed" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "attempted",
        width: 0,
        columnAvrgWidth: 11,
        dataKey: "attempted",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.attempted", defaultMessage: "attempted" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "pending_leads",
        width: 0,
        columnAvrgWidth: 14,
        dataKey: "pending_leads",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.pending", defaultMessage: "pending" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "approved_leads",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "approved_leads",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.approved", defaultMessage: "approved" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "rejected_leads",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "rejected_leads",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.rejected", defaultMessage: "rejected" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "seller_name",
        width: 0,
        columnAvrgWidth: 11,
        dataKey: "seller_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.user", defaultMessage: "User" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "uploaded_at",
        width: 0,
        columnAvrgWidth: 13,
        dataKey: "uploaded_at",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "data_upload_history.table.date", defaultMessage: "Date" })} />),
        cellRenderer: (row) => {
          const { displayDateTimeFormat } = this.props;

          return (<TableCellRenderer value={(_get(row, "cellData", null)) ? moment(_get(row, "cellData", null)).format(displayDateTimeFormat) : ""} tooltip={true} />);
        },
      },
    ];
  }

  componentDidMount() {
    const { dataUploadHistory } = this.props;

    this._fetchDataHistoryDetails();

    this.setState({ tableData: _get(dataUploadHistory, "data", []) });
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.dataUploadHistory, this.props.dataUploadHistory)) {

      if (_get(this.props, "dataUploadHistory.status", null) === null) { return false; }

      if (_get(this.props, "dataUploadHistory.status", false) === true) {

        this.setState({ tableData: _get(this.props, "dataUploadHistory.data", []) });
      } else {
        showAlertMessage(_get(this.props, "dataUploadHistory.message", "Something went wrong while fetching data upload history."));
        if (typeof this.props.resetDataUploadHistory === "function") { this.props.resetDataUploadHistory(); }
      }
    }
  }

  _fetchDataHistoryDetails = () => {
    const { fetchDataUploadHistory, projectId } = this.props;

    if (typeof fetchDataUploadHistory === "function") {
      fetchDataUploadHistory({ projectId: projectId, quoteId: null });
    }
  }

  downloadUploadedFile = async (fileURL = "") => {
    const { updateLoadingState } = this.props;

    if (!fileURL) {
      showAlertMessage("File not available.");
      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await downloadFile({ fileUrl: fileURL });

      if ((response.flag || false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.contents || "");
        const filename = (downloadFileDetails.fileName || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadAnyFile(contentType, contents, filename);

          showAlertMessage("File downloaded successfully.", "success");
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while downloading uploaded file.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading uploaded file.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  render() {
    const { intl } = this.props;
    const { tableData } = this.state;

    return (
      <div className="table-responsive">
        <div className="table-data position-relative h-250 overflow-x-auto">
          <AutoSizer className="table-autosizer-wrapper">
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                headerHeight={32}
                rowHeight={32}
                rowCount={(tableData || []).length}
                rowGetter={({ index }) => (tableData[index] || {})}
                noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
              >
                {(this.columns || []).map((column, key) => (
                  <Column key={key} {...column} width={width / (column.columnAvrgWidth || 11)} />
                ))}
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projectId: _get(state, "leads.dashboard.dashboardFiltersSelection.selectedProject.id", 0),
  dataUploadHistory: _get(state, "leads.workflowDetails.dataUploadHistory", {}),
  displayDateTimeFormat: _get(state, "application.constants.displayDateTimeFormat", "DD/MM/YYYY"),
  selectedQuoteId: _get(state, "leads.dashboard.selectedQuoteId.id", null),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataUploadHistory: (data) => dispatch(fetchDataUploadHistory(data)),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  resetDataUploadHistory: () => dispatch(resetDataUploadHistory())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DataUploadHistory)));