import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";

class ReactDatePicker extends Component {

	static defaultProps = {
		id: null,
		className:"form-control datepicker",
		popperPlacement: "bottom-start",
		dropdownMode: "select",
		selected: moment().toDate(),
		popperClassName: "",
		// labelClassName: "text-capitalize",
		// label: "Date",
		placeholder: "",
		// showMonthDropdown: true,
		// showYearDropdown: true,
		onChange: () => { },
		// onChangeRaw: () => { },
		formatType: "dd/MM/yyyy"
	};

	constructor(props) {
		super(props);

		this.state = {
			date: (props.selected) ? moment(props.selected).toDate() : moment().toDate()
		};
	}

	componentDidMount() {
		registerLocale("en-GB", enGB)
	}

	render() {
		const { selected, className, inputBaseClassName, popperClassName, placeholder, inputClassName, label, ...restProps } = this.props;
		const { date } = this.state;

		return (
			<DatePicker
				className={className}
				// popperClassName={`Datepicker__popper ${popperClassName} form-control dateLabel`}
				openToDate={date}
				selected={moment(selected).isValid() ? moment(selected).toDate() : ""}
				placeholderText={placeholder}
				{...restProps}
				locale={"en-GB"}
			/>
		);
	}
}

export default ReactDatePicker;