import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, NoDataMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { projectStatusLookup } from "../../../helpers/constants";
import { IconDots } from "../../../helpers/iconStyles";

import { fetchOverallDashboardRecords, fetchOverallDashboardProjects, resetOverallDashboardRecords, resetOverallDashboardProjects, UpdateDashboardFilterSelection } from "../../../actions/leads";
import { updateLoadingState } from "../../../actions/application";

class OverallDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overallDashboardRecords: [],
      overallDashboardProjects: [],
      selectedProjects: [],
      sortDirection: "ASC",
      sortBy: "project_name",
      pageNo: 1
    };
  }

  componentDidMount() {
    const { overallDashboardRecords, overallDashboardProjects } = this.props;

    this._filterOverallDashboardRecords();

    this._filterOverallDashboardProjects();

    this.setState({
      overallDashboardRecords: _get(overallDashboardRecords, "data", []),
      overallDashboardProjects: _get(overallDashboardProjects, "data.all_data", [])
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.sortBy, this.state.sortBy) || !_isEqual(prevState.sortDirection, this.state.sortDirection)) {
      this._filterOverallDashboardProjects();
    }

    if (!_isEqual(prevProps.overallDashboardRecords, this.props.overallDashboardRecords) && !_isEmpty(this.props.overallDashboardRecords)) {
      const { overallDashboardRecords, resetOverallDashboardRecords } = this.props;

      if (_get(overallDashboardRecords, "status", null) === null) { return false; }

      if (_get(overallDashboardRecords, "status", false) === true) {

        this.setState({ overallDashboardRecords: _get(overallDashboardRecords, "data", []) });
      } else {
        showAlertMessage((overallDashboardRecords.message || "Something went wrong while fetching dashboard details."));
      }

      if (typeof resetOverallDashboardRecords === "function") { resetOverallDashboardRecords(); }

    }

    if (!_isEqual(prevProps.overallDashboardProjects, this.props.overallDashboardProjects) && !_isEmpty(this.props.overallDashboardProjects)) {
      const { overallDashboardProjects, resetOverallDashboardProjects } = this.props;

      if (_get(overallDashboardProjects, "status", null) === null) { return false; }

      if (_get(overallDashboardProjects, "status", false) === true) {

        this.setState({ overallDashboardProjects: _get(overallDashboardProjects, "data.all_data", []) });
      } else {
        showAlertMessage((overallDashboardProjects.message || "Something went wrong while fetching project list."));
      }

      if (typeof resetOverallDashboardProjects === "function") { resetOverallDashboardProjects(); }

    }

    if (
      !_isEqual(_get(prevProps.dashboardFiltersSelection, "selectedBuyer.id", 0), _get(this.props.dashboardFiltersSelection, "selectedBuyer.id", 0)) ||
      !_isEqual(_get(prevProps.dashboardFiltersSelection, "selectedSeller.id", 0), _get(this.props.dashboardFiltersSelection, "selectedSeller.id", 0)) ||
      !_isEqual(_get(prevProps.dashboardFiltersSelection, "startDate", null), _get(this.props.dashboardFiltersSelection, "startDate", null)) ||
      !_isEqual(_get(prevProps.dashboardFiltersSelection, "endDate", null), _get(this.props.dashboardFiltersSelection, "endDate", null))) {

      this._filterOverallDashboardRecords();

      this._filterOverallDashboardProjects();
    }
  }

  _filterOverallDashboardRecords = () => {
    const { fetchOverallDashboardRecords, dashboardFiltersSelection, activeBuyerTab } = this.props;

    if (typeof fetchOverallDashboardRecords === "function") {
      fetchOverallDashboardRecords({
        projectId: null,
        fromDate: _get(dashboardFiltersSelection, "startDate", null),
        toDate: _get(dashboardFiltersSelection, "endDate", null),
        roleFlag: (activeBuyerTab || 1),
        buyerId: ((_get(dashboardFiltersSelection, "selectedBuyer.id", 0) !== 0) && (activeBuyerTab === 2)) ? _get(dashboardFiltersSelection, "selectedBuyer.id", 0) : null,
        sellerId: ((_get(dashboardFiltersSelection, "selectedSeller.id", 0) !== 0) && (activeBuyerTab === 1)) ? _get(dashboardFiltersSelection, "selectedSeller.id", 0) : null,
      });
    }
  }

  _filterOverallDashboardProjects = () => {
    const { fetchOverallDashboardProjects, dashboardFiltersSelection, activeBuyerTab } = this.props;
    const { sortDirection, sortBy, pageNo } = this.state;

    if (typeof fetchOverallDashboardProjects === "function") {
      fetchOverallDashboardProjects({
        projectId: null,
        fromDate: _get(dashboardFiltersSelection, "startDate", null),
        toDate: _get(dashboardFiltersSelection, "endDate", null),
        roleFlag: (activeBuyerTab || 1),
        buyerId: ((_get(dashboardFiltersSelection, "selectedBuyer.id", 0) !== 0) && (activeBuyerTab === 2)) ? _get(dashboardFiltersSelection, "selectedBuyer.id", 0) : null,
        sellerId: ((_get(dashboardFiltersSelection, "selectedSeller.id", 0) !== 0) && (activeBuyerTab === 1)) ? _get(dashboardFiltersSelection, "selectedSeller.id", 0) : null,
        sortBy: sortBy,
        sort: sortDirection,
        pageSize: 100,
        pageNo: pageNo
      });
    }
  }

  showProjectDashboard = (row) => {
    const { UpdateDashboardFilterSelection } = this.props;

    if (typeof UpdateDashboardFilterSelection === "function") {
      UpdateDashboardFilterSelection({ key: "selectedProject", value: { id: _get(row, "project_id", 0), project_name: _get(row, "project_name", "Select") } });
    }
  }

  _renderLeadData = () => {
    const { overallDashboardRecords } = this.state;

    return (
      <div className="col-lg-12">
        <div className="row gx-12">
          {((overallDashboardRecords || []).map((col, index) => {

            return (
              (col.role === "seller") && (
                <div className="col-lg-20 col-md-4" key={`headingOne${index}`} >
                  <div className="card p-20 mb-20 align-items-center justify-content-between">
                    <p className="heading-05 fw-medium text-primary">{_get(col, "dashboard_text", null)}
                    </p>
                    <h5 className="heading-07 lh-1 mt-15">{_get(col, "dashboard_number", null)}</h5>
                  </div>
                </div>
              )
            );

          }))}
        </div>
      </div>
    );
  }

  _renderBuyerQuotes = () => {
    const { displayDateFormat, currencySymbol, intl } = this.props;
    const { overallDashboardProjects, selectedProjects } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card p-15">
            <div className="d-flex flex-column flex-sm-row mb-15 gap-15">
              <h4 className="heading-03 fw-medium text-primary text-capitalize">
                <FormattedMessage id="seller_dashboard.overall.projects_accepted" defaultMessage="Projects Accepted" />
              </h4>

              <div className="ms-lg-auto">
                <IconDots height="20" width="4" color="#e84968" />
              </div>
            </div>
            <div className="project-list-wrap">
              <div className="h-500 table-responsive">
                {((overallDashboardProjects || []).length < 1) && (
                  <NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />
                )}

                {((overallDashboardProjects || []).length > 0) && (
                  (overallDashboardProjects || []).map((project, i) => {
                    const value = _get(project, "status", "").toUpperCase();
                    const statusClass = _get(projectStatusLookup, `[${value}.statusClass]`, "");

                    return (
                      <div key={i} className="project-list me-20">
                        <div className="project-list-checkbox">
                          <div className="form-check">
                            <input
                              className="form-check-input border"
                              type="checkbox"
                              value={_get(project, "project_id", 0)}
                              checked={((selectedProjects || []).includes(_get(project, "project_id", 0)))}
                              key={`checkbox-${_get(project, "project_id", 0)}`}
                              onChange={(e) => {
                                const isChecked = _get(e, "currentTarget.checked", false);
                                let finalSelectedProjects = _cloneDeep(selectedProjects);

                                if (isChecked) {
                                  if (!(finalSelectedProjects || []).includes(_get(project, "project_id", 0))) { finalSelectedProjects.push(_get(project, "project_id", 0)) }
                                } else {
                                  finalSelectedProjects.map((projectId, key) => {

                                    if ((projectId || 0) === _get(project, "project_id", 0)) {
                                      finalSelectedProjects.splice(key, 1);
                                    }
                                    return false;
                                  });
                                }

                                this.setState({ selectedProjects: (finalSelectedProjects || []) });
                              }}
                            />
                          </div>
                        </div>

                        <div className="project-list-card">
                          <p className="text-capitalize"><FormattedMessage id="dashboard.table.project_name" defaultMessage="Project Name" /></p>
                          <p className="cursor-pointer text-decoration-underline" onClick={() => this.showProjectDashboard(project)} title={_get(project, "project_name", "")}>{_get(project, "project_name", "")}</p>
                        </div>

                        <div className="project-list-card">
                          <p className="text-capitalize"><FormattedMessage id="dashboard.table.delivery_date" defaultMessage="Delivery Date" /></p>
                          <p>{(_get(project, "delivery_date", null)) ? moment(_get(project, "delivery_date", null)).format(displayDateFormat) : ""}</p>
                        </div>

                        <div className="project-list-card">
                          <p className="text-capitalize"><FormattedMessage id="dashboard.overall.leads_volume_required" defaultMessage="Volume Of Leads Required" /></p>
                          <p>{_get(project, "leads_volume", 0)}</p>
                        </div>

                        <div className="project-list-card">
                          <p className="text-capitalize"><FormattedMessage id="dashboard.cost_per_lead" defaultMessage="Cost per Lead" /></p>
                          <p>{`${currencySymbol}${_get(project, "cost_per_lead", 0)}`}</p>
                        </div>

                        <div className="project-list-card">
                          <p className="text-capitalize"><FormattedMessage id="dashboard.overall.budget" defaultMessage="Budget" /></p>
                          <p>{`${currencySymbol}${_get(project, "budget", 0)}`}</p>
                        </div>

                        <div className="project-list-card bg-success-f2">
                          <p className="text-dark text-capitalize"><FormattedMessage id="dashboard.table.status" defaultMessage="Status" /></p>
                          <p className={statusClass}>{_get(project, "status", null)}</p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="col-lg-12">

        {this._renderLeadData()}

        {this._renderBuyerQuotes()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overallDashboardRecords: _get(state, "leads.dashboard.overallDashboardRecords", []),
  overallDashboardProjects: _get(state, "leads.dashboard.overallDashboardProjects", []),
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", ""),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOverallDashboardProjects: (payload) => dispatch(fetchOverallDashboardProjects(payload)),
  fetchOverallDashboardRecords: (payload) => dispatch(fetchOverallDashboardRecords(payload)),
  UpdateDashboardFilterSelection: (data) => dispatch(UpdateDashboardFilterSelection(data)),
  resetOverallDashboardProjects: () => dispatch(resetOverallDashboardProjects()),
  resetOverallDashboardRecords: () => dispatch(resetOverallDashboardRecords()),
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OverallDashboard)));