import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ConfirmBox, ErrorMessage, showAlertMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { isValidEmail, isValidPhone } from "../../helpers/utils";
import { IconClose, IconSmDone } from "../../helpers/iconStyles";

import { updateLoadingState, fetchMyTeamData, resetMyTeamDataError } from "../../actions/application";
import { doValidateDisplayName, clearValidateDisplayName } from "../../actions/oauth";
import { addTeamMember, deleteTeamMember } from "../../services/application";

class MyTeam extends Component {
  constructor(props) {
    super(props);

    this.defaultCredentials = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      displayName: "",
      agreeTC: false
    };

    this.state = {
      confirmBoxOpen: false,
      delUserId: null,
      showAddMemberForm: false,
      memberList: [],
      errors: {},
      credentials: _cloneDeep(this.defaultCredentials),
      showDisplayNameLink: 1 // 1: show link || 2: show tick icon || 3: show close icon
    };
  }

  componentDidMount() {
    const { fetchMyTeamData, teamData } = this.props;

    if (typeof fetchMyTeamData === "function") { fetchMyTeamData(); }

    this.setState({ memberList: _get(teamData, "data", []) });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.credentials, this.state.credentials)) {
      this._handleValidation();
    }

    if (!_isEqual(_get(prevState, "credentials.displayName", ""), _get(this.state, "credentials.displayName", ""))) {
      this.setState({ showDisplayNameLink: 1 });
    }

    if (!_isEqual(prevProps.teamData, this.props.teamData) && !_isEmpty(this.props.teamData)) {
      const { teamData, resetMyTeamDataError } = this.props;

      if ((teamData.status || null) === null) { return false; }

      if ((teamData.status || false) === true) {

        this.setState({ memberList: _get(teamData, "data", []) });
      } else {
        showAlertMessage((teamData.message || "Something went wrong while fetching my team details."));

        if (typeof resetMyTeamDataError === "function") { resetMyTeamDataError(); }
      }
    }

    if (!_isEqual(prevProps.validateDisplayName, this.props.validateDisplayName) && !_isEmpty(this.props.validateDisplayName)) {
      const { validateDisplayName, clearValidateDisplayName } = this.props;

      if (_get(validateDisplayName, "status", false) === null) { return false; }

      if (_get(validateDisplayName, "status", false) === true) {
        showAlertMessage(_get(validateDisplayName, "data.message", "Display Name available."), "success");

        this.setState({ showDisplayNameLink: 2 });
      } else {
        showAlertMessage(_get(validateDisplayName, "message", "Something went wrong while checking display name."));

        this.setState({ showDisplayNameLink: 3 });
      }

      if (typeof clearValidateDisplayName === "function") { clearValidateDisplayName(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value
      },
      errorAlert: {},
    }));
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { credentials: { firstName, lastName, email, phoneNumber, displayName, password, confirmPassword, agreeTC } } = this.state;

    let errors = {};

    if (_isEmpty(firstName)) {
      errors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "first_name", defaultMessage: "first_name" }),
      });
    }

    if (_isEmpty(lastName)) {
      errors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "last_name", defaultMessage: "last_name" }),
      });
    }

    if (!isValidEmail(email)) {
      errors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if (!isValidPhone(phoneNumber)) {
      errors["phoneNumber"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "phone_number", defaultMessage: "phone number" }),
      });
    }

    if (_isEmpty(password)) {
      errors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" }),
      });
    }

    if (_isEmpty(displayName)) {
      errors["displayName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "display_name", defaultMessage: "Display Name" }),
      });
    }

    if (!_isEqual(password, confirmPassword)) {
      errors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} is no matched" }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "confirm password" })
      });
    }

    if (agreeTC === false) {
      errors["agreeTC"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}" }, {
        field: intl.formatMessage({ id: "terms_and_conditions", defaultMessage: "terms and conditions" }),
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errorAlert: {}, disabled: !_isEmpty(errors), errors });
  }

  submitAddMember = async () => {
    const { updateLoadingState, fetchMyTeamData } = this.props;
    const { credentials: { firstName, lastName, email, phoneNumber, displayName, password, agreeTC }, errors } = this.state;

    if (_isEmpty(email) || _isEmpty(password) || _isEmpty(firstName) || _isEmpty(lastName) || _isEmpty(phoneNumber) || !_isEmpty(errors)) {
      this._handleValidation();

      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await addTeamMember({
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phone": phoneNumber,
        "password": password,
        "agreeTc": agreeTC,
        "username": displayName
      });

      const resData = _get(response, "data", {})

      if ((resData.status || false) === true) {

        if (typeof fetchMyTeamData === "function") { fetchMyTeamData(); }

        showAlertMessage(_get(resData, "data.message", "Member added successfully."), "success");

        this.setState({
          errors: {},
          showAddMemberForm: false,
          credentials: _cloneDeep(this.defaultCredentials)
        });
      } else {

        showAlertMessage(_get(response, "message", "Something went wrong while adding team member."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while adding team member."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _doValidateDisplayName() {
    const { doValidateDisplayName } = this.props;
    const { credentials: { displayName } } = this.state;

    if (_isEmpty(displayName)) {
      showAlertMessage("Please enter display name");
      return;
    }

    if (typeof doValidateDisplayName === "function") {
      doValidateDisplayName({
        "username": displayName
      });
    }
  }

  deleteSelMember = async () => {
    const { updateLoadingState, fetchMyTeamData } = this.props;
    const { delUserId } = this.state;

    if (!delUserId) {
      showAlertMessage("Please select member which you want to delete.");

      return true;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await deleteTeamMember({ "userId": delUserId });

      const resData = _get(response, "data", {});

      if ((resData.status || false) === true) {

        if (typeof fetchMyTeamData === "function") { fetchMyTeamData(); }

        showAlertMessage(_get(resData, "data.message", "Member deleted successfully."), "success");

        this.setState({ confirmBoxOpen: false, delUserId: null, showAddMemberForm: false });
      } else {
        showAlertMessage(_get(resData, "data.message", "Something went wrong while deleting team member."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while deleting team member."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderMemberList = () => {
    const { memberList } = this.state;

    return (
      <div className="row ps-lg-45 s-lg-15">
        <div className="col-lg-12">
          {(memberList || []).map((m, index) => {

            return (
              <div key={`team-${(m.user_id || null)}`} className="row align-items-center">
                <div className="col-lg-3">
                  <div className="mb-18">
                    <input type="text" className="form-control" name={`firstname-${(index)}`} placeholder="first_name" defaultValue={(m.first_name || "")} readOnly />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="mb-18">
                    <input type="text" className="form-control" name={`lastname-${(index)}`} placeholder="last_name" defaultValue={(m.last_name || "")} readOnly />
                  </div>
                </div>
                <div className="col-lg-5 col-10">
                  <div className="mb-18">
                    <input type="text" className="form-control" name={`email-${(index)}`} placeholder="email" defaultValue={(m.email || "")} readOnly />
                  </div>
                </div>
                <div className="col-lg-1 col-2">
                  <div className="mb-18 cursor-pointer" onClick={() => this.setState({ confirmBoxOpen: true, delUserId: (m.user_id || null) })}>
                    <IconClose height="17" width="17" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _renderAddMemberPersonal = () => {
    const { errors, showDisplayNameLink, credentials: { firstName, lastName, email, phoneNumber, displayName, password, confirmPassword } } = this.state;

    return (
      <div className="row mt-18">

        <div className="col-lg-12">
          <p className="mb-16 heading-04 text-capitalize">
            <FormattedMessage id="signup.personal_details" defaultMessage="Personal Details" />
          </p>
        </div>
        <div className="col-lg-3">
          <div className="mb-16">
            <input type="text" name="firstName" value={(firstName || "")} className="form-control" placeholder="First Name" onChange={(e) => this._handleChange(e)} />
            {(errors.firstName || "") && (<ErrorMessage message={(errors.firstName || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16">
            <input type="text" name="lastName" value={(lastName || "")} className="form-control" placeholder="Last Name" onChange={(e) => this._handleChange(e)} />
            {(errors.lastName || "") && (<ErrorMessage message={(errors.lastName || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16">
            <input type="email" name="email" value={(email || "")} className="form-control" placeholder="Email" onChange={(e) => this._handleChange(e)} />
            {(errors.email || "") && (<ErrorMessage message={(errors.email || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16">
            <input type="text" name="phoneNumber" value={(phoneNumber || "")} className="form-control" placeholder="Phone Number" onChange={(e) => this._handleChange(e)} />
            {(errors.phoneNumber || "") && (<ErrorMessage message={(errors.phoneNumber || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16 position-relative">
            {((showDisplayNameLink || 1) === 3) && (
              <span className="mt-10 me-16 position-absolute top-0 end-0">
                <IconClose height="18px" width="18px" color="#d50100" />
              </span>
            )}

            {((showDisplayNameLink || 1) === 2) && (
              <span className="mt-10 me-16 position-absolute top-0 end-0">
                <IconSmDone height="18px" width="18px" color="#198754" />
              </span>
            )}

            {((showDisplayNameLink || 1) === 1) && (
              <div className="mt-10 me-16 position-absolute top-0 end-0">
                <span className="heading-05 text-decoration-underline text-primary cursor-pointer" onClick={() => this._doValidateDisplayName()}>
                  <FormattedMessage id="btn.check_display_name" defaultMessage="check availability" />
                </span>
              </div>
            )}

            <input type="text" name="displayName" value={(displayName || "")} className="form-control" placeholder="Display Name" onChange={(e) => this._handleChange(e)} />
            {(errors.displayName || "") && (<ErrorMessage message={(errors.displayName || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16">
            <input type="password" name="password" value={(password || "")} className="form-control" placeholder="Password" onChange={(e) => this._handleChange(e)} />
            {(errors.password || "") && (<ErrorMessage message={(errors.password || "")} />)}
          </div>
        </div>

        <div className="col-lg-3">
          <div className="mb-16">
            <input type="password" name="confirmPassword" value={(confirmPassword || "")} className="form-control" placeholder="Confirm Password" onChange={(e) => this._handleChange(e)} />
            {(errors.confirmPassword || "") && (<ErrorMessage message={(errors.confirmPassword || "")} />)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { companyName, intl } = this.props;
    const { confirmBoxOpen, showAddMemberForm, errors, credentials: { agreeTC } } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.my_team", defaultMessage: "My Team - Leads United" })} </title>
        </Helmet>
        <div>
          <div className="mb-20">
            <h1 className="heading-02 fw-medium">
              <FormattedMessage id="my_team.title" defaultMessage="My Team" />
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-5">
                  <div className="mb-18">
                    <input type="text" className="form-control" name="companyname" defaultValue={(companyName || "")} readOnly />
                  </div>
                </div>
                <div className="col-lg-12">
                  {this._renderMemberList()}

                  <div className="row mt-18">
                    <div className="col-lg-12">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-uppercase"
                        onClick={(e) => this.setState({ showAddMemberForm: !showAddMemberForm })}
                        aria-expanded="false"
                      >
                        <FormattedMessage id="my_team.add_member" defaultMessage="add member" />
                      </button>

                      <Collapse in={(showAddMemberForm || false)}>
                        <div>
                          {this._renderAddMemberPersonal()}

                          <div className="row mb-16">
                            <div className="col-lg-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={(agreeTC || false)}
                                  id="flexCheckDefault"
                                  checked={(agreeTC || false)}
                                  onChange={(e) => {
                                    const isChecked = _get(e, "currentTarget.checked", false);

                                    this.setState((prevState) => ({
                                      credentials: { ...prevState.credentials, agreeTC: isChecked },
                                      errorAlert: {},
                                    }));
                                  }}
                                />
                                <label className="form-check-label heading-05" htmlFor="flexCheckDefault">
                                  <FormattedMessage id="signup.agree_term_and_condition" defaultMessage="I agree to the terms and conditions" />
                                </label>
                                {(errors.agreeTC || "") && (<ErrorMessage className="text-danger mt-5 text-capitalize-first" message={(errors.agreeTC || "")} />)}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="text-end">
                                <button className="btn btn-sm btn-primary" onClick={(e) => this.submitAddMember()}>
                                  <FormattedMessage id="btn.save" defaultMessage="save" />
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={(confirmBoxOpen || false)}
          content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure want to delete {field}?" }, {
            field: intl.formatMessage({ id: "my_team.team_member", defaultMessage: "team member" })
          })}
          onConfirm={() => this.deleteSelMember()}
          onClose={() => this.setState({ confirmBoxOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyName: _get(state, "oauth.user.company", ""),
  teamData: _get(state, "application.myTeam.data", {}),
  validateDisplayName: _get(state, "oauth.validateDisplayName", {})
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  resetMyTeamDataError: () => dispatch(resetMyTeamDataError()),
  fetchMyTeamData: () => dispatch(fetchMyTeamData()),
  doValidateDisplayName: (data) => dispatch(doValidateDisplayName(data)),
  clearValidateDisplayName: () => dispatch(clearValidateDisplayName()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyTeam)));
