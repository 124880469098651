import React, { Component, Fragment } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { ErrorMessage, EmptyLabel, showAlertMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconLeftCaret } from "../../../helpers/iconStyles";
import { downloadAnyFile } from "../../../helpers/utils";

import { downloadFile } from "../../../services/application";
import { updateLoadingState } from "../../../actions/application";

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeTC: false,
      errors: {}
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.agreeTC, _get(this.state, "agreeTC", false))) {
      const { intl } = this.props;
      let errors = {};

      if (_get(this.state, "agreeTC", false) === false) {
        errors["agreeTC"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}" }, {
          field: intl.formatMessage({ id: "this_box", defaultMessage: "this box" }),
        });
      }

      this.setState({ errors: errors });
    }
  }

  _handleNext = () => {
    const { submitBuyLeadsRequest, intl } = this.props;
    const { agreeTC } = this.state;

    let errors = {};

    if (agreeTC === false) {
      errors["agreeTC"] = intl.formatMessage({ id: "error.select", defaultMessage: "Please select {field}" }, {
        field: intl.formatMessage({ id: "this_box", defaultMessage: "this box" }),
      });
    }

    if (!_isEmpty(errors)) {
      this.setState({ errors: errors });
      return false;
    }

    if (typeof submitBuyLeadsRequest === "function") {
      submitBuyLeadsRequest();
    }
  }

  _resetParentAllStates = () => {
    const { setState } = this.props;

    setState({ showForm: true, showPreview: false, showSuccess: false });
  }

  downloadFile = async (fileURL) => {
    const { updateLoadingState } = this.props;

    if (!fileURL) {
      showAlertMessage("No file uploaded.");
      return false;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await downloadFile({ fileUrl: fileURL });

      if ((response.flag || false) === true) {

        const downloadFileDetails = _get(response, "data", []);

        const contentType = (downloadFileDetails.contentType || "");
        const contents = (downloadFileDetails.contents || "");
        const filename = (downloadFileDetails.fileName || "");

        if ((contentType !== "") && (contents !== "") && (filename !== "")) {
          downloadAnyFile(contentType, contents, filename);
        }

      } else {
        showAlertMessage(response.message || "Something went wrong while downloading template file.");
      }

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while downloading file.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderTAL = () => {
    const { formData } = this.props;

    return (
      <Fragment>
        <div className="mt-16 mb-8">
          <h6 className="heading-05 fw-medium text-primary">
            <FormattedMessage id="app_buy_leads.title.tal_suppression" defaultMessage="TAL/Suppression" />
          </h6>
        </div>
        <div className="row g-8">
          <div className="col-6">
            <div className="text-start">
              <p><FormattedMessage id="app_buy_leads.tal_suppression.tal_upload" defaultMessage="TAL Upload" /></p>
            </div>
          </div>

          <div className="col-6 mb-10">
            <div className="text-end">
              {(_get(formData, "uploadedFileURLCloned", "") !== "") ? (
                <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadFile(_get(formData, "uploadedFileURLCloned", ""))}>
                  <FormattedMessage id="app_buy_leads.download_files" defaultMessage="Download Files" />
                </button>
              ) : (<p> - </p>)}
            </div>
          </div>

          <div className="col-6">
            <div className="text-start">
              <p><FormattedMessage id="app_buy_leads.tal_suppression.suppression_upload" defaultMessage="Suppression Upload" /></p>
            </div>
          </div>
          <div className="col-6">
            <div className="text-end">
              {(_get(formData, "uploadedFileURL", "") !== "") ? (
                <button type="button" className="btn btn-sm btn-primary px-20" onClick={() => this.downloadFile(_get(formData, "uploadedFileURL", ""))}>
                  <FormattedMessage id="app_buy_leads.download_files" defaultMessage="Download Files" />
                </button>
              ) : (<p> - </p>)}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  _renderAudienceTargeting = () => {
    const { formData } = this.props;
    const { activeCriteria = 0 } = this.state;

    return (
      <Fragment>
        {(_get(formData, "audienceTargeting", [])).map((audience, i) => {

          return (
            <div key={`preview${i}`} className="accordion">
              <div className="border-bottom mb-0">
                <div className="accordion-header">
                  <button className={`accordion-button heading-05 fw-medium px-0 py-10 text-capitalize ${(activeCriteria !== i) ? "collapsed" : ""}`} type="button" onClick={() => (activeCriteria === i) ? this.setState({ activeCriteria: null }) : this.setState({ activeCriteria: i })}>
                    <FormattedMessage id="app_buy_leads.criteria" defaultMessage="criteria {number}" values={{ number: (i + 1) }} />
                  </button>
                </div>
                <div key={`accordion-preview${i}`} className={`accordion-collapse ${(activeCriteria !== i) ? "collapse" : ""}`}>
                  <div key={`accordion-body${i}`} className="accordion-body p-0 pb-12">
                    <div className="row g-8">
                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.region" defaultMessage="Region" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.region", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.countries_to_target" defaultMessage="Countries to Target" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{(_get(audience, "criteria.countries", null) !== null) && _get(audience, "criteria.countries", []).join(", ")}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.industries" defaultMessage="Industries" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{(_get(audience, "criteria.industries", null) !== null) && _get(audience, "criteria.industries", []).join(", ")}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.employee_sizes" defaultMessage="Employee Sizes" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{(_get(audience, "criteria.employee_sizes", null) !== null) && _get(audience, "criteria.employee_sizes", []).join(", ")}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.job_function" defaultMessage="Job Function" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.job_function", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.job_level" defaultMessage="Job Level" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.job_level", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.job_keyword" defaultMessage="Job Keyword" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.job_keyword", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.custom_criteria" defaultMessage="Custom Criteria" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.custom_criteria", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.languages" defaultMessage="Languages" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{(_get(audience, "criteria.languages", null) !== null) && _get(audience, "criteria.languages", []).join(", ")}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.leads_per_org_per_country" defaultMessage="Leads per Org per Country" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.leads_per_org_per_country", <EmptyLabel />)}</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="text-start text-capitalize">
                          <p><FormattedMessage id="app_buy_leads.job_titles" defaultMessage="Job Titles" /></p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-end text-capitalize">
                          <p>{_get(audience, "criteria.job_titles", <EmptyLabel />)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }

  _renderLeadInformation = () => {
    const { formData, displayDateFormat, currencySymbol } = this.props;

    return (
      <Fragment>
        <div className="row g-8 mt-1">
          <div className="col-6">
            <div className="text-start text-capitalize">
              <p><FormattedMessage id="app_buy_leads.project_name" defaultMessage="project name" /></p>
            </div>
          </div>
          <div className="col-6">
            <div className="text-end text-capitalize">
              <p>{_get(formData, "projectName", <EmptyLabel />)}</p>
            </div>
          </div>

          <div className="col-6">
            <div className="text-start text-capitalize">
              <p><FormattedMessage id="app_buy_leads.volume_of_leads_required" defaultMessage="Volume of Leads Required" /></p>
            </div>
          </div>
          <div className="col-6">
            <div className="text-end text-capitalize">
              <p>{_get(formData, "leadsVolume", <EmptyLabel />)}</p>
            </div>
          </div>

          <div className="col-6">
            <div className="text-start text-capitalize">
              <p><FormattedMessage id="app_buy_leads.delivery_date" defaultMessage="Delivery Date" /></p>
            </div>
          </div>
          <div className="col-6">
            <div className="text-end text-capitalize">
              <p>{((moment(formData.deliveryDate).isValid() === true) ? (moment(formData.deliveryDate).format(displayDateFormat)) : (<EmptyLabel />))}</p>
            </div>
          </div>

          <div className="col-6">
            <div className="text-start text-capitalize">
              <p><FormattedMessage id="app_buy_leads.budget" defaultMessage="budget" /></p>
            </div>
          </div>
          <div className="col-6">
            <div className="text-end text-capitalize">
              <p>{`${currencySymbol}${_get(formData, "budget", <EmptyLabel />)}`}</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  _renderSelectedDataFields = () => {
    const { formData } = this.props;

    return (
      <div className="border-bottom mb-0">
        <div className="mt-16 mb-8">
          <h6 className="heading-05 fw-medium text-primary">
            <FormattedMessage id="app_buy_leads.data_fields.title" defaultMessage="Select the data you would like to buy" />
          </h6>
        </div>
        <div className="p-0 pb-12">
          <div className="row g-8">
            <div className="col-4">
              <div className="text-start text-capitalize">
                <p><FormattedMessage id="app_buy_leads.selected_data_fields" defaultMessage="Selected Data Fields" /></p>
              </div>
            </div>
            <div className="col-8">
              <div className="text-end text-capitalize">
                <p>{_get(formData, "selDataFieldNames", []).join(", ")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { formData } = this.props;
    const { agreeTC, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="card p-15 mb-20 w-100">
              <div className="mb-15">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="heading-06">
                      <div className="mb-8">
                        <h6 className="heading-05 fw-medium text-primary text-capitalize">
                          <FormattedMessage id="app_buy_leads.preview.page_title" defaultMessage="Preview" />
                        </h6>
                      </div>
                      {this._renderLeadInformation()}

                      <div className="mt-16 mb-12">
                        <h6 className="heading-05 fw-medium text-primary text-capitalize">
                          <FormattedMessage id="app_buy_leads.title.audience_targeting" defaultMessage="Audience Targeting" />
                        </h6>
                      </div>

                      {this._renderAudienceTargeting()}
                      {this._renderSelectedDataFields()}
                      {this._renderTAL()}
                    </div>
                    <div className="mt-30">
                      <div>
                        <p><FormattedMessage id="app_buy_leads.preview.title" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua." /></p>
                        <div className="form-check mt-36">
                          <input
                            className="form-check-input border"
                            type="checkbox"
                            value={(agreeTC || false)}
                            id="flexCheckDefault"
                            checked={(agreeTC || false)}
                            onChange={(e) => {
                              const isChecked = _get(e, "currentTarget.checked", false);

                              this.setState({ agreeTC: isChecked });
                            }}
                          />
                          <label className="form-check-label heading-05" htmlFor="flexCheckDefault">
                            <FormattedMessage id="app_buy_leads.preview.sub_title" defaultMessage="By ticking the box, you acknowledge that if campaign assets and copy are not delivery on time, your campaign may be delayed by up to 10 bussiness days. *" />
                          </label>
                          {(errors.agreeTC || "") && (<ErrorMessage className="text-danger mt-5 text-capitalize-first" message={(errors.agreeTC || "")} />)}
                        </div>
                        <div className="row g-8 mt-25 position-relative">
                          <div className="col-6">
                            <div className="text-start">
                              <button className="btn btn-md btn-primary" onClick={() => this._resetParentAllStates()}>
                                <FormattedMessage id="btn.back" defaultMessage="back" />
                                <div className="img-fluid position-absolute top-50 start-0 translate-middle-y ms-20">
                                  <IconLeftCaret height="17" width="17" color="#FFFFFF" />
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className="col-6 mb-10">
                            <div className="text-end">
                              <button className="btn btn-md btn-primary" onClick={() => this._handleNext()}>
                                {(_get(formData, "buyLeadsId", null) !== null) ?
                                  (<FormattedMessage id="btn.update" defaultMessage="update" />) :
                                  (<FormattedMessage id="btn.submit" defaultMessage="submit" />)}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  currencySymbol: _get(state, "application.constants.currencySymbol", "$ ")
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Preview)));
