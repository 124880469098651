export default {

  // Page Titles
  "LU.home": "Leads United - A Unique B2B Lead Generation Platform",
  "LU.login": "Login - Leads United",
  "LU.sign_up": "Sign Up - Leads United",
  "LU.contact": "Contact - Leads United",
  "LU.about_us": "About Us - Leads United",
  "LU.pricing": "Pricing - Leads United",
  "LU.buy_leads": "Buy Leads - Leads United",
  "LU.sell_leads": "Sell Leads - Leads United",
  "LU.integrate": "Integrate - Leads United",
  "LU.customers": "Customers - Leads United",
  "LU.forgot_password": "Forgot Password - Leads United",
  "LU.reset_password": "Reset Password - Leads United",
  "LU.generic_page.comming_soon": "Comming soon - Leads United",
  "LU.generic_page.not_found": "Page not found - Leads United",
  "LU.api_keys": "API Keys - Leads United",
  "LU.my_team": "My Team - Leads United",
  "LU.dashboard": "Dashboard - Leads United",
  "LU.my_invoice": "My Invoice - Leads United",
  "LU.market_place": "Marketplace - Leads United",
  "LU.payment_success": "Payment Success - Leads United",
  "LU.payment_failed": "Payment Failed - Leads United",
  "LU.view_quote_data": "View Quote Data - Leads United",
  "LU.settings": "Settings - Leads United",
  "LU.user_management": "User Management - Leads United",
  "LU.profile_review": "Profile review",

  // Common texts
  "email": "email",
  "username": "username",
  "password": "password",
  "first_name": "first name",
  "last_name": "last name",
  "phone_number": "phone number",
  "new_password": "new password",
  "old_password": "old password",
  "confirm_password": "confirm password",
  "privacy_policy": "privacy policy",
  "terms_and_conditions": "terms and conditions",
  "change_password": "change password",
  "logout": "Logout",
  "empty": "empty",
  "company": "company",
  "name": "name",
  "coming_soon": "coming soon",
  "under_constuction": "This page is under construction.",
  "contact": "contact",
  "sign_up": "Sign Up",
  "blog": "blog",
  "filter_by": "Filter By",
  "more": "more...",
  "address_line_1": "Address Line 1",
  "address_line_2": "Address Line 2",
  "city": "city",
  "state": "state",
  "county": "County",
  "postcode": "Postcode",
  "country": "Country",
  "api": "API",
  "dashboard": "dashboard",
  "message": "message",
  "select": "select",
  "sort_by": "sort by",
  "profile_details": "Profile Details",
  "this_box": "this box",
  "edit": "edit",
  "mark_as_complete": "mark as complete",
  "mark_as_on_hold": "mark as on hold",
  "mark_as_active": "mark as active",
  "delete": "delete",
  "display_name": "Display Name",
  "buy_leads": "BUY LEADS",
  "sell_leads": "SELL LEADS",
  "role": "Role",
  "settings": "settings",
  "fields": "fields",
  "note": "note",
  "project": "project",
  "quote": "quote",
  "lead": "lead",
  "view": "view",
  "batch": "batch",
  "seller": "seller",
  "user": "user",
  "dropdown.dots": "•••",
  "card_no": "Card No",
  "expiry_date": "Expiry Date",
  "cvv": "CVV",

  // Common labels
  "label.select_field": "select {field}",
  "label.yes": "yes",
  "label.no": "no",

  // Modal
  "modal.confirm": "confirm",
  "modal.alert": "alert !",

  // Record count display
  "showing_records_of_total": "showing {records} out of {total}",

  //Menu
  "menu.home": "home",
  "menu.solutions": "solutions",
  "menu.solutions.buy_leads": "buy leads",
  "menu.solutions.sell_leads": "sell leads",
  "menu.solutions.integrate": "integrate",
  "menu.solutions.enrich": "enrich",
  "menu.pricing": "pricing",
  "menu.customers": "customers",
  "menu.company": "company",
  "menu.company.about_us": "about us",
  "menu.company.blog": "blog",
  "menu.company.contact": "contact",
  "menu.login": "login",
  "menu.sign_up": "sign up now",
  "menu.dashboard": "Dashboard",
  "menu.settings": "Settings",
  "menu.logout": "Logout",

  // Admin Menu
  "menu.admin.marketplace": "Marketplace",
  "menu.admin.dashboard": "Dashboard",
  "menu.admin.buy_leads": "Buy Leads",
  "menu.admin.sell_leads": "Sell Leads",
  "menu.admin.enrich": "Enrich",
  "menu.admin.api": "API",
  "menu.admin.my_team": "My Team",
  "menu.admin.my_invoice": "My Invoice",
  "menu.admin.user_management": "User Management",
  "menu.admin.settings": "Settings",

  //Footer
  "footer.copyright_text": "Copyright © 2023 Leads United Ltd. All rights reserved | ",
  "footer.terms_and_conditions": "Terms & Conditions",
  "footer.admin.copyright_text": "Copyright © 2023 Leads United Ltd. All rights reserved | ",
  "footer.admin.terms_and_conditions": "Terms & Conditions",

  //Show permission errors
  "permission.page_error": "You don't have permission to visit this page.",
  "permissions.campaign_content.unauthorized": "campaign content listing",
  "permissions.unauthorized.description": "Sorry, You don't have permission to access {title}.",

  // error validation messages
  "error.required": "{field} is required.",
  "error.are_required": "{field} are required.",
  "error.select": "Please select {field}.",
  "error.select_atleast_one": "Please select at least one {field}.",
  "error.enter": "Please enter {field}.",
  "error.valid": "Please enter valid {field}.",
  "error.invalid": "Invalid {field}.",
  "error.password_not_match": "{field} does not match.",
  "error.old_new_password_match": "{field} is same as old password.",
  "error.password_min_char": "Password should contains minimum 8 characters.",
  "error.password_valid_char": "Password should contain at least 3 of a-z or A-Z and a number and a special character.",
  "error.exact_number_field": "Please enter {value} number {field}.",
  "error.less_than_value": "{field} should be less than {value}.",
  "error.atleast_value": "{field} should be atleast {value}.",
  "error.something_error": "There is some error inside the fields.",
  "error.less_than_value_character": "{field} should be less than {value} characters.",
  "error.after_start_date": "End date field should be after start date",
  "error.after_start_time": "End time should be after start time",
  "error.after_today": "End date should be today or future date.",
  "error.start_after_today": "Start date should be today or future date.",
  "error.date_after_today": "Date should be today or future date.",
  "error.greatereq_than_value": "{field} should be greater than or equal to {value}.",
  "error.image_upload": "Image not uploaded successfully",
  "error.after_date": "{field} should be greater than or equal to current date",
  "error.any_one_option_required": "Anyone option should be enable for {field}",
  "error.not_matched_with_val": "{field} not matched with {value}",
  "error.please_remove_uploaded_file": "Please remove uploaded file",

  // Success message
  "success.updated_successfully": "{field} updated successfully.",
  "success.lead_status_updated_success": "Lead {status} successfully",

  //Placeholder
  "placeholder.select_field": "Select {field}",
  "placeholder.enter_field": "Enter {field}",
  "placeholder.please_enter_field": "Please enter {field}",
  "placeholder.fix_field": "Fix {field}",
  "placeholder.search": "search",

  // Message
  "message.no_found_data": "no data found",
  "message.no_records_found": "no records found",
  "message.this_action_will_reset_all_your_form_data": "Note: This action will reset all your form data",
  "message.something_went_wrong_while_removing_file": "Something went wrong while removing file.",
  "message.file_removed_successfully": "File removed successfully.",
  "message.data_check": "No data uploaded for selected quote/batch.",
  "message.data_acceptance": "Required leads are not supplied yet. You will be able to accept/view data acceptance once all leads supplied.",
  "message.fund_release": "Required leads are not supplied yet. You will be able to release/view fund details once all leads supplied.",
  "message.project_completion": "Required leads are not supplied yet. You will be able to complete/view project completion details once all leads supplied.",
  "message.no_quote": "No quote has been submitted yet",
  "message.no_funds_deposit": "No funds has been deposited yet",

  // Confirm message
  "confirm.are_you_sure": "Are you sure want to {field}?",
  "confirm.are_you_sure_delete": "Are you sure want to delete {field}?",
  "confirm.are_you_sure_cancel": "Are you sure want to cancel {field}?",
  "confirm.are_you_sure_under_review": "Are you sure you want to under review {field}?",
  "confirm.are_you_sure_approve": "Are you sure you want to approve {field}?",
  "confirm.are_you_sure_reject": "Are you sure you want to reject {field}?",
  "confirm.are_you_sure_suspend": "Are you sure you want to suspend {field}?",
  "confirm.are_you_sure_want_to_change_upload_type": "Are you sure want to change upload type?",
  "confirm.are_you_sure_reset_password": "Are you sure you want to reset password for {field}?",
  "confirm.are_you_sure_lock": "Are you sure you want to lock {field}?",
  "confirm.are_you_sure_unlock": "Are you sure you want to unlock {field}?",
  "confirm.are_you_sure_restore": "Are you sure you want to restore {field}?",

  // Buttons
  "btn.close": "close",
  "btn.cancel": "cancel",
  "btn.submit": "submit",
  "btn.update": "update",
  "btn.reset": "reset",
  "btn.next": "next",
  "btn.back": "back",
  "btn.skip": "skip",
  "btn.save": "save",
  "btn.ok": "ok",
  "btn.reject": "reject",
  "btn.delete": "delete",
  "btn.yes": "yes",
  "btn.no": "no",
  "btn.continue": "continue",
  "btn.your_dashboard": "your dashboard",
  "btn.reset_section": "reset {section}",
  "btn.create_section": "create {section}",
  "btn.update_section": "update {section}",
  "btn.mark_as_action": "mark as {action}",
  "btn.accept": "accept",
  "btn.send": "send",
  "btn.action": "action",
  "btn.check_display_name": "check availability",
  "btn.payment_btn": "Approve & Pay Now",
  "btn.release_funds": "release funds",
  "btn.edit_quote": "Edit Quote",
  "btn.cancel_quote": "Cancel Quote",
  "btn.download": "download",
  "btn.seller_profile": "Seller Profile",
  "btn.contact_seller": "Contact Seller",
  "btn.contact_buyer": "Contact Buyer",
  "btn.download_data": "download data",
  "btn.approve_data": "approve data",
  "btn.reject_data": "reject data",
  "btn.approve_leads": "approve leads",
  "btn.reject_leads": "reject leads",
  "btn.raise_support_ticket": "raise support ticket",
  "btn.export": "export",
  "btn.filters": "filters",
  "btn.reset_filters": "reset filters",

  //Login page
  "login.title": "Login",
  "login.remember_me": "Remember me",
  "login.forgot_link": "forgot password?",
  "login.sign_up_link": "Not registered? Create an account",

  // SignUp page
  "signup.title": "Sign Up",
  "signup.login_link": "Already registerd? Login here",
  "signup.personal_details": "personal details",
  "signup.billing_details": "billing details",
  "signup.add_card": "add card",
  "signup.add_card_info": "We won't touch your card details. Your payment method is securely stored and handled by our payment partner Stripe. By adding your card here, you allow us to use your payment method for qualified recurring payments.",
  "signup.agree_term_and_condition": "I agree to the terms and conditions",

  //Forgot Password Page
  "forgot_password.title": "Forgot password",
  "forgot_password.login_link": "Back to login",

  //Reset Password Page
  "reset_password.title": "Reset password",
  "reset_password.show_password": "show password",
  "reset_password.hide_password": "hide password",

  // home page
  "home_old.banner.title": "<span className='text-primary'>Connecting</span> Quality Businesses to Quality Leads",
  "home_old.banner.sub_title1": "acquire quality leads",
  "home_old.banner.sub_title2": "lower your marketing costs",
  "home_old.banner.sub_title3": "grow your business",
  "home_old.info.heading": "Leads United - A Unique B2B Lead Generation Platform",
  "home_old.info.heading2": "Leads United is a unique online lead generation system that is in its own new marketing product category. Connect globally with other smart business owners for free, and generate more leads now! lead generation platform systematically matches quality sold leads to businesses by comparing the lead sold to the company’s criteria.",
  "home_old.info.heading3": "Simply stated, Leads United instantly turns one business’ unused lead into a client for another business. Rest assured you will receive a lead only if it fits your criteria, or else we will always stand behind our Lead Quality Guarantee.",
  "home_old.process.title": "Bridging the Gap to Reduce Your Marketing Costs",
  "home_old.process.sub_title1": "Effectively Recycle <br />Your Unused Leads",
  "home_old.process.buy_leads": "buy leads",
  "home_old.process.sub_title2": "Obtain Quality <br />Leads",
  "home_old.process.sell_leads": "sell leads",
  "home_old.process.sub_title3": "Integrate with <br />API",
  "home_old.process.integrate": "integrate",
  "home_old.why.title": "Why Leads United?",
  "home_old.why.sub_title": "Whether dropping or buying quality leads, we are passionate about having the best customer engagement. Leads Market has unmatched capabilities and a history of adapting the product to the end user's needs that maximize efficiency and results.",
  "home_old.why.feature1": "Lead Quality Guarantee",
  "home_old.why.feature2": "Complete Flexibility",
  "home_old.why.feature3": "Support 24/7/365",

  // home page
  "home.banner.title": "The comparison platform for sales leads",
  "home.banner.sub_title": "More choice. More value. More results",
  "home.banner.sub_title2": "Get the best price for targeted B2B company and contact data from hundreds of providers",
  "home.banner.simply": "Simply",
  "home.banner.note1": "Select what type of leads you need",
  "home.banner.note2": "Get matched with the right suppliers",
  "home.banner.note3": "Take your pick from the best bids",
  "home.banner.note4": "Gain access to the decision makers you want to do business with",
  "home.banner.free_note": "It’s free to use and there’s no pressure to purchase",
  "home.why.title": "Why Leads United?",
  "home.why.sub_title": "We’re unique to the market. This platform cuts out the conventional route of briefing one agency and accepting their results. Instead you get the tools to be in charge and the freedom of choice from hundreds of great vendors. You’ll only ever be greeted by verified quality results with transparent pricing.",
  "home.why.feature_title1": "Pay less for leads",
  "home.why.feature_description1": "By cutting out the middleman it’s much easier and cheaper to access targeted data each time you need it. Bid prices will be more competitive in an open market and give you more room to negotiate when needed on price",
  "home.why.feature_title2": "Improved data accuracy",
  "home.why.feature_description2": "Buyers are only matched with sellers that fit the brief. All sales data is validated by trusted external sources to ensure it is accurate, fresh and legally compliant. Any bad data will be spotted by us before it reaches you.",
  "home.why.feature_title3": "Total transparency",
  "home.why.feature_description3": "All seller bids are transparent with history, ratings and actions. Payments are held securely and only released once buyers have received their approved data.",
  "home.leads_to_sell.title": "Leads to sell?",
  "home.leads_to_sell.sub_title": "Come and join our global platform and connect with active buyers. We'll alert you every time someone is searching for leads that match your data. It’s a great way to effectively recycle your unused quality leads.",
  "home.feature.note1": "High-quality leads",
  "home.feature.note2": "Complete flexibility",
  "home.feature.note3": "Support 24/7",

  // Pricing page
  "pricing.banner.title": "<span className='text-primary'>Easy</span>, transparent pricing",
  "pricing.banner.sub_title": "For everyone",
  "pricing.banner.buyer_heading": "FREE for buyers",
  "pricing.banner.buyer_content": "There is no cost for anyone using Leads United to purchase leads. Buyers can use all the services on our platform for free. Enabling you to find a price that’s right, with no added charges.",
  "pricing.banner.buyer_list1": "Compare prices, no pressure to purchase",
  "pricing.banner.buyer_list2": "Easier access to multiple lead generation services",
  "pricing.banner.buyer_list3": "Our ‘hold-until-you’re-happy’ money transfer",
  "pricing.banner.buyer_list4": "No bad data returns (we’ll sniff out any duds before they reach you)",
  "pricing.banner.seller_heading": "Pay-as-you-go £99 per month, for sellers",
  "pricing.banner.seller_content": "Join our one-plan, no-contract subscription. Payment only starts after your first sale is made.",
  "pricing.banner.seller_list1": "Pay nothing until your first sale",
  "pricing.banner.seller_list2": "No contract, cancel anytime",
  "pricing.banner.seller_list3": "No setup or hidden fees",
  "pricing.banner.seller_list4": "Hundreds of potential clients",
  "pricing.banner.get_started": "Get Started",
  "pricing.banner.price_title": "$99 <span>/month</span>",
  "pricing.banner.price_title2": "Cancel anytime",
  "pricing.banner.price_title3": "Monthly subscription",
  "pricing.banner.price_title4": "Buy verified leads",
  "pricing.banner.price_title5": "Sell verified leads",
  "pricing.banner.price_title6": "Integrate with API",
  "pricing.feature.title": "Features",
  "pricing.feature.feature1": "Effectively Recycle Your Unused Leads",
  "pricing.feature.feature2": "Obtain Quality Leads",
  "pricing.feature.feature3": "Integrate with API",
  "pricing.feature.feature4": "Leads are verified",
  "pricing.feature.feature5": "Encrypted file transfer",
  "pricing.feature.feature6": "24/7 support",

  // Customers page
  "customers.banner.title": "customers",
  "customers.banner.sub_title": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem quam non quia voluptatem qui nihil natus ut molestiae molestiae. Qui labore natus vel minima laudantium est ipsam pariatur. Qui aperiam sequi ad suscipit asperiores nam soluta tempore. Sit doloribus nostrum sit nihil perspiciatis qui totam veritatis est ipsa tempora non repellendus eligendi rem voluptatem quasi.",
  "customers.banner.contact_us": "contact us",
  "customers.customer.title": "customers",

  // Contact-US page
  "contact.title": "Contact Us",
  "contact.sub_title": "Contact us to enquire or ask any questions – we are here to help at all times.",

  // profile-under-review page
  "profile_review.title": "Profile {field}",
  "profile_review.content": "Your profile is {field}, You will be get full access once your profile approved by admin.",
  "profile_review.content2": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem quam non quia voluptatem qui nihil natus ut molestiae molestiae. Qui labore natus vel minima laudantium est ipsam pariatur. Qui aperiam sequi ad suscipit asperiores nam soluta tempore. Sit doloribus nostrum sit nihil perspiciatis qui totam veritatis est ipsa tempora non repellendus eligendi rem voluptatem quasi.",

  // About-US page
  "about_us.title": "<span className='text-primary'>About</span> Us",
  "about_us.content": "We’re data experts that have been on both sides of the fence. After frustrating experiences, both buying and selling targeted sales leads, we decided that the solution to make the industry better for everyone was clear – an open marketplace where everyone could connect and communicate. Leads United provides that opportunity and makes meeting prospective customers who will love your business so much easier.",
  "about_us.contact_us": "contact us",

  // Buy-leads page
  "buy_leads.banner.title": "Buy <span className='text-primary'>Quality</span> Leads",
  "buy_leads.banner.sub_title": "Reach the right decision makers and their teams",
  "buy_leads.banner.content": "Simply add what type of company and contact data you need with your budget and your brief will be sent out. Any providers that match your requirements will be alerted and they will return with an offer. You can then compare reviews and prices. Pick your favourite, negotiate if needed, and send a secure payment (we'll hold it until you're happy) and your lead generation list will be sent over.",
  "buy_leads.banner.buy_leads": "buy leads",
  "buy_leads.banner.get_started": "Get started",
  "buy_leads.how_works.title": "How it works",
  "buy_leads.how_works.step1": "1. Fill out the tick-box brief",
  "buy_leads.how_works.step2": "2. Your request is sent to matched vendors",
  "buy_leads.how_works.step3": "3. Receive back quotes that you can accept, reject or make an offer",
  "buy_leads.how_works.step4": "4. Make your payment, it will be held securely by us until you’re happy",
  "buy_leads.how_works.step5": "5. Vendor sends us your leads. We verify to make sure they meet requirements",
  "buy_leads.how_works.step6": "6. Leads are encrypted and sent over. You’ll have time to review. Payment is released on your approval",

  // Sell-leads page
  "sell_leads.banner.title": "Sell <span className='text-primary'>Quality</span> Leads",
  "sell_leads.banner.sub_title": "No cold-calling required",
  "sell_leads.banner.content": "Leads United is a great way to sell your fresh, targeted, and real-time leads without having to build up a buyers network. You could be an existing lead generation company or just be interested in selling data as a secondary revenue. Every time a brief is submitted that matches the data you hold, you’ll receive an alert. Make a bid and if successful you can exchange leads for payment.",
  "sell_leads.banner.sell_leads": "sell leads",
  "sell_leads.how_works.title": "How it works",
  "sell_leads.how_works.step1": "1. Fill in your profile page and register",
  "sell_leads.how_works.step2": "2. Receive an alert every time someone submits a request that matches your data",
  "sell_leads.how_works.step3": "3. Send the buyer an offer. They can review, negotiate or accept",
  "sell_leads.how_works.step4": "4. If accepted, the buyer sends payment which we hold for both parties",
  "sell_leads.how_works.step5": "5. You send us the leads. We verify the data, encrypt and send to buyer",
  "sell_leads.how_works.step6": "6. The buyer reviews, confirms and the payment is released to you",

  // Integrate API page
  "integrate.banner.title": "Integrate with<span className='text-primary'> API</span>",
  "integrate.banner.sub_title": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem quam non quia voluptatem qui nihil natus ut molestiae molestiae. Qui labore natus vel minima laudantium est ipsam pariatur. Qui aperiam sequi ad suscipit asperiores nam soluta tempore. Sit doloribus nostrum sit nihil perspiciatis qui totam veritatis est ipsa tempora non repellendus eligendi rem voluptatem quasi.",
  "integrate.banner.integrate": "integrate",
  "integrate.how_works.title": "How it works",
  "integrate.how_works.step1": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem",
  "integrate.how_works.step2": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem",
  "integrate.how_works.step3": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem",
  "integrate.how_works.step4": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem.",
  "integrate.how_works.step5": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem",
  "integrate.how_works.step6": "Lorem ipsum dolor sit amet. Ut repudiandae Quis ea autem",

  //Dropzone
  "dropzone.title": "drop file here",
  "dropzone.allowed_files": "Allowed file extensions : {allowedExtensions}",
  "dropzone.file_name": "file name",
  "dropzone.file": "file",
  "dropzone.file_delete_success": "File removed successfully.",
  "dropzone.file_delete_error": "Something went wrong while removing file.",
  "dropzone.file_upload_error": "Something went wrong while uploading file.",
  "dropzone.error_modal.required_field_title": "Required fields",
  "dropzone.error_modal.duplicate_email_title": "Duplicate Emails",
  "dropzone.error_modal.email": "Email: {email}",
  "dropzone.error_modal.reason": "Reason: ",
  "dropzone.error_modal.columns_does_not_have_any_values_added": "{columns} columns does not have any values added.",

  //Generic page
  "generic_page.coming_soon.title": "coming soon",
  "generic_page.coming_soon.sub_title": "This page is under construction.",
  "generic_page.not_found.title": "not found",
  "generic_page.not_found.sub_title": "The requested page is not found.",

  // Date Range picker
  "date_range_picker.default_label": "Select a date range",
  "date_range_picker.date_range": "{startDate} - {endDate}",

  // Admin: API keys
  "api_keys.title": "API Keys",
  "api_keys.api_key": "API Key",
  "api_keys.add_a_card": "Add a card",
  "api_keys.add_a_card_details": " to your account. Our system will then apply test credits so you can trial our API before you make any commitment.",

  // Admin: My Team
  "my_team.title": "My Team",
  "my_team.add_member": "add member",
  "my_team.team_member": "team member",

  // Admin: Buyer Dashboard
  "buyer_dashboard.tab.title.dashboard": "project dashboard",
  "buyer_dashboard.tab.title.project_details": "project details",
  "buyer_dashboard.tab.title.discussion": "discussion",
  "buyer_dashboard.tab.title.quote": "quote",
  "buyer_dashboard.tab.title.funds": "funds",
  "buyer_dashboard.tab.title.data": "data",
  "buyer_dashboard.tab.title.workflow": "workflow",
  "dashboard.title": "dashboard",
  "dashboard.project": "project",
  "dashboard.project.dashboard.deposited": "deposited",
  "dashboard.project.dashboard.released": "released",
  "dashboard.title.project_status": "project status",
  "dashboard.subtitle.text": "Quotes have been received, you need to: ",
  "dashboard.subtitle.link_text": "review quote",
  "dashboard.vendor": "vendor",
  "dashboard.date_range": "date range",
  "dashboard.seller": "seller",
  "dashboard.buyer": "buyer",
  "dashboard.seller_quotes": "seller quotes",
  "dashboard.timeline": "timeline",
  "dashboard.cost_per_lead": "Cost per Lead",
  "dashboard.buy_lead_requests": "buy lead requests",
  "dashboard.table.vendor": "vendor",
  "dashboard.table.project_name": "project name",
  "dashboard.table.delivery_date": "delivery date",
  "dashboard.table.final_delivery_date": "final delivery date",
  "dashboard.overall.budget": "Budget",
  "dashboard.table.status": "status",
  "dashboard.table.volume_of_leads": "volume of leads",
  "dashboard.table.total_volume_of_leads": "total volume of leads required",
  "dashboard.overall.leads_volume_required": "Volume Of Leads Required",
  "dashboard.table.cost_per_lead": "cost per lead",
  "dashboard.table.multiple_batches": "Batches/Multiple Delivery Dates",
  "dashboard.table.total_price": "total price",
  "dashboard.table.total_cost": "total cost",
  "dashboard.table.details": "details",
  "dashboard.table.batches": "batches",
  "dashboard.table.buyer": "buyer",
  "dashboard.funds.tab.quote_status": "quote status",
  "dashboard.table.review_quote": "review quote",
  "dashboard.table.seller_name": "seller name",
  "dashboard.more": "more...",
  "dashboard.table.batch_id": "Batch ID",
  "dashboard.table.quote_id": "Quote ID",

  "buyer_dashboard.workflow.accepted_quote": "accepted quote",
  "buyer_dashboard.workflow.accepted_quote.quotes": "quotes",
  "buyer_dashboard.workflow.accepted_quote.approved_on": "approved on",
  "buyer_dashboard.workflow.accepted_quote.approved_by": "approved by",
  "buyer_dashboard.workflow.accepted_quote.seller_name": "seller name",
  "buyer_dashboard.workflow.accepted_quote.fund_details": "fund details",
  "buyer_dashboard.workflow.accepted_quote.fund_released_on": "fund released on",
  "buyer_dashboard.workflow.accepted_quote.fund_amount": "fund amount",
  "buyer_dashboard.workflow.accepted_quote.quote_summary": "quote summary",
  "buyer_dashboard.workflow.accepted_quote.delivery_date": "delivery date: {date}",
  "buyer_dashboard.workflow.accepted_quote.no_of_leads": "no of leads: {leads}",
  "buyer_dashboard.workflow.accepted_quote.batch": "batch {count}",
  "buyer_dashboard.workflow.accepted_quote.note_from_buyer": "note from buyer:",
  "buyer_dashboard.workflow.batche_table.title.seller_quote": "seller quote",
  "buyer_dashboard.workflow.batche_table.volume_of_leads": "volume of leads",
  "buyer_dashboard.workflow.batche_table.delivery_date": "delivery date",
  "buyer_dashboard.workflow.batche_table.cost_per_lead": "cost per lead",
  "buyer_dashboard.workflow.batche_table.total_cost": "total cost",
  "buyer_dashboard.workflow.batche_table.title.audience_targeting": "audience targeting",
  "buyer_dashboard.workflow.batche_table.audience": "audience",
  "buyer_dashboard.workflow.sample_data": "sample data",
  "buyer_dashboard.workflow.data_check.data_check": "data check",
  "buyer_dashboard.workflow.data_check.data_uploaded_on": "data uploaded on: {date}",
  "buyer_dashboard.workflow.data_check.download_uploaded_data": "download uploaded data",
  "buyer_dashboard.workflow.data_check.note": "note:",
  "buyer_dashboard.workflow.data_check.seller_profile": "seller profile",
  "buyer_dashboard.workflow.data_check.contact_seller": "contact seller",
  "buyer_dashboard.workflow.data_check.approve_data": "approve data",
  "buyer_dashboard.workflow.data_check.reject_data": "reject data",
  "buyer_dashboard.workflow.data_check.data_summary": "data summary",
  "buyer_dashboard.workflow.data_check.data_summary.rejected_by": "rejected by {sellerName}",
  "buyer_dashboard.workflow.data_check.data_summary.download_data": "download data",
  "buyer_dashboard.workflow.data_check.data_summary.rejected_note": "Rejected Note: {note}",
  "buyer_dashboard.workflow.data_check.data_summary.data_uploaded_by_seller": "Data uploaded by Seller:",
  "buyer_dashboard.workflow.data_check.data_summary.data_rejected_by_buyer": "Data rejected by Buyer:",
  "buyer_dashboard.workflow.data_check.max_date_notice": "Approve/Reject before {max_action_date}, failed to respond will be mark as approved data and payment will be released to seller",
  "buyer_dashboard.workflow.data_check.rejection_type": "rejection type",
  "buyer_dashboard.workflow.data_check.no_file_found_to_approve": "No file found to approve/reject.",
  "buyer_dashboard.workflow.buyer_note": "This is the test note provided by buyer",
  "buyer_dashboard.overall.date_range": "Select date",
  "buyer_dashboard.overall.projects": "Projects",
  "buyer_dashboard.overall.box.cost": "Cost",
  "buyer_dashboard.overall.box.leads_purchased": "Leads purchased",
  "buyer_dashboard.overall.box.leads_requested": "Leads requested",
  "buyer_dashboard.overall.box.leads_supplied": "Leads supplied",
  "buyer_dashboard.overall.box.total_spend": "Total spend",
  "buyer_dashboard.overall.leads_volume": "Volume of Leads",
  "buyer_dashboard.funds.tab.pending_funds": "pending funds",
  "buyer_dashboard.funds.tab.pending.title": "Funds",
  "buyer_dashboard.funds.tab.pending.subtitle": "Quote have been approved, add funds.",
  "buyer_dashboard.funds.tab.billing_details": "Billing Details",
  "buyer_dashboard.funds.tab.card_details": "Card Details",
  "buyer_dashboard.funds.tab.add_funds": "Add Funds: {currency_symbol}{section}",
  "buyer_dashboard.funds.btn.add_funds": "Add funds",
  "buyer_dashboard.funds.tab.funds_deposited.subtitle": "Funds have been deposited, waiting for data to be sent",
  "buyer_dashboard.funds.tab.funds_deposited": "funds deposited",
  "buyer_dashboard.funds.tab.funds_deposited.payment_status": "Payment Status: {status}",
  "buyer_dashboard.funds.tab.funds_deposited.reference_id": "Reference ID: {reference_id}",
  "buyer_dashboard.funds.tab.funds_deposited.amount": "Amount: {currency_symbol}{amount}",
  "buyer_dashboard.funds.tab.funds_released": "funds released",
  "buyer_dashboard.funds.tab.funds_released.subtitle": "Data has been checked, funds have been released",
  "buyer_dashboard.funds.tab.funds_released.fund_released_notice": "Funds have been automatically released",
  "buyer_dashboard.data.tab.title": "Data",
  "buyer_dashboard.data.tab.batch_no": "Batch {no}",
  "buyer_dashboard.data.tab.batch_volume_of_leads_required": "batch {no} volume of leads required",
  "buyer_dashboard.data.tab.batch_delivery_date": "batch {no} delivery date",
  "buyer_dashboard.data.tab.download_data": "download data",
  "buyer_dashboard.data.tab.data_status": "data status",
  "buyer_dashboard.data.tab.not_available": "not available",
  "buyer_dashboard.data.tab.available": "available",
  "buyer_dashboard.data.tab.uploaded_on": "uploaded on: ",
  "buyer_dashboard.data.tab.note": "note: ",
  "buyer_dashboard.data.tab.pending_leads": "Pending Leads",
  "buyer_dashboard.data.tab.approved_leads": "Approved Leads",
  "buyer_dashboard.data.tab.rejected_leads": "Rejected Leads",
  "buyer_dashboard.data.tab.max_date_notice": "Approve/Reject before {max_action_date}, failed to respond will be mark as approved data and payment will be released to seller",
  "buyer_dashboard.data.tab.sub_tab.title.data_check": "data check",
  "buyer_dashboard.data.tab.sub_tab.title.view_data": "view data",
  "buyer_dashboard.data.tab.sub_tab.title.data_history": "data history",
  "buyer_dashboard.data.tab.sub_tab.title.release_funds": "release funds",
  "buyer_dashboard.data.tab.view_data.column.id": "ID",
  "buyer_dashboard.data.tab.view_data.column.date": "date",
  "buyer_dashboard.data.tab.view_data.column.company": "company",
  "buyer_dashboard.data.tab.view_data.column.full_name": "full name",
  "buyer_dashboard.data.tab.view_data.column.email": "email",
  "buyer_dashboard.data.tab.view_data.column.employee_size": "employee size",
  "buyer_dashboard.data.tab.view_data.column.language": "language",
  "buyer_dashboard.data.tab.view_data.column.phone": "phone",
  "buyer_dashboard.data.tab.view_data.column.industry": "industry",
  "buyer_dashboard.data.tab.view_data.column.department": "department",
  "buyer_dashboard.data.tab.view_data.column.job_title": "job title",
  "buyer_dashboard.data.tab.view_data.column.job_level": "job level",
  "buyer_dashboard.data.tab.view_data.column.website": "website",
  "buyer_dashboard.data.tab.view_data.column.revenue": "revenue",
  "buyer_dashboard.data.tab.view_data.column.address_1": "address 1",
  "buyer_dashboard.data.tab.view_data.column.address_2": "address 2",
  "buyer_dashboard.data.tab.view_data.column.address_3": "address 3",
  "buyer_dashboard.data.tab.view_data.column.city": "city",
  "buyer_dashboard.data.tab.view_data.column.state": "state",
  "buyer_dashboard.data.tab.view_data.column.country": "country",
  "buyer_dashboard.data.tab.view_data.column.postcode": "postcode",
  "buyer_dashboard.data.tab.view_data.column.rejection_reason": "rejection reason",
  "buyer_dashboard.data.tab.view_data.column.status": "status",
  "buyer_dashboard.data.tab.view_data.column.action": "action",
  "buyer_dashboard.data.tab.view_data.btn.approved": "approved",
  "buyer_dashboard.data.tab.view_data.btn.rejected": "rejected",
  "buyer_dashboard.data.tab.view_data.btn.pending": "pending",
  "buyer_dashboard.data.tab.view_data.update_lead": "update lead",
  "buyer_dashboard.data.tab.view_data.modal.title.reject_leads": "reject leads",
  "buyer_dashboard.data.tab.view_data.modal.title.rejection_reason": "rejection reason",
  "buyer_dashboard.data.tab.view_data.modal.title.remark": "Remark",
  "buyer_dashboard.data.tab.view_data.modal.title.filter_leads": "filter leads",
  "buyer_dashboard.data.tab.view_data.modal.status": "status",
  "buyer_dashboard.data.tab.view_data.leads.table_summary": "{records} out of {total}",

  // Buyer > Reject Quote
  "buyer.workflow.reject_quote.title": "reject quote: ",
  "buyer.workflow.reject_quote.notes": "notes",
  "buyer.workflow.reject_quote.free_type": "free type",

  // Seller Dashboard
  "seller_dashboard.buyer_request": "buyer request",
  "seller_dashboard.tab.title.dashboard": "project dashboard",
  "seller_dashboard.tab.title.project_details": "project details",
  "seller_dashboard.tab.title.discussion": "discussion",
  "seller_dashboard.tab.title.quotes": "quotes",
  "seller_dashboard.tab.title.workflow": "workflow",
  "seller.workflow.submit_quote.batch": "batch",
  "seller.workflow.submit_quote.batch_count": "batch {count}",
  "seller.workflow.submit_quote.select_all": "select all",
  "seller.workflow.submit_quote.criteria": "criteria",
  "seller.workflow.submit_quote.vol_of_leads": "vol. of leads",
  "seller.workflow.submit_quote.delivery_date": "delivery date",
  "seller.workflow.submit_quote.cost_per_leads": "cost per leads",
  "seller.workflow.submit_quote.total_cost": "total cost",
  "seller.workflow.submit_quote.add_new_batch": "add new batch",
  "seller.workflow.submit_quote.more": "more",
  "seller.workflow.submit_quote.view_project_details": "view project details",
  "seller.workflow.submit_quote.submit_quotes": "submit quotes",
  "seller.workflow.submit_quote.upload_sample_data_file": "upload sample data file",
  "seller.workflow.submit_quote.modal.criteria_title_value": "{criteriaTitle}: {criteriaValue}",
  "seller.workflow.submit_quote.modal.criteria": "criteria {number}",
  "seller.workflow.select_quote": "select quote",
  "seller.workflow.select_batch": "select batch",
  "seller.workflow.buy_leads_request_received": "Buy leads request received",
  "seller.workflow.notes": "notes",
  "seller.workflow.upload_data": "upload data",
  "seller.workflow.table.vendor": "vendor",
  "seller.workflow.table.project_name": "project name",
  "seller.workflow.table.delivery_date": "delivery date",
  "seller.workflow.table.status": "status",
  "seller.workflow.table.volume_of_leads": "volume of leads",
  "seller.workflow.table.cost_per_lead": "cost per lead",
  "seller.workflow.table.total_cost": "total cost",
  "seller.workflow.table.total_price": "total price",
  "seller.workflow.table.details": "details",
  "seller.workflow.table.batches": "batches",
  "seller.workflow.table.buyer": "buyer",
  "seller.workflow.more": "more...",
  "seller.workflow": "Workflow",
  "seller.workflow.seller_quotes": "seller quotes",
  "seller.workflow.download_data": "download data",
  "seller.workflow.data_rejected_by_buyer": "Data rejected by Buyer:",
  "seller.workflow.data_uploaded_by_seller": "Data uploaded by Seller:",
  "seller.workflow.rejected_note": "rejected note:",
  "seller.workflow.raise_issue_to_support": "Raise issue to support",
  "seller.workflow.data_summary": "data summary",
  "seller.workflow.approved_quotes": "Approved Quotes",
  "seller.workflow.approved_on": "Approved on",
  "seller.workflow.approved_by": "Approved by",
  "seller.workflow.fund_amount": "Fund amount",
  "seller.workflow.fund_released_on": "Fund released on",
  "seller.workflow.fund_details": "Fund details",
  "seller.workflow.quote_summary": "Quote Summary",
  "seller.workflow.delivery_date": "Delivery Date: ",
  "seller.workflow.no_of_leads": "No of Leads: ",
  "seller.workflow.sample_data": "sample data:",
  "seller.workflow.batch_short": "B{number}",
  "seller.workflow.download_files": "Download Files",
  "seller.workflow.note_from_seller": "Note from seller: ",
  "seller.workflow.revision_note_from_buyer": "Revision note from buyer",
  "seller.workflow.note_by_buyer": "This is the test note provided by buyer",
  "seller.workflow.title.audience_targeting": "audience targeting",
  "seller.workflow.audience": "audience",
  "seller.workflow.criteria": "criteria {number}",
  "seller.workflow.upload_data.lead_upload": "lead upload",
  "seller.workflow.upload_data.download_lead_template": "download lead template",
  "seller.workflow.upload_data.data_upload_history": "Data Upload History",

  "seller.workflow.accepted_quote.batch": "batch {count}",
  "seller.workflow.accepted_quote.sample_file_not_provided": "sample file not provided",
  "seller.workflow.accepted_quote.btn.approve": "approve",
  "seller.workflow.accepted_quote.btn.reject": "reject",
  "seller.workflow.accepted_quote.btn.request_for_revision": "request for revision",
  "seller.workflow.accepted_quote.title": "Approve Quote: {sellerName}",
  "seller.workflow.accepted_quote.first_name": "First Name",
  "seller.workflow.accepted_quote.last_name": "Last Name",
  "seller.workflow.accepted_quote.company": "Company",
  "seller.workflow.accepted_quote.address1": "Address Line 1",
  "seller.workflow.accepted_quote.address2": "Address Line 2",
  "seller.workflow.accepted_quote.city": "City",
  "seller.workflow.accepted_quote.state": "State",
  "seller.workflow.accepted_quote.country": "Country",
  "seller.workflow.accepted_quote.postcode": "Postcode",
  "seller.workflow.accepted_quote.phone_number": "Phone Number",
  "seller.workflow.accepted_quote.billing_details": "Billing Details",

  "seller.workflow.title.seller_quote": "seller quote",
  "seller_dashboard.overall.date_range": "Select date",
  "seller_dashboard.overall.projects_accepted": "Projects Accepted",
  "seller_dashboard.overall.box.cost": "Cost",
  "seller_dashboard.overall.box.leads_sold": "Leads sold",
  "seller_dashboard.overall.box.price_per_lead": "Price per lead",
  "seller_dashboard.overall.box.quotes_sent": "Quotes sent",
  "seller_dashboard.overall.box.leads_supplied": "Leads supplied",
  "seller_dashboard.overall.box.revenue": "Revenue",
  "seller_dashboard.overall.leads_volume": "Volume of Leads",

  // Data upload history
  "data_upload_history.title": "data upload history",
  "data_upload_history.table.id": "ID",
  "data_upload_history.table.batch_id": "Batch",
  "data_upload_history.table.file_name": "file name",
  "data_upload_history.table.records": "records",
  "data_upload_history.table.processed": "processed",
  "data_upload_history.table.attempted": "attempted",
  "data_upload_history.table.pending": "pending",
  "data_upload_history.table.approved": "approved",
  "data_upload_history.table.rejected": "rejected",
  "data_upload_history.table.date": "Date",
  "data_upload_history.table.user": "user",
  "data_upload_history.table.status": "status",
  "data_upload_history.table.action_by": "action by",
  "data_upload_history.table.action_at": "action at",
  "data_upload_history.table.note": "note",
  "data_upload_history.table.uploaded_file": "uploaded file",
  "data_upload_history.table.download": "download",
  "data_upload_history.table.more": "more",

  // Data Numbers
  "data_numbers.required_leads": "required leads",
  "data_numbers.pending_leads": "pending leads",
  "data_numbers.approved_leads": "approved leads",
  "data_numbers.rejected_leads": "rejected leads",

  // Data summary
  "data_summary.title": "data summary",
  "data_summary.data_title": "Data {status}",
  "data_summary.pending_status_information": "Uploaded by {sellerName} | ",
  "data_summary.approve_reject_status_information": "{status} by {buyerName} | {sellerName} | ",
  "data_summary.download_data_file_label": "File uploaded by Seller: ",
  "data_summary.note": "Note: ",
  "data_summary.quote_id": "Batch ID: ",
  "data_summary.quote_group_id": "Quote ID: ",
  "data_summary.rejection_type": "Rejection Type: ",
  "data_summary.rejection_note": "Rejection Note: ",

  // Data Acceptance
  "data_acceptance.title": "data acceptance",
  "data_acceptance.sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "data_acceptance.project_delivery_date": "project delivery date",
  "data_acceptance.data_accepted_on": "data accepted on",
  "data_acceptance.data_accepted_by": "data accepted by",
  "data_acceptance.final_cost": "final cost",
  "data_acceptance.release_note": "Fund accepted on {approvedData} and release to seller on {fundCreditDate}",
  "data_acceptance.agree_terms_and_conditions": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  "data_acceptance.note": "Note: ",
  "data_acceptance.max_acceptance_date": "Accept data before {maxAcceptanceData}, failed to respond will release fund.",

  // Fund released
  "fund_release.title": "fund release",
  "fund_release.sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "fund_release.amount": "amount",
  "fund_release.final_amount": "final amount",
  "fund_release.fund_released_on": "fund released on",
  "fund_release.fund_released_by": "fund released by",

  // Project completed
  "project_completed.title": "Project Completed",
  "project_completed.sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "project_completed.status": "Status: ",
  "project_completed.completed_by": "Project completed by: ",
  "project_completed.completed_on": "Project completed on: ",

  //Dashboard : Project Details
  "project_details.title": "project details",
  "project_details.subtitle": "Project details have been sent, waiting for quote to be submitted",
  "project_details.lead_info": "lead info",
  "project_details.project_name": "project name",
  "project_details.buyer_name": "Buyer name",
  "project_details.status": "Status",
  "project_details.volume_of_leads_required": "volume of leads required",
  "project_details.audience_targeting": "audience targeting",
  "project_details.criteria": "criteria {number}",
  "project_details.tal_suppression": "TAL/Suppression",
  "project_details.tal_upload": "TAL Upload",
  "project_details.suppression_upload": "Suppression Upload",
  "project_details.download_files": "download files",
  "project_details.delivery_date": "delivery date",
  "project_details.budget": "budget",
  "project_details.buyer_dont_allow_to_edit_project": "Seller has already submitted quote for this project, You won't be able to edit project",

  //Dashboard: View Quote
  "view_quote.title": "quote",
  "view_quote.subtitle_buyer": "Quote have been submitted,  your approval is needed",
  "view_quote.subtitle_seller": "Project details have been sent, waiting for quote to be submitted",
  "view_quote.subtitle_selle_submittedr": "Project details have been sent, please wait for approval",
  "view_quote.project_details": "project details",
  "view_quote.quote_details": "quote details",
  "view_quote.criteria": "criteria",
  "view_quote.batches": "batches",
  "view_quote.total_cost": "Total Cost: {currency_symbol}{number}",
  "view_quote.approve_quote": "approve quote",
  "view_quote.reject_quote": "reject quote",
  "view_quote.send_quote_to_buyer": "send quote to buyer",
  "view_quote.back_to_all_quotes": "back to all quotes",

  //Dashboard: Submit Quote
  "submit_quote.title": "quote",
  "submit_quote.subtitle": "You have received a buy leads request, submit a quote",
  "submit_quote.project_details": "project details",

  // Admin: Invoices
  "invoices.title": "Invoices",
  "invoices.table.invoice_name": "Invoice Name",
  "invoices.table.download": "Download",
  "invoices.table.invoice_date": "Invoice Date",
  "invoices.table.amount": "Amount",
  "invoices.table.credits": "# of Credits",

  // Buy leads
  "app_buy_leads.title": "Buy Leads",
  "app_buy_leads.title.lead_information": "Lead Information",
  "app_buy_leads.title.audience_targeting": "Audience Targeting",
  "app_buy_leads.title.tal_suppression": "TAL/Suppression",
  "app_buy_leads.tal_suppression.tal_upload": "TAL Upload",
  "app_buy_leads.tal_suppression.suppression_upload": "Suppression Upload",
  "app_buy_leads.tal_suppression.download_tal_template": "Download TAL Template",
  "app_buy_leads.tal_suppression.download_suppression_template": "Download Suppression Template",
  "app_buy_leads.preview.page_title": "Preview",
  "app_buy_leads.preview.title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua.",
  "app_buy_leads.preview.sub_title": "By ticking the box, you acknowledge that if campaign assets and copy are not delivery on time, your campaign may be delayed by up to 10 bussiness days. *",
  "app_buy_leads.project_name": "Project Name",
  "app_buy_leads.volume_of_leads_required": "Volume of Leads Required",
  "app_buy_leads.delivery_date": "Delivery Date",
  "app_buy_leads.budget": "Budget",
  "app_buy_leads.region": "Region",
  "app_buy_leads.countries_to_target": "Countries to Target",
  "app_buy_leads.employee_sizes": "Employee Sizes",
  "app_buy_leads.leads_per_org_per_country": "Leads per Org per Country",
  "app_buy_leads.job_function": "Job Function",
  "app_buy_leads.job_level": "Job Level",
  "app_buy_leads.languages": "Languages",
  "app_buy_leads.industries": "industries",
  "app_buy_leads.job_keyword": "Job Keyword",
  "app_buy_leads.success.title": "success",
  "app_buy_leads.success.sub_title1": "Success, your request has been submitted",
  "app_buy_leads.success.sub_title2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  "app_buy_leads.duplicate_audience": "duplicate audience",
  "app_buy_leads.add_new_audience": "add new audience",
  "app_buy_leads.Separate_words_with_a_comma": "Separate words with a comma",
  "app_buy_leads.custom_criteria": "custom criteria",
  "app_buy_leads.job_titles": "job titles",
  "app_buy_leads.batches": "batches",
  "app_buy_leads.free_type": "free type",
  "app_buy_leads.download_files": "Download Files",
  "app_buy_leads.criteria_number": "Criteria {number}",
  "app_buy_leads.criteria": "Criteria",
  "app_buy_leads.atleast_fill_one_criteria_list": "Please fill atleast one criteria list",
  "app_buy_leads.column_relationship": "column relationship",
  "app_buy_leads.required_fields": "required fields",
  "app_buy_leads.invalid_values": "Invalid Values",
  "app_buy_leads.upload_job_titles": "upload job title",
  "app_buy_leads.download_uploaded_file": "Download uploaded file",
  "app_buy_leads.selected_data_fields": "selected data fields",
  "app_buy_leads.data_fields.title": "Select the data you would like to buy",

  // Sell leads
  "app_sell_leads.title": "Sell Leads",
  "app_sell_leads.title.targeting": "Targeting",
  "app_sell_leads.title.upload_lead_sample": "Upload Lead Sample",
  "app_sell_leads.title.data_field": "Data Field",
  "app_sell_leads.title.data_fields": "Data Fields",
  "app_sell_leads.data_fields.title": "Select the data you would like to sell",
  "app_sell_leads.data_fields.select_all": "Select All",
  "app_sell_leads.fields": "Fields",
  "app_sell_leads.upload_lead_sample.description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "app_sell_leads.targeting.description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "app_sell_leads.project_name": "Project Name",
  "app_sell_leads.file_name": "File Name",
  "app_sell_leads.region": "Region",
  "app_sell_leads.countries_to_target": "Countries to Target",
  "app_sell_leads.industries_include": "Industries Include",
  "app_sell_leads.employee_sizes": "Employee Sizes",
  "app_sell_leads.leads_per_org_per_country": "Leads per Org per Country",
  "app_sell_leads.job_functions": "Job Functions",
  "app_sell_leads.job_levels": "Job Levels",
  "app_sell_leads.languages": "Languages",
  "app_sell_leads.other_industries": "Other Industries",
  "app_sell_leads.industries_exclude": "Industries Exclude",
  "app_sell_leads.job_titles_to_include": "Job Titles to Include",
  "app_sell_leads.job_titles_to_exclude": "Job Titles to Exclude",
  "app_sell_leads.job_keywords": "Job Keywords",
  "app_sell_leads.success.title": "success",
  "app_sell_leads.success.sub_title1": "Success, your request has been submitted",
  "app_sell_leads.success.sub_title2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  "app_sell_leads.preview.page_title": "Preview",
  "app_sell_leads.preview.title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua.",
  "app_sell_leads.preview.sub_title": "By ticking the box, you acknowledge that if campaign assets and copy are not delivery on time, your campaign may be delayed by up to 10 bussiness days. *",

  //Admin: MarketPlace 
  "marketplace.latest_offerings": "latest offerings",
  "marketplace.title": "marketPlace",
  "marketplace.filters": "filters",
  "marketplace.lead_type": "lead type",
  "marketplace.people": "people",
  "marketplace.companies": "companies",
  "marketplace.saved_searches": "saved searches",
  "marketplace.search": "search",
  "marketplace.region": "region",
  "marketplace.countries_to_target": "Countries to Target",
  "marketplace.language": "language",
  "marketplace.industries": "Industries",
  "marketplace.leads_per_org_per_country": "Leads per Org per Country",
  "marketplace.employee_sizes": "Employee Sizes",
  "marketplace.job_functions": "Job Functions",
  "marketplace.job_levels": "Job Levels",
  "marketplace.job_titles": "Job Titles",
  "marketplace.job_keywords": "Job Keywords",
  "marketplace.show_filters": "show filters",
  "marketplace.latest_offerings.table.project_name": "project name",
  "marketplace.latest_offerings.table.details": "details",
  "marketplace.latest_offerings.table.total_price": "total price",
  "marketplace.latest_offerings.table.budget": "budget",
  "marketplace.latest_offerings.table.volume_of_leads": "volume of leads",
  "marketplace.latest_offerings.table.status": "status",
  "marketplace.latest_offerings.table.delivery_date": "delivery date",
  "marketplace.latest_offerings.table.buyer": "buyer",
  "marketplace.more": "more...",
  "marketplace.atleast_fill_one_filter": "Please fill atleast one filter",
  "marketplace.custom_criteria": "custom criteria",
  "marketplace.free_type": "Free Type",
  "marketplace.search_name": "search name",
  "marketplace.saved_search": "Saved Search",

  //Admin: settings
  "settings.title": "Settings",
  "settings.email": "Email",
  "settings.first_name": "First Name",
  "settings.last_name": "Last Name",
  "settings.phone": "Phone Number",
  "settings.company": "Company",
  "settings.address_line_1": "Address Line 1",
  "settings.address_line_2": "Address Line 2",
  "settings.city": "City",
  "settings.county": "County",
  "settings.postcode": "Postcode",
  "settings.country": "Country",
  "settings.profile_details": "Profile Details",
  "settings.billing_details": "Billing Details",
  "settings.card_details": "Card Details",
  "settings.card_no": "Card No",
  "settings.expire_date": "Expire Date",
  "settings.cvv": "CVV",
  "settings.display_name": "Display Name",
  "settings.state": "State",

  "payment.success.title": "Success",
  "payment.success.page_title": "Payment Success",
  "payment.success.sub_title": "Payment completed successfully.",
  "payment.success.payment_status": "Payment Status: ",
  "payment.success.reference_id": "Reference ID: ",
  "payment.success.amount": "Amount: ",

  "payment.failed.title": "Failed",
  "payment.failed.page_title": "Payment Failed",
  "payment.failed.sub_title": "Payment could not be completed successfully.",
  "payment.failed.payment_status": "Payment Status: ",
  "payment.failed.reference_id": "Reference ID: ",
  "payment.failed.amount": "Amount: ",

  "quote.view_quote.quotes": "{status} Quotes",
  "quote.view_quote.updated_on": "Updated on",
  "quote.view_quote.updated_by": "Updated by",
  "quote.view_quote.fund_details": "Fund details",
  "quote.view_quote.fund_released_on": "Fund released on",
  "quote.view_quote.fund_amount": "Fund amount",
  "quote.view_quote.notes": "Notes",
  "quote.view_quote.status": "Status",
  "quote.view_quote.created_on": "Created on",
  "quote.view_quote.created_by": "Created by",

  // View quote data
  "view_quote_data.title": "View quote data",

  // User Management
  "user_management.table.column.name": "name",
  "user_management.table.column.email": "email",
  "user_management.table.column.company": "company",
  "user_management.table.column.display_name": "display name",
  "user_management.table.column.role": "role",
  "user_management.table.column.status": "status",
  "user_management.table.column.profile_status": "profile status",
  "user_management.table.column.action": "action",
  "user_management.title": "user management",
  "user_management.create_user.title": "create user",
  "user_management.action.under_review": "under review",
  "user_management.action.approve": "approve",
  "user_management.action.reject": "reject",
  "user_management.action.suspend": "suspend",
  "user_management.action.resume": "resume",
  "user_management.action.lock": "lock",
  "user_management.action.unlock": "unlock",
  "user_management.action.restore": "restore",
  "user_management.action.edit": "edit",
  "user_management.action.delete": "delete",
  "user_management.action.reset_password": "reset password",
  "user_management.status": "Status",
  "user_management.edit_user.title": "Edit User",
  "user_management.email": "Email",
  "user_management.first_name": "First Name",
  "user_management.last_name": "Last Name",
  "user_management.phone": "Phone Number",
  "user_management.company": "Company",
  "user_management.address_line_1": "Address Line 1",
  "user_management.address_line_2": "Address Line 2",
  "user_management.city": "City",
  "user_management.postcode": "Postcode",
  "user_management.country": "Country",
  "user_management.profile_details": "Profile Details",
  "user_management.billing_details": "Billing Details",
  "user_management.card_details": "Card Details",
  "user_management.display_name": "Display Name",
  "user_management.state": "State",
  "user_management.personal_details": "Personal Details",

};