import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../hoc/withRouter";

import { setAdminMenuCollapse } from "../../../actions/application";

// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <NavLink
    className="d-flex"
    to={"/"}
    ref={ref}
    onClick={(e) => { e.preventDefault(); onClick(e); }}
  >
    {children}
  </NavLink>
));

class Header extends Component {

  render() {
    const { setAdminMenuCollapse, adminMenuCollapse, firstName } = this.props;

    return (
      <header className="header-admin">
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container-fluid justify-content-between">
            <button
              className="navbar-toggler p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSidebar"
              aria-controls="navbarAdmin"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => { if (typeof setAdminMenuCollapse === "function") { setAdminMenuCollapse(!adminMenuCollapse) } }}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Link to="/" className="navbar-brand">
              <img src={require("../../../assets/images/logo.svg")} className="img-fluid" alt="" />
            </Link>
            <ul className="navbar-nav me-0 align-items-center">
              <li className="nav-item nav-item-profile dropdown">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                    <div className="nav-link dropdown-toggle heading-05">
                      <span className="text-truncate">{(firstName || "User")}</span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end position-absolute">
                    <Dropdown.Item href="/dashboard" className="dropdown-item text-capitalize">
                      <FormattedMessage id="dashboard" defaultMessage="dashboard" />
                    </Dropdown.Item>
                    <Dropdown.Item href="/settings" className="dropdown-item text-capitalize">
                      <FormattedMessage id="settings" defaultMessage="settings" />
                    </Dropdown.Item>
                    <Dropdown.Item href="/logout" className="dropdown-item text-capitalize">
                      <FormattedMessage id="logout" defaultMessage="Logout" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </header>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAdminMenuCollapse: (payload) => dispatch(setAdminMenuCollapse(payload)),
});

const mapStateToProps = (state) => ({
  firstName: _get(state, "oauth.user.first_name", ""),
  adminMenuCollapse: _get(state, "application.adminMenuCollapse", false)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
