import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual, map as _map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";

import { ErrorMessage, Select } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import Preview from "./components/preview"

import { IconRightCaret, IconLgDone, IconClose, IconResetAlt, IconXls } from "../../helpers/iconStyles";

const defaultList = [
  { id: 0, name: "Select", isdisabled: true },
  { id: 1, name: "option1", isdisabled: false },
  { id: 2, name: "option2", isdisabled: false },
  { id: 3, name: "option3", isdisabled: false },
];

const dataFields = [
  { id: 24, name: "First Name" }, { id: 1, name: "Last Name" }, { id: 2, name: "Email" }, { id: 3, name: "Job Title" },
  { id: 4, name: "Job Function 2" }, { id: 5, name: "Seniority" }, { id: 6, name: "Linkedin " }, { id: 7, name: "Company" },
  { id: 8, name: "Job Function" }, { id: 9, name: "Address" }, { id: 10, name: "Company Full Name" }, { id: 11, name: "Company Description" },
  { id: 12, name: "City" }, { id: 13, name: "State" }, { id: 14, name: "Postal Code" }, { id: 15, name: "Country" },
  { id: 16, name: "Company IP" }, { id: 17, name: "Phone" }, { id: 18, name: "Website" }, { id: 19, name: "Domain" },
  { id: 20, name: "Employee Size" }, { id: 21, name: "Revenue" }, { id: 22, name: "Industry" }, { id: 23, name: "Linkedin URL - Company" },
];

class SellLeads extends Component {
  constructor(props) {
    super(props);

    this.defaultStates = {
      projectName: "",
      region: null,
      countriesToTarget: null,
      languages: null,
      industriesInclude: null,
      otherIndustry: "",
      industriesExclude: null,
      employeeSizes: null,
      LeadsPerOrg: null,
      jobFunctions: null,
      jobLevels: null,
      jobTitleToInclude: null,
      jobTitleToExclude: null,
      jobKeywords: null,
      selDataFieldIds: [],
      selDataFieldNames: [],
      selectAllDataFields: false
    };

    this.state = {
      formData: _cloneDeep(this.defaultStates),
      errors: {},
      showForm: true,
      showPreview: false,
      showSuccess: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.formData, this.state.formData)) {
      this._handleValidation();
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const {
      formData: { projectName, region, selDataFieldIds, countriesToTarget, industriesInclude, employeeSizes, LeadsPerOrg, jobFunctions, jobLevels }
    } = this.state;

    let errors = {};

    if (_isEmpty(projectName)) {
      errors["projectName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.project_name", defaultMessage: "Project Name" }),
      });
    }

    if (_isEmpty(selDataFieldIds)) {
      errors["selDataFieldIds"] = intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "app_sell_leads.title.data_field", defaultMessage: "Data Field" }),
      });
    }

    if (_isEmpty(region)) {
      errors["region"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.region", defaultMessage: "Region" }),
      });
    }

    if (_isEmpty(countriesToTarget)) {
      errors["countriesToTarget"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.countries_to_target", defaultMessage: "Countries to Target" }),
      });
    }

    if (_isEmpty(industriesInclude)) {
      errors["industriesInclude"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.industries_include", defaultMessage: "Industries Include" }),
      });
    }

    if (_isEmpty(employeeSizes)) {
      errors["employeeSizes"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.employee_sizes", defaultMessage: "Employee Sizes" }),
      });
    }

    if (_isEmpty(LeadsPerOrg)) {
      errors["LeadsPerOrg"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.leads_per_org_per_country", defaultMessage: "Leads per Org per Country" }),
      });
    }

    if (_isEmpty(jobFunctions)) {
      errors["jobFunctions"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.job_functions", defaultMessage: "Job Functions" }),
      });
    }

    if (_isEmpty(jobLevels)) {
      errors["jobLevels"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "app_sell_leads.job_levels", defaultMessage: "Job Levels" }),
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errors });
  }

  _handleReset = () => {
    this.setState({
      formData: _cloneDeep(this.defaultStates),
      errors: {},
      showForm: true,
      showPreview: false,
      showSuccess: false
    });
  }

  _handleNext = async () => {
    const { formData: { projectName, selDataFieldIds, region, countriesToTarget }, errors } = this.state;

    if (_isEmpty(projectName) || _isEmpty(region) || _isEmpty(countriesToTarget) || _isEmpty(selDataFieldIds) || !_isEmpty(errors)) {
      this._handleValidation();

      return false;
    }

    this.setState({ showPreview: true, showForm: false, showSuccess: false });
  }

  _selectDataFieldIds = (id = null, name = "", source = "single") => {
    const { formData: { selDataFieldIds, selDataFieldNames, selectAllDataFields } } = this.state;

    let updatedState = null;
    let tmpSelectIds = _cloneDeep(selDataFieldIds || []);
    let tmpSelectNames = _cloneDeep(selDataFieldNames || []);

    switch (source) {
      case "single":
        let tmpCheckAllIds = _cloneDeep(selectAllDataFields);

        if (tmpSelectIds.includes(id)) {
          tmpSelectIds = (tmpSelectIds).filter(c => (c !== id));
          tmpSelectNames = (tmpSelectNames).filter(c => (c !== name));
          tmpCheckAllIds = false;
        } else {
          tmpSelectIds.push(id);
          tmpSelectNames.push(name);
        }

        updatedState = { selDataFieldIds: tmpSelectIds, selDataFieldNames: tmpSelectNames, selectAllDataFields: tmpCheckAllIds };

        break;

      case "all":
        updatedState = {
          selDataFieldIds: (selectAllDataFields === true) ? [] : _map(dataFields, "id"),
          selDataFieldNames: (selectAllDataFields === true) ? [] : _map(dataFields, "name"),
          selectAllDataFields: !selectAllDataFields
        };
        break;

      default:
        break;
    }

    this.setState((prevState) => ({ formData: { ...prevState.formData, ...updatedState } }))
  }

  _renderUploadLeadSample = () => {

    return (
      <div className="row">
        <div className="col-lg-12">
          <h5 className="heading-03 text-primary fw-medium mb-10">
            <FormattedMessage id="app_sell_leads.title.upload_lead_sample" defaultMessage="Upload Lead Sample" />
          </h5>
          <p className="mb-25">
            <FormattedMessage id="app_sell_leads.upload_lead_sample.description" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          </p>
        </div>

        <div className="col-lg-4">
          <div className="mb-35">
            <div className="custom-file" data-text="Drop Files Here" data-button="Attach File">
              <input type="file" name="talupload" id="talupload" />
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="mb-35">
            <div className="d-flex align-items-center">
              <div>
                <IconXls height="48" width="42" color="#9D9B9B" />
              </div>
              <div className="flex-grow-1 ps-24">
                <div className="d-flex align-items-center justify-content-between mb-10 heading-03">
                  <span className="text-grey-9d">file-name.xls</span>
                  <span className="text-primary fw-semibold">100%</span>
                </div>
                <div className="progress progress-lg">
                  <div className="progress-bar bg-primary" style={{ width: '100%' }} role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                </div>
              </div>
              <div className="flex-shrink-1 ps-18 align-self-end cursor-pointer">
                <IconClose height="17" width="17" color="#FFFFFF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSelectDataFields = () => {
    const { formData: { selectAllDataFields, selDataFieldIds }, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <h5 className="heading-03 text-primary fw-medium mb-20">
            <FormattedMessage id="app_sell_leads.data_fields.title" defaultMessage="Select the data you would like to sell" />
          </h5>
        </div>
        <div className="col-lg-12">
          <div className="card p-15 mb-32">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-check mb-16" onClick={(e) => this._selectDataFieldIds(null, null, "all")}>
                  <input
                    className="form-check-input border"
                    type="checkbox"
                    name="selectAllDataFields"
                    checked={(selectAllDataFields || false)}
                    key="selectall"
                    onChange={() => { }}
                  />
                  <label className="form-check-label heading-05" htmlFor="selectall">
                    <FormattedMessage id="app_sell_leads.data_fields.select_all" defaultMessage="Select All" />
                  </label>
                </div>
              </div>

              {_map(dataFields, (df, i) => {
                const id = (df.id || null);
                const name = _get(df, "name", "");

                return (
                  <div className="col-lg-3" key={id}>
                    <div className="form-check mb-16" onClick={(e) => this._selectDataFieldIds(id, name)}>
                      <input
                        className="form-check-input border"
                        type="checkbox"
                        name="dataFields"
                        checked={((selDataFieldIds || []).includes(id))}
                        key={`checkbox-${id}`}
                        onChange={() => { }}
                      />
                      <label className="form-check-label heading-05" htmlFor={`checkbox-${id}`}>{name}</label>
                    </div>
                  </div>
                );
              })}

              {(errors.selDataFieldIds || "") && (<ErrorMessage message={(errors.selDataFieldIds || "")} />)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderTargeting = () => {
    const { intl } = this.props;
    const { formData: { region, countriesToTarget, languages, industriesInclude, otherIndustry, industriesExclude, employeeSizes, LeadsPerOrg, jobFunctions, jobLevels, jobTitleToInclude, jobTitleToExclude, jobKeywords }, errors } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">
          <h5 className="heading-03 text-primary fw-medium mb-20"><FormattedMessage id="app_sell_leads.title.targeting" defaultMessage="Targeting" /></h5>
          <p className="mb-25">
            <FormattedMessage id="app_sell_leads.targeting.description" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          </p>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.region" defaultMessage="Region" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(region || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, region: (selectedOption || null) } }))}
                error={(errors.region || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.countries_to_target" defaultMessage="Countries to Target" />
              <span className="text-danger heading-06">*</span></label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                value={(countriesToTarget || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, countriesToTarget: (selectedOption || null) } }))}
                error={(errors.countriesToTarget || "")}
                isMulti={true}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.languages" defaultMessage="Languages" />
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(languages || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, languages: (selectedOption || null) } }))}
                error={(errors.languages || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.industries_include" defaultMessage="Industries Include" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(industriesInclude || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, industriesInclude: (selectedOption || null) } }))}
                error={(errors.industriesInclude || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.other_industries" defaultMessage="Other Industries" />
            </label>
            <input
              type="text"
              className="form-control"
              name="otherIndustry"
              value={(otherIndustry || "")}
              placeholder="Other Industries"
              onChange={(e) => this.setState((prevState) => ({
                formData: {
                  ...prevState.formData,
                  otherIndustry: _get(e, "target.value", "")
                }
              }))}
            />
            {(errors.otherIndustry || "") && (<ErrorMessage message={(errors.otherIndustry || "")} />)}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.industries_exclude" defaultMessage="Industries Exclude" />
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(industriesExclude || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, industriesExclude: (selectedOption || null) } }))}
                error={(errors.industriesExclude || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.employee_sizes" defaultMessage="Employee Sizes" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(employeeSizes || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, employeeSizes: (selectedOption || null) } }))}
                error={(errors.employeeSizes || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.leads_per_org_per_country" defaultMessage="Leads per Org per Country" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(LeadsPerOrg || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, LeadsPerOrg: (selectedOption || null) } }))}
                error={(errors.LeadsPerOrg || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.job_functions" defaultMessage="Job Functions" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(jobFunctions || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, jobFunctions: (selectedOption || null) } }))}
                error={(errors.jobFunctions || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.job_levels" defaultMessage="Job Levels" />
              <span className="text-danger heading-06">*</span>
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(jobLevels || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, jobLevels: (selectedOption || null) } }))}
                error={(errors.jobLevels || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.job_titles_to_include" defaultMessage="Job Titles to Include" />
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(jobTitleToInclude || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, jobTitleToInclude: (selectedOption || null) } }))}
                error={(errors.jobTitleToInclude || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.job_titles_to_exclude" defaultMessage="Job Titles to Exclude" />
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(jobTitleToExclude || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, jobTitleToExclude: (selectedOption || null) } }))}
                error={(errors.jobTitleToExclude || "")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-35">
            <label className="mb-12">
              <FormattedMessage id="app_sell_leads.job_keywords" defaultMessage="Job Keywords" />
            </label>
            <div className="position-relative">
              <Select
                className="text-capitalize form-custom-select"
                placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                options={(defaultList || [])}
                defaultValue={(jobKeywords || null)}
                getOptionLabel={(option) => (option.name || "")}
                getOptionValue={(option) => (option.id || null)}
                isOptionDisabled={(option) => (option.isdisabled || false)}
                onChange={(selectedOption) => this.setState((prevState) => ({ formData: { ...prevState.formData, jobKeywords: (selectedOption || null) } }))}
                error={(errors.jobKeywords || "")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderSuccessMessage = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card p-15">
            <div className="my-30 text-center">
              <IconLgDone height="110" width="110" color="#e84968" />
              <div className="heading-02 text-primary fw-medium mt-10 text-capitalize" role="alert">
                <FormattedMessage id="app_sell_leads.success.title" defaultMessage="Success" />
              </div>
              <div className="px-lg-50">
                <p className="heading-04 mb-50 mt-20">
                  <FormattedMessage id="app_sell_leads.success.sub_title1" defaultMessage="Success, your request has been submitted" />
                </p>
                <p className="heading-04 mb-50 mt-20">
                  <FormattedMessage id="app_sell_leads.success.sub_title2" defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />

                </p>
                <div>
                  <NavLink to="/dashboard" className="btn btn-md btn-primary text-capitalize">
                    <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { formData: { projectName }, showForm, showPreview, showSuccess, formData, errors } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.sell_leads", defaultMessage: "Sell Leads - Leads United" })} </title>
        </Helmet>
        <div className="mb-20">
          <h1 className="heading-02 fw-medium"><FormattedMessage id="app_sell_leads.title" defaultMessage="Sell Leads" /></h1>
        </div>

        {(showForm === true) && (
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-35">
                    <label className="mb-12">
                      <FormattedMessage id="app_sell_leads.project_name" defaultMessage="Project Name" />
                      <span className="text-danger heading-06">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="projectName"
                      value={(projectName || "")}
                      placeholder="Project Name"
                      onChange={(e) => this.setState((prevState) => ({
                        formData: {
                          ...prevState.formData,
                          projectName: _get(e, "target.value", "")
                        }
                      }))}
                    />
                    {(errors.projectName || "") && (<ErrorMessage message={(errors.projectName || "")} />)}
                  </div>
                </div>
              </div>

              {this._renderUploadLeadSample()}
              {this._renderSelectDataFields()}
              {this._renderTargeting()}

              <div className="row">
                <div className="col-lg-12">
                  <div className="text-end mt-lg-30 position-relative">
                    <button className="btn btn-md btn-light" onClick={() => this._handleReset()}>
                      <FormattedMessage id="btn.reset" defaultMessage="reset" />
                      <div className="img-fluid position-absolute top-50 start-0 translate-middle-y ms-20">
                        <IconResetAlt height="20" width="16" color="#FFFFFF" />
                      </div>
                    </button>

                    <button className="btn btn-md btn-primary" onClick={() => this._handleNext()}>
                      <FormattedMessage id="btn.next" defaultMessage="next" />
                      <div className="img-fluid position-absolute top-50 end-0 translate-middle-y me-20">
                        <IconRightCaret height="15" width="8" color="#FFFFFF" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(showPreview === true) && (
          <Preview formData={(formData || {})} setState={(e) => this.setState(e)} />
        )}

        {(showSuccess === true) && (
          this._renderSuccessMessage()
        )}
      </>
    );
  }
}

export default withRouter(injectIntl(SellLeads));
