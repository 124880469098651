import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

import { Modal, showAlertMessage, ErrorMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";

import { IconEye } from "../../helpers/iconStyles";
import config from "../../helpers/config";

import { doChangePassword, clearChangePasswordResponse, clearChangePasswordError } from "../../actions/oauth";

class ChangePasswordUser extends Component {

  constructor(props) {
    super(props);

    this.defaultCredentials = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };

    this.state = {
      credentials: _cloneDeep(this.defaultCredentials),
      showChangePasswordModal: false,
      errors: {},
      showPasswordType: "password"
    };

    this.legalUrl = config.getFrontendURL();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.credentials, this.state.credentials)) { this._handleValidation(); }

    if (!_isEqual(prevProps.error, this.props.error) && !_isEmpty(this.props.error)) {
      const { clearChangePasswordError, error } = this.props;

      showAlertMessage(_get(error, "message", "Something went wrong while changing password."));

      if (typeof clearChangePasswordError === "function") { clearChangePasswordError(); }
    }

    if (!_isEqual(prevProps.success, this.props.success) && !_isEmpty(this.props.success)) {
      const { clearChangePasswordResponse, success } = this.props;

      showAlertMessage(_get(success, "message", "Password changed successfully."), "success");

      this._closePopover();

      if (typeof clearChangePasswordResponse === "function") { clearChangePasswordResponse(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value,
      }
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { credentials: { oldPassword, newPassword, confirmPassword } } = this.state;

    let errors = {};

    if (_isEmpty(oldPassword)) {
      errors["oldPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "old_password", defaultMessage: "Old Password" })
      });
    }

    if (_isEmpty(newPassword)) {
      errors["newPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required." }, {
        field: intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })
      });
    }

    if (_isEqual(oldPassword, newPassword)) {
      errors["newPassword"] = intl.formatMessage({ id: "error.old_new_password_match", defaultMessage: "{field} is same as old password." }, {
        field: intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })
      });
    }

    if (!_isEqual(newPassword, confirmPassword)) {
      errors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} does not match." }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password" })
      });
    }

    this.setState({ errors });
  }

  _doChangePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { doChangePassword } = this.props;
    const { credentials: { oldPassword, newPassword, confirmPassword }, errors } = this.state;

    if (
      _isEmpty(oldPassword) ||
      _isEmpty(newPassword) ||
      _isEmpty(confirmPassword) ||
      !_isEmpty(errors)
    ) {
      this._handleValidation();
      return;
    }

    if (typeof doChangePassword === "function") { doChangePassword({ oldPassword: oldPassword, newPassword: newPassword }); }

  }

  _showPassword = () => {
    const { showPasswordType } = this.state;

    this.setState({ showPasswordType: (showPasswordType === "password") ? "text" : "password" });
  }

  _closePopover(ev) {
    if (typeof ev === "object") { ev.preventDefault(); }

    this.setState({
      credentials: _cloneDeep(this.defaultCredentials),
      errors: {},
      showChangePasswordModal: false
    });
  }

  _renderModal() {
    const { intl } = this.props;
    const { showChangePasswordModal, errors, showPasswordType } = this.state;

    return (
      <Modal
        isOpen={(showChangePasswordModal || false)}
        bodyClassName="bg-white-f2"
        modalTitleClassName="col-md-11"
        modalTitle={intl.formatMessage({ id: "change_password", defaultMessage: "change password" })}
        onHide={() => this._closePopover()}
        onClose={() => this._closePopover()}
      >
        <Form className="form-change-password" autoComplete="off">
          <div className="tab-content">
            <div className="cardBox bg-gray-f8">
              <div className="cardBox-content">
                <div className="card">
                  <div className="card-body p-20">
                    <div className="form-group required mb-35">
                      <label htmlFor="UserOldPassword" className="control-label text-capitalize mb-12">
                        <FormattedMessage id="old_password" defaultMessage="Old Password" />
                      </label>
                      <input
                        type="password"
                        name="oldPassword"
                        className="form-control text-capitalize border"
                        id="oldPassword"
                        placeholder={intl.formatMessage({ id: "old_password", defaultMessage: "Old Password" })}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.oldPassword || "") && (
                        <ErrorMessage message={(errors.oldPassword || "")} />
                      )}
                    </div>
                    <div className="form-group required mb-35">
                      <label className="text-capitalize mb-12" htmlFor="UserNewPassword">
                        <FormattedMessage id="new_password" defaultMessage="New Password" />
                      </label>
                      <div className="position-relative">
                        <span className="position-absolute end-0 top-50 translate-middle-y me-15 cursor-pointer" onClick={() => this._showPassword()}>
                          <IconEye width="24px" height="24px" color="#9d9b9b" />
                        </span>
                        <input
                          type={(showPasswordType || "")}
                          name="newPassword"
                          className="form-control text-capitalize-first border"
                          id="newPassword"
                          placeholder={intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })}
                          onChange={(e) => this._handleChange(e)}
                        />
                      </div>
                      {(errors.newPassword || "") && (
                        <ErrorMessage message={(errors.newPassword || "")} />
                      )}
                    </div>
                    <div className="form-group required mb-35">
                      <label className="text-capitalize mb-12" htmlFor="UserConfirmPassword">
                        <FormattedMessage id="confirm_password" defaultMessage="Confirm Password" />
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control text-capitalize-first border"
                        id="confirmPassword"
                        placeholder={intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password" })}
                        onChange={(e) => this._handleChange(e)}
                      />
                      {(errors.confirmPassword || "") && (
                        <ErrorMessage message={(errors.confirmPassword || "")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="row gutters-5">
          <div className="col-lg-12">
            <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
              <button className="btn btn-primary" onClick={(e) => this._doChangePassword(e)}>
                <FormattedMessage id="change_password" defaultMessage="Change Password" />
              </button>
              <button className="btn btn-secondary" onClick={(e) => this._closePopover(e)}>
                <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {

    return (
      <>
        <button type="button" className="btn btn-sm btn-secondary" onClick={() => this.setState({ showChangePasswordModal: true })}>
          <FormattedMessage id="change_password" defaultMessage="change password" />
        </button>

        {this._renderModal()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: _get(state, "oauth.error", {}),
  success: _get(state, "oauth.success", {}),
  user: _get(state, "oauth.user", {}),
  changePasswordResponse: _get(state, "oauth.changePasswordResponse", true)
});

const mapDispatchToProps = (dispatch) => ({
  doChangePassword: (data) => dispatch(doChangePassword(data)),
  clearChangePasswordError: () => dispatch(clearChangePasswordError()),
  clearChangePasswordResponse: () => dispatch(clearChangePasswordResponse())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordUser)));