import React, { Component } from "react";
import { Column, Table, AutoSizer } from "react-virtualized";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";
import Helmet from "react-helmet";
import moment from "moment";

import { TableHeaderRenderer, TableCellRenderer, NoDataMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { projectStatusLookup } from "../../../helpers/constants";

class ViewQuoteData extends Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;

    this.state = {
      errors: {},
      quoteData: [],
      sortDirection: "ASC",
      sortBy: "project_name",
      pageSize: 1,
      pageNo: 1
    };

    this.columns = [
      {
        id: "project_name",
        width: 0,
        columnAvrgWidth: 3,
        dataKey: "project_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.project_name", defaultMessage: "project name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "delivery_date",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "delivery_date",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.delivery_date", defaultMessage: "delivery date" })} />),
        cellRenderer: (row) => {
          const { displayDateFormat } = props;
          const date = _get(row, "cellData", null);

          return (<TableCellRenderer tooltip={true} value={(date) ? moment(date).format(displayDateFormat) : ""} />)
        }
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "status",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.status", defaultMessage: "status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "cellData", "").toUpperCase();

          const statusClass = _get(projectStatusLookup, `[${value}.statusClass]`, "");

          return (
            <span className={statusClass}><TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} /></span>
          );
        }
      },
      {
        id: "leads_volume",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "leads_volume",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.overall.leads_volume", defaultMessage: "volume of leads" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "budget",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "budget",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "buyer_dashboard.overall.budget", defaultMessage: "Budget" })} />),
        cellRenderer: (row) => {
          const { currencySymbol } = props;

          return (<TableCellRenderer tooltip={true} value={`${currencySymbol}${_get(row, "cellData", null)}`} />)
        }
      },
      {
        id: "id",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "id",
        headerRenderer: () => (<TableHeaderRenderer value={intl.formatMessage({ id: "dashboard.table.details", defaultMessage: "details" })} />),
        cellRenderer: (row) => (
          <span className="d-block text-blue-1a text-decoration-underline text-capitalize cursor-pointer" onClick={() => this.showProjectDashboard(row)}>
            <FormattedMessage id="dashboard.more" defaultMessage="more..." />
          </span>
        )
      },
    ];
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy: sortBy, sortDirection: sortDirection });
  };

  _renderTopFilter = () => {
    return (
      <></>
    )
  }

  _renderOfferingData = () => {
    const { intl } = this.props;
    const { sortDirection, sortBy, quoteData } = this.state;

    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="card p-15">
              <div className="table-responsive">
                <div className="table-data position-relative h-500 overflow-x-auto">
                  <AutoSizer className="table-autosizer-wrapper">
                    {({ height, width }) => (
                      <Table
                        width={width}
                        height={height}
                        headerHeight={32}
                        rowHeight={32}
                        sort={(sort) => this._sort(sort)}
                        sortBy={(sortBy || "project_name")}
                        sortDirection={(sortDirection || "ASC")}
                        rowCount={(quoteData || []).length}
                        rowGetter={({ index }) => (quoteData[index] || {})}
                        noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
                      >
                        {(this.columns || []).map((column, key) => (
                          <Column key={key} {...column} width={width / (column.columnAvrgWidth || 6)} />
                        ))}
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.view_quote_data", defaultMessage: "View Quote Data - Leads United" })} </title>
        </Helmet>
        <div className="mb-20">
          <h1 className="heading-03 fw-medium text-capitalize">
            <FormattedMessage id="view_quote_data.title" defaultMessage="View quote data" />
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {this._renderTopFilter()}
          </div>
          {this._renderOfferingData()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "oauth.user.role_id", 0)
});

export default withRouter(connect(mapStateToProps)(injectIntl(ViewQuoteData)));
