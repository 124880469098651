import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { get as _get, isEqual as _isEqual } from "lodash";
import { connect } from "react-redux";

import Helmet from "react-helmet";

import { withRouter } from "../../components/hoc/withRouter";

import SellerDashboard from "../Dashboard/Seller";
import BuyerDashboard from "../Dashboard/Buyer";

import { USER_ROLES } from "../../helpers/constants";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    const { roleId } = this.props;

    this.state = {
      // 1- buyer, 2- seller
      activeBuyerTab: (
        [_get(USER_ROLES, "SELLER.id", 0), _get(USER_ROLES, "SELLER_MEMBER.id", 0)].includes(roleId)
      ) ? 2 : (
        (
          [_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "BUYER.id", 0), _get(USER_ROLES, "BUYER_MEMBER.id", 0)].includes(roleId)
        ) ? 1 : null
      )
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.roleId, this.props.roleId) && (this.props.roleId !== null)) {
      const { roleId } = this.props;

      let currentRoleId = (
        [_get(USER_ROLES, "SELLER.id", 0), _get(USER_ROLES, "SELLER_MEMBER.id", 0)].includes(roleId)
      ) ? 2 : (
        (
          [_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "BUYER.id", 0), _get(USER_ROLES, "BUYER_MEMBER.id", 0)].includes(roleId)
        ) ? 1 : null
      );

      this.setState({ activeBuyerTab: currentRoleId });
    }
  }

  render() {
    const { roleId, intl } = this.props;
    const { activeBuyerTab } = this.state;

    return (
      <>
        <Helmet>
          <title>{intl.formatMessage({ id: "LU.dashboard", defaultMessage: "Dashboard - Leads United" })}</title>
        </Helmet>
        <div className="mb-20 d-sm-flex justify-content-between align-items-center">
          <h1 className="heading-02 fw-medium text-capitalize"><FormattedMessage id="dashboard.title" defaultMessage="dashboard" /></h1>
          {(_get(USER_ROLES, "ADMIN.id", 0) === (roleId || 0)) && (
            <div className="btn-group d-flex mt-sm-0 mt-20">
              <span
                className={`btn btn-sm text-capitalize fw-normal ${([null, 1].includes(activeBuyerTab)) ? "btn-primary" : "btn-secondary"}`}
                onClick={() => this.setState({ activeBuyerTab: 1 })}
              >
                <FormattedMessage id="dashboard.buyer" defaultMessage="buyer" />
              </span>
              <span
                className={`btn btn-sm text-capitalize fw-normal ${(activeBuyerTab === 2) ? "btn-primary" : "btn-secondary"}`}
                onClick={() => this.setState({ activeBuyerTab: 2 })}
              >
                <FormattedMessage id="dashboard.seller" defaultMessage="seller" />
              </span>
            </div>
          )}
        </div>

        {(
          ((_get(USER_ROLES, "ADMIN.id", 0) === (roleId || 0)) && ([null, 1].includes(activeBuyerTab))) ||
          (_get(USER_ROLES, "BUYER.id", 0) === (roleId || 0)) ||
          (_get(USER_ROLES, "BUYER_MEMBER.id", 0) === (roleId || 0))
        ) && (
            <BuyerDashboard activeBuyerTab={(activeBuyerTab || null)} />
          )}

        {(
          ((_get(USER_ROLES, "ADMIN.id", 0) === (roleId || 0)) && (activeBuyerTab === 2)) ||
          (_get(USER_ROLES, "SELLER.id", 0) === (roleId || 0)) ||
          (_get(USER_ROLES, "SELLER_MEMBER.id", 0) === (roleId || 0))
        ) && (
            <SellerDashboard activeBuyerTab={(activeBuyerTab || null)} />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "oauth.user.role_id", 0)
});

export default withRouter(connect(mapStateToProps)(injectIntl(Dashboard)));