import { combineReducers } from "redux";
import { get as _get } from "lodash";

import actionTypes from "../actions";

const initialUsersData = { status: null, data: [], message: "" };
const list = (state = initialUsersData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_USERS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_USERS_DATA:
      return initialUsersData;

    default:
      return state;
  }
}

export default {
  users: combineReducers({
    list,
  }),
}