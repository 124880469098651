import React, { Component } from "react";
import { forEach as _forEach, get as _get, map as _map, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { matchPath } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "../../../components/hoc/withRouter";

import { IconMarketplace, IconDashboard, IconMagic, IconSearch, IconTeam, IconInvoice, IconUser, IconSetting, IconSmShare, IconBuy, IconSell } from "../../../helpers/iconStyles";
import { appRoutes } from "../../../helpers/constants";
import { ucfirst } from "../../../helpers/utils";

const iconList = {
  iconMarketplace: IconMarketplace,
  iconDashboard: IconDashboard,
  iconBuyLeads: IconBuy,
  iconSellLeads: IconSell,
  iconMagic: IconMagic,
  iconSearch: IconSearch,
  iconSmShare: IconSmShare,
  iconTeam: IconTeam,
  iconInvoice: IconInvoice,
  iconUser: IconUser,
  iconSetting: IconSetting,
};

class Sidebar extends Component {

  static defaultProps = {
    isPublic: false
  };

  constructor(props) {
    super(props);

    this.state = {
      activeMenu: "query",
      activeSubMenu: "advance_search",
      showConfirmationBox: false,
      redirectRoutePath: "",
    };

  }

  componentDidMount() {
    this._setActiveMenu();
  }

  componentDidUpdate(prevProps) {

    if (!_isEqual(_get(prevProps, "location.pathname", "/"), _get(this.props, "location.pathname", "/"))) {
      this._setActiveMenu();
    }
  }

  // Set the active menu based on current route of url || passed from props state
  _setActiveMenu() {
    const { location } = this.props;

    let activeMenu = "";
    let activeSubMenu = "";
    const route = _get(location, "pathname", "/");

    // Check if history has active menu state
    const menuState = _get(location, "state.activeMenu", {});

    if (Object.keys(menuState).length > 0) {
      activeMenu = (menuState.group || "");
      activeSubMenu = (menuState.name || "");
    } else {
      // Match with current url route
      _forEach(appRoutes, (r) => {
        const path = (r.path || "");
        const exact = (r.exact || null);
        const matched = matchPath({ path, exact, strict: false }, route);

        // Break the loop and get the menu
        if (matched) {
          activeMenu = _get(r, "activeMenu.group", "");
          activeSubMenu = _get(r, "activeMenu.name", "");
          return false;
        }
      });
    }

    this.setState({ activeMenu, activeSubMenu });
  }

  // Open parent menu
  _handleActiveParentMenu(e, mainMenu) {
    e.preventDefault();
    const { activeMenu } = this.state;
    const setActiveMenu = ((mainMenu || "") === (activeMenu || "")) ? "" : (mainMenu || "");

    this.setState({ activeMenu: setActiveMenu });
  }

  _redirectToPage = (routePath = "/") => {
    const { navigate } = this.props;

    if (typeof navigate === "function") { navigate(routePath); }
  };

  render() {
    const { menuConfig, adminMenuCollapse } = this.props;
    const { activeMenu, activeSubMenu } = this.state;

    return (
      <nav id="navbarSidebar" className={`d-lg-block sidebar-admin collapse ${(adminMenuCollapse === true) ? "show" : ""}`}>
        <div className="my-20">
          <ul className="nav flex-column">
            {_map(menuConfig, (conf, i) => {
              if (_isEmpty(conf)) { return null; }

              const IconComponent = iconList[(conf.icon || "iconCoin")];

              if (_get(conf, "containSubMenu", false) === true) {
                const mainMenu = (
                  <Nav.Link className={`btn-toggle cursor-pointer ${(i !== activeMenu) ? "collapsed" : "active"}`} key={i} to={"/"} onClick={(e) => this._handleActiveParentMenu(e, i)}>
                    <span className="nav-icon"> <IconComponent width={`${(conf.width || "")}`} height={`${(conf.height || "")}`} /> </span>
                    <span className="nav-text"><FormattedMessage id={`menu.admin.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(i)} /></span>
                  </Nav.Link>
                );

                const childMenu = [];
                _map(conf.subMenus, (a, k) => {
                  const SubIcon = iconList[(a.icon || "iconDashboard")];

                  childMenu.push((
                    <Nav.Item key={k}>
                      <div
                        className={`nav-link cursor-pointer text-capitalize ${(a.name === activeSubMenu) ? "active" : ""}`}
                        onClick={() => (a.name === "api_docs") ? window.open(`${(process.env.REACT_APP_API_DOC_LINK || "")}`, "_blank") : this._redirectToPage(`/${(a.route || "")}`)}
                      >
                        <span className="nav-icon"> <SubIcon width={`${(a.width || "")}`} height={`${(a.height || "")}`} /> </span>
                        <span className="nav-text"><FormattedMessage id={`menu.admin.${(a.menuLanguageKey || "")}`} defaultMessage={ucfirst((a.menuLanguageKey || ""))} /></span>
                      </div>
                    </Nav.Item>
                  ));
                });

                return (
                  <Nav.Item as="li" key={i}>
                    {mainMenu}
                    <div className={`collapse ${(i === activeMenu) ? "show" : ""}`}>
                      <ul className="list-unstyled">
                        {childMenu}
                      </ul>
                    </div>
                  </Nav.Item>
                );
              } else {
                let routeUrl = `/${(conf.route || "")}`;

                return (
                  <li className="nav-item" key={i}>
                    <div key={i} className={(`nav-link cursor-pointer text-capitalize ${((conf.group || "") === activeMenu) ? "active" : ""}`)} onClick={() => this._redirectToPage(routeUrl)}>
                      <span className="nav-icon"> <IconComponent width={`${(conf.width || "")}`} height={`${(conf.height || "")}`} /> </span>
                      <span className="nav-text"><FormattedMessage id={`menu.admin.${(conf.menuLanguageKey || "")}`} defaultMessage={ucfirst(conf.menuLanguageKey || "")} /></span>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: _get(state, "oauth.user", {}),
  menuConfig: _get(state, "application.config.menu", {}),
  adminMenuCollapse: _get(state, "application.adminMenuCollapse", false)
});

export default withRouter(connect(mapStateToProps, null)(injectIntl(Sidebar)));
