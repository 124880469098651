import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { Navigate, Outlet } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";

import { withRouter } from "../withRouter";

import { USER_ROLES, userProfileStatusLookup } from "../../../helpers/constants";
import { decryptData } from "../../../helpers/crypto";
import config from "../../../helpers/config";

import { setToken } from "../../../actions/oauth";

class PrivateRoute extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies, setToken, token } = props;

    if ((typeof setToken === "function") && _isEmpty(token)) {
      const tokenData = decryptData(cookies.get("tknData"));

      setToken(_get(tokenData, "token", {}));
    }
  }

  render() {
    const { token, roleId, approvalStatus, ...rest } = this.props;

    const from = _get(rest, "location.pathname", "/");

    if (!_isEmpty(token) && [config.getFrontendURL()].includes(window.location.host)) {

      if (
        (roleId === _get(USER_ROLES, "SELLER.id", 0)) &&
        [_get(userProfileStatusLookup, "UNDER_REVIEW.id", 0), _get(userProfileStatusLookup, "REJECTED.id", 0)].includes(approvalStatus)
      ) {

        return <Navigate to="profile-review" />
      } else {

        window.location = config.getAdminURL(true, true, "/dashboard");
        return null;
      }
    }

    if (
      (roleId === _get(USER_ROLES, "SELLER.id", 0)) &&
      [_get(userProfileStatusLookup, "UNDER_REVIEW.id", 0), _get(userProfileStatusLookup, "REJECTED.id", 0)].includes(approvalStatus)
    ) {

      window.location = config.getFrontendURL(true, true, "/profile-review");
      return null;
    }

    return (
      (!_isEmpty(token)) ? <Outlet {...rest} /> : <Navigate to={{ pathname: "/login", search: `?continue=${from || "/"}` }} />
    )
  }
}

const mapStateToProps = (state) => ({
  token: _get(state, "oauth.token"),
  roleId: _get(state, "oauth.user.role_id", null),
  approvalStatus: _get(state, "oauth.user.approval_status", null)
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(PrivateRoute)));
