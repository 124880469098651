import { combineReducers } from "redux";
import { get as _get } from "lodash";

import actionTypes from "../actions";

const initialDropdownLookup = { status: null, data: [], message: "" };
const dropdownLookup = (state = initialDropdownLookup, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DROPDOWN_LOOKUP_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_DROPDOWN_LOOKUP_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DROPDOWN_LOOKUP_DATA:
      return initialDropdownLookup;

    default:
      return state;
  }
}

const initialAddBuyLeads = { status: null, data: [], message: "" };
const addBuyLeads = (state = initialAddBuyLeads, action) => {
  switch (action.type) {
    case actionTypes.ADD_BUY_LEADS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.ADD_BUY_LEADS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_BUY_LEADS_DATA:
      return initialAddBuyLeads;

    default:
      return state;
  }
}

const initialTopFilter = { status: null, data: {}, message: "" };
const dashboardTopFilters = (state = initialTopFilter, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_TOP_FILTERS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_DASHBOARD_TOP_FILTERS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_DASHBOARD_TOP_FILTERS_DATA:
      return initialTopFilter;

    default:
      return state;
  }
}

const initialProjectDetails = { status: null, data: {}, message: "" };
const projectDetails = (state = initialProjectDetails, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
    case actionTypes.FETCH_PROJECT_DETAILS_ERROR:
    case actionTypes.RESET_ALL_TABS_DATA:
      return initialProjectDetails;

    default:
      return state;
  }
}

const intiailState = { selectedSeller: { id: 0, user_name: "Select" }, selectedBuyer: { id: 0, user_name: "Select" }, selectedProject: { id: 0, project_name: "Select" }, startDate: null, endDate: null }
const dashboardFiltersSelection = (state = intiailState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DASHBOARD_FILTERS_SELECTION:
      const key = _get(action, "payload.key", "");
      return { ...state, [key]: _get(action, "payload.value", null) };

    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return intiailState;

    default:
      return state;
  }
}

const initialDashboardError = { status: null, data: {}, message: "" };
const errorDashboard = (state = initialDashboardError, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_DETAILS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.FETCH_PROJECT_DETAILS_SUCCESS:
      return initialDashboardError;

    case actionTypes.RESET_DASHBOARD_ERROR:
      return initialDashboardError;

    default:
      return state;
  }
}

const initialMapping = { showModal: false, data: {}, success: {}, successMessage: "" };

const dataMapping = (state = initialMapping, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_SUCCESS:
      const showModal = _get(action, "payload.showModel", true)
      return { showModal, data: _get(action, "payload.data", {}), success: {}, successMessage: "" };

    case actionTypes.SAVE_UPLOAD_MAPPING_SUCCESS:
      return { showModal: false, data: {}, success: _get(action, "payload.data", {}), successMessage: _get(action, "payload.message", {}) };

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
      return initialMapping;

    default:
      return state;
  }
}

const dataMappingErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_ERROR:
    case actionTypes.SAVE_UPLOAD_MAPPING_ERROR:
      return (action.error || {});

    case actionTypes.RESET_DATA_MAPPING_CONFIG:
    case actionTypes.RESET_DATA_MAPPING_ERROR:
      return {};

    default:
      return state;
  }
}

const workSheets = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_READ_FILE_ERROR:
      return (_get(action, "error.data", {}) || {});

    default:
      return state;
  }
}

const initialRemoveUploadState = { status: null, message: "" };

const removeUpload = (state = initialRemoveUploadState, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_UPLOADED_FILE_SUCCESS:
      return { status: true, message: _get(action, "payload.message", "") };

    case actionTypes.REMOVE_UPLOADED_FILE_ERROR:
      return { status: false, message: _get(action, "error.message", "") };

    case actionTypes.RESET_REMOVE_UPLOADED_FILE:
      return initialRemoveUploadState;
    default:
      return state;
  }
}

const initialSubmitQuote = { status: null, data: {}, message: "" };
const submitQuote = (state = initialSubmitQuote, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_QUOTE_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.SUBMIT_QUOTE_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_SUBMIT_QUOTE_DATA:
      return initialSubmitQuote;

    default:
      return state;
  }
}

const initialOverallDashboardRecords = { status: null, data: [], message: "" };
const overallDashboardRecords = (state = initialOverallDashboardRecords, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OVERALL_DASHBOARD_RECORDS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_OVERALL_DASHBOARD_RECORDS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_OVERALL_DASHBOARD_RECORDS:
      return initialOverallDashboardRecords;

    default:
      return state;
  }
}

const initialOverallDashboardProjects = { status: null, data: [], message: "" };
const overallDashboardProjects = (state = initialOverallDashboardProjects, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OVERALL_DASHBOARD_PROJECTS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_OVERALL_DASHBOARD_PROJECTS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_OVERALL_DASHBOARD_PROJECTS:
      return initialOverallDashboardProjects;

    default:
      return state;
  }
}

const initialProjectQuotes = { status: null, data: [], message: "" };
const dashboardProjectQuotes = (state = initialProjectQuotes, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_DASHBOARD_PROJECT_QUOTE_DETAILS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DASHBOARD_PROJECT_QUOTE_DETAILS:
    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return initialProjectQuotes;

    default:
      return state;
  }
}

const initialProjectTimeline = { status: null, data: [], message: "" };
const dashboardProjectTimeline = (state = initialProjectTimeline, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_DASHBOARD_PROJECT_TIMELINE_DETAILS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DASHBOARD_PROJECT_TIMELINE_DETAILS:
    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return initialProjectTimeline;

    default:
      return state;
  }
}

const initialCostPerLead = { status: null, data: [], message: "" };
const dashboardCostPerLead = (state = initialCostPerLead, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_DASHBOARD_COST_PER_LEAD_DETAILS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DASHBOARD_COST_PER_LEAD_DETAILS:
    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return initialCostPerLead;

    default:
      return state;
  }
}

const initialQuoteDetails = { status: null, data: [], message: "" };
const quoteDetails = (state = initialQuoteDetails, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUOTE_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_QUOTE_DETAILS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
    case actionTypes.RESET_QUOTE_DETAILS_DATA:
    case actionTypes.RESET_ALL_TABS_DATA:
      return initialQuoteDetails;

    default:
      return state;
  }
}

const selectedQuoteGroupId = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_QUOTE_GROUP_ID:
      return _get(action, "groupId", null);

    case actionTypes.SUBMIT_QUOTE_SUCCESS:
      return { id: _get(action, "data.quote_id", null), name: _get(action, "data.quote_id", null) };

    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return null;

    default:
      return state;
  }
}

const selectedQuoteId = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_QUOTE_ID:
      return _get(action, "quoteId", null);

    case actionTypes.RESET_PROPS_AFTER_PROJECT_DELETED:
      return null;

    default:
      return state;
  }
}

const initialUpdateQuote = { status: null, data: {}, message: "" };
const updateQuoteDetails = (state = initialUpdateQuote, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_QUOTE_STATUS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.UPDATE_QUOTE_STATUS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_UPDATE_QUOTE_STATUS_DATA:
      return initialUpdateQuote;

    default:
      return state;
  }
}

const initialQuoteList = { status: null, data: [], message: "" };
const quoteGroupList = (state = initialQuoteList, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUOTE_GROUP_LIST_SUCCESS:
      return { status: true, data: _get(action, "data.data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_QUOTE_GROUP_LIST_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_QUOTE_GROUP_LIST_DATA:
      return initialQuoteList;

    default:
      return state;
  }
}

const initialBatchList = { status: null, data: [], message: "" };
const quoteBatchList = (state = initialBatchList, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUOTE_BATCH_LIST_SUCCESS:
      return { status: true, data: _get(action, "data.data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_QUOTE_BATCH_LIST_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_QUOTE_BATCH_LIST_DATA:
      return initialBatchList;

    default:
      return state;
  }
}

const initialUploadLeadData = { status: null, data: {}, message: "" };
const uploadLeadData = (state = initialUploadLeadData, action) => {
  switch (action.type) {
    case actionTypes.SAVE_UPLOAD_LEAD_DATA_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.SAVE_UPLOAD_LEAD_DATA_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_UPLOAD_LEAD_DATA:
      return initialUploadLeadData;

    default:
      return state;
  }
}

const initialDataUploadHistory = { status: null, data: [], message: "" };
const dataUploadHistory = (state = initialDataUploadHistory, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_UPLOAD_HISTORY_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_DATA_UPLOAD_HISTORY_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DATA_UPLOAD_HISTORY_DATA:
      return initialDataUploadHistory;

    default:
      return state;
  }
}

const initialDataSummaryLog = { status: null, data: [], message: "" };
const dataSummaryLog = (state = initialDataSummaryLog, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_SUMMARY_LOG_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_DATA_SUMMARY_LOG_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DATA_SUMMARY_LOG_DATA:
      return initialDataSummaryLog;

    default:
      return state;
  }
}

const initialDataAcceptanceDetails = { status: null, data: [], message: "" };
const dataAcceptanceDetails = (state = initialDataAcceptanceDetails, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_ACCEPTANCE_DETAILS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_DATA_ACCEPTANCE_DETAILS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DATA_ACCEPTANCE_DETAILS_DATA:
    case actionTypes.RESET_ALL_TABS_DATA:
      return initialDataAcceptanceDetails;

    default:
      return state;
  }
}

const initialDataCheckFile = { status: null, data: {}, message: "" };
const dataCheckFile = (state = initialDataCheckFile, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UPLOADED_DATA_CHECK_FILE_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_UPLOADED_DATA_CHECK_FILE_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_UPLOADED_DATA_CHECK_FILE_DATA:
    case actionTypes.RESET_ALL_TABS_DATA:
      return initialDataCheckFile;

    default:
      return state;
  }
}

const initialUpdateQuoteBatchUpload = { status: null, data: {}, message: "" };
const updateQuoteBatchUpload = (state = initialUpdateQuoteBatchUpload, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_QUOTE_BATCH_UPLOAD_STATUS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "data.message", "") };

    case actionTypes.UPDATE_QUOTE_BATCH_UPLOAD_STATUS_ERROR:
      return { status: false, data: {}, message: _get(action, "error.message", "") };

    case actionTypes.RESET_UPDATE_QUOTE_BATCH_UPLOAD_STATUS_DATA:
      return initialUpdateQuoteBatchUpload;

    default:
      return state;
  }
}

const initialDataNumbers = { status: null, data: [], message: "" };
const dataNumbers = (state = initialDataNumbers, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_NUMBERS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "data.message", "") };

    case actionTypes.FETCH_DATA_NUMBERS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_DATA_NUMBERS_DATA:
      return initialDataNumbers;

    default:
      return state;
  }
}

const intitialSelectEditQuoteGroupId = { status: null, data: {}, message: "" };
const selectEditQuoteGroupId = (state = intitialSelectEditQuoteGroupId, action) => {
  switch (action.type) {
    case actionTypes.SET_EDIT_QUOTE_GROUP_ID:
      return { status: true, data: _get(action, "payload", {}), message: _get(action, "data.message", "") };

    case actionTypes.RESET_EDIT_QUOTE_GROUP_ID:
      return intitialSelectEditQuoteGroupId;

    default:
      return state;
  }
}

const discussionData = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_DISCUSSION_MESSAGES_SUCCESS:
      return _get(action, "payload.data", []);

    default:
      return state;
  }
}
const initialProjectDashboardOverallRecords = { status: null, data: [], message: "" };
const projectDashboardOverallRecords = (state = initialProjectDashboardOverallRecords, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_PROJECT_DASHBOARD_OVERALL_RECORDS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    default:
      return state;
  }
}

const discussionErrors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DISCUSSION_MESSAGES_ERROR:
      return (action.error || {});

    case actionTypes.RESET_DISCUSSION_MESSAGE_ERRORS:
      return {};

    default:
      return state;
  }
}

const initialApproveSellerQuote = { status: null, data: [], message: "" };
const approveSellerQuote = (state = initialApproveSellerQuote, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_SELLER_QUOTE_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.APPROVE_SELLER_QUOTE_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_APPROVE_SELLER_QUOTE_DATA:
      return initialApproveSellerQuote;

    default:
      return state;
  }
}

const quoteLeads = (state = {}, action) => {

  switch (action.type) {
    case actionTypes.GET_QUOTE_LEADS_SUCCESS:
      if (_get(action, "data.data.leads_page_info[0].current_page", 1) > 1) {
        const oldData = _get(action, "oldData", []);
        const newData = _get(action, "data.data.leads", []);

        return {
          data: {
            data: {
              "leads": [...oldData, ...newData],
              "leads_page_info": _get(action, "data.data.leads_page_info", []),
            },
            message: _get(action, "data.message", ""),
            status: _get(action, "data.status", true),
          },
          status: true,
          message: _get(action, "message", "")
        };
      } else {
        return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };
      }

    case actionTypes.GET_QUOTE_LEADS_ERROR:
    case actionTypes.RESET_ALL_TABS_DATA:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    default:
      return state;
  }
}

const initialApproveRejectQuoteLeads = { status: null, data: [], message: "" };
const approveRejectQuoteLeads = (state = initialApproveRejectQuoteLeads, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_REJECT_QUOTE_LEADS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.APPROVE_REJECT_QUOTE_LEADS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_APPROVE_REJECT_QUOTE_LEADS_DATA:
      return initialApproveRejectQuoteLeads;

    default:
      return state;
  }
}

const initialExportQuoteLeads = { status: null, data: [], message: "" };
const exportQuoteLeads = (state = initialExportQuoteLeads, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_QUOTE_LEADS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.EXPORT_QUOTE_LEADS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_EXPORT_QUOTE_LEADS_DATA:
      return initialExportQuoteLeads;

    default:
      return state;
  }
}

const initialLeadRejectionReasons = { status: null, data: [], message: "" };
const leadRejectionReasons = (state = {}, action) => {

  switch (action.type) {
    case actionTypes.FETCH_LEAD_REJECTION_REASONS_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_LEAD_REJECTION_REASONS_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_FETCH_LEAD_REJECTION_REASONS_DATA:
    case actionTypes.RESET_ALL_TABS_DATA:
      return initialLeadRejectionReasons;

    default:
      return state;
  }
}

const initialUpdateLead = { status: null, data: [], message: "" };
const updateLead = (state = {}, action) => {

  switch (action.type) {
    case actionTypes.UPDATE_LEAD_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.UPDATE_LEAD_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_UPDATE_LEAD_DATA:
      return initialUpdateLead;

    default:
      return state;
  }
}

const initialLeadsRequiredFieldsLookup = { status: null, data: [], message: "" };
const leadsRequiredFieldsLookup = (state = initialLeadsRequiredFieldsLookup, action) => {
  switch (action.type) {
    case actionTypes.FETCHLEADS_REQUIRED_FIELDS_LOOKUP_VALUES_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_LEADS_REQUIRED_FIELDS_LOOKUP_VALUES:
      return initialLeadsRequiredFieldsLookup;

    default:
      return state;
  }
}

const initialProjectRequiredFields = { status: null, data: {}, message: "" };
const projectRequiredFields = (state = initialProjectRequiredFields, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_REQUIRED_FIELDS_SUCCESS:
      return { status: true, data: _get(action, "data", {}), message: _get(action, "message", "") };

    case actionTypes.FETCH_PROJECT_REQUIRED_FIELDS_ERROR:
      return initialProjectRequiredFields;

    default:
      return state;
  }
}

const buyLeads = combineReducers({
  dropdownLookup: dropdownLookup,
  addBuyLeads: addBuyLeads,
  leadsRequiredFieldsLookup: leadsRequiredFieldsLookup
});

const workflowDetails = combineReducers({
  quoteList: quoteGroupList,
  batchList: quoteBatchList,
  uploadLeadData: uploadLeadData,
  dataSummaryLog: dataSummaryLog,
  dataUploadHistory: dataUploadHistory,
  dataAcceptanceDetails: dataAcceptanceDetails,
  dataCheckFile: dataCheckFile,
  updateQuoteBatchUpload: updateQuoteBatchUpload,
  dataNumbers: dataNumbers,
  selectEditQuoteGroupId: selectEditQuoteGroupId,
  quoteLeads: quoteLeads,
  exportQuoteLeads: exportQuoteLeads,
  leadRejectionReasons: leadRejectionReasons,
  updateLead: updateLead,
  projectRequiredFields: projectRequiredFields
});

const dashboard = combineReducers({
  errorDashboard,
  projectDetails,
  quoteDetails,
  submitQuote,
  selectedQuoteGroupId,
  selectedQuoteId,
  dashboardTopFilters: dashboardTopFilters,
  overallDashboardRecords: overallDashboardRecords,
  overallDashboardProjects: overallDashboardProjects,
  dashboardFiltersSelection: dashboardFiltersSelection,
  projectQuotes: dashboardProjectQuotes,
  projectTimeline: dashboardProjectTimeline,
  costPerLead: dashboardCostPerLead,
  updateQuoteDetails: updateQuoteDetails,
  discussion: combineReducers({
    data: discussionData,
    errors: discussionErrors
  }),
  projectDashboardOverallRecords: projectDashboardOverallRecords,
  approveSellerQuote: approveSellerQuote,
  approveRejectQuoteLeads: approveRejectQuoteLeads
});

const fileUpload = combineReducers({
  dataMapping,
  errors: dataMappingErrors,
  removeUpload,
  workSheets
});

export default {
  leads: combineReducers({
    buyLeads,
    dashboard,
    fileUpload,
    workflowDetails
  }),
}
