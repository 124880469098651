import React, { Component, Fragment } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { Column, Table, AutoSizer } from "react-virtualized";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { NoDataMessage, TableHeaderRenderer, TableCellRenderer, showAlertMessage, Select, ConfirmBox, Modal, ErrorMessage } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";
import EditUser from "./components/EditUser";

import { userProfileStatusLookup, userStatusesLookup, USER_ROLES, USER_MANAGEMENT_ROLES, userProfileStatuses } from "../../helpers/constants";
import { IconClose, IconSmDone, IconSearch } from "../../helpers/iconStyles";
import { isValidEmail, isValidPhone } from "../../helpers/utils";
import config from "../../helpers/config";

import { approveRejectSeller, lockUnlockUser } from "../../services/userManagement";
import { deleteTeamMember } from "../../services/application";

import { doSignUp, clearOauthError, clearOauthSuccess, doValidateDisplayName, clearValidateDisplayName, doForgotPassword } from "../../actions/oauth";
import { fetchUsers, resetUsersData } from "../../actions/userManagement";
import { updateLoadingState } from "../../actions/application";

const defaultRolefilter = { id: null, name: "Select" };
const defaultStatusfilter = { id: null, name: "All Status" };

class UserManagement extends Component {

  constructor(props) {
    super(props);

    const { intl } = props;

    this.defaultFilters = {
      roleId: defaultRolefilter,
      profileStatus: defaultStatusfilter
    };

    this.defaultCreateUserState = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      password: "",
      confirmPassword: "",
      displayName: "",
      role: null,
    };

    this.timer = null;
    this.legalUrl = config.getFrontendURL(true, true);

    this.state = {
      searchText: "",
      filters: _cloneDeep(this.defaultFilters),
      createUser: _cloneDeep(this.defaultCreateUserState),
      tableData: [],
      showUnderReviewModal: false,
      showApproveModal: false,
      showRejectModal: false,
      showSuspendedModal: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      showLockedModal: false,
      showUnlockedModal: false,
      showRestoreModal: false,
      showResetPasswordModal: false,
      reSyncTableData: false,
      errors: {},
      selectedUser: null,
      showDisplayNameLink: 1,
      sort: "ASC",
      sortBy: "first_name",
      pageNo: 1,
      pageSize: 100
    };

    this.columns = [
      {
        id: "first_name",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "first_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.column.name", defaultMessage: "Name" })} />),
        cellRenderer: (row) => {
          const userName = ((_get(row, "rowData.first_name", "") !== "") && (_get(row, "rowData.last_name", "") !== "")) ? `${_get(row, "rowData.first_name", "")} ${_get(row, "rowData.last_name", "")}` : "";

          return (<TableCellRenderer value={userName} tooltip={true} />)
        },
      },
      {
        id: "email",
        width: 0,
        columnAvrgWidth: 4,
        dataKey: "email",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.column.email", defaultMessage: "Email" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "client_name",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "client_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.column.company", defaultMessage: "Company" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "user_name",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "user_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.column.display_name", defaultMessage: "Display Name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "role",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "role",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "user_management.table.column.role", defaultMessage: "Role" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "approval_status",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "approval_status",
        headerRenderer: () => (<TableHeaderRenderer sortable={false} value={intl.formatMessage({ id: "user_management.table.column.profile_status", defaultMessage: "Profile Status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "rowData.approval_status_text", "").toUpperCase();

          const statusClass = _get(userProfileStatusLookup, `[${value}].statusClass`, "");
          const statusText = _get(userProfileStatusLookup, `[${value}].status`, "");

          return (
            <TableCellRenderer className={`text-truncate text-capitalize ${statusClass}`} value={statusText} tooltip={true} />
          )
        },
      },
      {
        id: "is_deleted",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "is_deleted",
        headerRenderer: () => (<TableHeaderRenderer sortable={false} value={intl.formatMessage({ id: "user_management.table.column.status", defaultMessage: "Status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "rowData.deleted_status_text", "").toUpperCase();

          const statusClass = _get(userStatusesLookup, `[${value}].statusClass`, "");
          const statusText = _get(userStatusesLookup, `[${value}].status`, "");

          return (
            <TableCellRenderer className={`text-truncate text-capitalize ${statusClass}`} value={statusText} tooltip={true} />
          )
        },
      },
      {
        id: "user_id",
        width: 0,
        columnAvrgWidth: 10,
        dataKey: "user_id",
        headerRenderer: () => (<TableHeaderRenderer sortable={false} value={intl.formatMessage({ id: "user_management.table.column.action", defaultMessage: "Action" })} />),
        cellRenderer: (row) => {
          return this._renderActionMenu(row);
        }
      },
    ];
  }

  componentDidMount() {
    const { tableData } = this.props;

    this._fetchTableData();

    this.setState({ tableData: _get(tableData, "data", []), reSyncTableData: false });
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.createUser, this.state.createUser)) { this._handleValidation(); }

    if (!_isEqual(prevProps.tableData, this.props.tableData) && !_isEmpty(this.props.tableData)) {
      const { tableData, resetUsersData } = this.props;

      if (_get(tableData, "status", null) === null) { return false; }

      if (_get(tableData, "status", false) === true) {
        this.setState({ tableData: _get(tableData, "data", []), reSyncTableData: false });
      } else {
        showAlertMessage(tableData.message || "Something went wrong while fetching users.");

        if (typeof resetUsersData === "function") { resetUsersData(); }
      }
    }

    if (
      (!_isEqual(prevState.filters, _get(this.state, "filters", {})) && !_isEmpty(_get(this.state, "filters", {}))) ||
      (!_isEqual(prevState.reSyncTableData, _get(this.state, "reSyncTableData", false)) && (_get(this.state, "reSyncTableData", false) === true)) ||
      !_isEqual(prevState.sort, this.state.sort) ||
      !_isEqual(prevState.sortBy, this.state.sortBy)
    ) {
      this._fetchTableData();
    }

    if (!_isEqual(prevState.searchText, _get(this.state, "searchText", ""))) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this._fetchTableData();
      }, 500);
    }

    if (!_isEqual(_get(prevState, "createUser.displayName", ""), _get(this.state, "createUser.displayName", ""))) {
      this.setState({ showDisplayNameLink: 1 });
    }

    if (!_isEqual(prevProps.validateDisplayName, this.props.validateDisplayName) && !_isEmpty(this.props.validateDisplayName)) {
      const { validateDisplayName, clearValidateDisplayName } = this.props;

      if (_get(validateDisplayName, "status", false) === null) { return false; }

      if (_get(validateDisplayName, "status", false) === true) {
        showAlertMessage(_get(validateDisplayName, "data.message", "Display Name available."), "success");

        this.setState({ showDisplayNameLink: 2 });
      } else {
        showAlertMessage(_get(validateDisplayName, "message", "Something went wrong while checking display name."));

        this.setState({ showDisplayNameLink: 3 });
      }

      if (typeof clearValidateDisplayName === "function") { clearValidateDisplayName(); }
    }

    if (!_isEqual(prevProps.success, this.props.success) && !_isEmpty(this.props.success)) {
      showAlertMessage(_get(this.props, "success.message", "User added successfully!"), "success");

      this.setState({ showCreateModal: false, showResetPasswordModal: false, createUser: _cloneDeep(this.defaultCreateUserState), errors: {}, showDisplayNameLink: 1 });
      this._fetchTableData();

      if (typeof this.props.clearOauthSuccess === "function") { this.props.clearOauthSuccess(); }
    }

    if (!_isEqual(prevProps.error, this.props.error) && !_isEmpty(this.props.error)) {
      showAlertMessage(_get(this.props, "error.message", "something went wrong while creating user!"));

      if (typeof this.props.clearOauthError === "function") { this.props.clearOauthError(); }
    }
  }

  _renderActionMenu = (row) => {
    const approvalStatus = _get(row, "rowData.approval_status", 0);
    const isDeleted = _get(row, "rowData.is_deleted", 0);
    const userRoleId = _get(row, "rowData.role_id", 0);

    return (
      <Dropdown className="navbar-nav action-menu-dropdown">
        <Dropdown.Toggle>
          <span>
            <FormattedMessage id="dropdown.dots" defaultMessage="•••" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-end position-absolute">

          {(isDeleted !== _get(userStatusesLookup, "DELETED.id", null)) && (
            <Fragment>
              <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showEditModal: true })}>
                <span className="text-capitalize cursor-pointer">
                  <FormattedMessage id="user_management.action.edit" defaultMessage="Edit" />
                </span>
              </Dropdown.Item>

              {(userRoleId === _get(USER_ROLES, "SELLER.id", 0)) && (approvalStatus !== _get(userProfileStatusLookup, "UNDER_REVIEW.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showUnderReviewModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.under_review" defaultMessage="Under Review" />
                  </span>
                </Dropdown.Item>
              )}

              {(userRoleId === _get(USER_ROLES, "SELLER.id", 0)) && (approvalStatus !== _get(userProfileStatusLookup, "APPROVED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showApproveModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.approve" defaultMessage="Approve" />
                  </span>
                </Dropdown.Item>
              )}

              {(userRoleId === _get(USER_ROLES, "SELLER.id", 0)) && (approvalStatus !== _get(userProfileStatusLookup, "REJECTED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showRejectModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.reject" defaultMessage="Reject" />
                  </span>
                </Dropdown.Item>
              )}

              {(userRoleId === _get(USER_ROLES, "SELLER.id", 0)) && (approvalStatus !== _get(userProfileStatusLookup, "SUSPENDED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showSuspendedModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.suspend" defaultMessage="Suspend" />
                  </span>
                </Dropdown.Item>
              )}

              {(isDeleted === _get(userStatusesLookup, "UNLOCKED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showLockedModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.lock" defaultMessage="Lock" />
                  </span>
                </Dropdown.Item>
              )}

              {(isDeleted === _get(userStatusesLookup, "LOCKED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showUnlockedModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.unlock" defaultMessage="Unlock" />
                  </span>
                </Dropdown.Item>
              )}

              {(isDeleted !== _get(userStatusesLookup, "DELETED.id", null)) && (
                <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showDeleteModal: true })}>
                  <span className="text-capitalize cursor-pointer">
                    <FormattedMessage id="user_management.action.delete" defaultMessage="Delete" />
                  </span>
                </Dropdown.Item>
              )}

              <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showResetPasswordModal: true })}>
                <span className="text-capitalize cursor-pointer">
                  <FormattedMessage id="user_management.action.reset_password" defaultMessage="Reset Password" />
                </span>
              </Dropdown.Item>
            </Fragment>
          )}

          {(isDeleted === _get(userStatusesLookup, "DELETED.id", null)) && (
            <Dropdown.Item className="dropdown-item text-capitalize" onClick={() => this.setState({ selectedUser: _get(row, "rowData", null), showRestoreModal: true })}>
              <span className="text-capitalize cursor-pointer">
                <FormattedMessage id="user_management.action.restore" defaultMessage="Restore" />
              </span>
            </Dropdown.Item>
          )}

        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { createUser: { firstName, lastName, email, phoneNumber, displayName, company, password, confirmPassword, role } } = this.state;

    let errors = {};

    if (_get(role, "id", null) === null) {
      errors["role"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "role", defaultMessage: "Role" }),
      });
    }

    if (_isEmpty(firstName)) {
      errors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "first_name", defaultMessage: "first_name" }),
      });
    }

    if (_isEmpty(lastName)) {
      errors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "last_name", defaultMessage: "last_name" }),
      });
    }

    if (_isEmpty(email)) {
      errors["email"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if (!_isEmpty(email) && !isValidEmail(email)) {
      errors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "email", defaultMessage: "email" }),
      });
    }

    if (!isValidPhone(phoneNumber)) {
      errors["phoneNumber"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "phone_number", defaultMessage: "phone number" }),
      });
    }

    if (_isEmpty(company)) {
      errors["company"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "company", defaultMessage: "Company" }),
      });
    }

    if (_isEmpty(password)) {
      errors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password" }),
      });
    }

    if (_isEmpty(displayName)) {
      errors["displayName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "display_name", defaultMessage: "Display Name" }),
      });
    }

    if (!_isEqual(password, confirmPassword)) {
      errors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} is no matched" }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password", })
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errors });
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      createUser: {
        ...prevState.createUser,
        [name]: value
      },
    }));
  }

  _fetchTableData = () => {
    const { fetchUsers } = this.props;
    const { filters, searchText, sort, sortBy, pageNo, pageSize } = this.state;

    if (typeof fetchUsers === "function") {
      fetchUsers({
        searchText: (searchText || ""),
        pageNo: (pageNo || 1),
        pageSize: (pageSize || 100),
        sort: (sort || null),
        sortBy: (sortBy || null),
        profileStatus: _get(filters, "profileStatus.id", null),
        roleId: _get(filters, "roleId.id", null),
      });
    }
  }

  _createUser = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { doSignUp } = this.props;
    const { createUser: { firstName, lastName, email, phoneNumber, displayName, password, confirmPassword, company, role }, errors } = this.state;

    if ((_get(role, "id", null) === null) || _isEmpty(email) || _isEmpty(password) || _isEmpty(firstName) || _isEmpty(lastName) || _isEmpty(company) || _isEmpty(confirmPassword) || _isEmpty(displayName) || _isEmpty(phoneNumber) || !_isEmpty(errors)) {
      this._handleValidation();

      return false;
    }

    if (typeof doSignUp === "function") {
      doSignUp({
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phone": phoneNumber,
        "company": company,
        "password": password,
        "username": displayName,
        "agreeTc": true,
        "role": _get(role, "id", 0)
      });
    }
  }

  _doValidateDisplayName() {
    const { doValidateDisplayName } = this.props;
    const { createUser: { displayName } } = this.state;

    if (_isEmpty(displayName)) {
      showAlertMessage("Please enter display name");
      return;
    }

    if (typeof doValidateDisplayName === "function") {
      doValidateDisplayName({
        "username": displayName
      });
    }
  }

  _approveRejectSeller = async (status = 0) => {
    const { updateLoadingState, intl } = this.props;
    const { selectedUser } = this.state;

    if (_isEmpty(selectedUser) || _get(selectedUser, "email", "") === "") {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));

      return;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await approveRejectSeller({ sellerId: _get(selectedUser, "id", 0), status: (status || 0) });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User profile status changed successfully."), "success");

        this._fetchTableData();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while changing user's profile status."));
      }

      this.setState({ showUnderReviewModal: false, showApproveModal: false, showRejectModal: false, showSuspendedModal: false, selectedUser: null });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching approving/rejecting seller.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _lockUnlockUser = async (lockUnlcokStatus = 0) => {
    const { updateLoadingState, intl } = this.props;
    const { selectedUser } = this.state;

    if (_isEmpty(selectedUser) || _get(selectedUser, "email", "") === "") {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));

      return;
    }

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await lockUnlockUser({ userId: _get(selectedUser, "id", 0), lockStatus: (lockUnlcokStatus || 0) });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User status changed successfully."), "success");

        this._fetchTableData();
      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while changing user status."));
      }

      this.setState({ showLockedModal: false, showUnlockedModal: false, showRestoreModal: false, selectedUser: null });
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while changing user status.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleDeleteUser = async () => {
    const { updateLoadingState } = this.props;
    const { selectedUser } = this.state;

    if (typeof updateLoadingState === "function") { updateLoadingState(true); }

    try {
      const response = await deleteTeamMember({ "userId": _get(selectedUser, "id", null) });

      const resData = _get(response, "data", {});

      if (_get(resData, "status", false) === true) {

        this._fetchTableData();

        showAlertMessage(_get(resData, "data.message", "User deleted successfully."), "success");

        this.setState({ showDeleteModal: false, selectedUser: null });
      } else {
        showAlertMessage(_get(resData, "data.message", "Something went wrong while deleting user."));
      }
    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while deleting user."));
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleResetUserPassword = async () => {
    const { doForgotPassword, intl } = this.props;
    const { selectedUser } = this.state;

    if (_isEmpty(selectedUser) || _get(selectedUser, "email", "") === "") {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "user", defaultMessage: "user" })
      }));

      return;
    }

    try {

      if (typeof doForgotPassword === "function") { doForgotPassword({ email: _get(selectedUser, "email", ""), subdomain: this.legalUrl }); }

    } catch (error) {

      showAlertMessage(_get(error, "message", "Something went wrong while generating reset password link."));
    }
  }

  _renderTopFilters = () => {
    const { intl } = this.props;
    const { searchText, filters: { roleId, profileStatus } } = this.state;

    return (
      <div className="row mb-25">
        <div className="col-lg-4">
          <div className="position-relative mb-lg-0 mb-15">
            <span className="img-fluid position-absolute end-0 top-50 translate-middle-y me-20">
              <IconSearch width="14px" height="14px" color="#9d9b9b" />
            </span>
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder="Search"
              value={(searchText || "")}
              onChange={(e) => {
                const val = _get(e, "target.value", "");

                this.setState({ "searchText": val });
              }}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="text-capitalize form-custom-select"
              placeholder={intl.formatMessage({ id: "user_management.status", defaultMessage: "Status" })}
              value={(profileStatus || null)}
              options={(userProfileStatuses || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id === null) ? (option.id || null) : (option.id || 0)}
              onChange={(y) => this.setState((prevState) => ({ filters: { ...prevState.filters, "profileStatus": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>

        <div className="col-lg">
          <div className="position-relative mb-lg-0 mb-15">
            <Select
              className="text-capitalize form-custom-select"
              placeholder={intl.formatMessage({ id: "role", defaultMessage: "Role" })}
              value={(roleId || null)}
              options={(USER_MANAGEMENT_ROLES || [])}
              getOptionLabel={(option) => (option.name || "")}
              getOptionValue={(option) => (option.id || null)}
              onChange={(y) => this.setState((prevState) => ({ filters: { ...prevState.filters, "roleId": (y || null) } }))}
              isMulti={false}
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="text-end mb-lg-0 mb-15">
            <button type="button" className="btn btn-md btn-secondary" onClick={() => this.setState({ filters: { ...this.defaultFilters }, searchText: "" })} >
              <FormattedMessage id="btn.reset" defaultMessage="reset" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy: sortBy, sort: sortDirection });
  };

  _renderTableData = () => {
    const { intl } = this.props;
    const { tableData, sort, sortBy } = this.state;

    return (
      <div className="col-lg-12">
        <div className="card p-15">
          <div className="table-data position-relative h-500 overflow-x-auto">
            <AutoSizer className="table-autosizer-wrapper">
              {({ height, width }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={32}
                  rowHeight={32}
                  /*onScroll={this._projectListHandleScroll}*/
                  sort={(sort) => this._sort(sort)}
                  sortBy={(sortBy || "first_name")}
                  sortDirection={(sort || "ASC")}
                  rowCount={(tableData || []).length}
                  rowGetter={({ index }) => (tableData[index] || {})}
                  noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
                >
                  {(this.columns || []).map((column, key) => (
                    <Column key={key} {...column} width={width / (column.columnAvrgWidth || 8)} />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }

  _closeCreateUserModal = () => {
    this.setState({ showCreateModal: false, createUser: _cloneDeep(this.defaultCreateUserState), errors: {}, showDisplayNameLink: 1 });
  }

  _renderCreateUser = () => {
    const { intl } = this.props;
    const { errors, showCreateModal, showDisplayNameLink, createUser: { firstName, lastName, email, phoneNumber, displayName, company, password, confirmPassword, role } } = this.state;

    return (
      <Modal
        size="lg"
        modalTitle={intl.formatMessage({ id: "user_management.create_user.title", defaultMessage: "Create User" })}
        onHide={() => this._closeCreateUserModal()}
        onClose={() => this._closeCreateUserModal()}
        onSubmit={() => this._handleCreateUser()}
        centered={true}
        showCloseButton={true}
        isOpen={(showCreateModal || false)}
      >
        <div className="section-create-user">
          <div className="container">
            <div className="row">
              <Form autoComplete="off" onSubmit={(e) => this._createUser(e)}>
                <div className="row mb-16">
                  <div className="col-lg-12">
                    <p className="mb-16 heading-04 text-capitalize">
                      <FormattedMessage id="user_management.personal_details" defaultMessage="Personal Details" />
                    </p>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="text" name="firstName" value={(firstName || "")} className="form-control border" placeholder="First Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.firstName || "") && <ErrorMessage message={(errors.firstName || "")} />}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="text" name="lastName" value={(lastName || "")} className="form-control border" placeholder="Last Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.lastName || "") && <ErrorMessage message={(errors.lastName || "")} />}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="email" name="email" value={(email || "")} className="form-control border" placeholder="Email" onChange={(e) => this._handleChange(e)} />
                      {(errors.email || "") && <ErrorMessage message={(errors.email || "")} />}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="text" name="phoneNumber" value={(phoneNumber || "")} className="form-control border" placeholder="Phone Number" onChange={(e) => this._handleChange(e)} />
                      {(errors.phoneNumber || "") && (<ErrorMessage message={(errors.phoneNumber || "")} />)}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="text" name="company" value={(company || "")} className="form-control border" placeholder="Company" onChange={(e) => this._handleChange(e)} />
                      {(errors.company || "") && (<ErrorMessage message={(errors.company || "")} />)}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16 position-relative">

                      {((showDisplayNameLink || 1) === 3) && (
                        <span className="mt-10 me-16 position-absolute top-0 end-0">
                          <IconClose height="18px" width="18px" color="#d50100" />
                        </span>
                      )}

                      {((showDisplayNameLink || 1) === 2) && (
                        <span className="mt-10 me-16 position-absolute top-0 end-0">
                          <IconSmDone height="18px" width="18px" color="#198754" />
                        </span>
                      )}

                      {((showDisplayNameLink || 1) === 1) && (
                        <div className="mt-10 me-16 position-absolute top-0 end-0">
                          <NavLink to="#" className="heading-05 text-decoration-underline text-primary" onClick={() => this._doValidateDisplayName()}>
                            <FormattedMessage id="btn.check_display_name" defaultMessage="check availability" />
                          </NavLink>
                        </div>
                      )}

                      <input type="text" name="displayName" value={(displayName || "")} className="form-control border" placeholder="Display Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.displayName || "") && (<ErrorMessage message={(errors.displayName || "")} />)}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="password" name="password" value={(password || "")} className="form-control border" placeholder="Password" onChange={(e) => this._handleChange(e)} />
                      {(errors.password || "") && (<ErrorMessage message={(errors.password || "")} />)}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <input type="password" name="confirmPassword" value={(confirmPassword || "")} className="form-control border" placeholder="Confirm Password" onChange={(e) => this._handleChange(e)} />
                      {(errors.confirmPassword || "") && (<ErrorMessage message={(errors.confirmPassword || "")} />)}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-16">
                      <Select
                        className="text-capitalize form-custom-select form-custom-lg-select border"
                        placeholder={intl.formatMessage({ id: "role", defaultMessage: "Role" })}
                        defaultValue={(role || null)}
                        options={(USER_MANAGEMENT_ROLES || [])}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(y) => { this.setState((prevState) => ({ createUser: { ...prevState.createUser, "role": (y || null) } })) }}
                        isMulti={false}
                        error={(errors.role || "")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-end mt-20 position-relative">
                        <button type="submit" className="btn btn-sm btn-primary">
                          <FormattedMessage id="btn.save" defaultMessage="Save" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { intl } = this.props;
    const { showDeleteModal, showEditModal, selectedUser, showApproveModal, showUnderReviewModal, showSuspendedModal, showRejectModal, showResetPasswordModal, showLockedModal, showUnlockedModal, showRestoreModal } = this.state;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "LU.user_management", defaultMessage: "User Management - Leads United" })} </title>
        </Helmet>

        <div>
          <div className="row section-heading mb-12">
            <div className="col-lg">
              <h1 className="heading-03 fw-medium text-capitalize">
                <FormattedMessage id="user_management.title" defaultMessage="User Management" />
              </h1>
            </div>
            <div className="col-lg">
              <div className="text-end mb-lg-0 mb-15">
                <button type="button" className="btn btn-md btn-primary" onClick={() => this.setState({ showCreateModal: true })}>
                  <FormattedMessage id="btn.create_section" defaultMessage="create {section}" values={{ section: intl.formatMessage({ id: "user", defaultMessage: "User" }) }} />
                </button>
              </div>
            </div>
          </div>

          {this._renderTopFilters()}

          <div className="row">
            {this._renderTableData()}
          </div>
        </div>

        <ConfirmBox
          isOpen={(showDeleteModal || false)}
          onConfirm={() => this._handleDeleteUser()}
          onClose={() => this.setState({ showDeleteModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_delete", defaultMessage: "Are you sure you want to delete {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showUnderReviewModal || false)}
          onConfirm={() => this._approveRejectSeller(_get(userProfileStatusLookup, "UNDER_REVIEW.id", null))}
          onClose={() => this.setState({ showUnderReviewModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_under_review", defaultMessage: "Are you sure you want to under review {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showApproveModal || false)}
          onConfirm={() => this._approveRejectSeller(_get(userProfileStatusLookup, "APPROVED.id", null))}
          onClose={() => this.setState({ showApproveModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_approve", defaultMessage: "Are you sure you want to approve {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showRejectModal || false)}
          onConfirm={() => this._approveRejectSeller(_get(userProfileStatusLookup, "REJECTED.id", null))}
          onClose={() => this.setState({ showRejectModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_reject", defaultMessage: "Are you sure you want to reject {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showSuspendedModal || false)}
          onConfirm={() => this._approveRejectSeller(_get(userProfileStatusLookup, "SUSPENDED.id", null))}
          onClose={() => this.setState({ showSuspendedModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_suspend", defaultMessage: "Are you sure you want to suspend {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showResetPasswordModal || false)}
          onConfirm={() => this._handleResetUserPassword()}
          onClose={() => this.setState({ showResetPasswordModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_reset_password", defaultMessage: "Are you sure you want to reset password for {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showLockedModal || false)}
          onConfirm={() => this._lockUnlockUser(_get(userStatusesLookup, "LOCKED.id", null))}
          onClose={() => this.setState({ showLockedModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_lock", defaultMessage: "Are you sure you want to lock {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showUnlockedModal || false)}
          onConfirm={() => this._lockUnlockUser(_get(userStatusesLookup, "UNLOCKED.id", null))}
          onClose={() => this.setState({ showUnlockedModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_unlock", defaultMessage: "Are you sure you want to unlock {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        <ConfirmBox
          isOpen={(showRestoreModal || false)}
          onConfirm={() => this._lockUnlockUser(_get(userStatusesLookup, "UNLOCKED.id", null))}
          onClose={() => this.setState({ showRestoreModal: false, selectedUser: null })}
          content={intl.formatMessage({ id: "confirm.are_you_sure_restore", defaultMessage: "Are you sure you want to restore {field}?" }, { field: _get(selectedUser, "user_name", "") })}
        />

        {this._renderCreateUser()}

        <EditUser
          showEditModal={(showEditModal || false)}
          selectedUserId={_get(selectedUser, "id", null)}
          setState={(args) => this.setState(args)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  error: _get(state, "oauth.error", {}),
  tableData: _get(state, "users.list", {}),
  success: _get(state, "oauth.success", {}),
  validateDisplayName: _get(state, "oauth.validateDisplayName", {})
});

const mapDispatchToProps = (dispatch) => ({
  doValidateDisplayName: (data) => dispatch(doValidateDisplayName(data)),
  clearValidateDisplayName: () => dispatch(clearValidateDisplayName()),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  doForgotPassword: (data) => dispatch(doForgotPassword(data)),
  clearOauthSuccess: () => dispatch(clearOauthSuccess()),
  clearOauthError: () => dispatch(clearOauthError()),
  resetUsersData: () => dispatch(resetUsersData()),
  fetchUsers: (data) => dispatch(fetchUsers(data)),
  doSignUp: (data) => dispatch(doSignUp(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagement)));