import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { NavDropdown } from "react-bootstrap";

import config from "../../../helpers/config";

export default React.memo(function Header(props) {

  const { isLoggedIn, firstName } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-white" aria-label="Leads United">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={require("../../../assets/images/svg/logo.svg")} className="img-fluid" alt="Leads United" />
          </Link>
          <button
            className={`navbar-toggler ${(showMenu === true) ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobileMenu"
            aria-controls="navbarmobileMenu"
            aria-expanded={(showMenu === true) ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`navbar-collapse collapse ${(showMenu === true) ? "show" : ""}`} id="mobileMenu">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/">
                  <FormattedMessage id="menu.home" defaultMessage="home" />
                </NavLink>
              </li>

              <NavDropdown activeclassname="active" className="text-capitalize" title="Solutions">
                <NavDropdown.Item className="dropdown-item text-capitalize" href="/buy-leads">
                  <FormattedMessage id="menu.solutions.buy_leads" defaultMessage="buy leads" />
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item text-capitalize" href="/sell-leads">
                  <FormattedMessage id="menu.solutions.sell_leads" defaultMessage="sell leads" />
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item text-capitalize" href="/integrate">
                  <FormattedMessage id="menu.solutions.integrate" defaultMessage="integrate" />
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item text-capitalize" href="/enrich">
                  <FormattedMessage id="menu.solutions.enrich" defaultMessage="enrich" />
                </NavDropdown.Item>
              </NavDropdown>

              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/pricing">
                  <FormattedMessage id="menu.pricing" defaultMessage="pricing" />
                </NavLink>
              </li>

              <li className="nav-item text-capitalize">
                <NavLink activeclassname="active" className="nav-link" to="/customers">
                  <FormattedMessage id="menu.customers" defaultMessage="customers" />
                </NavLink>
              </li>

              <NavDropdown className="text-capitalize" title="Company">
                <NavDropdown.Item activeclassname="active" className="dropdown-item text-capitalize" href="/about-us">
                  <FormattedMessage id="menu.company.about_us" defaultMessage="about us" />
                </NavDropdown.Item>
                <NavDropdown.Item activeclassname="active" className="dropdown-item text-capitalize" href="/blog">
                  <FormattedMessage id="menu.company.blog" defaultMessage="blog" />
                </NavDropdown.Item>
                <NavDropdown.Item activeclassname="active" className="dropdown-item text-capitalize" href="/contact-us">
                  <FormattedMessage id="menu.company.contact" defaultMessage="contact" />
                </NavDropdown.Item>
              </NavDropdown>
            </ul>

            <ul className="navbar-nav align-items-center justify-content-end navbar-nav-login">
              {(isLoggedIn === false) && (
                <>
                  <li className="nav-item text-capitalize">
                    <NavLink activeclassname="active" className="nav-link" to="/login">
                      <FormattedMessage id="menu.login" defaultMessage="login" />
                    </NavLink>
                  </li>
                  <li className="nav-item text-capitalize">
                    <Link className="btn btn-sm btn-primary" to="/sign-up">
                      <FormattedMessage id="menu.sign_up" defaultMessage="sign up now" />
                    </Link>
                  </li>
                </>
              )}

              {(isLoggedIn === true) && (
                <NavDropdown className="text-capitalize" title={(firstName || "User")}>
                  <NavDropdown.Item className="dropdown-item text-capitalize" href={config.getAdminURL(true, true, "/dashboard")}>
                    <FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" />
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item text-capitalize" href={config.getAdminURL(true, true, "/settings")}>
                    <FormattedMessage id="menu.settings" defaultMessage="Settings" />
                  </NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item text-capitalize" href={config.getAdminURL(true, true, "/logout")}>
                    <FormattedMessage id="menu.logout" defaultMessage="Logout" />
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </ul>
          </div>
        </div>
      </nav >
    </header >
  );
});