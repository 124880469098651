import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEqual as _isEqual } from "lodash";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../../components/hoc/withRouter";

import { IconDots } from "../../../helpers/iconStyles";

import { fetchProjectDashboardOverallRecords } from "../../../actions/leads";

import ProjectQuotes from "./components/ProjectQuotes";

class Dashboard extends Component {

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0))) {

      this._fetchProjectDashboardOverallRecords();
    }

    if (!_isEqual(prevProps.activeBuyerTab, this.props.activeBuyerTab) && (this.props.activeBuyerTab !== null)) {
      this._fetchDashboardTopFilters();
    }
  }

  _fetchProjectDashboardOverallRecords = () => {
    const { fetchProjectDashboardOverallRecords, dashboardFiltersSelection } = this.props;

    if (typeof fetchProjectDashboardOverallRecords === "function") {
      fetchProjectDashboardOverallRecords({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        fromDate: null,
        toDate: null,
        sellerId: null
      });
    }
  }

  _renderBuyBox = () => {
    const { projectDashboardOverallRecords, currencySymbol, setStateFromChild } = this.props;

    return (
      <div className="col-lg-12">
        <div className="row g-12 mb-15">
          {_get(projectDashboardOverallRecords, "data.dashboard_numbers", []).map((data, i) => {
            let onClickTiles = {};
            let className = "";
            if (_get(data, "dashboard_text", "") === "Pending Leads") {
              onClickTiles = { activeTab: "data", activeTabForData: "view_data", filterStatus: 0 }
              className = "cursor-pointer";
            } else if (_get(data, "dashboard_text", "") === "Approved Leads") {
              onClickTiles = { activeTab: "data", activeTabForData: "view_data", filterStatus: 1 }
              className = "cursor-pointer";
            } else if (_get(data, "dashboard_text", "") === "Rejected Leads") {
              onClickTiles = { activeTab: "data", activeTabForData: "view_data", filterStatus: 2 }
              className = "cursor-pointer";
            }

            return (
              <div key={`boxes-${i}`} className={`col-xl-2 col-lg-4 ${className}`} onClick={() => setStateFromChild(onClickTiles)}>
                <div className="card p-12 text-center">
                  <p className={`heading-05 fw-medium ${_get(data, "css_class", "")}`}>{_get(data, "dashboard_text", "")}</p>
                  <h5 className="heading-07 lh-1 mt-15">{_get(data, "dashboard_number", 0)}</h5>
                </div>
              </div>
            );
          })}

          {_get(projectDashboardOverallRecords, "data.funds_details", []).map((fund, i) => {

            return (
              <div key={`box-fund-${i}`} className="col-xl-2 col-lg-4">
                <div className="card p-12 text-center">
                  <p className="heading-05 fw-medium text-capitalize text-grey-9d">{_get(fund, "dashboard_text", "")}</p>
                  <h5 className="heading-07 lh-1 mt-15">{`${currencySymbol}${_get(fund, "dashboard_number", 0)}`}</h5>
                  <p className="d-flex justify-content-between position-absolute bottom-0 start-0 heading-06 w-100 opacity-25 px-12 pb-4">
                    <span className={`text-capitalize ${(_get(fund, "desposited", 0) === 1) ? "text-success" : ""}`}>
                      <FormattedMessage id="dashboard.project.dashboard.deposited" defaultMessage="Deposited" />
                    </span>
                    <span className={`text-capitalize ${(_get(fund, "released", 0) === 1) ? "text-success" : ""}`}>
                      <FormattedMessage id="dashboard.project.dashboard.released" defaultMessage="Released" />
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div >
    )
  }

  _renderProjectStatus = () => {
    return (
      <div className="col-lg-12 mt-20">
        <div className="card p-15">
          <div className="d-flex flex-column flex-sm-row mb-15 gap-15 border-bottom border-secondary pb-4">
            <h4 className="heading-03 fw-medium text-primary text-capitalize">
              <FormattedMessage id="dashboard.title.project_status" defaultMessage="project status" />
            </h4>

            <div className="ms-lg-auto">
              <IconDots height="20" width="4" color="#e84968" />
            </div>
          </div>

          <div className="project-status-timeline">
            <div className="project-status-timeline-item done">
              <span>1</span>
              <span>Buy Leads Request Submitted</span>
            </div>

            <div className="project-status-timeline-item done">
              <span>2</span>
              <span>Sellers Notified</span>
            </div>

            <div className="project-status-timeline-item done">
              <span>3</span>
              <span>Quotes Received</span>
            </div>

            <div className="project-status-timeline-item pending">
              <span>4</span>
              <span>Quotes Accepted*</span>
            </div>

            <div className="project-status-timeline-item">
              <span>5</span>
              <span>Funds Deposited*</span>
            </div>

            <div className="project-status-timeline-item">
              <span>6</span>
              <span>Data Received</span>
            </div>

            <div className="project-status-timeline-item">
              <span>7</span>
              <span>Data Checked*</span>
            </div>

            <div className="project-status-timeline-item">
              <span>8</span>
              <span>Funds Released</span>
            </div>

            <div className="project-status-timeline-item">
              <span>9</span>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { setStateFromChild } = this.props;

    return (
      <>
        {this._renderBuyBox()}

        {this._renderProjectStatus()}

        <ProjectQuotes
          source="dashboard"
          setStateFromChild={(args) => {
            if (typeof setStateFromChild === "function") { setStateFromChild(args); }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  projectDashboardOverallRecords: _get(state, "leads.dashboard.projectDashboardOverallRecords", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", "$ "),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectDashboardOverallRecords: (data) => dispatch(fetchProjectDashboardOverallRecords(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard)));