import React, { Component } from "react";
import { get as _get, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { Column, Table, AutoSizer } from "react-virtualized";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { showAlertMessage, NoDataMessage, TableHeaderRenderer, TableCellRenderer } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { projectStatusLookup, USER_ROLES } from "../../../helpers/constants";

import { fetchMarketplaceSavedCriteriaAndList, resetMarketplaceSavedCriteriaAndListData, updateLoadingState } from "../../../actions/application";
import { UpdateDashboardFilterSelection } from "../../../actions/leads";

class LatestOffering extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.buyerColumns = [
      {
        id: "project_name",
        width: 0,
        columnAvrgWidth: 3,
        dataKey: "project_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.project_name", defaultMessage: "project name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "delivery_date",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "delivery_date",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.delivery_date", defaultMessage: "delivery date" })} />),
        cellRenderer: (row) => {
          const { displayDateFormat } = props;
          const date = _get(row, "cellData", null);

          return (<TableCellRenderer tooltip={true} value={(date) ? moment(date).format(displayDateFormat) : ""} />)
        }
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 12,
        dataKey: "status",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.status", defaultMessage: "status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "cellData", "").toUpperCase();

          const statusClass = _get(projectStatusLookup, `[${value}.statusClass]`, "");

          return (
            <span className={statusClass}><TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} /></span>
          );
        }
      },
      {
        id: "leads_volume",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "leads_volume",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.volume_of_leads", defaultMessage: "volume of leads" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "budget",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "budget",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.budget", defaultMessage: "Budget" })} />),
        cellRenderer: (row) => {
          const { currencySymbol } = props;

          return (<TableCellRenderer tooltip={true} value={`${currencySymbol}${_get(row, "cellData", null)}`} />)
        }
      },
      {
        id: "id",
        width: 0,
        columnAvrgWidth: 6,
        dataKey: "id",
        headerRenderer: () => (<TableHeaderRenderer sortable={false} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.details", defaultMessage: "details" })} />),
        cellRenderer: (row) => (
          <span className="d-block text-blue-1a text-decoration-underline text-capitalize cursor-pointer" onClick={() => this.showProjectDashboard(row)}>
            <FormattedMessage id="dashboard.more" defaultMessage="more..." />
          </span>
        )
      },
    ];

    this.sellerColumns = [
      {
        id: "buyer_name",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "buyer_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.buyer", defaultMessage: "buyer" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "project_name",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "project_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.project_name", defaultMessage: "project name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "delivery_date",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "delivery_date",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.delivery_date", defaultMessage: "delivery date" })} />),
        cellRenderer: (row) => {
          const { displayDateFormat } = props;
          const date = _get(row, "cellData", null);

          return (<TableCellRenderer tooltip={true} value={(date) ? moment(date).format(displayDateFormat) : ""} />)
        }
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "status",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.status", defaultMessage: "status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "cellData", "").toUpperCase();

          const statusClass = _get(projectStatusLookup, `[${value}.statusClass]`, "");

          return (
            <span className={statusClass}><TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} /></span>
          );
        }
      },
      {
        id: "leads_volume",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "leads_volume",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.volume_of_leads", defaultMessage: "volume of leads" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "budget",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "budget",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "marketplace.latest_offerings.table.budget", defaultMessage: "Budget" })} />),
        cellRenderer: (row) => {
          const { currencySymbol } = props;

          return (<TableCellRenderer tooltip={true} value={`${currencySymbol}${_get(row, "cellData", null)}`} />)
        }
      },
      {
        id: "id",
        width: 0,
        columnAvrgWidth: 7,
        dataKey: "id",
        disableSort: true,
        headerRenderer: () => (<TableHeaderRenderer value={intl.formatMessage({ id: "marketplace.latest_offerings.table.details", defaultMessage: "details" })} />),
        cellRenderer: (row) => (
          <span className="d-block text-blue-1a text-decoration-underline text-capitalize cursor-pointer" onClick={() => this.showProjectDashboard(row)}>
            <FormattedMessage id="dashboard.more" defaultMessage="more..." />
          </span>
        )
      },
    ];

    this.state = {
      latestOfferingProjects: [],
      sortDirection: "ASC",
      sortBy: "project_name",
      pageNo: 1,
      pageSize: 100
    };

    this.timer = null;
  }

  componentDidMount() {
    const { getSavedCriteriaAndList } = this.props;

    this._fetchLatestOfferingList();

    this.setState({ latestOfferingProjects: _get(getSavedCriteriaAndList, "data.project_list", []) });
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      !_isEqual(prevState.sortBy, this.state.sortBy) ||
      !_isEqual(prevState.sortDirection, this.state.sortDirection) ||
      !_isEqual(prevProps.selectedSearchId, _get(this.props, "selectedSearchId", null))
    ) {

      this._fetchLatestOfferingList();
    }

    if (!_isEqual(prevProps.searchText, _get(this.props, "searchText", null))) {

      clearTimeout(this.timer);
      this.timer = setTimeout(() => this._fetchLatestOfferingList(), 1000);
    }

    if (!_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0))) {

      return this.props.navigate("/dashboard");
    }

    if (!_isEqual(prevProps.getSavedCriteriaAndList, this.props.getSavedCriteriaAndList) && !_isEmpty(this.props.getSavedCriteriaAndList)) {
      const { getSavedCriteriaAndList, resetMarketplaceSavedCriteriaAndListData } = this.props;

      if (_get(getSavedCriteriaAndList, "status", null) === null) { return false; }

      if (_get(getSavedCriteriaAndList, "status", false) === true) {

        this.setState({ latestOfferingProjects: _get(getSavedCriteriaAndList, "data.project_list", []) });
      } else {
        showAlertMessage(getSavedCriteriaAndList.message || "Something went wrong while fetching project list.");

        if (typeof resetMarketplaceSavedCriteriaAndListData === "function") { resetMarketplaceSavedCriteriaAndListData(); }
      }
    }
  }

  showProjectDashboard = (row) => {
    const { UpdateDashboardFilterSelection } = this.props;

    if (typeof UpdateDashboardFilterSelection === "function") {
      UpdateDashboardFilterSelection({ key: "selectedProject", value: { id: _get(row, "rowData.project_id", 0), project_name: _get(row, "rowData.project_name", "Select") } });
    }
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy: sortBy, sortDirection: sortDirection });
  };

  _fetchLatestOfferingList = () => {
    const { fetchMarketplaceSavedCriteriaAndList, selectedSearchId, searchText } = this.props;
    const { sortBy, sortDirection, pageSize, pageNo } = this.state;

    if (typeof fetchMarketplaceSavedCriteriaAndList === "function") {
      fetchMarketplaceSavedCriteriaAndList({
        "searchId": (_get(selectedSearchId, "id", null) === 0) ? null : _get(selectedSearchId, "id", null),
        "sort": (sortDirection || "ASC"),
        "sortBy": sortBy,
        "pageNo": (pageNo || 1),
        "pageSize": (pageSize || 100),
        "searchText": (searchText || null)
      })
    }
  }

  render() {
    const { roleId, intl } = this.props;
    const { latestOfferingProjects, sortBy, sortDirection } = this.state;

    return (
      <div className="table-responsive">
        <div className="table-data position-relative h-500 overflow-x-auto">
          <AutoSizer className="table-autosizer-wrapper">
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                headerHeight={32}
                rowHeight={32}
                sort={(sort) => this._sort(sort)}
                sortBy={(sortBy || "project_name")}
                sortDirection={(sortDirection || "ASC")}
                rowCount={(latestOfferingProjects || []).length}
                rowGetter={({ index }) => (latestOfferingProjects[index] || {})}
                noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
              >
                {([_get(USER_ROLES, "SELLER.id", 0), _get(USER_ROLES, "SELLER_MEMBER.id", 0)].includes(roleId)) && (
                  (this.sellerColumns || []).map((column, key) => (
                    <Column key={`projectlist-${key}`} {...column} width={width / (column.columnAvrgWidth || 7)} />
                  ))
                )}
                {([_get(USER_ROLES, "ADMIN.id", 0), _get(USER_ROLES, "BUYER.id", 0), _get(USER_ROLES, "BUYER_MEMBER.id", 0)].includes(roleId)) && (
                  (this.buyerColumns || []).map((column, key) => (
                    <Column key={`filter-${key}`}{...column} width={width / (column.columnAvrgWidth || 6)} />
                  ))
                )}
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roleId: _get(state, "oauth.user.role_id", 0),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", ""),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  getSavedCriteriaAndList: _get(state, "application.marketPlace.getSavedCriteriaAndList", {})
});

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (loader) => dispatch(updateLoadingState(loader)),
  UpdateDashboardFilterSelection: (data) => dispatch(UpdateDashboardFilterSelection(data)),
  fetchMarketplaceSavedCriteriaAndList: (data) => dispatch(fetchMarketplaceSavedCriteriaAndList(data)),
  resetMarketplaceSavedCriteriaAndListData: () => dispatch(resetMarketplaceSavedCriteriaAndListData())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(LatestOffering)));