import React, { Component } from "react";
import { Column, Table, AutoSizer } from "react-virtualized";
import { get as _get, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { withRouter } from "../../../../components/hoc/withRouter";

import { quoteStatusLookup } from "../../../../helpers/constants";

import ViewQuote from "../../components/ViewQuote";

import { NoDataMessage, TableHeaderRenderer, TableCellRenderer, showAlertMessage } from "../../../../components/common/controls";

import { fetchDashboardProjectQuoteDetails, resetDashboardProjectQuote, setQuoteGroupID, setQuoteID } from "../../../../actions/leads";

class ProjectQuotes extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.state = {
      sortDirection: "ASC",
      sortBy: "project_name",
      tableData: [],
      showDetail: false,
    };

    this.columns = [
      {
        id: "quote_group_id",
        width: 0,
        columnAvrgWidth: 5,
        dataKey: "quote_group_id",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.quote_id", defaultMessage: "Quote ID" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "seller_name",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "seller_name",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.seller_name", defaultMessage: "seller name" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "delivery_date",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "delivery_date",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.delivery_date", defaultMessage: "delivery date" })} />),
        cellRenderer: (row) => {
          const { displayDateFormat } = this.props;
          const date = moment(_get(row, "cellData", null)).format(displayDateFormat);

          return (<TableCellRenderer value={(date || "")} tooltip={true} />);
        },
      },
      {
        id: "status",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "status",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.status", defaultMessage: "status" })} />),
        cellRenderer: (row) => {
          const value = _get(row, "cellData", "").toUpperCase();

          const statusClass = _get(quoteStatusLookup, `[${value}.statusClass]`, "");

          return (
            <span className={statusClass}><TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} /></span>
          );
        }
      },
      {
        id: "lead_volume",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "lead_volume",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.volume_of_leads", defaultMessage: "volume of leads" })} />),
        cellRenderer: (row) => (<TableCellRenderer value={_get(row, "cellData", "")} tooltip={true} />),
      },
      {
        id: "cost_per_lead",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "cost_per_lead",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.cost_per_lead", defaultMessage: "cost per lead" })} />),
        cellRenderer: (row) => {
          const { currencySymbol } = props;

          return (<TableCellRenderer tooltip={true} value={`${currencySymbol}${_get(row, "cellData", null)}`} />)
        }
      },
      {
        id: "total_price",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "total_price",
        headerRenderer: () => (<TableHeaderRenderer sortable={true} value={intl.formatMessage({ id: "dashboard.table.total_price", defaultMessage: "total price" })} />),
        cellRenderer: (row) => {
          const { currencySymbol } = props;

          return (<TableCellRenderer tooltip={true} value={`${currencySymbol}${_get(row, "cellData", null)}`} />)
        }
      },
      {
        id: "project_id",
        width: 0,
        columnAvrgWidth: 9,
        dataKey: "project_id",
        disableSort: true,
        headerRenderer: () => (<TableHeaderRenderer value={intl.formatMessage({ id: "dashboard.table.details", defaultMessage: "details" })} />),
        cellRenderer: (row) => {

          return (
            <span className="d-block text-blue-1a text-decoration-underline text-capitalize cursor-pointer" onClick={() => this.showQuoteDetailPage(row)}>
              <FormattedMessage id="dashboard.more" defaultMessage="more..." />
            </span>
          );
        }
      },
    ];
  }

  componentDidMount() {
    const { projectQuotes, setQuoteGroupID, setQuoteID } = this.props;

    this._fetchProjectQuotes();

    this.setState({ tableData: _get(projectQuotes, "data", []) });

    if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    if (typeof setQuoteID === "function") { setQuoteID(null); }
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedSeller.id", 0), _get(this.props, "dashboardFiltersSelection.selectedSeller.id", 0)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.startDate", null), _get(this.props, "dashboardFiltersSelection.startDate", null)) ||
      !_isEqual(_get(prevProps, "dashboardFiltersSelection.endDate", null), _get(this.props, "dashboardFiltersSelection.endDate", null)) ||
      !_isEqual(prevState.sortBy, this.state.sortBy) ||
      !_isEqual(prevState.sortDirection, this.state.sortDirection)
    ) {

      this._fetchProjectQuotes();
    }

    if (!_isEqual(prevProps.projectQuotes, this.props.projectQuotes)) {

      if (_get(this.props, "projectQuotes.status", null) === null) { return false; }

      if (_get(this.props, "projectQuotes.status", false) === true) {

        this.setState({ tableData: _get(this.props, "projectQuotes.data", []) });
      } else {
        showAlertMessage(_get(this.props, "projectQuotes.message", "Something went wrong while fetching project list."));
        if (typeof this.props.resetDashboardProjectQuote === "function") { this.props.resetDashboardProjectQuote(); }
      }
    }

    if (!_isEqual(prevProps.selectedQuoteGroupId, this.props.selectedQuoteGroupId) && (_get(this.props, "selectedQuoteGroupId", null) !== null)) {

      this.setState({ showDetail: true });
    }

    if (!_isEqual(prevState.showDetail, this.state.showDetail) && (_get(this.state, "showDetail", false) === false)) {
      const { setQuoteGroupID } = this.props;

      if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    }
  }

  _fetchProjectQuotes = () => {
    const { fetchDashboardProjectQuoteDetails, dashboardFiltersSelection, quoteStatus = null } = this.props;
    const { sortDirection, sortBy } = this.state;

    if (typeof fetchDashboardProjectQuoteDetails === "function") {
      fetchDashboardProjectQuoteDetails({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        sellerId: (_get(dashboardFiltersSelection, "selectedSeller.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedSeller.id", 0),
        fromDate: _get(dashboardFiltersSelection, "startDate", null),
        toDate: _get(dashboardFiltersSelection, "endDate", null),
        sortBy: sortBy,
        sort: sortDirection,
        status: (quoteStatus || null),
        pageSize: "100",
        pageNo: "1"
      });
    }
  }

  showQuoteDetailPage = (row) => {
    const { setQuoteGroupID, source = "", setStateFromChild } = this.props;

    if ((source === "dashboard") && (typeof setStateFromChild === "function")) {
      setStateFromChild({ activeTab: "quotes" });
    } else if (typeof setQuoteGroupID === "function") {
      setQuoteGroupID({ "id": _get(row, "rowData.quote_group_id", null), name: _get(row, "rowData.quote_group_id", null) });
    }
  }

  _sort = ({ sortBy, sortDirection }) => {

    this.setState({ sortBy, sortDirection });
  };

  render() {
    const { source = "dashboard", intl } = this.props;
    const { tableData, sortDirection, sortBy, showDetail } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12">

          {((showDetail || false) === false) && (
            <div className="card p-15">
              <div className="d-flex flex-column flex-sm-row justify-content-between mb-15 gap-15">
                <h4 className="heading-03 fw-medium text-primary text-capitalize">
                  {(["quoteTab", "workflow-accepted-quotes"].includes(source)) && (
                    <FormattedMessage id="dashboard.seller_quotes" defaultMessage="seller quotes" />
                  )}
                  {(source === "dashboard") && (
                    <FormattedMessage id="buyer_dashboard.tab.title.project_details" defaultMessage="project details" />
                  )}
                </h4>
              </div>
              <div className="table-responsive">
                <div className="table-data position-relative h-500 overflow-x-auto">
                  <AutoSizer className="table-autosizer-wrapper">
                    {({ height, width }) => (
                      <Table
                        width={width}
                        height={height}
                        headerHeight={32}
                        rowHeight={32}
                        sort={(sort) => this._sort(sort)}
                        sortBy={(sortBy || "project_name")}
                        sortDirection={(sortDirection || "ASC")}
                        rowCount={(tableData || []).length}
                        rowGetter={({ index }) => (tableData[index] || {})}
                        noRowsRenderer={() => (<NoDataMessage message={intl.formatMessage({ id: "message.no_records_found", defaultMessage: "no records found" })} />)}
                      >
                        {(this.columns || []).map((column, key) => (
                          <Column key={key} {...column} width={width / (column.columnAvrgWidth || 8)} />
                        ))}
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
          )}

          {((showDetail || false) === true) && (
            <ViewQuote setStateFromChild={(args) => this.setState(args)} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  projectQuotes: _get(state, "leads.dashboard.projectQuotes", {}),
  currencySymbol: _get(state, "application.constants.currencySymbol", ""),
  displayDateFormat: _get(state, "application.constants.displayDateFormat", "DD/MM/YYYY"),
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", null),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardProjectQuoteDetails: (data) => dispatch(fetchDashboardProjectQuoteDetails(data)),
  resetDashboardProjectQuote: () => dispatch(resetDashboardProjectQuote()),
  setQuoteGroupID: (id) => dispatch(setQuoteGroupID(id)),
  setQuoteID: (id) => dispatch(setQuoteID(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectQuotes)));