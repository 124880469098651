import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../../../../components/hoc/withRouter";

import { showAlertMessage, Modal } from "../../../../../components/common/controls";

import { quoteStatusLookup } from "../../../../../helpers/constants";

import { updateQuoteStatus, resetUpdateQuoteStatusData } from "../../../../../actions/leads";

class RejectQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: ""
    };
  }

  _updateRejectedQuoteStatus = () => {
    const { updateQuoteStatus, selectedQuoteGroupId, intl } = this.props;
    const { note } = this.state;

    if ((selectedQuoteGroupId || "") === "") {
      showAlertMessage(intl.formatMessage({ id: "error.select_atleast_one", defaultMessage: "Please select at least one {field}." }, {
        field: intl.formatMessage({ id: "quote", defaultMessage: "quote" })
      }));

      return false;
    }

    if (typeof updateQuoteStatus === "function") {
      updateQuoteStatus({
        quoteGroupId: (selectedQuoteGroupId || ""),
        status: _get(quoteStatusLookup, "REJECTED.id", 4),
        notes: (note || "")
      });
    }
  }

  _closeRejectDataModal = () => {
    const { setStateFromChild } = this.props;

    if (typeof setStateFromChild === "function") {
      setStateFromChild({ isOpenRejectQuoteModal: false });
    }
  }

  render() {
    const { isOpenRejectQuoteModal, intl } = this.props;
    const { note } = this.state;

    return (
      <Modal
        isOpen={isOpenRejectQuoteModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "buyer.workflow.reject_quote.title", defaultMessage: "Reject Quote" })}
        onHide={() => this._closeRejectQuoteModal()}
        onClose={() => this._closeRejectQuoteModal()}
      >
        <div className="row gy-20">
          <div className="col-lg-12">
            <div className="mb-35 text-capitalize">
              <label className="heading-05 mb-12"><FormattedMessage id="buyer.workflow.reject_quote.notes" defaultMessage="notes" /></label>
              <textarea
                className="form-control border rounded-3"
                placeholder={intl.formatMessage({ id: "buyer.workflow.reject_quote.notes", defaultMessage: "Notes" })}
                rowSpan="5"
                value={(note || "")}
                onChange={(e) => this.setState({ note: _get(e, "target.value", "") })}
              />
            </div>
            <div className="row gutters-5">
              <div className="col-lg-12">
                <div className="mt-16 text-end d-flex align-items-center justify-content-end gap-8 flex-md-row flex-column">
                  <button className="btn btn-secondary" onClick={() => this._closeRejectDataModal()}>
                    <FormattedMessage id="btn.cancel" defaultMessage="cancel" />
                  </button>
                  <button className="btn btn-primary" onClick={() => this._updateRejectedQuoteStatus()} >
                    <FormattedMessage id="view_quote.reject_quote" defaultMessage="reject quote" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedQuoteGroupId: _get(state, "leads.dashboard.selectedQuoteGroupId.id", ""),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuoteStatus: (data) => dispatch(updateQuoteStatus(data)),
  resetUpdateQuoteStatusData: () => dispatch(resetUpdateQuoteStatusData())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(RejectQuotes)));