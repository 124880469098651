import React from "react";

export const ErrorMessage = ({ message, className = "text-danger mt-5 ps-15 text-capitalize-first" }) => {
  return (
    <p className={(className || "")}>{(message || "")}</p>
  );
};

export const showContentLoader = (props) => {
  return (
    <div className={`loader ${(props.className || "")}`} />
  );
};