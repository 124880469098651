import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Select, DateRangePicker, showAlertMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { IconTabDiscussion, IconTabWorkflow, IconBuyerDashboard, IconInfo, IconQuote } from "../../../helpers/iconStyles";
import { USER_ROLES } from "../../../helpers/constants";

import { fetchDashboardTopFilters, resetDashboardTopFiltersData, UpdateDashboardFilterSelection, setQuoteGroupID, setQuoteID, fetchProjectDashboardOverallRecords, resetAllTabsData } from "../../../actions/leads";

import SubmitQuote from "./components/workflowComponents/SubmitQuote";
import ProjectDetails from "../components/ProjectDetails";
import OverallDashboard from "./OverallDashboard";
import Funds from "../components/Funds";
import Data from "../components/Data";
import Dashboard from "./Dashboard";

class Seller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "dashboard",
      dashboardTopFilters: {}
    };
  }

  componentDidMount() {
    const { setQuoteGroupID, setQuoteID } = this.props;

    this._fetchDashboardTopFilters();
    this._setDashboardTopFilters();

    if (typeof setQuoteGroupID === "function") { setQuoteGroupID(null); }
    if (typeof setQuoteID === "function") { setQuoteID(null); }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevProps.dashboardTopFilters, this.props.dashboardTopFilters) && !_isEmpty(this.props.dashboardTopFilters)) {
      const { dashboardTopFilters, resetDashboardTopFiltersData } = this.props;

      if ((dashboardTopFilters.status || null) === null) { return false; }

      if ((dashboardTopFilters.status || false) === true) {

        this._setDashboardTopFilters();
      } else {
        showAlertMessage((dashboardTopFilters.message || "Something went wrong while fetching dashboard top filters details."));

        if (typeof resetDashboardTopFiltersData === "function") { resetDashboardTopFiltersData(); }
      }
    }

    if (!_isEqual(_get(prevProps, "dashboardFiltersSelection.selectedProject.id", 0), _get(this.props, "dashboardFiltersSelection.selectedProject.id", 0))) {
      const { resetAllTabsData } = this.props;

      this._fetchDashboardTopFilters();

      this._fetchProjectDashboardOverallRecords();

      this._updateDashboardFilters("selectedBuyer", { id: 0, user_name: "Select" });

      if (typeof resetAllTabsData === "function") { resetAllTabsData(); }
      this.setState({ activeTab: "dashboard" })
    }

    if (!_isEqual(prevProps.selectEditQuoteGroupId, _get(this.props, "selectEditQuoteGroupId", {})) && !_isEmpty(_get(this.props, "selectEditQuoteGroupId.data", {}))) {
      this.setState({ activeTab: "workflow" });
    }

    if (!_isEqual(prevState.activeTab, this.state.activeTab) && (this.state.activeTab === "dashboard")) {
      this._fetchProjectDashboardOverallRecords();
    }
  }

  _setDashboardTopFilters = () => {
    const { dashboardTopFilters } = this.props;

    this.setState({ dashboardTopFilters: _get(dashboardTopFilters, "data", {}) });
  }

  _fetchDashboardTopFilters = () => {
    const { fetchDashboardTopFilters, /*dashboardFiltersSelection,*/ activeBuyerTab } = this.props;

    if (typeof fetchDashboardTopFilters === "function") {
      fetchDashboardTopFilters({
        projectId: null, //(_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        roleFlag: (activeBuyerTab || null)
      });
    }
  }

  _fetchProjectDashboardOverallRecords = () => {
    const { fetchProjectDashboardOverallRecords, dashboardFiltersSelection } = this.props;

    if (typeof fetchProjectDashboardOverallRecords === "function") {
      fetchProjectDashboardOverallRecords({
        projectId: (_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0) ? null : _get(dashboardFiltersSelection, "selectedProject.id", 0),
        fromDate: null,
        toDate: null,
        sellerId: null
      });
    }
  }

  _setDateRange = (startDate, endDate) => {

    this._updateDashboardFilters("startDate", (startDate || null));
    this._updateDashboardFilters("endDate", (endDate || null));
  }

  _updateDashboardFilters = (key, val) => {
    const { UpdateDashboardFilterSelection, setQuoteGroupID, setQuoteID } = this.props;

    if (typeof UpdateDashboardFilterSelection === "function") {
      UpdateDashboardFilterSelection({
        key: (key || null),
        value: (val || null)
      });
    }

    if (key === "selectedProject" && (_get(val, "quote_group_id", null) !== null) && (_get(val, "quote_group_id", null) !== null)) {

      setQuoteGroupID({ "id": _get(val, "quote_group_id", null), name: _get(val, "quote_group_id", null) });
      setQuoteID({ "id": _get(val, "quote_id", null), name: _get(val, "quote_id", null) });
    }
  }

  _renderTopFilter = () => {
    const { dashboardTopFilters, dashboardFiltersSelection, roleId, intl } = this.props;

    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4">
            <div className="mb-20">
              <label className="mb-12 text-capitalize"><FormattedMessage id="dashboard.project" defaultMessage="project" /></label>
              <div className="position-relative">
                <Select
                  className="text-capitalize form-custom-select"
                  placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                  options={_get(dashboardTopFilters, "data.projects", [])}
                  value={_get(dashboardFiltersSelection, "selectedProject", null)}
                  getOptionLabel={(option) => (option.project_name || "")}
                  getOptionValue={(option) => (option.id || null)}
                  onChange={(selectedOption) => this._updateDashboardFilters("selectedProject", (selectedOption || null))}
                />
              </div>
            </div>
          </div>

          {(roleId === _get(USER_ROLES, "ADMIN.id", 0)) && (
            <div className="col-lg-4">
              <div className="mb-20">
                <label className="mb-12 text-capitalize"><FormattedMessage id="dashboard.buyer" defaultMessage="buyer" /></label>
                <div className="position-relative">
                  <Select
                    className="text-capitalize form-custom-select"
                    placeholder={intl.formatMessage({ id: "select", defaultMessage: "select" })}
                    options={_get(dashboardTopFilters, "data.users", [])}
                    value={_get(dashboardFiltersSelection, "selectedBuyer", null)}
                    getOptionLabel={(option) => (option.user_name || "")}
                    getOptionValue={(option) => (option.id || null)}
                    onChange={(selectedOption) => this._updateDashboardFilters("selectedBuyer", (selectedOption || null))}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-4">
            <div className="mb-20">
              <label className="mb-12 text-capitalize"><FormattedMessage id="dashboard.date_range" defaultMessage="date range" /></label>
              <div className="position-relative">
                <DateRangePicker
                  onChange={(startDate, endDate) => this._setDateRange(startDate, endDate)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { dashboardFiltersSelection, activeBuyerTab } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <div className="row">
          {this._renderTopFilter()}

          {((_get(dashboardFiltersSelection, "selectedProject.id", 0) === 0)) ? (
            <OverallDashboard activeBuyerTab={activeBuyerTab} />
          ) : (
            <>

              <div className="col-md-12">
                <ul className="nav nav-tabs nav-tabs-secondary mb-20" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link text-capitalize ${(activeTab === "dashboard") ? "active" : ""}`} id="dashboard-tab" onClick={() => this.setState({ activeTab: "dashboard" })}>
                      <IconBuyerDashboard />
                      <FormattedMessage id="seller_dashboard.tab.title.dashboard" defaultMessage="Project Dashboard" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link text-capitalize ${(activeTab === "projectDetails") ? "active" : ""}`} id="summary-tab" onClick={() => this.setState({ activeTab: "projectDetails" })}>
                      <IconInfo />
                      <FormattedMessage id="seller_dashboard.tab.title.project_details" defaultMessage="Project Details" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link text-capitalize ${(activeTab === "quotes") ? "active" : ""}`} id="quotes-tab" onClick={() => this.setState({ activeTab: "quotes" })}>
                      <IconQuote />
                      <FormattedMessage id="seller_dashboard.tab.title.quotes" defaultMessage="Quotes" /> </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link text-capitalize ${(activeTab === "funds") ? "active" : ""}`} id="funds-tab" onClick={() => this.setState({ activeTab: "funds" })}>
                      <IconTabWorkflow />
                      <FormattedMessage id="buyer_dashboard.tab.title.funds" defaultMessage="funds" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link text-capitalize ${(activeTab === "data") ? "active" : ""}`} id="data-tab" onClick={() => this.setState({ activeTab: "data" })}>
                      <IconTabDiscussion />
                      <FormattedMessage id="buyer_dashboard.tab.title.data" defaultMessage="Data" />
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="dashboard" role="tabpanel">
                    {(activeTab === "dashboard") && (
                      <Dashboard setStateFromChild={(args) => this.setState(args)} />
                    )}

                    {(activeTab === "projectDetails") && (
                      <ProjectDetails />
                    )}

                    {(activeTab === "quotes") && (
                      <SubmitQuote source="quoteTab" />
                    )}

                    {(activeTab === "funds") && (
                      <Funds source="seller" />
                    )}

                    {(activeTab === "data") && (
                      <Data />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardTopFilters: _get(state, "leads.dashboard.dashboardTopFilters", {}),
  dashboardFiltersSelection: _get(state, "leads.dashboard.dashboardFiltersSelection", {}),
  selectEditQuoteGroupId: _get(state, "leads.workflowDetails.selectEditQuoteGroupId", {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectDashboardOverallRecords: (data) => dispatch(fetchProjectDashboardOverallRecords(data)),
  fetchDashboardTopFilters: (data) => dispatch(fetchDashboardTopFilters(data)),
  resetDashboardTopFiltersData: () => dispatch(resetDashboardTopFiltersData()),
  UpdateDashboardFilterSelection: (data) => dispatch(UpdateDashboardFilterSelection(data)),
  setQuoteGroupID: (id) => dispatch(setQuoteGroupID(id)),
  setQuoteID: (id) => dispatch(setQuoteID(id)),
  resetAllTabsData: () => dispatch(resetAllTabsData()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Seller)));